import S3 from 'aws-sdk/clients/s3'
import { makeObservable, observable, action, reaction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { refreshTrainingIndex } from '~/src/features/backend/manager'
import { env } from '~/src/features/backend/env'
import { isEmpty } from 'lodash'
import { TrainingDoesNotExist } from './errors'
import config from './config'

class PersistenceStore {
  lastSavedTrainingHash = undefined
  savingState = 'saved' // saved | saving | error

  constructor(rootStore) {
    this.rootStore = rootStore
    this.s3 = null

    makeObservable(this, {
      lastSavedTrainingHash: observable,
      savingState: observable,
      saveTraining: action,
    })
  }

  async initialize(forceRefresh = false) {
    if (this.s3 === null || forceRefresh) {
      const credentials = await this.rootStore.auth.currentCredentials()
      this.s3 = new S3({ ...config.s3, credentials })
    }
  }

  async userFilePath(path) {
    const userId = await this.rootStore.auth.currentUserId()
    if (path.startsWith(userId)) {
      return path
    } else {
      return `${userId}/${path}`
    }
  }

  // file api

  async retrieveRawFile(filePath, addUserPath = true) {
    await this.initialize()
    const res = await this.s3
      .getObject({
        Key: addUserPath ? await this.userFilePath(filePath) : filePath,
      })
      .promise()
    return res.Body
  }

  async retrieveFile(filePath) {
    try {
      const body = await this.retrieveRawFile(filePath)
      return body ? body.toString() : ''
    } catch (e) {
      console.error(e)
      return ''
    }
  }

  async persistFile(filePath, body) {
    await this.initialize()
    try {
      const key = await this.userFilePath(filePath)
      await this.s3.putObject({ Key: key, Body: body }).promise()
      return key
    } catch (e) {
      console.error(e)
    }
  }

  async persistJson(filePath, json) {
    return this.persistFile(filePath, JSON.stringify(json))
  }

  async retrieveJson(filePath) {
    try {
      const contents = await this.retrieveFile(filePath)
      return contents !== '' ? JSON.parse(contents) : {}
    } catch (e) {
      return {}
    }
  }

  async updateJson(filepath, callback) {
    const content = await this.retrieveJson(filepath)
    const result = callback(content)
    await this.persistJson(filepath, result)
  }

  async removeFile(filePath) {
    await this.initialize()
    try {
      const key = await this.userFilePath(filePath)
      await this.s3.deleteObject({ Key: key }).promise()
    } catch (e) {
      console.log(e)
    }
  }

  // user's files

  async uploadFile(file, path = '', progressHandler) {
    await this.initialize()
    const id = uuid()
    const objectName = `${path}${id}-${file.name}`.replace(/\s/g, '_')
    const managedUpload = new S3.ManagedUpload({
      service: this.s3,
      params: {
        Bucket: config.bucket,
        Key: await this.userFilePath(objectName),
        Body: file,
      },
    })
    if (progressHandler) {
      managedUpload.on('httpUploadProgress', ({ total, loaded }) => {
        progressHandler(loaded / total)
      })
    }
    return await managedUpload.promise()
  }

  async getTempLink(key) {
    const params = {
      Bucket: config.bucket,
      Key: await userFilePath(key),
      Expires: config.tempLinkDuration,
    }
    return this.s3.getSignedUrl('getObject', params)
  }

  getContentUrl(path) {
    if (path.startsWith('http')) {
      return path
    } else {
      return `${config.contentServer}/${path}`
    }
  }

  // training api

  trainingPath(id, path) {
    return `trainings/${id}/${path}`
  }

  async saveTraining() {
    try {
      this.savingState = 'saving'
      const { training } = this.rootStore
      const json = training.toJSON()
      await this.updateTrainingFiles(json)
      this.lastSavedTrainingHash = JSON.stringify(json)
      this.savingState = 'saved'
      console.log('persisted>', json)
    } catch (err) {
      console.error('Error saving training:', err)
      this.savingState = 'error'
    }
  }

  async updateTrainingFiles(training) {
    const updatedAt = new Date().getTime()
    const path = this.trainingPath(training.id, 'training.json')
    const current = await this.retrieveJson(path)
    const updatedTraining = { ...current, ...training, updatedAt }
    await this.persistJson(path, updatedTraining)
    await this.updateJson(`trainings-list.json`, list => {
      return list.map(t => (t.id === updatedTraining.id ? updatedTraining : t))
    })
  }

  async loadTraining(trainingId) {
    const { training } = this.rootStore
    const path = this.trainingPath(trainingId, 'training.json')
    const json = await this.retrieveJson(path)
    if (isEmpty(json)) throw new TrainingDoesNotExist(trainingId)
    training.readJSON({ ...json, id: trainingId })
    // we don't want to hash properties added by the manager
    this.lastSavedTrainingHash = JSON.stringify(training.toJSON())
  }

  async loadPublicTraining(trainingPath) {
    const url = this.getContentUrl(trainingPath)
    console.log('URL:', url)
    const response = await fetch(`${url}/training.json`)
    return response.json()
  }

  async saveBlob(data, filename, unique = true) {
    const trainingId = this.rootStore.training.id
    const path = this.trainingPath(trainingId, 'uploads')
    const key = `${path}/${unique ? uuid() + '-' : ''}${filename}`
    const url = await this.persistFile(key, data)
    return this.getContentUrl(url)
  }
}

export default PersistenceStore

{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-08",
    "endpointPrefix": "workspaces-web",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon WorkSpaces Web",
    "serviceId": "WorkSpaces Web",
    "signatureVersion": "v4",
    "signingName": "workspaces-web",
    "uid": "workspaces-web-2020-07-08"
  },
  "operations": {
    "AssociateBrowserSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/portals/{portalArn+}/browserSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "browserSettingsArn",
          "portalArn"
        ],
        "members": {
          "browserSettingsArn": {
            "location": "querystring",
            "locationName": "browserSettingsArn"
          },
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "browserSettingsArn",
          "portalArn"
        ],
        "members": {
          "browserSettingsArn": {},
          "portalArn": {}
        }
      },
      "idempotent": true
    },
    "AssociateNetworkSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/portals/{portalArn+}/networkSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "networkSettingsArn",
          "portalArn"
        ],
        "members": {
          "networkSettingsArn": {
            "location": "querystring",
            "locationName": "networkSettingsArn"
          },
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "networkSettingsArn",
          "portalArn"
        ],
        "members": {
          "networkSettingsArn": {},
          "portalArn": {}
        }
      },
      "idempotent": true
    },
    "AssociateTrustStore": {
      "http": {
        "method": "PUT",
        "requestUri": "/portals/{portalArn+}/trustStores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn",
          "trustStoreArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          },
          "trustStoreArn": {
            "location": "querystring",
            "locationName": "trustStoreArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalArn",
          "trustStoreArn"
        ],
        "members": {
          "portalArn": {},
          "trustStoreArn": {}
        }
      },
      "idempotent": true
    },
    "AssociateUserSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/portals/{portalArn+}/userSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn",
          "userSettingsArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          },
          "userSettingsArn": {
            "location": "querystring",
            "locationName": "userSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalArn",
          "userSettingsArn"
        ],
        "members": {
          "portalArn": {},
          "userSettingsArn": {}
        }
      },
      "idempotent": true
    },
    "CreateBrowserSettings": {
      "http": {
        "requestUri": "/browserSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "browserPolicy"
        ],
        "members": {
          "additionalEncryptionContext": {
            "shape": "Sb"
          },
          "browserPolicy": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "customerManagedKey": {},
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "browserSettingsArn"
        ],
        "members": {
          "browserSettingsArn": {}
        }
      }
    },
    "CreateIdentityProvider": {
      "http": {
        "requestUri": "/identityProviders",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identityProviderDetails",
          "identityProviderName",
          "identityProviderType",
          "portalArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "identityProviderDetails": {
            "shape": "Sm"
          },
          "identityProviderName": {},
          "identityProviderType": {},
          "portalArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "identityProviderArn"
        ],
        "members": {
          "identityProviderArn": {}
        }
      }
    },
    "CreateNetworkSettings": {
      "http": {
        "requestUri": "/networkSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "securityGroupIds",
          "subnetIds",
          "vpcId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "securityGroupIds": {
            "shape": "Sr"
          },
          "subnetIds": {
            "shape": "St"
          },
          "tags": {
            "shape": "Sg"
          },
          "vpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "networkSettingsArn"
        ],
        "members": {
          "networkSettingsArn": {}
        }
      }
    },
    "CreatePortal": {
      "http": {
        "requestUri": "/portals",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "additionalEncryptionContext": {
            "shape": "Sb"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "customerManagedKey": {},
          "displayName": {},
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalArn",
          "portalEndpoint"
        ],
        "members": {
          "portalArn": {},
          "portalEndpoint": {}
        }
      }
    },
    "CreateTrustStore": {
      "http": {
        "requestUri": "/trustStores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateList"
        ],
        "members": {
          "certificateList": {
            "shape": "S12"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "trustStoreArn"
        ],
        "members": {
          "trustStoreArn": {}
        }
      }
    },
    "CreateUserSettings": {
      "http": {
        "requestUri": "/userSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "copyAllowed",
          "downloadAllowed",
          "pasteAllowed",
          "printAllowed",
          "uploadAllowed"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "copyAllowed": {},
          "downloadAllowed": {},
          "pasteAllowed": {},
          "printAllowed": {},
          "tags": {
            "shape": "Sg"
          },
          "uploadAllowed": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "userSettingsArn"
        ],
        "members": {
          "userSettingsArn": {}
        }
      }
    },
    "DeleteBrowserSettings": {
      "http": {
        "method": "DELETE",
        "requestUri": "/browserSettings/{browserSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "browserSettingsArn"
        ],
        "members": {
          "browserSettingsArn": {
            "location": "uri",
            "locationName": "browserSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteIdentityProvider": {
      "http": {
        "method": "DELETE",
        "requestUri": "/identityProviders/{identityProviderArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identityProviderArn"
        ],
        "members": {
          "identityProviderArn": {
            "location": "uri",
            "locationName": "identityProviderArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteNetworkSettings": {
      "http": {
        "method": "DELETE",
        "requestUri": "/networkSettings/{networkSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "networkSettingsArn"
        ],
        "members": {
          "networkSettingsArn": {
            "location": "uri",
            "locationName": "networkSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePortal": {
      "http": {
        "method": "DELETE",
        "requestUri": "/portals/{portalArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteTrustStore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/trustStores/{trustStoreArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trustStoreArn"
        ],
        "members": {
          "trustStoreArn": {
            "location": "uri",
            "locationName": "trustStoreArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteUserSettings": {
      "http": {
        "method": "DELETE",
        "requestUri": "/userSettings/{userSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "userSettingsArn"
        ],
        "members": {
          "userSettingsArn": {
            "location": "uri",
            "locationName": "userSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateBrowserSettings": {
      "http": {
        "method": "DELETE",
        "requestUri": "/portals/{portalArn+}/browserSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateNetworkSettings": {
      "http": {
        "method": "DELETE",
        "requestUri": "/portals/{portalArn+}/networkSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateTrustStore": {
      "http": {
        "method": "DELETE",
        "requestUri": "/portals/{portalArn+}/trustStores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateUserSettings": {
      "http": {
        "method": "DELETE",
        "requestUri": "/portals/{portalArn+}/userSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetBrowserSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/browserSettings/{browserSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "browserSettingsArn"
        ],
        "members": {
          "browserSettingsArn": {
            "location": "uri",
            "locationName": "browserSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "browserSettings": {
            "shape": "S1u"
          }
        }
      }
    },
    "GetIdentityProvider": {
      "http": {
        "method": "GET",
        "requestUri": "/identityProviders/{identityProviderArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identityProviderArn"
        ],
        "members": {
          "identityProviderArn": {
            "location": "uri",
            "locationName": "identityProviderArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "identityProvider": {
            "shape": "S1y"
          }
        }
      }
    },
    "GetNetworkSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/networkSettings/{networkSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "networkSettingsArn"
        ],
        "members": {
          "networkSettingsArn": {
            "location": "uri",
            "locationName": "networkSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "networkSettings": {
            "shape": "S21"
          }
        }
      }
    },
    "GetPortal": {
      "http": {
        "method": "GET",
        "requestUri": "/portals/{portalArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "portal": {
            "shape": "S24"
          }
        }
      }
    },
    "GetPortalServiceProviderMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/portalIdp/{portalArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "portalArn": {},
          "serviceProviderSamlMetadata": {}
        }
      }
    },
    "GetTrustStore": {
      "http": {
        "method": "GET",
        "requestUri": "/trustStores/{trustStoreArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trustStoreArn"
        ],
        "members": {
          "trustStoreArn": {
            "location": "uri",
            "locationName": "trustStoreArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "trustStore": {
            "type": "structure",
            "members": {
              "associatedPortalArns": {
                "shape": "S1v"
              },
              "trustStoreArn": {}
            }
          }
        }
      }
    },
    "GetTrustStoreCertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/trustStores/{trustStoreArn+}/certificate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "thumbprint",
          "trustStoreArn"
        ],
        "members": {
          "thumbprint": {
            "location": "querystring",
            "locationName": "thumbprint"
          },
          "trustStoreArn": {
            "location": "uri",
            "locationName": "trustStoreArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificate": {
            "type": "structure",
            "members": {
              "body": {
                "type": "blob"
              },
              "issuer": {},
              "notValidAfter": {
                "type": "timestamp"
              },
              "notValidBefore": {
                "type": "timestamp"
              },
              "subject": {},
              "thumbprint": {}
            }
          },
          "trustStoreArn": {}
        }
      }
    },
    "GetUserSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/userSettings/{userSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "userSettingsArn"
        ],
        "members": {
          "userSettingsArn": {
            "location": "uri",
            "locationName": "userSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userSettings": {
            "shape": "S2n"
          }
        }
      }
    },
    "ListBrowserSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/browserSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "browserSettings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "browserSettingsArn": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListIdentityProviders": {
      "http": {
        "method": "GET",
        "requestUri": "/portals/{portalArn+}/identityProviders",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "identityProviders": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "identityProviderArn": {},
                "identityProviderName": {},
                "identityProviderType": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListNetworkSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/networkSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "networkSettings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "networkSettingsArn": {},
                "vpcId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPortals": {
      "http": {
        "method": "GET",
        "requestUri": "/portals",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "portals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "browserSettingsArn": {},
                "browserType": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "displayName": {},
                "networkSettingsArn": {},
                "portalArn": {},
                "portalEndpoint": {},
                "portalStatus": {},
                "rendererType": {},
                "trustStoreArn": {},
                "userSettingsArn": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sg"
          }
        }
      }
    },
    "ListTrustStoreCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/trustStores/{trustStoreArn+}/certificates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trustStoreArn"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "trustStoreArn": {
            "location": "uri",
            "locationName": "trustStoreArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "issuer": {},
                "notValidAfter": {
                  "type": "timestamp"
                },
                "notValidBefore": {
                  "type": "timestamp"
                },
                "subject": {},
                "thumbprint": {}
              }
            }
          },
          "nextToken": {},
          "trustStoreArn": {}
        }
      }
    },
    "ListTrustStores": {
      "http": {
        "method": "GET",
        "requestUri": "/trustStores",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "trustStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "trustStoreArn": {}
              }
            }
          }
        }
      }
    },
    "ListUserSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/userSettings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "userSettings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "copyAllowed": {},
                "downloadAllowed": {},
                "pasteAllowed": {},
                "printAllowed": {},
                "uploadAllowed": {},
                "userSettingsArn": {}
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateBrowserSettings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/browserSettings/{browserSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "browserSettingsArn"
        ],
        "members": {
          "browserPolicy": {},
          "browserSettingsArn": {
            "location": "uri",
            "locationName": "browserSettingsArn"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "browserSettings"
        ],
        "members": {
          "browserSettings": {
            "shape": "S1u"
          }
        }
      }
    },
    "UpdateIdentityProvider": {
      "http": {
        "method": "PATCH",
        "requestUri": "/identityProviders/{identityProviderArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identityProviderArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "identityProviderArn": {
            "location": "uri",
            "locationName": "identityProviderArn"
          },
          "identityProviderDetails": {
            "shape": "Sm"
          },
          "identityProviderName": {},
          "identityProviderType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "identityProvider"
        ],
        "members": {
          "identityProvider": {
            "shape": "S1y"
          }
        }
      }
    },
    "UpdateNetworkSettings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/networkSettings/{networkSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "networkSettingsArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "networkSettingsArn": {
            "location": "uri",
            "locationName": "networkSettingsArn"
          },
          "securityGroupIds": {
            "shape": "Sr"
          },
          "subnetIds": {
            "shape": "St"
          },
          "vpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "networkSettings"
        ],
        "members": {
          "networkSettings": {
            "shape": "S21"
          }
        }
      }
    },
    "UpdatePortal": {
      "http": {
        "method": "PUT",
        "requestUri": "/portals/{portalArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalArn"
        ],
        "members": {
          "displayName": {},
          "portalArn": {
            "location": "uri",
            "locationName": "portalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "portal": {
            "shape": "S24"
          }
        }
      },
      "idempotent": true
    },
    "UpdateTrustStore": {
      "http": {
        "method": "PATCH",
        "requestUri": "/trustStores/{trustStoreArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trustStoreArn"
        ],
        "members": {
          "certificatesToAdd": {
            "shape": "S12"
          },
          "certificatesToDelete": {
            "type": "list",
            "member": {}
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "trustStoreArn": {
            "location": "uri",
            "locationName": "trustStoreArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "trustStoreArn"
        ],
        "members": {
          "trustStoreArn": {}
        }
      }
    },
    "UpdateUserSettings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/userSettings/{userSettingsArn+}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "userSettingsArn"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "copyAllowed": {},
          "downloadAllowed": {},
          "pasteAllowed": {},
          "printAllowed": {},
          "uploadAllowed": {},
          "userSettingsArn": {
            "location": "uri",
            "locationName": "userSettingsArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "userSettings"
        ],
        "members": {
          "userSettings": {
            "shape": "S2n"
          }
        }
      }
    }
  },
  "shapes": {
    "Sb": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sg": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sm": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sr": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "blob"
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "browserSettingsArn"
      ],
      "members": {
        "associatedPortalArns": {
          "shape": "S1v"
        },
        "browserPolicy": {},
        "browserSettingsArn": {}
      }
    },
    "S1v": {
      "type": "list",
      "member": {}
    },
    "S1y": {
      "type": "structure",
      "required": [
        "identityProviderArn"
      ],
      "members": {
        "identityProviderArn": {},
        "identityProviderDetails": {
          "shape": "Sm"
        },
        "identityProviderName": {},
        "identityProviderType": {}
      }
    },
    "S21": {
      "type": "structure",
      "required": [
        "networkSettingsArn"
      ],
      "members": {
        "associatedPortalArns": {
          "shape": "S1v"
        },
        "networkSettingsArn": {},
        "securityGroupIds": {
          "shape": "Sr"
        },
        "subnetIds": {
          "shape": "St"
        },
        "vpcId": {}
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "browserSettingsArn": {},
        "browserType": {},
        "creationDate": {
          "type": "timestamp"
        },
        "displayName": {},
        "networkSettingsArn": {},
        "portalArn": {},
        "portalEndpoint": {},
        "portalStatus": {},
        "rendererType": {},
        "statusReason": {},
        "trustStoreArn": {},
        "userSettingsArn": {}
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "userSettingsArn"
      ],
      "members": {
        "associatedPortalArns": {
          "shape": "S1v"
        },
        "copyAllowed": {},
        "downloadAllowed": {},
        "pasteAllowed": {},
        "printAllowed": {},
        "uploadAllowed": {},
        "userSettingsArn": {}
      }
    }
  }
}
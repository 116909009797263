{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-11-01",
    "endpointPrefix": "cloudtrail",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CloudTrail",
    "serviceFullName": "AWS CloudTrail",
    "serviceId": "CloudTrail",
    "signatureVersion": "v4",
    "targetPrefix": "com.amazonaws.cloudtrail.v20131101.CloudTrail_20131101",
    "uid": "cloudtrail-2013-11-01"
  },
  "operations": {
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagsList"
        ],
        "members": {
          "ResourceId": {},
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CancelQuery": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore",
          "QueryId"
        ],
        "members": {
          "EventDataStore": {},
          "QueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryId",
          "QueryStatus"
        ],
        "members": {
          "QueryId": {},
          "QueryStatus": {}
        }
      },
      "idempotent": true
    },
    "CreateEventDataStore": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "AdvancedEventSelectors": {
            "shape": "Sf"
          },
          "MultiRegionEnabled": {
            "type": "boolean"
          },
          "OrganizationEnabled": {
            "type": "boolean"
          },
          "RetentionPeriod": {
            "type": "integer"
          },
          "TerminationProtectionEnabled": {
            "type": "boolean"
          },
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventDataStoreArn": {},
          "Name": {},
          "Status": {},
          "AdvancedEventSelectors": {
            "shape": "Sf"
          },
          "MultiRegionEnabled": {
            "type": "boolean"
          },
          "OrganizationEnabled": {
            "type": "boolean"
          },
          "RetentionPeriod": {
            "type": "integer"
          },
          "TerminationProtectionEnabled": {
            "type": "boolean"
          },
          "TagsList": {
            "shape": "S3"
          },
          "CreatedTimestamp": {
            "type": "timestamp"
          },
          "UpdatedTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "S3BucketName"
        ],
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "EnableLogFileValidation": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          },
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {
            "deprecated": true
          },
          "SnsTopicARN": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "TrailARN": {},
          "LogFileValidationEnabled": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEventDataStore": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore"
        ],
        "members": {
          "EventDataStore": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeQuery": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore",
          "QueryId"
        ],
        "members": {
          "EventDataStore": {},
          "QueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryId": {},
          "QueryString": {},
          "QueryStatus": {},
          "QueryStatistics": {
            "type": "structure",
            "members": {
              "EventsMatched": {
                "type": "long"
              },
              "EventsScanned": {
                "type": "long"
              },
              "BytesScanned": {
                "type": "long"
              },
              "ExecutionTimeInMillis": {
                "type": "integer"
              },
              "CreationTime": {
                "type": "timestamp"
              }
            }
          },
          "ErrorMessage": {}
        }
      },
      "idempotent": true
    },
    "DescribeTrails": {
      "input": {
        "type": "structure",
        "members": {
          "trailNameList": {
            "type": "list",
            "member": {}
          },
          "includeShadowTrails": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "trailList": {
            "type": "list",
            "member": {
              "shape": "S1a"
            }
          }
        }
      },
      "idempotent": true
    },
    "GetEventDataStore": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore"
        ],
        "members": {
          "EventDataStore": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventDataStoreArn": {},
          "Name": {},
          "Status": {},
          "AdvancedEventSelectors": {
            "shape": "Sf"
          },
          "MultiRegionEnabled": {
            "type": "boolean"
          },
          "OrganizationEnabled": {
            "type": "boolean"
          },
          "RetentionPeriod": {
            "type": "integer"
          },
          "TerminationProtectionEnabled": {
            "type": "boolean"
          },
          "CreatedTimestamp": {
            "type": "timestamp"
          },
          "UpdatedTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "GetEventSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName"
        ],
        "members": {
          "TrailName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "EventSelectors": {
            "shape": "S1f"
          },
          "AdvancedEventSelectors": {
            "shape": "Sf"
          }
        }
      },
      "idempotent": true
    },
    "GetInsightSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName"
        ],
        "members": {
          "TrailName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "InsightSelectors": {
            "shape": "S1o"
          }
        }
      },
      "idempotent": true
    },
    "GetQueryResults": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore",
          "QueryId"
        ],
        "members": {
          "EventDataStore": {},
          "QueryId": {},
          "NextToken": {},
          "MaxQueryResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryStatus": {},
          "QueryStatistics": {
            "type": "structure",
            "members": {
              "ResultsCount": {
                "type": "integer"
              },
              "TotalResultsCount": {
                "type": "integer"
              },
              "BytesScanned": {
                "type": "long"
              }
            }
          },
          "QueryResultRows": {
            "type": "list",
            "member": {
              "type": "list",
              "member": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          },
          "NextToken": {},
          "ErrorMessage": {}
        }
      }
    },
    "GetTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trail": {
            "shape": "S1a"
          }
        }
      },
      "idempotent": true
    },
    "GetTrailStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsLogging": {
            "type": "boolean"
          },
          "LatestDeliveryError": {},
          "LatestNotificationError": {},
          "LatestDeliveryTime": {
            "type": "timestamp"
          },
          "LatestNotificationTime": {
            "type": "timestamp"
          },
          "StartLoggingTime": {
            "type": "timestamp"
          },
          "StopLoggingTime": {
            "type": "timestamp"
          },
          "LatestCloudWatchLogsDeliveryError": {},
          "LatestCloudWatchLogsDeliveryTime": {
            "type": "timestamp"
          },
          "LatestDigestDeliveryTime": {
            "type": "timestamp"
          },
          "LatestDigestDeliveryError": {},
          "LatestDeliveryAttemptTime": {},
          "LatestNotificationAttemptTime": {},
          "LatestNotificationAttemptSucceeded": {},
          "LatestDeliveryAttemptSucceeded": {},
          "TimeLoggingStarted": {},
          "TimeLoggingStopped": {}
        }
      },
      "idempotent": true
    },
    "ListEventDataStores": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventDataStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventDataStoreArn": {},
                "Name": {},
                "TerminationProtectionEnabled": {
                  "type": "boolean"
                },
                "Status": {},
                "AdvancedEventSelectors": {
                  "shape": "Sf"
                },
                "MultiRegionEnabled": {
                  "type": "boolean"
                },
                "OrganizationEnabled": {
                  "type": "boolean"
                },
                "RetentionPeriod": {
                  "type": "integer"
                },
                "CreatedTimestamp": {
                  "type": "timestamp"
                },
                "UpdatedTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListPublicKeys": {
      "input": {
        "type": "structure",
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {
                  "type": "blob"
                },
                "ValidityStartTime": {
                  "type": "timestamp"
                },
                "ValidityEndTime": {
                  "type": "timestamp"
                },
                "Fingerprint": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListQueries": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore"
        ],
        "members": {
          "EventDataStore": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "QueryStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Queries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QueryId": {},
                "QueryStatus": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIdList"
        ],
        "members": {
          "ResourceIdList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceTagList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceId": {},
                "TagsList": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTrails": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TrailARN": {},
                "Name": {},
                "HomeRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "LookupEvents": {
      "input": {
        "type": "structure",
        "members": {
          "LookupAttributes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AttributeKey",
                "AttributeValue"
              ],
              "members": {
                "AttributeKey": {},
                "AttributeValue": {}
              }
            }
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "EventCategory": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "EventName": {},
                "ReadOnly": {},
                "AccessKeyId": {},
                "EventTime": {
                  "type": "timestamp"
                },
                "EventSource": {},
                "Username": {},
                "Resources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ResourceType": {},
                      "ResourceName": {}
                    }
                  }
                },
                "CloudTrailEvent": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "PutEventSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName"
        ],
        "members": {
          "TrailName": {},
          "EventSelectors": {
            "shape": "S1f"
          },
          "AdvancedEventSelectors": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "EventSelectors": {
            "shape": "S1f"
          },
          "AdvancedEventSelectors": {
            "shape": "Sf"
          }
        }
      },
      "idempotent": true
    },
    "PutInsightSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName",
          "InsightSelectors"
        ],
        "members": {
          "TrailName": {},
          "InsightSelectors": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "InsightSelectors": {
            "shape": "S1o"
          }
        }
      },
      "idempotent": true
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagsList"
        ],
        "members": {
          "ResourceId": {},
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RestoreEventDataStore": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore"
        ],
        "members": {
          "EventDataStore": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventDataStoreArn": {},
          "Name": {},
          "Status": {},
          "AdvancedEventSelectors": {
            "shape": "Sf"
          },
          "MultiRegionEnabled": {
            "type": "boolean"
          },
          "OrganizationEnabled": {
            "type": "boolean"
          },
          "RetentionPeriod": {
            "type": "integer"
          },
          "TerminationProtectionEnabled": {
            "type": "boolean"
          },
          "CreatedTimestamp": {
            "type": "timestamp"
          },
          "UpdatedTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "StartLogging": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StartQuery": {
      "input": {
        "type": "structure",
        "required": [
          "QueryStatement"
        ],
        "members": {
          "QueryStatement": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryId": {}
        }
      },
      "idempotent": true
    },
    "StopLogging": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateEventDataStore": {
      "input": {
        "type": "structure",
        "required": [
          "EventDataStore"
        ],
        "members": {
          "EventDataStore": {},
          "Name": {},
          "AdvancedEventSelectors": {
            "shape": "Sf"
          },
          "MultiRegionEnabled": {
            "type": "boolean"
          },
          "OrganizationEnabled": {
            "type": "boolean"
          },
          "RetentionPeriod": {
            "type": "integer"
          },
          "TerminationProtectionEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventDataStoreArn": {},
          "Name": {},
          "Status": {},
          "AdvancedEventSelectors": {
            "shape": "Sf"
          },
          "MultiRegionEnabled": {
            "type": "boolean"
          },
          "OrganizationEnabled": {
            "type": "boolean"
          },
          "RetentionPeriod": {
            "type": "integer"
          },
          "TerminationProtectionEnabled": {
            "type": "boolean"
          },
          "CreatedTimestamp": {
            "type": "timestamp"
          },
          "UpdatedTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "UpdateTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "EnableLogFileValidation": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {
            "deprecated": true
          },
          "SnsTopicARN": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "TrailARN": {},
          "LogFileValidationEnabled": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FieldSelectors"
        ],
        "members": {
          "Name": {},
          "FieldSelectors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Field"
              ],
              "members": {
                "Field": {},
                "Equals": {
                  "shape": "Sl"
                },
                "StartsWith": {
                  "shape": "Sl"
                },
                "EndsWith": {
                  "shape": "Sl"
                },
                "NotEquals": {
                  "shape": "Sl"
                },
                "NotStartsWith": {
                  "shape": "Sl"
                },
                "NotEndsWith": {
                  "shape": "Sl"
                }
              }
            }
          }
        }
      }
    },
    "Sl": {
      "type": "list",
      "member": {}
    },
    "S1a": {
      "type": "structure",
      "members": {
        "Name": {},
        "S3BucketName": {},
        "S3KeyPrefix": {},
        "SnsTopicName": {
          "deprecated": true
        },
        "SnsTopicARN": {},
        "IncludeGlobalServiceEvents": {
          "type": "boolean"
        },
        "IsMultiRegionTrail": {
          "type": "boolean"
        },
        "HomeRegion": {},
        "TrailARN": {},
        "LogFileValidationEnabled": {
          "type": "boolean"
        },
        "CloudWatchLogsLogGroupArn": {},
        "CloudWatchLogsRoleArn": {},
        "KmsKeyId": {},
        "HasCustomEventSelectors": {
          "type": "boolean"
        },
        "HasInsightSelectors": {
          "type": "boolean"
        },
        "IsOrganizationTrail": {
          "type": "boolean"
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ReadWriteType": {},
          "IncludeManagementEvents": {
            "type": "boolean"
          },
          "DataResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "ExcludeManagementEventSources": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "InsightType": {}
        }
      }
    }
  }
}
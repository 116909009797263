{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-22",
    "endpointPrefix": "personalize",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Personalize",
    "serviceId": "Personalize",
    "signatureVersion": "v4",
    "signingName": "personalize",
    "targetPrefix": "AmazonPersonalize",
    "uid": "personalize-2018-05-22"
  },
  "operations": {
    "CreateBatchInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "solutionVersionArn",
          "jobInput",
          "jobOutput",
          "roleArn"
        ],
        "members": {
          "jobName": {},
          "solutionVersionArn": {},
          "filterArn": {},
          "numResults": {
            "type": "integer"
          },
          "jobInput": {
            "shape": "S5"
          },
          "jobOutput": {
            "shape": "S9"
          },
          "roleArn": {},
          "batchInferenceJobConfig": {
            "shape": "Sb"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchInferenceJobArn": {}
        }
      }
    },
    "CreateBatchSegmentJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "solutionVersionArn",
          "jobInput",
          "jobOutput",
          "roleArn"
        ],
        "members": {
          "jobName": {},
          "solutionVersionArn": {},
          "filterArn": {},
          "numResults": {
            "type": "integer"
          },
          "jobInput": {
            "shape": "Sl"
          },
          "jobOutput": {
            "shape": "Sm"
          },
          "roleArn": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchSegmentJobArn": {}
        }
      }
    },
    "CreateCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "solutionVersionArn"
        ],
        "members": {
          "name": {},
          "solutionVersionArn": {},
          "minProvisionedTPS": {
            "type": "integer"
          },
          "campaignConfig": {
            "shape": "Sq"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaignArn": {}
        }
      },
      "idempotent": true
    },
    "CreateDataset": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "schemaArn",
          "datasetGroupArn",
          "datasetType"
        ],
        "members": {
          "name": {},
          "schemaArn": {},
          "datasetGroupArn": {},
          "datasetType": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetArn": {}
        }
      },
      "idempotent": true
    },
    "CreateDatasetExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "datasetArn",
          "roleArn",
          "jobOutput"
        ],
        "members": {
          "jobName": {},
          "datasetArn": {},
          "ingestionMode": {},
          "roleArn": {},
          "jobOutput": {
            "shape": "Sx"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetExportJobArn": {}
        }
      },
      "idempotent": true
    },
    "CreateDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "roleArn": {},
          "kmsKeyArn": {},
          "domain": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "domain": {}
        }
      }
    },
    "CreateDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "datasetArn",
          "dataSource",
          "roleArn"
        ],
        "members": {
          "jobName": {},
          "datasetArn": {},
          "dataSource": {
            "shape": "S13"
          },
          "roleArn": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetImportJobArn": {}
        }
      }
    },
    "CreateEventTracker": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn"
        ],
        "members": {
          "name": {},
          "datasetGroupArn": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTrackerArn": {},
          "trackingId": {}
        }
      },
      "idempotent": true
    },
    "CreateFilter": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn",
          "filterExpression"
        ],
        "members": {
          "name": {},
          "datasetGroupArn": {},
          "filterExpression": {
            "shape": "S19"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "filterArn": {}
        }
      }
    },
    "CreateRecommender": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn",
          "recipeArn"
        ],
        "members": {
          "name": {},
          "datasetGroupArn": {},
          "recipeArn": {},
          "recommenderConfig": {
            "shape": "S1c"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommenderArn": {}
        }
      },
      "idempotent": true
    },
    "CreateSchema": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "schema"
        ],
        "members": {
          "name": {},
          "schema": {},
          "domain": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schemaArn": {}
        }
      },
      "idempotent": true
    },
    "CreateSolution": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "datasetGroupArn"
        ],
        "members": {
          "name": {},
          "performHPO": {
            "type": "boolean"
          },
          "performAutoML": {
            "type": "boolean"
          },
          "recipeArn": {},
          "datasetGroupArn": {},
          "eventType": {},
          "solutionConfig": {
            "shape": "S1l"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionArn": {}
        }
      }
    },
    "CreateSolutionVersion": {
      "input": {
        "type": "structure",
        "required": [
          "solutionArn"
        ],
        "members": {
          "solutionArn": {},
          "trainingMode": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {}
        }
      }
    },
    "DeleteCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDataset": {
      "input": {
        "type": "structure",
        "required": [
          "datasetArn"
        ],
        "members": {
          "datasetArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "datasetGroupArn"
        ],
        "members": {
          "datasetGroupArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteEventTracker": {
      "input": {
        "type": "structure",
        "required": [
          "eventTrackerArn"
        ],
        "members": {
          "eventTrackerArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteFilter": {
      "input": {
        "type": "structure",
        "required": [
          "filterArn"
        ],
        "members": {
          "filterArn": {}
        }
      }
    },
    "DeleteRecommender": {
      "input": {
        "type": "structure",
        "required": [
          "recommenderArn"
        ],
        "members": {
          "recommenderArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteSchema": {
      "input": {
        "type": "structure",
        "required": [
          "schemaArn"
        ],
        "members": {
          "schemaArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteSolution": {
      "input": {
        "type": "structure",
        "required": [
          "solutionArn"
        ],
        "members": {
          "solutionArn": {}
        }
      },
      "idempotent": true
    },
    "DescribeAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "algorithmArn"
        ],
        "members": {
          "algorithmArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "algorithm": {
            "type": "structure",
            "members": {
              "name": {},
              "algorithmArn": {},
              "algorithmImage": {
                "type": "structure",
                "required": [
                  "dockerURI"
                ],
                "members": {
                  "name": {},
                  "dockerURI": {}
                }
              },
              "defaultHyperParameters": {
                "shape": "Sc"
              },
              "defaultHyperParameterRanges": {
                "type": "structure",
                "members": {
                  "integerHyperParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "minValue": {
                          "type": "integer"
                        },
                        "maxValue": {
                          "type": "integer"
                        },
                        "isTunable": {
                          "type": "boolean"
                        }
                      }
                    }
                  },
                  "continuousHyperParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "minValue": {
                          "type": "double"
                        },
                        "maxValue": {
                          "type": "double"
                        },
                        "isTunable": {
                          "type": "boolean"
                        }
                      }
                    }
                  },
                  "categoricalHyperParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "name": {},
                        "values": {
                          "shape": "S25"
                        },
                        "isTunable": {
                          "type": "boolean"
                        }
                      }
                    }
                  }
                }
              },
              "defaultResourceConfig": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "trainingInputMode": {},
              "roleArn": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeBatchInferenceJob": {
      "input": {
        "type": "structure",
        "required": [
          "batchInferenceJobArn"
        ],
        "members": {
          "batchInferenceJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchInferenceJob": {
            "type": "structure",
            "members": {
              "jobName": {},
              "batchInferenceJobArn": {},
              "filterArn": {},
              "failureReason": {},
              "solutionVersionArn": {},
              "numResults": {
                "type": "integer"
              },
              "jobInput": {
                "shape": "S5"
              },
              "jobOutput": {
                "shape": "S9"
              },
              "batchInferenceJobConfig": {
                "shape": "Sb"
              },
              "roleArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeBatchSegmentJob": {
      "input": {
        "type": "structure",
        "required": [
          "batchSegmentJobArn"
        ],
        "members": {
          "batchSegmentJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchSegmentJob": {
            "type": "structure",
            "members": {
              "jobName": {},
              "batchSegmentJobArn": {},
              "filterArn": {},
              "failureReason": {},
              "solutionVersionArn": {},
              "numResults": {
                "type": "integer"
              },
              "jobInput": {
                "shape": "Sl"
              },
              "jobOutput": {
                "shape": "Sm"
              },
              "roleArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaign": {
            "type": "structure",
            "members": {
              "name": {},
              "campaignArn": {},
              "solutionVersionArn": {},
              "minProvisionedTPS": {
                "type": "integer"
              },
              "campaignConfig": {
                "shape": "Sq"
              },
              "status": {},
              "failureReason": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "latestCampaignUpdate": {
                "type": "structure",
                "members": {
                  "solutionVersionArn": {},
                  "minProvisionedTPS": {
                    "type": "integer"
                  },
                  "campaignConfig": {
                    "shape": "Sq"
                  },
                  "status": {},
                  "failureReason": {},
                  "creationDateTime": {
                    "type": "timestamp"
                  },
                  "lastUpdatedDateTime": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDataset": {
      "input": {
        "type": "structure",
        "required": [
          "datasetArn"
        ],
        "members": {
          "datasetArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataset": {
            "type": "structure",
            "members": {
              "name": {},
              "datasetArn": {},
              "datasetGroupArn": {},
              "datasetType": {},
              "schemaArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "datasetExportJobArn"
        ],
        "members": {
          "datasetExportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetExportJob": {
            "type": "structure",
            "members": {
              "jobName": {},
              "datasetExportJobArn": {},
              "datasetArn": {},
              "ingestionMode": {},
              "roleArn": {},
              "status": {},
              "jobOutput": {
                "shape": "Sx"
              },
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "failureReason": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "datasetGroupArn"
        ],
        "members": {
          "datasetGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetGroup": {
            "type": "structure",
            "members": {
              "name": {},
              "datasetGroupArn": {},
              "status": {},
              "roleArn": {},
              "kmsKeyArn": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "failureReason": {},
              "domain": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeDatasetImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "datasetImportJobArn"
        ],
        "members": {
          "datasetImportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetImportJob": {
            "type": "structure",
            "members": {
              "jobName": {},
              "datasetImportJobArn": {},
              "datasetArn": {},
              "dataSource": {
                "shape": "S13"
              },
              "roleArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "failureReason": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeEventTracker": {
      "input": {
        "type": "structure",
        "required": [
          "eventTrackerArn"
        ],
        "members": {
          "eventTrackerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTracker": {
            "type": "structure",
            "members": {
              "name": {},
              "eventTrackerArn": {},
              "accountId": {},
              "trackingId": {},
              "datasetGroupArn": {},
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeFeatureTransformation": {
      "input": {
        "type": "structure",
        "required": [
          "featureTransformationArn"
        ],
        "members": {
          "featureTransformationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "featureTransformation": {
            "type": "structure",
            "members": {
              "name": {},
              "featureTransformationArn": {},
              "defaultParameters": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "status": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeFilter": {
      "input": {
        "type": "structure",
        "required": [
          "filterArn"
        ],
        "members": {
          "filterArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "filter": {
            "type": "structure",
            "members": {
              "name": {},
              "filterArn": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "datasetGroupArn": {},
              "failureReason": {},
              "filterExpression": {
                "shape": "S19"
              },
              "status": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeRecipe": {
      "input": {
        "type": "structure",
        "required": [
          "recipeArn"
        ],
        "members": {
          "recipeArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recipe": {
            "type": "structure",
            "members": {
              "name": {},
              "recipeArn": {},
              "algorithmArn": {},
              "featureTransformationArn": {},
              "status": {},
              "description": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "recipeType": {},
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeRecommender": {
      "input": {
        "type": "structure",
        "required": [
          "recommenderArn"
        ],
        "members": {
          "recommenderArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommender": {
            "type": "structure",
            "members": {
              "recommenderArn": {},
              "datasetGroupArn": {},
              "name": {},
              "recipeArn": {},
              "recommenderConfig": {
                "shape": "S1c"
              },
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "status": {},
              "failureReason": {},
              "latestRecommenderUpdate": {
                "type": "structure",
                "members": {
                  "recommenderConfig": {
                    "shape": "S1c"
                  },
                  "creationDateTime": {
                    "type": "timestamp"
                  },
                  "lastUpdatedDateTime": {
                    "type": "timestamp"
                  },
                  "status": {},
                  "failureReason": {}
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeSchema": {
      "input": {
        "type": "structure",
        "required": [
          "schemaArn"
        ],
        "members": {
          "schemaArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schema": {
            "type": "structure",
            "members": {
              "name": {},
              "schemaArn": {},
              "schema": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "domain": {}
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeSolution": {
      "input": {
        "type": "structure",
        "required": [
          "solutionArn"
        ],
        "members": {
          "solutionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solution": {
            "type": "structure",
            "members": {
              "name": {},
              "solutionArn": {},
              "performHPO": {
                "type": "boolean"
              },
              "performAutoML": {
                "type": "boolean"
              },
              "recipeArn": {},
              "datasetGroupArn": {},
              "eventType": {},
              "solutionConfig": {
                "shape": "S1l"
              },
              "autoMLResult": {
                "type": "structure",
                "members": {
                  "bestRecipeArn": {}
                }
              },
              "status": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              },
              "latestSolutionVersion": {
                "shape": "S4l"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeSolutionVersion": {
      "input": {
        "type": "structure",
        "required": [
          "solutionVersionArn"
        ],
        "members": {
          "solutionVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersion": {
            "type": "structure",
            "members": {
              "solutionVersionArn": {},
              "solutionArn": {},
              "performHPO": {
                "type": "boolean"
              },
              "performAutoML": {
                "type": "boolean"
              },
              "recipeArn": {},
              "eventType": {},
              "datasetGroupArn": {},
              "solutionConfig": {
                "shape": "S1l"
              },
              "trainingHours": {
                "type": "double"
              },
              "trainingMode": {},
              "tunedHPOParams": {
                "type": "structure",
                "members": {
                  "algorithmHyperParameters": {
                    "shape": "Sc"
                  }
                }
              },
              "status": {},
              "failureReason": {},
              "creationDateTime": {
                "type": "timestamp"
              },
              "lastUpdatedDateTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "GetSolutionMetrics": {
      "input": {
        "type": "structure",
        "required": [
          "solutionVersionArn"
        ],
        "members": {
          "solutionVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {},
          "metrics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "double"
            }
          }
        }
      }
    },
    "ListBatchInferenceJobs": {
      "input": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchInferenceJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "batchInferenceJobArn": {},
                "jobName": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {},
                "solutionVersionArn": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListBatchSegmentJobs": {
      "input": {
        "type": "structure",
        "members": {
          "solutionVersionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "batchSegmentJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "batchSegmentJobArn": {},
                "jobName": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {},
                "solutionVersionArn": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListCampaigns": {
      "input": {
        "type": "structure",
        "members": {
          "solutionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaigns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "campaignArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetExportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "datasetArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetExportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "datasetExportJobArn": {},
                "jobName": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "datasetGroupArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {},
                "domain": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasetImportJobs": {
      "input": {
        "type": "structure",
        "members": {
          "datasetArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetImportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "datasetImportJobArn": {},
                "jobName": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "failureReason": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListDatasets": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "datasetArn": {},
                "datasetType": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListEventTrackers": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTrackers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "eventTrackerArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListFilters": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "filterArn": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "datasetGroupArn": {},
                "failureReason": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListRecipes": {
      "input": {
        "type": "structure",
        "members": {
          "recipeProvider": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "domain": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recipes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "recipeArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "domain": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListRecommenders": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommenders": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "recommenderArn": {},
                "datasetGroupArn": {},
                "recipeArn": {},
                "recommenderConfig": {
                  "shape": "S1c"
                },
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListSchemas": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "schemaArn": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "domain": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListSolutionVersions": {
      "input": {
        "type": "structure",
        "members": {
          "solutionArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutionVersions": {
            "type": "list",
            "member": {
              "shape": "S4l"
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListSolutions": {
      "input": {
        "type": "structure",
        "members": {
          "datasetGroupArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "solutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "solutionArn": {},
                "status": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sf"
          }
        }
      }
    },
    "StartRecommender": {
      "input": {
        "type": "structure",
        "required": [
          "recommenderArn"
        ],
        "members": {
          "recommenderArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommenderArn": {}
        }
      },
      "idempotent": true
    },
    "StopRecommender": {
      "input": {
        "type": "structure",
        "required": [
          "recommenderArn"
        ],
        "members": {
          "recommenderArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommenderArn": {}
        }
      },
      "idempotent": true
    },
    "StopSolutionVersionCreation": {
      "input": {
        "type": "structure",
        "required": [
          "solutionVersionArn"
        ],
        "members": {
          "solutionVersionArn": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCampaign": {
      "input": {
        "type": "structure",
        "required": [
          "campaignArn"
        ],
        "members": {
          "campaignArn": {},
          "solutionVersionArn": {},
          "minProvisionedTPS": {
            "type": "integer"
          },
          "campaignConfig": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "campaignArn": {}
        }
      },
      "idempotent": true
    },
    "UpdateRecommender": {
      "input": {
        "type": "structure",
        "required": [
          "recommenderArn",
          "recommenderConfig"
        ],
        "members": {
          "recommenderArn": {},
          "recommenderConfig": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recommenderArn": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "s3DataSource"
      ],
      "members": {
        "s3DataSource": {
          "shape": "S6"
        }
      }
    },
    "S6": {
      "type": "structure",
      "required": [
        "path"
      ],
      "members": {
        "path": {},
        "kmsKeyArn": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "s3DataDestination"
      ],
      "members": {
        "s3DataDestination": {
          "shape": "S6"
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "itemExplorationConfig": {
          "shape": "Sc"
        }
      }
    },
    "Sc": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "tagKey",
          "tagValue"
        ],
        "members": {
          "tagKey": {},
          "tagValue": {}
        }
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "s3DataSource"
      ],
      "members": {
        "s3DataSource": {
          "shape": "S6"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "s3DataDestination"
      ],
      "members": {
        "s3DataDestination": {
          "shape": "S6"
        }
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "itemExplorationConfig": {
          "shape": "Sc"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "s3DataDestination"
      ],
      "members": {
        "s3DataDestination": {
          "shape": "S6"
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "dataLocation": {}
      }
    },
    "S19": {
      "type": "string",
      "sensitive": true
    },
    "S1c": {
      "type": "structure",
      "members": {
        "itemExplorationConfig": {
          "shape": "Sc"
        },
        "minRecommendationRequestsPerSecond": {
          "type": "integer"
        }
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "eventValueThreshold": {},
        "hpoConfig": {
          "type": "structure",
          "members": {
            "hpoObjective": {
              "type": "structure",
              "members": {
                "type": {},
                "metricName": {},
                "metricRegex": {}
              }
            },
            "hpoResourceConfig": {
              "type": "structure",
              "members": {
                "maxNumberOfTrainingJobs": {},
                "maxParallelTrainingJobs": {}
              }
            },
            "algorithmHyperParameterRanges": {
              "type": "structure",
              "members": {
                "integerHyperParameterRanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "minValue": {
                        "type": "integer"
                      },
                      "maxValue": {
                        "type": "integer"
                      }
                    }
                  }
                },
                "continuousHyperParameterRanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "minValue": {
                        "type": "double"
                      },
                      "maxValue": {
                        "type": "double"
                      }
                    }
                  }
                },
                "categoricalHyperParameterRanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "values": {
                        "shape": "S25"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "algorithmHyperParameters": {
          "shape": "Sc"
        },
        "featureTransformationParameters": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "autoMLConfig": {
          "type": "structure",
          "members": {
            "metricName": {},
            "recipeList": {
              "type": "list",
              "member": {}
            }
          }
        },
        "optimizationObjective": {
          "type": "structure",
          "members": {
            "itemAttribute": {},
            "objectiveSensitivity": {}
          }
        }
      }
    },
    "S25": {
      "type": "list",
      "member": {}
    },
    "S4l": {
      "type": "structure",
      "members": {
        "solutionVersionArn": {},
        "status": {},
        "creationDateTime": {
          "type": "timestamp"
        },
        "lastUpdatedDateTime": {
          "type": "timestamp"
        },
        "failureReason": {}
      }
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-11",
    "endpointPrefix": "synthetics",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Synthetics",
    "serviceFullName": "Synthetics",
    "serviceId": "synthetics",
    "signatureVersion": "v4",
    "signingName": "synthetics",
    "uid": "synthetics-2017-10-11"
  },
  "operations": {
    "CreateCanary": {
      "http": {
        "requestUri": "/canary"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Code",
          "ArtifactS3Location",
          "ExecutionRoleArn",
          "Schedule",
          "RuntimeVersion"
        ],
        "members": {
          "Name": {},
          "Code": {
            "shape": "S3"
          },
          "ArtifactS3Location": {},
          "ExecutionRoleArn": {},
          "Schedule": {
            "shape": "S8"
          },
          "RunConfig": {
            "shape": "Sa"
          },
          "SuccessRetentionPeriodInDays": {
            "type": "integer"
          },
          "FailureRetentionPeriodInDays": {
            "type": "integer"
          },
          "RuntimeVersion": {},
          "VpcConfig": {
            "shape": "Si"
          },
          "Tags": {
            "shape": "Sn"
          },
          "ArtifactConfig": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Canary": {
            "shape": "Sv"
          }
        }
      }
    },
    "DeleteCanary": {
      "http": {
        "method": "DELETE",
        "requestUri": "/canary/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCanaries": {
      "http": {
        "requestUri": "/canaries"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Names": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Canaries": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeCanariesLastRun": {
      "http": {
        "requestUri": "/canaries/last-run"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Names": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CanariesLastRun": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CanaryName": {},
                "LastRun": {
                  "shape": "S1s"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRuntimeVersions": {
      "http": {
        "requestUri": "/runtime-versions"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionName": {},
                "Description": {},
                "ReleaseDate": {
                  "type": "timestamp"
                },
                "DeprecationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCanary": {
      "http": {
        "method": "GET",
        "requestUri": "/canary/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Canary": {
            "shape": "Sv"
          }
        }
      }
    },
    "GetCanaryRuns": {
      "http": {
        "requestUri": "/canary/{name}/runs"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CanaryRuns": {
            "type": "list",
            "member": {
              "shape": "S1s"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sn"
          }
        }
      }
    },
    "StartCanary": {
      "http": {
        "requestUri": "/canary/{name}/start"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopCanary": {
      "http": {
        "requestUri": "/canary/{name}/stop"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "Tags": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCanary": {
      "http": {
        "method": "PATCH",
        "requestUri": "/canary/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "name"
          },
          "Code": {
            "shape": "S3"
          },
          "ExecutionRoleArn": {},
          "RuntimeVersion": {},
          "Schedule": {
            "shape": "S8"
          },
          "RunConfig": {
            "shape": "Sa"
          },
          "SuccessRetentionPeriodInDays": {
            "type": "integer"
          },
          "FailureRetentionPeriodInDays": {
            "type": "integer"
          },
          "VpcConfig": {
            "shape": "Si"
          },
          "VisualReference": {
            "type": "structure",
            "required": [
              "BaseCanaryRunId"
            ],
            "members": {
              "BaseScreenshots": {
                "shape": "S19"
              },
              "BaseCanaryRunId": {}
            }
          },
          "ArtifactS3Location": {},
          "ArtifactConfig": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "Handler"
      ],
      "members": {
        "S3Bucket": {},
        "S3Key": {},
        "S3Version": {},
        "ZipFile": {
          "type": "blob"
        },
        "Handler": {}
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Expression"
      ],
      "members": {
        "Expression": {},
        "DurationInSeconds": {
          "type": "long"
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "TimeoutInSeconds": {
          "type": "integer"
        },
        "MemoryInMB": {
          "type": "integer"
        },
        "ActiveTracing": {
          "type": "boolean"
        },
        "EnvironmentVariables": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "Sj"
        },
        "SecurityGroupIds": {
          "shape": "Sl"
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sq": {
      "type": "structure",
      "members": {
        "S3Encryption": {
          "shape": "Sr"
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "EncryptionMode": {},
        "KmsKeyArn": {}
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Code": {
          "type": "structure",
          "members": {
            "SourceLocationArn": {},
            "Handler": {}
          }
        },
        "ExecutionRoleArn": {},
        "Schedule": {
          "type": "structure",
          "members": {
            "Expression": {},
            "DurationInSeconds": {
              "type": "long"
            }
          }
        },
        "RunConfig": {
          "type": "structure",
          "members": {
            "TimeoutInSeconds": {
              "type": "integer"
            },
            "MemoryInMB": {
              "type": "integer"
            },
            "ActiveTracing": {
              "type": "boolean"
            }
          }
        },
        "SuccessRetentionPeriodInDays": {
          "type": "integer"
        },
        "FailureRetentionPeriodInDays": {
          "type": "integer"
        },
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateReason": {},
            "StateReasonCode": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "Created": {
              "type": "timestamp"
            },
            "LastModified": {
              "type": "timestamp"
            },
            "LastStarted": {
              "type": "timestamp"
            },
            "LastStopped": {
              "type": "timestamp"
            }
          }
        },
        "ArtifactS3Location": {},
        "EngineArn": {},
        "RuntimeVersion": {},
        "VpcConfig": {
          "type": "structure",
          "members": {
            "VpcId": {},
            "SubnetIds": {
              "shape": "Sj"
            },
            "SecurityGroupIds": {
              "shape": "Sl"
            }
          }
        },
        "VisualReference": {
          "type": "structure",
          "members": {
            "BaseScreenshots": {
              "shape": "S19"
            },
            "BaseCanaryRunId": {}
          }
        },
        "Tags": {
          "shape": "Sn"
        },
        "ArtifactConfig": {
          "type": "structure",
          "members": {
            "S3Encryption": {
              "shape": "Sr"
            }
          }
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScreenshotName"
        ],
        "members": {
          "ScreenshotName": {},
          "IgnoreCoordinates": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateReason": {},
            "StateReasonCode": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "Started": {
              "type": "timestamp"
            },
            "Completed": {
              "type": "timestamp"
            }
          }
        },
        "ArtifactS3Location": {}
      }
    }
  }
}
import { createRoot } from 'react-dom/client'
import rootStore from '~/src/app/store'
import { initializeBackend } from '~/src/features/backend'
import App from './App'

const main = async () => {
  await initializeBackend({
    credentialsProvider: rootStore.auth.currentCredentials,
  })
  render()
}

const render = () => {
  const app = document.getElementById('app')
  const root = createRoot(app)
  root.render(<App />)
}

main()

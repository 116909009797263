{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-08-07",
    "endpointPrefix": "models-v2-lex",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Lex Models V2",
    "serviceFullName": "Amazon Lex Model Building V2",
    "serviceId": "Lex Models V2",
    "signatureVersion": "v4",
    "signingName": "lex",
    "uid": "models.lex.v2-2020-08-07"
  },
  "operations": {
    "BuildBotLocale": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botLocaleStatus": {},
          "lastBuildSubmittedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateBot": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "roleArn",
          "dataPrivacy",
          "idleSessionTTLInSeconds"
        ],
        "members": {
          "botName": {},
          "description": {},
          "roleArn": {},
          "dataPrivacy": {
            "shape": "Sc"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "botTags": {
            "shape": "Sf"
          },
          "testBotAliasTags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botName": {},
          "description": {},
          "roleArn": {},
          "dataPrivacy": {
            "shape": "Sc"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "botStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "botTags": {
            "shape": "Sf"
          },
          "testBotAliasTags": {
            "shape": "Sf"
          }
        }
      }
    },
    "CreateBotAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botaliases/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botAliasName",
          "botId"
        ],
        "members": {
          "botAliasName": {},
          "description": {},
          "botVersion": {},
          "botAliasLocaleSettings": {
            "shape": "Sm"
          },
          "conversationLogSettings": {
            "shape": "St"
          },
          "sentimentAnalysisSettings": {
            "shape": "S16"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botAliasId": {},
          "botAliasName": {},
          "description": {},
          "botVersion": {},
          "botAliasLocaleSettings": {
            "shape": "Sm"
          },
          "conversationLogSettings": {
            "shape": "St"
          },
          "sentimentAnalysisSettings": {
            "shape": "S16"
          },
          "botAliasStatus": {},
          "botId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      }
    },
    "CreateBotLocale": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "nluIntentConfidenceThreshold"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {},
          "description": {},
          "nluIntentConfidenceThreshold": {
            "type": "double"
          },
          "voiceSettings": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeName": {},
          "localeId": {},
          "description": {},
          "nluIntentConfidenceThreshold": {
            "type": "double"
          },
          "voiceSettings": {
            "shape": "S1c"
          },
          "botLocaleStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateBotVersion": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersionLocaleSpecification"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "description": {},
          "botVersionLocaleSpecification": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "description": {},
          "botVersion": {},
          "botVersionLocaleSpecification": {
            "shape": "S1i"
          },
          "botStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateExport": {
      "http": {
        "method": "PUT",
        "requestUri": "/exports/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceSpecification",
          "fileFormat"
        ],
        "members": {
          "resourceSpecification": {
            "shape": "S1n"
          },
          "fileFormat": {},
          "filePassword": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportId": {},
          "resourceSpecification": {
            "shape": "S1n"
          },
          "fileFormat": {},
          "exportStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateIntent": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "intentName",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "intentName": {},
          "description": {},
          "parentIntentSignature": {},
          "sampleUtterances": {
            "shape": "S1x"
          },
          "dialogCodeHook": {
            "shape": "S20"
          },
          "fulfillmentCodeHook": {
            "shape": "S21"
          },
          "intentConfirmationSetting": {
            "shape": "S2s"
          },
          "intentClosingSetting": {
            "shape": "S2v"
          },
          "inputContexts": {
            "shape": "S2w"
          },
          "outputContexts": {
            "shape": "S2y"
          },
          "kendraConfiguration": {
            "shape": "S32"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intentId": {},
          "intentName": {},
          "description": {},
          "parentIntentSignature": {},
          "sampleUtterances": {
            "shape": "S1x"
          },
          "dialogCodeHook": {
            "shape": "S20"
          },
          "fulfillmentCodeHook": {
            "shape": "S21"
          },
          "intentConfirmationSetting": {
            "shape": "S2s"
          },
          "intentClosingSetting": {
            "shape": "S2v"
          },
          "inputContexts": {
            "shape": "S2w"
          },
          "outputContexts": {
            "shape": "S2y"
          },
          "kendraConfiguration": {
            "shape": "S32"
          },
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateResourcePolicy": {
      "http": {
        "requestUri": "/policy/{resourceArn}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "policy"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "revisionId": {}
        }
      }
    },
    "CreateResourcePolicyStatement": {
      "http": {
        "requestUri": "/policy/{resourceArn}/statements/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "statementId",
          "effect",
          "principal",
          "action"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "statementId": {},
          "effect": {},
          "principal": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "service": {},
                "arn": {}
              }
            }
          },
          "action": {
            "type": "list",
            "member": {}
          },
          "condition": {
            "type": "map",
            "key": {},
            "value": {
              "type": "map",
              "key": {},
              "value": {}
            }
          },
          "expectedRevisionId": {
            "location": "querystring",
            "locationName": "expectedRevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "revisionId": {}
        }
      }
    },
    "CreateSlot": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/slots/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "slotName",
          "valueElicitationSetting",
          "botId",
          "botVersion",
          "localeId",
          "intentId"
        ],
        "members": {
          "slotName": {},
          "description": {},
          "slotTypeId": {},
          "valueElicitationSetting": {
            "shape": "S3r"
          },
          "obfuscationSetting": {
            "shape": "S41"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          },
          "multipleValuesSetting": {
            "shape": "S43"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotId": {},
          "slotName": {},
          "description": {},
          "slotTypeId": {},
          "valueElicitationSetting": {
            "shape": "S3r"
          },
          "obfuscationSetting": {
            "shape": "S41"
          },
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "intentId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "multipleValuesSetting": {
            "shape": "S43"
          }
        }
      }
    },
    "CreateSlotType": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/slottypes/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "slotTypeName",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "slotTypeName": {},
          "description": {},
          "slotTypeValues": {
            "shape": "S46"
          },
          "valueSelectionSetting": {
            "shape": "S4b"
          },
          "parentSlotTypeSignature": {},
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "externalSourceSetting": {
            "shape": "S4i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotTypeId": {},
          "slotTypeName": {},
          "description": {},
          "slotTypeValues": {
            "shape": "S46"
          },
          "valueSelectionSetting": {
            "shape": "S4b"
          },
          "parentSlotTypeSignature": {},
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "externalSourceSetting": {
            "shape": "S4i"
          }
        }
      }
    },
    "CreateUploadUrl": {
      "http": {
        "requestUri": "/createuploadurl/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "importId": {},
          "uploadUrl": {}
        }
      }
    },
    "DeleteBot": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "skipResourceInUseCheck": {
            "location": "querystring",
            "locationName": "skipResourceInUseCheck",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botStatus": {}
        }
      }
    },
    "DeleteBotAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botaliases/{botAliasId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botAliasId",
          "botId"
        ],
        "members": {
          "botAliasId": {
            "location": "uri",
            "locationName": "botAliasId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "skipResourceInUseCheck": {
            "location": "querystring",
            "locationName": "skipResourceInUseCheck",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botAliasId": {},
          "botId": {},
          "botAliasStatus": {}
        }
      }
    },
    "DeleteBotLocale": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botLocaleStatus": {}
        }
      }
    },
    "DeleteBotVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "skipResourceInUseCheck": {
            "location": "querystring",
            "locationName": "skipResourceInUseCheck",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "botStatus": {}
        }
      }
    },
    "DeleteCustomVocabulary": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/customvocabulary",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "customVocabularyStatus": {}
        }
      }
    },
    "DeleteExport": {
      "http": {
        "method": "DELETE",
        "requestUri": "/exports/{exportId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "exportId"
        ],
        "members": {
          "exportId": {
            "location": "uri",
            "locationName": "exportId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportId": {},
          "exportStatus": {}
        }
      }
    },
    "DeleteImport": {
      "http": {
        "method": "DELETE",
        "requestUri": "/imports/{importId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "importId"
        ],
        "members": {
          "importId": {
            "location": "uri",
            "locationName": "importId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "importId": {},
          "importStatus": {}
        }
      }
    },
    "DeleteIntent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "intentId",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      }
    },
    "DeleteResourcePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policy/{resourceArn}/",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "expectedRevisionId": {
            "location": "querystring",
            "locationName": "expectedRevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "revisionId": {}
        }
      }
    },
    "DeleteResourcePolicyStatement": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policy/{resourceArn}/statements/{statementId}/",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "statementId"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "statementId": {
            "location": "uri",
            "locationName": "statementId"
          },
          "expectedRevisionId": {
            "location": "querystring",
            "locationName": "expectedRevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "revisionId": {}
        }
      }
    },
    "DeleteSlot": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/slots/{slotId}/",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "slotId",
          "botId",
          "botVersion",
          "localeId",
          "intentId"
        ],
        "members": {
          "slotId": {
            "location": "uri",
            "locationName": "slotId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          }
        }
      }
    },
    "DeleteSlotType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/slottypes/{slotTypeId}/",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "slotTypeId",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "slotTypeId": {
            "location": "uri",
            "locationName": "slotTypeId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "skipResourceInUseCheck": {
            "location": "querystring",
            "locationName": "skipResourceInUseCheck",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteUtterances": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bots/{botId}/utterances/",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "botId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "localeId": {
            "location": "querystring",
            "locationName": "localeId"
          },
          "sessionId": {
            "location": "querystring",
            "locationName": "sessionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeBot": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botName": {},
          "description": {},
          "roleArn": {},
          "dataPrivacy": {
            "shape": "Sc"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "botStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeBotAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botaliases/{botAliasId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botAliasId",
          "botId"
        ],
        "members": {
          "botAliasId": {
            "location": "uri",
            "locationName": "botAliasId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botAliasId": {},
          "botAliasName": {},
          "description": {},
          "botVersion": {},
          "botAliasLocaleSettings": {
            "shape": "Sm"
          },
          "conversationLogSettings": {
            "shape": "St"
          },
          "sentimentAnalysisSettings": {
            "shape": "S16"
          },
          "botAliasHistoryEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "botVersion": {},
                "startDate": {
                  "type": "timestamp"
                },
                "endDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "botAliasStatus": {},
          "botId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeBotLocale": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "localeName": {},
          "description": {},
          "nluIntentConfidenceThreshold": {
            "type": "double"
          },
          "voiceSettings": {
            "shape": "S1c"
          },
          "intentsCount": {
            "type": "integer"
          },
          "slotTypesCount": {
            "type": "integer"
          },
          "botLocaleStatus": {},
          "failureReasons": {
            "shape": "S5r"
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "lastBuildSubmittedDateTime": {
            "type": "timestamp"
          },
          "botLocaleHistoryEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "event",
                "eventDate"
              ],
              "members": {
                "event": {},
                "eventDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "recommendedActions": {
            "shape": "S5w"
          }
        }
      }
    },
    "DescribeBotRecommendation": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/botrecommendations/{botRecommendationId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "botRecommendationId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "botRecommendationId": {
            "location": "uri",
            "locationName": "botRecommendationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botRecommendationStatus": {},
          "botRecommendationId": {},
          "failureReasons": {
            "shape": "S5r"
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "transcriptSourceSetting": {
            "shape": "S61"
          },
          "encryptionSetting": {
            "shape": "S6a"
          },
          "botRecommendationResults": {
            "type": "structure",
            "members": {
              "botLocaleExportUrl": {},
              "associatedTranscriptsUrl": {},
              "statistics": {
                "type": "structure",
                "members": {
                  "intents": {
                    "type": "structure",
                    "members": {
                      "discoveredIntentCount": {
                        "type": "integer"
                      }
                    }
                  },
                  "slotTypes": {
                    "type": "structure",
                    "members": {
                      "discoveredSlotTypeCount": {
                        "type": "integer"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeBotVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botName": {},
          "botVersion": {},
          "description": {},
          "roleArn": {},
          "dataPrivacy": {
            "shape": "Sc"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "botStatus": {},
          "failureReasons": {
            "shape": "S5r"
          },
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeCustomVocabularyMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/customvocabulary/DEFAULT/metadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "customVocabularyStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeExport": {
      "http": {
        "method": "GET",
        "requestUri": "/exports/{exportId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "exportId"
        ],
        "members": {
          "exportId": {
            "location": "uri",
            "locationName": "exportId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportId": {},
          "resourceSpecification": {
            "shape": "S1n"
          },
          "fileFormat": {},
          "exportStatus": {},
          "failureReasons": {
            "shape": "S5r"
          },
          "downloadUrl": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeImport": {
      "http": {
        "method": "GET",
        "requestUri": "/imports/{importId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "importId"
        ],
        "members": {
          "importId": {
            "location": "uri",
            "locationName": "importId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "importId": {},
          "resourceSpecification": {
            "shape": "S6p"
          },
          "importedResourceId": {},
          "importedResourceName": {},
          "mergeStrategy": {},
          "importStatus": {},
          "failureReasons": {
            "shape": "S5r"
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeIntent": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "intentId",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intentId": {},
          "intentName": {},
          "description": {},
          "parentIntentSignature": {},
          "sampleUtterances": {
            "shape": "S1x"
          },
          "dialogCodeHook": {
            "shape": "S20"
          },
          "fulfillmentCodeHook": {
            "shape": "S21"
          },
          "slotPriorities": {
            "shape": "S6x"
          },
          "intentConfirmationSetting": {
            "shape": "S2s"
          },
          "intentClosingSetting": {
            "shape": "S2v"
          },
          "inputContexts": {
            "shape": "S2w"
          },
          "outputContexts": {
            "shape": "S2y"
          },
          "kendraConfiguration": {
            "shape": "S32"
          },
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeResourcePolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/policy/{resourceArn}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "policy": {},
          "revisionId": {}
        }
      }
    },
    "DescribeSlot": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/slots/{slotId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "slotId",
          "botId",
          "botVersion",
          "localeId",
          "intentId"
        ],
        "members": {
          "slotId": {
            "location": "uri",
            "locationName": "slotId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotId": {},
          "slotName": {},
          "description": {},
          "slotTypeId": {},
          "valueElicitationSetting": {
            "shape": "S3r"
          },
          "obfuscationSetting": {
            "shape": "S41"
          },
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "intentId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "multipleValuesSetting": {
            "shape": "S43"
          }
        }
      }
    },
    "DescribeSlotType": {
      "http": {
        "method": "GET",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/slottypes/{slotTypeId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "slotTypeId",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "slotTypeId": {
            "location": "uri",
            "locationName": "slotTypeId"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotTypeId": {},
          "slotTypeName": {},
          "description": {},
          "slotTypeValues": {
            "shape": "S46"
          },
          "valueSelectionSetting": {
            "shape": "S4b"
          },
          "parentSlotTypeSignature": {},
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "externalSourceSetting": {
            "shape": "S4i"
          }
        }
      }
    },
    "ListAggregatedUtterances": {
      "http": {
        "requestUri": "/bots/{botId}/aggregatedutterances/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "localeId",
          "aggregationDuration"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botAliasId": {},
          "botVersion": {},
          "localeId": {},
          "aggregationDuration": {
            "shape": "S77"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botAliasId": {},
          "botVersion": {},
          "localeId": {},
          "aggregationDuration": {
            "shape": "S77"
          },
          "aggregationWindowStartTime": {
            "type": "timestamp"
          },
          "aggregationWindowEndTime": {
            "type": "timestamp"
          },
          "aggregationLastRefreshedDateTime": {
            "type": "timestamp"
          },
          "aggregatedUtterancesSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "utterance": {},
                "hitCount": {
                  "type": "integer"
                },
                "missedCount": {
                  "type": "integer"
                },
                "utteranceFirstRecordedInAggregationDuration": {
                  "type": "timestamp"
                },
                "utteranceLastRecordedInAggregationDuration": {
                  "type": "timestamp"
                },
                "containsDataFromDeletedResources": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListBotAliases": {
      "http": {
        "requestUri": "/bots/{botId}/botaliases/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botAliasSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "botAliasId": {},
                "botAliasName": {},
                "description": {},
                "botVersion": {},
                "botAliasStatus": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {},
          "botId": {}
        }
      }
    },
    "ListBotLocales": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "nextToken": {},
          "botLocaleSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "localeId": {},
                "localeName": {},
                "description": {},
                "botLocaleStatus": {},
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "lastBuildSubmittedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListBotRecommendations": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/botrecommendations/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botRecommendationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "botRecommendationStatus",
                "botRecommendationId"
              ],
              "members": {
                "botRecommendationStatus": {},
                "botRecommendationId": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListBotVersions": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "botName": {},
                "botVersion": {},
                "description": {},
                "botStatus": {},
                "creationDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListBots": {
      "http": {
        "requestUri": "/bots/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "botId": {},
                "botName": {},
                "description": {},
                "botStatus": {},
                "latestBotVersion": {},
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListBuiltInIntents": {
      "http": {
        "requestUri": "/builtins/locales/{localeId}/intents/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "localeId"
        ],
        "members": {
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "builtInIntentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "intentSignature": {},
                "description": {}
              }
            }
          },
          "nextToken": {},
          "localeId": {}
        }
      }
    },
    "ListBuiltInSlotTypes": {
      "http": {
        "requestUri": "/builtins/locales/{localeId}/slottypes/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "localeId"
        ],
        "members": {
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "builtInSlotTypeSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "slotTypeSignature": {},
                "description": {}
              }
            }
          },
          "nextToken": {},
          "localeId": {}
        }
      }
    },
    "ListExports": {
      "http": {
        "requestUri": "/exports/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "localeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "exportSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "exportId": {},
                "resourceSpecification": {
                  "shape": "S1n"
                },
                "fileFormat": {},
                "exportStatus": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {},
          "localeId": {}
        }
      }
    },
    "ListImports": {
      "http": {
        "requestUri": "/imports/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "localeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "importSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "importId": {},
                "importedResourceId": {},
                "importedResourceName": {},
                "importStatus": {},
                "mergeStrategy": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "importedResourceType": {}
              }
            }
          },
          "nextToken": {},
          "localeId": {}
        }
      }
    },
    "ListIntents": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "intentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "intentId": {},
                "intentName": {},
                "description": {},
                "parentIntentSignature": {},
                "inputContexts": {
                  "shape": "S2w"
                },
                "outputContexts": {
                  "shape": "S2y"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRecommendedIntents": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/botrecommendations/{botRecommendationId}/intents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "botRecommendationId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "botRecommendationId": {
            "location": "uri",
            "locationName": "botRecommendationId"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botRecommendationId": {},
          "summaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "intentId": {},
                "intentName": {},
                "sampleUtterancesCount": {
                  "type": "integer"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSlotTypes": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/slottypes/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "slotTypeSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "slotTypeId": {},
                "slotTypeName": {},
                "description": {},
                "parentSlotTypeSignature": {},
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                },
                "slotTypeCategory": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSlots": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/slots/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "intentId"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          },
          "sortBy": {
            "type": "structure",
            "required": [
              "attribute",
              "order"
            ],
            "members": {
              "attribute": {},
              "order": {}
            }
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values",
                "operator"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                },
                "operator": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "intentId": {},
          "slotSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "slotId": {},
                "slotName": {},
                "description": {},
                "slotConstraint": {},
                "slotTypeId": {},
                "valueElicitationPromptSpecification": {
                  "shape": "S2t"
                },
                "lastUpdatedDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceARN}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN"
        ],
        "members": {
          "resourceARN": {
            "location": "uri",
            "locationName": "resourceARN"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sf"
          }
        }
      }
    },
    "SearchAssociatedTranscripts": {
      "http": {
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/botrecommendations/{botRecommendationId}/associatedtranscripts",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "botRecommendationId",
          "filters"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "botRecommendationId": {
            "location": "uri",
            "locationName": "botRecommendationId"
          },
          "searchOrder": {},
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "values"
              ],
              "members": {
                "name": {},
                "values": {
                  "shape": "S7h"
                }
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextIndex": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botRecommendationId": {},
          "nextIndex": {
            "type": "integer"
          },
          "associatedTranscripts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "transcript": {}
              }
            }
          },
          "totalResults": {
            "type": "integer"
          }
        }
      }
    },
    "StartBotRecommendation": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/botrecommendations/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "transcriptSourceSetting"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "transcriptSourceSetting": {
            "shape": "S61"
          },
          "encryptionSetting": {
            "shape": "S6a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botRecommendationStatus": {},
          "botRecommendationId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "transcriptSourceSetting": {
            "shape": "S61"
          },
          "encryptionSetting": {
            "shape": "S6a"
          }
        }
      }
    },
    "StartImport": {
      "http": {
        "method": "PUT",
        "requestUri": "/imports/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "importId",
          "resourceSpecification",
          "mergeStrategy"
        ],
        "members": {
          "importId": {},
          "resourceSpecification": {
            "shape": "S6p"
          },
          "mergeStrategy": {},
          "filePassword": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "importId": {},
          "resourceSpecification": {
            "shape": "S6p"
          },
          "mergeStrategy": {},
          "importStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceARN}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tags"
        ],
        "members": {
          "resourceARN": {
            "location": "uri",
            "locationName": "resourceARN"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceARN}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tagKeys"
        ],
        "members": {
          "resourceARN": {
            "location": "uri",
            "locationName": "resourceARN"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateBot": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botName",
          "roleArn",
          "dataPrivacy",
          "idleSessionTTLInSeconds"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botName": {},
          "description": {},
          "roleArn": {},
          "dataPrivacy": {
            "shape": "Sc"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botName": {},
          "description": {},
          "roleArn": {},
          "dataPrivacy": {
            "shape": "Sc"
          },
          "idleSessionTTLInSeconds": {
            "type": "integer"
          },
          "botStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateBotAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botaliases/{botAliasId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botAliasId",
          "botAliasName",
          "botId"
        ],
        "members": {
          "botAliasId": {
            "location": "uri",
            "locationName": "botAliasId"
          },
          "botAliasName": {},
          "description": {},
          "botVersion": {},
          "botAliasLocaleSettings": {
            "shape": "Sm"
          },
          "conversationLogSettings": {
            "shape": "St"
          },
          "sentimentAnalysisSettings": {
            "shape": "S16"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botAliasId": {},
          "botAliasName": {},
          "description": {},
          "botVersion": {},
          "botAliasLocaleSettings": {
            "shape": "Sm"
          },
          "conversationLogSettings": {
            "shape": "St"
          },
          "sentimentAnalysisSettings": {
            "shape": "S16"
          },
          "botAliasStatus": {},
          "botId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateBotLocale": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "nluIntentConfidenceThreshold"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "description": {},
          "nluIntentConfidenceThreshold": {
            "type": "double"
          },
          "voiceSettings": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "localeName": {},
          "description": {},
          "nluIntentConfidenceThreshold": {
            "type": "double"
          },
          "voiceSettings": {
            "shape": "S1c"
          },
          "botLocaleStatus": {},
          "failureReasons": {
            "shape": "S5r"
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "recommendedActions": {
            "shape": "S5w"
          }
        }
      }
    },
    "UpdateBotRecommendation": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/botrecommendations/{botRecommendationId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "botId",
          "botVersion",
          "localeId",
          "botRecommendationId",
          "encryptionSetting"
        ],
        "members": {
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "botRecommendationId": {
            "location": "uri",
            "locationName": "botRecommendationId"
          },
          "encryptionSetting": {
            "shape": "S6a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "botRecommendationStatus": {},
          "botRecommendationId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "transcriptSourceSetting": {
            "shape": "S61"
          },
          "encryptionSetting": {
            "shape": "S6a"
          }
        }
      }
    },
    "UpdateExport": {
      "http": {
        "method": "PUT",
        "requestUri": "/exports/{exportId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "exportId"
        ],
        "members": {
          "exportId": {
            "location": "uri",
            "locationName": "exportId"
          },
          "filePassword": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportId": {},
          "resourceSpecification": {
            "shape": "S1n"
          },
          "fileFormat": {},
          "exportStatus": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateIntent": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "intentId",
          "intentName",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          },
          "intentName": {},
          "description": {},
          "parentIntentSignature": {},
          "sampleUtterances": {
            "shape": "S1x"
          },
          "dialogCodeHook": {
            "shape": "S20"
          },
          "fulfillmentCodeHook": {
            "shape": "S21"
          },
          "slotPriorities": {
            "shape": "S6x"
          },
          "intentConfirmationSetting": {
            "shape": "S2s"
          },
          "intentClosingSetting": {
            "shape": "S2v"
          },
          "inputContexts": {
            "shape": "S2w"
          },
          "outputContexts": {
            "shape": "S2y"
          },
          "kendraConfiguration": {
            "shape": "S32"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intentId": {},
          "intentName": {},
          "description": {},
          "parentIntentSignature": {},
          "sampleUtterances": {
            "shape": "S1x"
          },
          "dialogCodeHook": {
            "shape": "S20"
          },
          "fulfillmentCodeHook": {
            "shape": "S21"
          },
          "slotPriorities": {
            "shape": "S6x"
          },
          "intentConfirmationSetting": {
            "shape": "S2s"
          },
          "intentClosingSetting": {
            "shape": "S2v"
          },
          "inputContexts": {
            "shape": "S2w"
          },
          "outputContexts": {
            "shape": "S2y"
          },
          "kendraConfiguration": {
            "shape": "S32"
          },
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateResourcePolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/policy/{resourceArn}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "policy"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "policy": {},
          "expectedRevisionId": {
            "location": "querystring",
            "locationName": "expectedRevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "revisionId": {}
        }
      }
    },
    "UpdateSlot": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/intents/{intentId}/slots/{slotId}/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "slotId",
          "slotName",
          "valueElicitationSetting",
          "botId",
          "botVersion",
          "localeId",
          "intentId"
        ],
        "members": {
          "slotId": {
            "location": "uri",
            "locationName": "slotId"
          },
          "slotName": {},
          "description": {},
          "slotTypeId": {},
          "valueElicitationSetting": {
            "shape": "S3r"
          },
          "obfuscationSetting": {
            "shape": "S41"
          },
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "intentId": {
            "location": "uri",
            "locationName": "intentId"
          },
          "multipleValuesSetting": {
            "shape": "S43"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotId": {},
          "slotName": {},
          "description": {},
          "slotTypeId": {},
          "valueElicitationSetting": {
            "shape": "S3r"
          },
          "obfuscationSetting": {
            "shape": "S41"
          },
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "intentId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "multipleValuesSetting": {
            "shape": "S43"
          }
        }
      }
    },
    "UpdateSlotType": {
      "http": {
        "method": "PUT",
        "requestUri": "/bots/{botId}/botversions/{botVersion}/botlocales/{localeId}/slottypes/{slotTypeId}/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "slotTypeId",
          "slotTypeName",
          "botId",
          "botVersion",
          "localeId"
        ],
        "members": {
          "slotTypeId": {
            "location": "uri",
            "locationName": "slotTypeId"
          },
          "slotTypeName": {},
          "description": {},
          "slotTypeValues": {
            "shape": "S46"
          },
          "valueSelectionSetting": {
            "shape": "S4b"
          },
          "parentSlotTypeSignature": {},
          "botId": {
            "location": "uri",
            "locationName": "botId"
          },
          "botVersion": {
            "location": "uri",
            "locationName": "botVersion"
          },
          "localeId": {
            "location": "uri",
            "locationName": "localeId"
          },
          "externalSourceSetting": {
            "shape": "S4i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "slotTypeId": {},
          "slotTypeName": {},
          "description": {},
          "slotTypeValues": {
            "shape": "S46"
          },
          "valueSelectionSetting": {
            "shape": "S4b"
          },
          "parentSlotTypeSignature": {},
          "botId": {},
          "botVersion": {},
          "localeId": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "lastUpdatedDateTime": {
            "type": "timestamp"
          },
          "externalSourceSetting": {
            "shape": "S4i"
          }
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "structure",
      "required": [
        "childDirected"
      ],
      "members": {
        "childDirected": {
          "type": "boolean"
        }
      }
    },
    "Sf": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "enabled"
        ],
        "members": {
          "enabled": {
            "type": "boolean"
          },
          "codeHookSpecification": {
            "type": "structure",
            "required": [
              "lambdaCodeHook"
            ],
            "members": {
              "lambdaCodeHook": {
                "type": "structure",
                "required": [
                  "lambdaARN",
                  "codeHookInterfaceVersion"
                ],
                "members": {
                  "lambdaARN": {},
                  "codeHookInterfaceVersion": {}
                }
              }
            }
          }
        }
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "textLogSettings": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "enabled",
              "destination"
            ],
            "members": {
              "enabled": {
                "type": "boolean"
              },
              "destination": {
                "type": "structure",
                "required": [
                  "cloudWatch"
                ],
                "members": {
                  "cloudWatch": {
                    "type": "structure",
                    "required": [
                      "cloudWatchLogGroupArn",
                      "logPrefix"
                    ],
                    "members": {
                      "cloudWatchLogGroupArn": {},
                      "logPrefix": {}
                    }
                  }
                }
              }
            }
          }
        },
        "audioLogSettings": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "enabled",
              "destination"
            ],
            "members": {
              "enabled": {
                "type": "boolean"
              },
              "destination": {
                "type": "structure",
                "required": [
                  "s3Bucket"
                ],
                "members": {
                  "s3Bucket": {
                    "type": "structure",
                    "required": [
                      "s3BucketArn",
                      "logPrefix"
                    ],
                    "members": {
                      "kmsKeyArn": {},
                      "s3BucketArn": {},
                      "logPrefix": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "detectSentiment"
      ],
      "members": {
        "detectSentiment": {
          "type": "boolean"
        }
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "voiceId"
      ],
      "members": {
        "voiceId": {},
        "engine": {}
      }
    },
    "S1i": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "sourceBotVersion"
        ],
        "members": {
          "sourceBotVersion": {}
        }
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "botExportSpecification": {
          "type": "structure",
          "required": [
            "botId",
            "botVersion"
          ],
          "members": {
            "botId": {},
            "botVersion": {}
          }
        },
        "botLocaleExportSpecification": {
          "type": "structure",
          "required": [
            "botId",
            "botVersion",
            "localeId"
          ],
          "members": {
            "botId": {},
            "botVersion": {},
            "localeId": {}
          }
        },
        "customVocabularyExportSpecification": {
          "type": "structure",
          "required": [
            "botId",
            "botVersion",
            "localeId"
          ],
          "members": {
            "botId": {},
            "botVersion": {},
            "localeId": {}
          }
        }
      }
    },
    "S1s": {
      "type": "string",
      "sensitive": true
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "utterance"
        ],
        "members": {
          "utterance": {}
        }
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "enabled"
      ],
      "members": {
        "enabled": {
          "type": "boolean"
        }
      }
    },
    "S21": {
      "type": "structure",
      "required": [
        "enabled"
      ],
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "postFulfillmentStatusSpecification": {
          "type": "structure",
          "members": {
            "successResponse": {
              "shape": "S23"
            },
            "failureResponse": {
              "shape": "S23"
            },
            "timeoutResponse": {
              "shape": "S23"
            }
          }
        },
        "fulfillmentUpdatesSpecification": {
          "type": "structure",
          "required": [
            "active"
          ],
          "members": {
            "active": {
              "type": "boolean"
            },
            "startResponse": {
              "type": "structure",
              "required": [
                "delayInSeconds",
                "messageGroups"
              ],
              "members": {
                "delayInSeconds": {
                  "type": "integer"
                },
                "messageGroups": {
                  "shape": "S24"
                },
                "allowInterrupt": {
                  "type": "boolean"
                }
              }
            },
            "updateResponse": {
              "type": "structure",
              "required": [
                "frequencyInSeconds",
                "messageGroups"
              ],
              "members": {
                "frequencyInSeconds": {
                  "type": "integer"
                },
                "messageGroups": {
                  "shape": "S24"
                },
                "allowInterrupt": {
                  "type": "boolean"
                }
              }
            },
            "timeoutInSeconds": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "messageGroups"
      ],
      "members": {
        "messageGroups": {
          "shape": "S24"
        },
        "allowInterrupt": {
          "type": "boolean"
        }
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "message"
        ],
        "members": {
          "message": {
            "shape": "S26"
          },
          "variations": {
            "type": "list",
            "member": {
              "shape": "S26"
            }
          }
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "plainTextMessage": {
          "type": "structure",
          "required": [
            "value"
          ],
          "members": {
            "value": {}
          }
        },
        "customPayload": {
          "type": "structure",
          "required": [
            "value"
          ],
          "members": {
            "value": {}
          }
        },
        "ssmlMessage": {
          "type": "structure",
          "required": [
            "value"
          ],
          "members": {
            "value": {}
          }
        },
        "imageResponseCard": {
          "type": "structure",
          "required": [
            "title"
          ],
          "members": {
            "title": {},
            "subtitle": {},
            "imageUrl": {},
            "buttons": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "text",
                  "value"
                ],
                "members": {
                  "text": {},
                  "value": {}
                }
              }
            }
          }
        }
      }
    },
    "S2s": {
      "type": "structure",
      "required": [
        "promptSpecification",
        "declinationResponse"
      ],
      "members": {
        "promptSpecification": {
          "shape": "S2t"
        },
        "declinationResponse": {
          "shape": "S23"
        },
        "active": {
          "type": "boolean"
        }
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "messageGroups",
        "maxRetries"
      ],
      "members": {
        "messageGroups": {
          "shape": "S24"
        },
        "maxRetries": {
          "type": "integer"
        },
        "allowInterrupt": {
          "type": "boolean"
        }
      }
    },
    "S2v": {
      "type": "structure",
      "required": [
        "closingResponse"
      ],
      "members": {
        "closingResponse": {
          "shape": "S23"
        },
        "active": {
          "type": "boolean"
        }
      }
    },
    "S2w": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      }
    },
    "S2y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "timeToLiveInSeconds",
          "turnsToLive"
        ],
        "members": {
          "name": {},
          "timeToLiveInSeconds": {
            "type": "integer"
          },
          "turnsToLive": {
            "type": "integer"
          }
        }
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "kendraIndex"
      ],
      "members": {
        "kendraIndex": {},
        "queryFilterStringEnabled": {
          "type": "boolean"
        },
        "queryFilterString": {}
      }
    },
    "S3r": {
      "type": "structure",
      "required": [
        "slotConstraint"
      ],
      "members": {
        "defaultValueSpecification": {
          "type": "structure",
          "required": [
            "defaultValueList"
          ],
          "members": {
            "defaultValueList": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "defaultValue"
                ],
                "members": {
                  "defaultValue": {}
                }
              }
            }
          }
        },
        "slotConstraint": {},
        "promptSpecification": {
          "shape": "S2t"
        },
        "sampleUtterances": {
          "shape": "S1x"
        },
        "waitAndContinueSpecification": {
          "type": "structure",
          "required": [
            "waitingResponse",
            "continueResponse"
          ],
          "members": {
            "waitingResponse": {
              "shape": "S23"
            },
            "continueResponse": {
              "shape": "S23"
            },
            "stillWaitingResponse": {
              "type": "structure",
              "required": [
                "messageGroups",
                "frequencyInSeconds",
                "timeoutInSeconds"
              ],
              "members": {
                "messageGroups": {
                  "shape": "S24"
                },
                "frequencyInSeconds": {
                  "type": "integer"
                },
                "timeoutInSeconds": {
                  "type": "integer"
                },
                "allowInterrupt": {
                  "type": "boolean"
                }
              }
            },
            "active": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S41": {
      "type": "structure",
      "required": [
        "obfuscationSettingType"
      ],
      "members": {
        "obfuscationSettingType": {}
      }
    },
    "S43": {
      "type": "structure",
      "members": {
        "allowMultipleValues": {
          "type": "boolean"
        }
      }
    },
    "S46": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "sampleValue": {
            "shape": "S48"
          },
          "synonyms": {
            "type": "list",
            "member": {
              "shape": "S48"
            }
          }
        }
      }
    },
    "S48": {
      "type": "structure",
      "required": [
        "value"
      ],
      "members": {
        "value": {}
      }
    },
    "S4b": {
      "type": "structure",
      "required": [
        "resolutionStrategy"
      ],
      "members": {
        "resolutionStrategy": {},
        "regexFilter": {
          "type": "structure",
          "required": [
            "pattern"
          ],
          "members": {
            "pattern": {}
          }
        },
        "advancedRecognitionSetting": {
          "type": "structure",
          "members": {
            "audioRecognitionStrategy": {}
          }
        }
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "grammarSlotTypeSetting": {
          "type": "structure",
          "members": {
            "source": {
              "type": "structure",
              "required": [
                "s3BucketName",
                "s3ObjectKey"
              ],
              "members": {
                "s3BucketName": {},
                "s3ObjectKey": {},
                "kmsKeyArn": {}
              }
            }
          }
        }
      }
    },
    "S5r": {
      "type": "list",
      "member": {}
    },
    "S5w": {
      "type": "list",
      "member": {}
    },
    "S61": {
      "type": "structure",
      "members": {
        "s3BucketTranscriptSource": {
          "type": "structure",
          "required": [
            "s3BucketName",
            "transcriptFormat"
          ],
          "members": {
            "s3BucketName": {},
            "pathFormat": {
              "type": "structure",
              "members": {
                "objectPrefixes": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "transcriptFormat": {},
            "transcriptFilter": {
              "type": "structure",
              "members": {
                "lexTranscriptFilter": {
                  "type": "structure",
                  "members": {
                    "dateRangeFilter": {
                      "type": "structure",
                      "required": [
                        "startDateTime",
                        "endDateTime"
                      ],
                      "members": {
                        "startDateTime": {
                          "type": "timestamp"
                        },
                        "endDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                }
              }
            },
            "kmsKeyArn": {}
          }
        }
      }
    },
    "S6a": {
      "type": "structure",
      "members": {
        "kmsKeyArn": {},
        "botLocaleExportPassword": {
          "shape": "S6b"
        },
        "associatedTranscriptsPassword": {
          "shape": "S6b"
        }
      }
    },
    "S6b": {
      "type": "string",
      "sensitive": true
    },
    "S6p": {
      "type": "structure",
      "members": {
        "botImportSpecification": {
          "type": "structure",
          "required": [
            "botName",
            "roleArn",
            "dataPrivacy"
          ],
          "members": {
            "botName": {},
            "roleArn": {},
            "dataPrivacy": {
              "shape": "Sc"
            },
            "idleSessionTTLInSeconds": {
              "type": "integer"
            },
            "botTags": {
              "shape": "Sf"
            },
            "testBotAliasTags": {
              "shape": "Sf"
            }
          }
        },
        "botLocaleImportSpecification": {
          "type": "structure",
          "required": [
            "botId",
            "botVersion",
            "localeId"
          ],
          "members": {
            "botId": {},
            "botVersion": {},
            "localeId": {},
            "nluIntentConfidenceThreshold": {
              "type": "double"
            },
            "voiceSettings": {
              "shape": "S1c"
            }
          }
        },
        "customVocabularyImportSpecification": {
          "type": "structure",
          "required": [
            "botId",
            "botVersion",
            "localeId"
          ],
          "members": {
            "botId": {},
            "botVersion": {},
            "localeId": {}
          }
        }
      }
    },
    "S6x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "priority",
          "slotId"
        ],
        "members": {
          "priority": {
            "type": "integer"
          },
          "slotId": {}
        }
      }
    },
    "S77": {
      "type": "structure",
      "required": [
        "relativeAggregationDuration"
      ],
      "members": {
        "relativeAggregationDuration": {
          "type": "structure",
          "required": [
            "timeDimension",
            "timeValue"
          ],
          "members": {
            "timeDimension": {},
            "timeValue": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S7h": {
      "type": "list",
      "member": {}
    }
  }
}
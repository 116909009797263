{
  "pagination": {
    "ListEnvironmentAccountConnections": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "environmentAccountConnections"
    },
    "ListEnvironmentOutputs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "outputs"
    },
    "ListEnvironmentProvisionedResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "provisionedResources"
    },
    "ListEnvironmentTemplateVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templateVersions"
    },
    "ListEnvironmentTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templates"
    },
    "ListEnvironments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "environments"
    },
    "ListRepositories": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "repositories"
    },
    "ListRepositorySyncDefinitions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "syncDefinitions"
    },
    "ListServiceInstanceOutputs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "outputs"
    },
    "ListServiceInstanceProvisionedResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "provisionedResources"
    },
    "ListServiceInstances": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "serviceInstances"
    },
    "ListServicePipelineOutputs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "outputs"
    },
    "ListServicePipelineProvisionedResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "provisionedResources"
    },
    "ListServiceTemplateVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templateVersions"
    },
    "ListServiceTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "templates"
    },
    "ListServices": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "services"
    },
    "ListTagsForResource": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "tags"
    }
  }
}

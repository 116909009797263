{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-13",
    "endpointPrefix": "finspace-api",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "FinSpace Data",
    "serviceFullName": "FinSpace Public API",
    "serviceId": "finspace data",
    "signatureVersion": "v4",
    "signingName": "finspace-api",
    "uid": "finspace-2020-07-13"
  },
  "operations": {
    "CreateChangeset": {
      "http": {
        "requestUri": "/datasets/{datasetId}/changesetsv2"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId",
          "changeType",
          "sourceParams",
          "formatParams"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "changeType": {},
          "sourceParams": {
            "shape": "S5"
          },
          "formatParams": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetId": {},
          "changesetId": {}
        }
      }
    },
    "CreateDataView": {
      "http": {
        "requestUri": "/datasets/{datasetId}/dataviewsv2"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId",
          "destinationTypeParams"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "autoUpdate": {
            "type": "boolean"
          },
          "sortColumns": {
            "shape": "Sd"
          },
          "partitionColumns": {
            "shape": "Sf"
          },
          "asOfTimestamp": {
            "type": "long"
          },
          "destinationTypeParams": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetId": {},
          "dataViewId": {}
        }
      }
    },
    "CreateDataset": {
      "http": {
        "requestUri": "/datasetsv2"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetTitle",
          "kind",
          "permissionGroupParams"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "datasetTitle": {},
          "kind": {},
          "datasetDescription": {},
          "ownerInfo": {
            "shape": "Sr"
          },
          "permissionGroupParams": {
            "type": "structure",
            "members": {
              "permissionGroupId": {},
              "datasetPermissions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "permission": {}
                  }
                }
              }
            }
          },
          "alias": {},
          "schemaDefinition": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetId": {}
        }
      }
    },
    "CreatePermissionGroup": {
      "http": {
        "requestUri": "/permission-group"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "applicationPermissions"
        ],
        "members": {
          "name": {
            "shape": "S1b"
          },
          "description": {
            "shape": "S1c"
          },
          "applicationPermissions": {
            "shape": "S1d"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "permissionGroupId": {}
        }
      }
    },
    "CreateUser": {
      "http": {
        "requestUri": "/user"
      },
      "input": {
        "type": "structure",
        "required": [
          "emailAddress",
          "type"
        ],
        "members": {
          "emailAddress": {
            "shape": "Su"
          },
          "type": {},
          "firstName": {
            "shape": "S1i"
          },
          "lastName": {
            "shape": "S1j"
          },
          "ApiAccess": {},
          "apiAccessPrincipalArn": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userId": {}
        }
      }
    },
    "DeleteDataset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/datasetsv2/{datasetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetId": {}
        }
      }
    },
    "DeletePermissionGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/permission-group/{permissionGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "permissionGroupId"
        ],
        "members": {
          "permissionGroupId": {
            "location": "uri",
            "locationName": "permissionGroupId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "permissionGroupId": {}
        }
      }
    },
    "DisableUser": {
      "http": {
        "requestUri": "/user/{userId}/disable"
      },
      "input": {
        "type": "structure",
        "required": [
          "userId"
        ],
        "members": {
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userId": {}
        }
      }
    },
    "EnableUser": {
      "http": {
        "requestUri": "/user/{userId}/enable"
      },
      "input": {
        "type": "structure",
        "required": [
          "userId"
        ],
        "members": {
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userId": {}
        }
      }
    },
    "GetChangeset": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetId}/changesetsv2/{changesetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId",
          "changesetId"
        ],
        "members": {
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "changesetId": {
            "location": "uri",
            "locationName": "changesetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "changesetId": {},
          "changesetArn": {},
          "datasetId": {},
          "changeType": {},
          "sourceParams": {
            "shape": "S5"
          },
          "formatParams": {
            "shape": "S8"
          },
          "createTime": {
            "type": "long"
          },
          "status": {},
          "errorInfo": {
            "shape": "S20"
          },
          "activeUntilTimestamp": {
            "type": "long"
          },
          "activeFromTimestamp": {
            "type": "long"
          },
          "updatesChangesetId": {},
          "updatedByChangesetId": {}
        }
      }
    },
    "GetDataView": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetId}/dataviewsv2/{dataviewId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "dataViewId",
          "datasetId"
        ],
        "members": {
          "dataViewId": {
            "location": "uri",
            "locationName": "dataviewId"
          },
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "autoUpdate": {
            "type": "boolean"
          },
          "partitionColumns": {
            "shape": "Sf"
          },
          "datasetId": {},
          "asOfTimestamp": {
            "type": "long"
          },
          "errorInfo": {
            "shape": "S25"
          },
          "lastModifiedTime": {
            "type": "long"
          },
          "createTime": {
            "type": "long"
          },
          "sortColumns": {
            "shape": "Sd"
          },
          "dataViewId": {},
          "dataViewArn": {},
          "destinationTypeParams": {
            "shape": "Sh"
          },
          "status": {}
        }
      }
    },
    "GetDataset": {
      "http": {
        "method": "GET",
        "requestUri": "/datasetsv2/{datasetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId"
        ],
        "members": {
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetId": {},
          "datasetArn": {},
          "datasetTitle": {},
          "kind": {},
          "datasetDescription": {},
          "createTime": {
            "type": "long"
          },
          "lastModifiedTime": {
            "type": "long"
          },
          "schemaDefinition": {
            "shape": "S11"
          },
          "alias": {},
          "status": {}
        }
      }
    },
    "GetProgrammaticAccessCredentials": {
      "http": {
        "method": "GET",
        "requestUri": "/credentials/programmatic"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "durationInMinutes": {
            "location": "querystring",
            "locationName": "durationInMinutes",
            "type": "long"
          },
          "environmentId": {
            "location": "querystring",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "credentials": {
            "type": "structure",
            "members": {
              "accessKeyId": {},
              "secretAccessKey": {},
              "sessionToken": {}
            }
          },
          "durationInMinutes": {
            "type": "long"
          }
        }
      }
    },
    "GetUser": {
      "http": {
        "method": "GET",
        "requestUri": "/user/{userId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "userId"
        ],
        "members": {
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userId": {},
          "status": {},
          "firstName": {
            "shape": "S1i"
          },
          "lastName": {
            "shape": "S1j"
          },
          "emailAddress": {
            "shape": "Su"
          },
          "type": {},
          "apiAccess": {},
          "apiAccessPrincipalArn": {},
          "createTime": {
            "type": "long"
          },
          "lastEnabledTime": {
            "type": "long"
          },
          "lastDisabledTime": {
            "type": "long"
          },
          "lastModifiedTime": {
            "type": "long"
          },
          "lastLoginTime": {
            "type": "long"
          }
        }
      }
    },
    "GetWorkingLocation": {
      "http": {
        "requestUri": "/workingLocationV1"
      },
      "input": {
        "type": "structure",
        "members": {
          "locationType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "s3Uri": {},
          "s3Path": {},
          "s3Bucket": {}
        }
      }
    },
    "ListChangesets": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetId}/changesetsv2"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId"
        ],
        "members": {
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "changesets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "changesetId": {},
                "changesetArn": {},
                "datasetId": {},
                "changeType": {},
                "sourceParams": {
                  "shape": "S5"
                },
                "formatParams": {
                  "shape": "S8"
                },
                "createTime": {
                  "type": "long"
                },
                "status": {},
                "errorInfo": {
                  "shape": "S20"
                },
                "activeUntilTimestamp": {
                  "type": "long"
                },
                "activeFromTimestamp": {
                  "type": "long"
                },
                "updatesChangesetId": {},
                "updatedByChangesetId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataViews": {
      "http": {
        "method": "GET",
        "requestUri": "/datasets/{datasetId}/dataviewsv2"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId"
        ],
        "members": {
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "dataViews": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "dataViewId": {},
                "dataViewArn": {},
                "datasetId": {},
                "asOfTimestamp": {
                  "type": "long"
                },
                "partitionColumns": {
                  "shape": "Sf"
                },
                "sortColumns": {
                  "shape": "Sd"
                },
                "status": {},
                "errorInfo": {
                  "shape": "S25"
                },
                "destinationTypeProperties": {
                  "shape": "Sh"
                },
                "autoUpdate": {
                  "type": "boolean"
                },
                "createTime": {
                  "type": "long"
                },
                "lastModifiedTime": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "ListDatasets": {
      "http": {
        "method": "GET",
        "requestUri": "/datasetsv2"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "datasetId": {},
                "datasetArn": {},
                "datasetTitle": {},
                "kind": {},
                "datasetDescription": {},
                "ownerInfo": {
                  "shape": "Sr"
                },
                "createTime": {
                  "type": "long"
                },
                "lastModifiedTime": {
                  "type": "long"
                },
                "schemaDefinition": {
                  "shape": "S11"
                },
                "alias": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPermissionGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/permission-group"
      },
      "input": {
        "type": "structure",
        "required": [
          "maxResults"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "permissionGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "permissionGroupId": {},
                "name": {
                  "shape": "S1b"
                },
                "description": {
                  "shape": "S1c"
                },
                "applicationPermissions": {
                  "shape": "S1d"
                },
                "createTime": {
                  "type": "long"
                },
                "lastModifiedTime": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/user"
      },
      "input": {
        "type": "structure",
        "required": [
          "maxResults"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "users": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "userId": {},
                "status": {},
                "firstName": {
                  "shape": "S1i"
                },
                "lastName": {
                  "shape": "S1j"
                },
                "emailAddress": {
                  "shape": "Su"
                },
                "type": {},
                "apiAccess": {},
                "apiAccessPrincipalArn": {},
                "createTime": {
                  "type": "long"
                },
                "lastEnabledTime": {
                  "type": "long"
                },
                "lastDisabledTime": {
                  "type": "long"
                },
                "lastModifiedTime": {
                  "type": "long"
                },
                "lastLoginTime": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ResetUserPassword": {
      "http": {
        "requestUri": "/user/{userId}/password"
      },
      "input": {
        "type": "structure",
        "required": [
          "userId"
        ],
        "members": {
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userId": {},
          "temporaryPassword": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "UpdateChangeset": {
      "http": {
        "method": "PUT",
        "requestUri": "/datasets/{datasetId}/changesetsv2/{changesetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId",
          "changesetId",
          "sourceParams",
          "formatParams"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "changesetId": {
            "location": "uri",
            "locationName": "changesetId"
          },
          "sourceParams": {
            "shape": "S5"
          },
          "formatParams": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "changesetId": {},
          "datasetId": {}
        }
      }
    },
    "UpdateDataset": {
      "http": {
        "method": "PUT",
        "requestUri": "/datasetsv2/{datasetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "datasetId",
          "datasetTitle",
          "kind"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "datasetId": {
            "location": "uri",
            "locationName": "datasetId"
          },
          "datasetTitle": {},
          "kind": {},
          "datasetDescription": {},
          "alias": {},
          "schemaDefinition": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "datasetId": {}
        }
      }
    },
    "UpdatePermissionGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/permission-group/{permissionGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "permissionGroupId"
        ],
        "members": {
          "permissionGroupId": {
            "location": "uri",
            "locationName": "permissionGroupId"
          },
          "name": {
            "shape": "S1b"
          },
          "description": {
            "shape": "S1c"
          },
          "applicationPermissions": {
            "shape": "S1d"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "permissionGroupId": {}
        }
      }
    },
    "UpdateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/user/{userId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "userId"
        ],
        "members": {
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "type": {},
          "firstName": {
            "shape": "S1i"
          },
          "lastName": {
            "shape": "S1j"
          },
          "apiAccess": {},
          "apiAccessPrincipalArn": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userId": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S8": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "list",
      "member": {}
    },
    "Sh": {
      "type": "structure",
      "required": [
        "destinationType"
      ],
      "members": {
        "destinationType": {},
        "s3DestinationExportFileFormat": {},
        "s3DestinationExportFileFormatOptions": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "name": {},
        "phoneNumber": {},
        "email": {
          "shape": "Su"
        }
      }
    },
    "Su": {
      "type": "string",
      "sensitive": true
    },
    "S11": {
      "type": "structure",
      "members": {
        "tabularSchemaConfig": {
          "type": "structure",
          "members": {
            "columns": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "dataType": {},
                  "columnName": {},
                  "columnDescription": {}
                }
              }
            },
            "primaryKeyColumns": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S1b": {
      "type": "string",
      "sensitive": true
    },
    "S1c": {
      "type": "string",
      "sensitive": true
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "string",
      "sensitive": true
    },
    "S1j": {
      "type": "string",
      "sensitive": true
    },
    "S20": {
      "type": "structure",
      "members": {
        "errorMessage": {},
        "errorCategory": {}
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "errorMessage": {},
        "errorCategory": {}
      }
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-12-01",
    "endpointPrefix": "devops-guru",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon DevOps Guru",
    "serviceId": "DevOps Guru",
    "signatureVersion": "v4",
    "signingName": "devops-guru",
    "uid": "devops-guru-2020-12-01"
  },
  "operations": {
    "AddNotificationChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Config"
        ],
        "members": {
          "Config": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      }
    },
    "DeleteInsight": {
      "http": {
        "method": "DELETE",
        "requestUri": "/insights/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountHealth": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/health",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "OpenReactiveInsights",
          "OpenProactiveInsights",
          "MetricsAnalyzed",
          "ResourceHours"
        ],
        "members": {
          "OpenReactiveInsights": {
            "type": "integer"
          },
          "OpenProactiveInsights": {
            "type": "integer"
          },
          "MetricsAnalyzed": {
            "type": "integer"
          },
          "ResourceHours": {
            "type": "long"
          }
        }
      }
    },
    "DescribeAccountOverview": {
      "http": {
        "requestUri": "/accounts/overview",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FromTime"
        ],
        "members": {
          "FromTime": {
            "type": "timestamp"
          },
          "ToTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ReactiveInsights",
          "ProactiveInsights",
          "MeanTimeToRecoverInMilliseconds"
        ],
        "members": {
          "ReactiveInsights": {
            "type": "integer"
          },
          "ProactiveInsights": {
            "type": "integer"
          },
          "MeanTimeToRecoverInMilliseconds": {
            "type": "long"
          }
        }
      }
    },
    "DescribeAnomaly": {
      "http": {
        "method": "GET",
        "requestUri": "/anomalies/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "AccountId": {
            "location": "querystring",
            "locationName": "AccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveAnomaly": {
            "type": "structure",
            "members": {
              "Id": {},
              "Severity": {},
              "Status": {},
              "UpdateTime": {
                "type": "timestamp"
              },
              "AnomalyTimeRange": {
                "shape": "St"
              },
              "AnomalyReportedTimeRange": {
                "shape": "Su"
              },
              "PredictionTimeRange": {
                "shape": "Sv"
              },
              "SourceDetails": {
                "shape": "Sw"
              },
              "AssociatedInsightId": {},
              "ResourceCollection": {
                "shape": "S21"
              },
              "Limit": {
                "type": "double"
              },
              "SourceMetadata": {
                "shape": "S2b"
              },
              "AnomalyResources": {
                "shape": "S2f"
              }
            }
          },
          "ReactiveAnomaly": {
            "type": "structure",
            "members": {
              "Id": {},
              "Severity": {},
              "Status": {},
              "AnomalyTimeRange": {
                "shape": "St"
              },
              "AnomalyReportedTimeRange": {
                "shape": "Su"
              },
              "SourceDetails": {
                "shape": "Sw"
              },
              "AssociatedInsightId": {},
              "ResourceCollection": {
                "shape": "S21"
              },
              "Type": {},
              "Name": {},
              "Description": {},
              "CausalAnomalyId": {},
              "AnomalyResources": {
                "shape": "S2f"
              }
            }
          }
        }
      }
    },
    "DescribeEventSourcesConfig": {
      "http": {
        "requestUri": "/event-sources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSources": {
            "shape": "S2n"
          }
        }
      }
    },
    "DescribeFeedback": {
      "http": {
        "requestUri": "/feedback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "InsightId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InsightFeedback": {
            "shape": "S2s"
          }
        }
      }
    },
    "DescribeInsight": {
      "http": {
        "method": "GET",
        "requestUri": "/insights/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "AccountId": {
            "location": "querystring",
            "locationName": "AccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsight": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Severity": {},
              "Status": {},
              "InsightTimeRange": {
                "shape": "S30"
              },
              "PredictionTimeRange": {
                "shape": "Sv"
              },
              "ResourceCollection": {
                "shape": "S21"
              },
              "SsmOpsItemId": {},
              "Description": {}
            }
          },
          "ReactiveInsight": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Severity": {},
              "Status": {},
              "InsightTimeRange": {
                "shape": "S30"
              },
              "ResourceCollection": {
                "shape": "S21"
              },
              "SsmOpsItemId": {},
              "Description": {}
            }
          }
        }
      }
    },
    "DescribeOrganizationHealth": {
      "http": {
        "requestUri": "/organization/health",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "shape": "S35"
          },
          "OrganizationalUnitIds": {
            "shape": "S36"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OpenReactiveInsights",
          "OpenProactiveInsights",
          "MetricsAnalyzed",
          "ResourceHours"
        ],
        "members": {
          "OpenReactiveInsights": {
            "type": "integer"
          },
          "OpenProactiveInsights": {
            "type": "integer"
          },
          "MetricsAnalyzed": {
            "type": "integer"
          },
          "ResourceHours": {
            "type": "long"
          }
        }
      }
    },
    "DescribeOrganizationOverview": {
      "http": {
        "requestUri": "/organization/overview",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FromTime"
        ],
        "members": {
          "FromTime": {
            "type": "timestamp"
          },
          "ToTime": {
            "type": "timestamp"
          },
          "AccountIds": {
            "shape": "S35"
          },
          "OrganizationalUnitIds": {
            "shape": "S36"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ReactiveInsights",
          "ProactiveInsights"
        ],
        "members": {
          "ReactiveInsights": {
            "type": "integer"
          },
          "ProactiveInsights": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeOrganizationResourceCollectionHealth": {
      "http": {
        "requestUri": "/organization/health/resource-collection",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "OrganizationResourceCollectionType"
        ],
        "members": {
          "OrganizationResourceCollectionType": {},
          "AccountIds": {
            "shape": "S35"
          },
          "OrganizationalUnitIds": {
            "shape": "S36"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFormation": {
            "shape": "S3g"
          },
          "Service": {
            "shape": "S3j"
          },
          "Account": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "Insight": {
                  "type": "structure",
                  "members": {
                    "OpenProactiveInsights": {
                      "type": "integer"
                    },
                    "OpenReactiveInsights": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {},
          "Tags": {
            "shape": "S3q"
          }
        }
      }
    },
    "DescribeResourceCollectionHealth": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/health/resource-collection/{ResourceCollectionType}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceCollectionType"
        ],
        "members": {
          "ResourceCollectionType": {
            "location": "uri",
            "locationName": "ResourceCollectionType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFormation": {
            "shape": "S3g"
          },
          "Service": {
            "shape": "S3j"
          },
          "NextToken": {},
          "Tags": {
            "shape": "S3q"
          }
        }
      }
    },
    "DescribeServiceIntegration": {
      "http": {
        "method": "GET",
        "requestUri": "/service-integrations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceIntegration": {
            "type": "structure",
            "members": {
              "OpsCenter": {
                "type": "structure",
                "members": {
                  "OptInStatus": {}
                }
              }
            }
          }
        }
      }
    },
    "GetCostEstimation": {
      "http": {
        "method": "GET",
        "requestUri": "/cost-estimation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceCollection": {
            "shape": "S42"
          },
          "Status": {},
          "Costs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "State": {},
                "Count": {
                  "type": "integer"
                },
                "UnitCost": {
                  "type": "double"
                },
                "Cost": {
                  "type": "double"
                }
              }
            }
          },
          "TimeRange": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          },
          "TotalCost": {
            "type": "double"
          },
          "NextToken": {}
        }
      }
    },
    "GetResourceCollection": {
      "http": {
        "method": "GET",
        "requestUri": "/resource-collections/{ResourceCollectionType}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceCollectionType"
        ],
        "members": {
          "ResourceCollectionType": {
            "location": "uri",
            "locationName": "ResourceCollectionType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceCollection": {
            "type": "structure",
            "members": {
              "CloudFormation": {
                "type": "structure",
                "members": {
                  "StackNames": {
                    "shape": "S23"
                  }
                }
              },
              "Tags": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "AppBoundaryKey",
                    "TagValues"
                  ],
                  "members": {
                    "AppBoundaryKey": {},
                    "TagValues": {
                      "shape": "S28"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAnomaliesForInsight": {
      "http": {
        "requestUri": "/anomalies/insight/{InsightId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightId"
        ],
        "members": {
          "InsightId": {
            "location": "uri",
            "locationName": "InsightId"
          },
          "StartTimeRange": {
            "shape": "S4m"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveAnomalies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Severity": {},
                "Status": {},
                "UpdateTime": {
                  "type": "timestamp"
                },
                "AnomalyTimeRange": {
                  "shape": "St"
                },
                "AnomalyReportedTimeRange": {
                  "shape": "Su"
                },
                "PredictionTimeRange": {
                  "shape": "Sv"
                },
                "SourceDetails": {
                  "shape": "Sw"
                },
                "AssociatedInsightId": {},
                "ResourceCollection": {
                  "shape": "S21"
                },
                "Limit": {
                  "type": "double"
                },
                "SourceMetadata": {
                  "shape": "S2b"
                },
                "AnomalyResources": {
                  "shape": "S2f"
                }
              }
            }
          },
          "ReactiveAnomalies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Severity": {},
                "Status": {},
                "AnomalyTimeRange": {
                  "shape": "St"
                },
                "AnomalyReportedTimeRange": {
                  "shape": "Su"
                },
                "SourceDetails": {
                  "shape": "Sw"
                },
                "AssociatedInsightId": {},
                "ResourceCollection": {
                  "shape": "S21"
                },
                "Type": {},
                "Name": {},
                "Description": {},
                "CausalAnomalyId": {},
                "AnomalyResources": {
                  "shape": "S2f"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEvents": {
      "http": {
        "requestUri": "/events",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Filters"
        ],
        "members": {
          "Filters": {
            "type": "structure",
            "members": {
              "InsightId": {},
              "EventTimeRange": {
                "type": "structure",
                "required": [
                  "FromTime",
                  "ToTime"
                ],
                "members": {
                  "FromTime": {
                    "type": "timestamp"
                  },
                  "ToTime": {
                    "type": "timestamp"
                  }
                }
              },
              "EventClass": {},
              "EventSource": {},
              "DataSource": {},
              "ResourceCollection": {
                "shape": "S21"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Events"
        ],
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceCollection": {
                  "shape": "S21"
                },
                "Id": {},
                "Time": {
                  "type": "timestamp"
                },
                "EventSource": {},
                "Name": {},
                "DataSource": {},
                "EventClass": {},
                "Resources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Type": {},
                      "Name": {},
                      "Arn": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInsights": {
      "http": {
        "requestUri": "/insights",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StatusFilter"
        ],
        "members": {
          "StatusFilter": {
            "shape": "S5b"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsights": {
            "shape": "S5j"
          },
          "ReactiveInsights": {
            "shape": "S5p"
          },
          "NextToken": {}
        }
      }
    },
    "ListNotificationChannels": {
      "http": {
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Channels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Config": {
                  "shape": "S2"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListOrganizationInsights": {
      "http": {
        "requestUri": "/organization/insights",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StatusFilter"
        ],
        "members": {
          "StatusFilter": {
            "shape": "S5b"
          },
          "MaxResults": {
            "type": "integer"
          },
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "OrganizationalUnitIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsights": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "AccountId": {},
                "OrganizationalUnitId": {},
                "Name": {},
                "Severity": {},
                "Status": {},
                "InsightTimeRange": {
                  "shape": "S30"
                },
                "PredictionTimeRange": {
                  "shape": "Sv"
                },
                "ResourceCollection": {
                  "shape": "S21"
                },
                "ServiceCollection": {
                  "shape": "S5l"
                }
              }
            }
          },
          "ReactiveInsights": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "AccountId": {},
                "OrganizationalUnitId": {},
                "Name": {},
                "Severity": {},
                "Status": {},
                "InsightTimeRange": {
                  "shape": "S30"
                },
                "ResourceCollection": {
                  "shape": "S21"
                },
                "ServiceCollection": {
                  "shape": "S5l"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRecommendations": {
      "http": {
        "requestUri": "/recommendations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightId"
        ],
        "members": {
          "InsightId": {},
          "NextToken": {},
          "Locale": {},
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Recommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "Link": {},
                "Name": {},
                "Reason": {},
                "RelatedEvents": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Resources": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Type": {}
                          }
                        }
                      }
                    }
                  }
                },
                "RelatedAnomalies": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Resources": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Type": {}
                          }
                        }
                      },
                      "SourceDetails": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "CloudWatchMetrics": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "MetricName": {},
                                  "Namespace": {}
                                }
                              }
                            }
                          }
                        }
                      },
                      "AnomalyId": {}
                    }
                  }
                },
                "Category": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutFeedback": {
      "http": {
        "method": "PUT",
        "requestUri": "/feedback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "InsightFeedback": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveNotificationChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SearchInsights": {
      "http": {
        "requestUri": "/insights/search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTimeRange",
          "Type"
        ],
        "members": {
          "StartTimeRange": {
            "shape": "S4m"
          },
          "Filters": {
            "type": "structure",
            "members": {
              "Severities": {
                "shape": "S72"
              },
              "Statuses": {
                "shape": "S73"
              },
              "ResourceCollection": {
                "shape": "S21"
              },
              "ServiceCollection": {
                "shape": "S5l"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsights": {
            "shape": "S5j"
          },
          "ReactiveInsights": {
            "shape": "S5p"
          },
          "NextToken": {}
        }
      }
    },
    "SearchOrganizationInsights": {
      "http": {
        "requestUri": "/organization/insights/search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds",
          "StartTimeRange",
          "Type"
        ],
        "members": {
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "StartTimeRange": {
            "shape": "S4m"
          },
          "Filters": {
            "type": "structure",
            "members": {
              "Severities": {
                "shape": "S72"
              },
              "Statuses": {
                "shape": "S73"
              },
              "ResourceCollection": {
                "shape": "S21"
              },
              "ServiceCollection": {
                "shape": "S5l"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProactiveInsights": {
            "shape": "S5j"
          },
          "ReactiveInsights": {
            "shape": "S5p"
          },
          "NextToken": {}
        }
      }
    },
    "StartCostEstimation": {
      "http": {
        "method": "PUT",
        "requestUri": "/cost-estimation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceCollection"
        ],
        "members": {
          "ResourceCollection": {
            "shape": "S42"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEventSourcesConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/event-sources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "EventSources": {
            "shape": "S2n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateResourceCollection": {
      "http": {
        "method": "PUT",
        "requestUri": "/resource-collections",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Action",
          "ResourceCollection"
        ],
        "members": {
          "Action": {},
          "ResourceCollection": {
            "type": "structure",
            "members": {
              "CloudFormation": {
                "type": "structure",
                "members": {
                  "StackNames": {
                    "type": "list",
                    "member": {}
                  }
                }
              },
              "Tags": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "AppBoundaryKey",
                    "TagValues"
                  ],
                  "members": {
                    "AppBoundaryKey": {},
                    "TagValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateServiceIntegration": {
      "http": {
        "method": "PUT",
        "requestUri": "/service-integrations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ServiceIntegration"
        ],
        "members": {
          "ServiceIntegration": {
            "type": "structure",
            "members": {
              "OpsCenter": {
                "type": "structure",
                "members": {
                  "OptInStatus": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "Sns"
      ],
      "members": {
        "Sns": {
          "type": "structure",
          "members": {
            "TopicArn": {}
          }
        }
      }
    },
    "St": {
      "type": "structure",
      "required": [
        "StartTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "OpenTime"
      ],
      "members": {
        "OpenTime": {
          "type": "timestamp"
        },
        "CloseTime": {
          "type": "timestamp"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "StartTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "CloudWatchMetrics": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "MetricName": {},
              "Namespace": {},
              "Dimensions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                }
              },
              "Stat": {},
              "Unit": {},
              "Period": {
                "type": "integer"
              },
              "MetricDataSummary": {
                "type": "structure",
                "members": {
                  "TimestampMetricValuePairList": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Timestamp": {
                          "type": "timestamp"
                        },
                        "MetricValue": {
                          "type": "double"
                        }
                      }
                    }
                  },
                  "StatusCode": {}
                }
              }
            }
          }
        },
        "PerformanceInsightsMetrics": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "MetricDisplayName": {},
              "Unit": {},
              "MetricQuery": {
                "shape": "S1h"
              },
              "ReferenceData": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "ComparisonValues": {
                      "type": "structure",
                      "members": {
                        "ReferenceScalar": {
                          "type": "structure",
                          "members": {
                            "Value": {
                              "type": "double"
                            }
                          }
                        },
                        "ReferenceMetric": {
                          "type": "structure",
                          "members": {
                            "MetricQuery": {
                              "shape": "S1h"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              "StatsAtAnomaly": {
                "shape": "S1y"
              },
              "StatsAtBaseline": {
                "shape": "S1y"
              }
            }
          }
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Metric": {},
        "GroupBy": {
          "type": "structure",
          "members": {
            "Group": {},
            "Dimensions": {
              "type": "list",
              "member": {}
            },
            "Limit": {
              "type": "integer"
            }
          }
        },
        "Filter": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Value": {
            "type": "double"
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "CloudFormation": {
          "type": "structure",
          "members": {
            "StackNames": {
              "shape": "S23"
            }
          }
        },
        "Tags": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AppBoundaryKey",
              "TagValues"
            ],
            "members": {
              "AppBoundaryKey": {},
              "TagValues": {
                "shape": "S28"
              }
            }
          }
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {}
    },
    "S28": {
      "type": "list",
      "member": {}
    },
    "S2b": {
      "type": "structure",
      "members": {
        "Source": {},
        "SourceResourceName": {},
        "SourceResourceType": {}
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Type": {}
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "AmazonCodeGuruProfiler": {
          "type": "structure",
          "members": {
            "Status": {}
          }
        }
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "Id": {},
        "Feedback": {}
      }
    },
    "S30": {
      "type": "structure",
      "required": [
        "StartTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "S35": {
      "type": "list",
      "member": {}
    },
    "S36": {
      "type": "list",
      "member": {}
    },
    "S3g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StackName": {},
          "Insight": {
            "shape": "S3i"
          }
        }
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "OpenProactiveInsights": {
          "type": "integer"
        },
        "OpenReactiveInsights": {
          "type": "integer"
        },
        "MeanTimeToRecoverInMilliseconds": {
          "type": "long"
        }
      }
    },
    "S3j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ServiceName": {},
          "Insight": {
            "type": "structure",
            "members": {
              "OpenProactiveInsights": {
                "type": "integer"
              },
              "OpenReactiveInsights": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AppBoundaryKey": {},
          "TagValue": {},
          "Insight": {
            "shape": "S3i"
          }
        }
      }
    },
    "S42": {
      "type": "structure",
      "members": {
        "CloudFormation": {
          "type": "structure",
          "members": {
            "StackNames": {
              "type": "list",
              "member": {}
            }
          }
        },
        "Tags": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AppBoundaryKey",
              "TagValues"
            ],
            "members": {
              "AppBoundaryKey": {},
              "TagValues": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "FromTime": {
          "type": "timestamp"
        },
        "ToTime": {
          "type": "timestamp"
        }
      }
    },
    "S5b": {
      "type": "structure",
      "members": {
        "Ongoing": {
          "type": "structure",
          "required": [
            "Type"
          ],
          "members": {
            "Type": {}
          }
        },
        "Closed": {
          "type": "structure",
          "required": [
            "Type",
            "EndTimeRange"
          ],
          "members": {
            "Type": {},
            "EndTimeRange": {
              "type": "structure",
              "members": {
                "FromTime": {
                  "type": "timestamp"
                },
                "ToTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        },
        "Any": {
          "type": "structure",
          "required": [
            "Type",
            "StartTimeRange"
          ],
          "members": {
            "Type": {},
            "StartTimeRange": {
              "shape": "S4m"
            }
          }
        }
      }
    },
    "S5j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {},
          "Severity": {},
          "Status": {},
          "InsightTimeRange": {
            "shape": "S30"
          },
          "PredictionTimeRange": {
            "shape": "Sv"
          },
          "ResourceCollection": {
            "shape": "S21"
          },
          "ServiceCollection": {
            "shape": "S5l"
          },
          "AssociatedResourceArns": {
            "shape": "S5n"
          }
        }
      }
    },
    "S5l": {
      "type": "structure",
      "members": {
        "ServiceNames": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S5n": {
      "type": "list",
      "member": {}
    },
    "S5p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {},
          "Severity": {},
          "Status": {},
          "InsightTimeRange": {
            "shape": "S30"
          },
          "ResourceCollection": {
            "shape": "S21"
          },
          "ServiceCollection": {
            "shape": "S5l"
          },
          "AssociatedResourceArns": {
            "shape": "S5n"
          }
        }
      }
    },
    "S72": {
      "type": "list",
      "member": {}
    },
    "S73": {
      "type": "list",
      "member": {}
    }
  }
}
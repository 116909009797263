{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-11-19",
    "endpointPrefix": "geo",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Location Service",
    "serviceId": "Location",
    "signatureVersion": "v4",
    "signingName": "geo",
    "uid": "location-2020-11-19"
  },
  "operations": {
    "AssociateTrackerConsumer": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/consumers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ConsumerArn",
          "TrackerName"
        ],
        "members": {
          "ConsumerArn": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "BatchDeleteDevicePositionHistory": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/delete-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceIds",
          "TrackerName"
        ],
        "members": {
          "DeviceIds": {
            "type": "list",
            "member": {}
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "BatchDeleteGeofence": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/delete-geofences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "GeofenceIds"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "GeofenceIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Error",
                "GeofenceId"
              ],
              "members": {
                "Error": {
                  "shape": "Sb"
                },
                "GeofenceId": {}
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "BatchEvaluateGeofences": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "DevicePositionUpdates"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "DevicePositionUpdates": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error",
                "SampleTime"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                },
                "SampleTime": {
                  "shape": "St"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "BatchGetDevicePosition": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/get-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceIds",
          "TrackerName"
        ],
        "members": {
          "DeviceIds": {
            "type": "list",
            "member": {}
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DevicePositions",
          "Errors"
        ],
        "members": {
          "DevicePositions": {
            "shape": "S11"
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "BatchPutGeofence": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/put-geofences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "Entries"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GeofenceId",
                "Geometry"
              ],
              "members": {
                "GeofenceId": {},
                "Geometry": {
                  "shape": "S18"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors",
          "Successes"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Error",
                "GeofenceId"
              ],
              "members": {
                "Error": {
                  "shape": "Sb"
                },
                "GeofenceId": {}
              }
            }
          },
          "Successes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "GeofenceId",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "St"
                },
                "GeofenceId": {},
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "BatchUpdateDevicePosition": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName",
          "Updates"
        ],
        "members": {
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          },
          "Updates": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Errors"
        ],
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Error",
                "SampleTime"
              ],
              "members": {
                "DeviceId": {},
                "Error": {
                  "shape": "Sb"
                },
                "SampleTime": {
                  "shape": "St"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "CalculateRoute": {
      "http": {
        "requestUri": "/routes/v0/calculators/{CalculatorName}/calculate/route",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName",
          "DeparturePosition",
          "DestinationPosition"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          },
          "CarModeOptions": {
            "shape": "S1m"
          },
          "DepartNow": {
            "type": "boolean"
          },
          "DeparturePosition": {
            "shape": "So"
          },
          "DepartureTime": {
            "shape": "St"
          },
          "DestinationPosition": {
            "shape": "So"
          },
          "DistanceUnit": {},
          "IncludeLegGeometry": {
            "type": "boolean"
          },
          "TravelMode": {},
          "TruckModeOptions": {
            "shape": "S1q"
          },
          "WaypointPositions": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Legs",
          "Summary"
        ],
        "members": {
          "Legs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Distance",
                "DurationSeconds",
                "EndPosition",
                "StartPosition",
                "Steps"
              ],
              "members": {
                "Distance": {
                  "type": "double"
                },
                "DurationSeconds": {
                  "type": "double"
                },
                "EndPosition": {
                  "shape": "So"
                },
                "Geometry": {
                  "type": "structure",
                  "members": {
                    "LineString": {
                      "type": "list",
                      "member": {
                        "shape": "So"
                      }
                    }
                  }
                },
                "StartPosition": {
                  "shape": "So"
                },
                "Steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Distance",
                      "DurationSeconds",
                      "EndPosition",
                      "StartPosition"
                    ],
                    "members": {
                      "Distance": {
                        "type": "double"
                      },
                      "DurationSeconds": {
                        "type": "double"
                      },
                      "EndPosition": {
                        "shape": "So"
                      },
                      "GeometryOffset": {
                        "type": "integer"
                      },
                      "StartPosition": {
                        "shape": "So"
                      }
                    }
                  }
                }
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Distance",
              "DistanceUnit",
              "DurationSeconds",
              "RouteBBox"
            ],
            "members": {
              "DataSource": {},
              "Distance": {
                "type": "double"
              },
              "DistanceUnit": {},
              "DurationSeconds": {
                "type": "double"
              },
              "RouteBBox": {
                "shape": "S2f"
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "CalculateRouteMatrix": {
      "http": {
        "requestUri": "/routes/v0/calculators/{CalculatorName}/calculate/route-matrix",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName",
          "DeparturePositions",
          "DestinationPositions"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          },
          "CarModeOptions": {
            "shape": "S1m"
          },
          "DepartNow": {
            "type": "boolean"
          },
          "DeparturePositions": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "DepartureTime": {
            "shape": "St"
          },
          "DestinationPositions": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "DistanceUnit": {},
          "TravelMode": {},
          "TruckModeOptions": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RouteMatrix",
          "Summary"
        ],
        "members": {
          "RouteMatrix": {
            "type": "list",
            "member": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Distance": {
                    "type": "double"
                  },
                  "DurationSeconds": {
                    "type": "double"
                  },
                  "Error": {
                    "type": "structure",
                    "required": [
                      "Code"
                    ],
                    "members": {
                      "Code": {},
                      "Message": {}
                    }
                  }
                }
              }
            }
          },
          "SnappedDeparturePositions": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "SnappedDestinationPositions": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "DistanceUnit",
              "ErrorCount",
              "RouteCount"
            ],
            "members": {
              "DataSource": {},
              "DistanceUnit": {},
              "ErrorCount": {
                "type": "integer"
              },
              "RouteCount": {
                "type": "integer"
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "CreateGeofenceCollection": {
      "http": {
        "requestUri": "/geofencing/v0/collections",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {},
          "Description": {},
          "KmsKeyId": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "PricingPlanDataSource": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. No longer allowed."
          },
          "Tags": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CollectionArn",
          "CollectionName",
          "CreateTime"
        ],
        "members": {
          "CollectionArn": {},
          "CollectionName": {},
          "CreateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      },
      "idempotent": true
    },
    "CreateMap": {
      "http": {
        "requestUri": "/maps/v0/maps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Configuration",
          "MapName"
        ],
        "members": {
          "Configuration": {
            "shape": "S35"
          },
          "Description": {},
          "MapName": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "Tags": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "MapArn",
          "MapName"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "MapArn": {},
          "MapName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      },
      "idempotent": true
    },
    "CreatePlaceIndex": {
      "http": {
        "requestUri": "/places/v0/indexes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSource",
          "IndexName"
        ],
        "members": {
          "DataSource": {},
          "DataSourceConfiguration": {
            "shape": "S39"
          },
          "Description": {},
          "IndexName": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "Tags": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "IndexArn",
          "IndexName"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "IndexArn": {},
          "IndexName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      },
      "idempotent": true
    },
    "CreateRouteCalculator": {
      "http": {
        "requestUri": "/routes/v0/calculators",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName",
          "DataSource"
        ],
        "members": {
          "CalculatorName": {},
          "DataSource": {},
          "Description": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "Tags": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CalculatorArn",
          "CalculatorName",
          "CreateTime"
        ],
        "members": {
          "CalculatorArn": {},
          "CalculatorName": {},
          "CreateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      },
      "idempotent": true
    },
    "CreateTracker": {
      "http": {
        "requestUri": "/tracking/v0/trackers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "Description": {},
          "KmsKeyId": {},
          "PositionFiltering": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "PricingPlanDataSource": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. No longer allowed."
          },
          "Tags": {
            "shape": "S30"
          },
          "TrackerName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "TrackerArn",
          "TrackerName"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "TrackerArn": {},
          "TrackerName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      },
      "idempotent": true
    },
    "DeleteGeofenceCollection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/geofencing/v0/collections/{CollectionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      },
      "idempotent": true
    },
    "DeleteMap": {
      "http": {
        "method": "DELETE",
        "requestUri": "/maps/v0/maps/{MapName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "maps."
      },
      "idempotent": true
    },
    "DeletePlaceIndex": {
      "http": {
        "method": "DELETE",
        "requestUri": "/places/v0/indexes/{IndexName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "places."
      },
      "idempotent": true
    },
    "DeleteRouteCalculator": {
      "http": {
        "method": "DELETE",
        "requestUri": "/routes/v0/calculators/{CalculatorName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "routes."
      },
      "idempotent": true
    },
    "DeleteTracker": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tracking/v0/trackers/{TrackerName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "tracking."
      },
      "idempotent": true
    },
    "DescribeGeofenceCollection": {
      "http": {
        "method": "GET",
        "requestUri": "/geofencing/v0/collections/{CollectionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CollectionArn",
          "CollectionName",
          "CreateTime",
          "Description",
          "UpdateTime"
        ],
        "members": {
          "CollectionArn": {},
          "CollectionName": {},
          "CreateTime": {
            "shape": "St"
          },
          "Description": {},
          "KmsKeyId": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
          },
          "PricingPlanDataSource": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Unused."
          },
          "Tags": {
            "shape": "S30"
          },
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "DescribeMap": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Configuration",
          "CreateTime",
          "DataSource",
          "Description",
          "MapArn",
          "MapName",
          "UpdateTime"
        ],
        "members": {
          "Configuration": {
            "shape": "S35"
          },
          "CreateTime": {
            "shape": "St"
          },
          "DataSource": {},
          "Description": {},
          "MapArn": {},
          "MapName": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
          },
          "Tags": {
            "shape": "S30"
          },
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "DescribePlaceIndex": {
      "http": {
        "method": "GET",
        "requestUri": "/places/v0/indexes/{IndexName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "DataSource",
          "DataSourceConfiguration",
          "Description",
          "IndexArn",
          "IndexName",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "DataSource": {},
          "DataSourceConfiguration": {
            "shape": "S39"
          },
          "Description": {},
          "IndexArn": {},
          "IndexName": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
          },
          "Tags": {
            "shape": "S30"
          },
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "DescribeRouteCalculator": {
      "http": {
        "method": "GET",
        "requestUri": "/routes/v0/calculators/{CalculatorName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CalculatorArn",
          "CalculatorName",
          "CreateTime",
          "DataSource",
          "Description",
          "UpdateTime"
        ],
        "members": {
          "CalculatorArn": {},
          "CalculatorName": {},
          "CreateTime": {
            "shape": "St"
          },
          "DataSource": {},
          "Description": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
          },
          "Tags": {
            "shape": "S30"
          },
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "DescribeTracker": {
      "http": {
        "method": "GET",
        "requestUri": "/tracking/v0/trackers/{TrackerName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "Description",
          "TrackerArn",
          "TrackerName",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "Description": {},
          "KmsKeyId": {},
          "PositionFiltering": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
          },
          "PricingPlanDataSource": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. Unused."
          },
          "Tags": {
            "shape": "S30"
          },
          "TrackerArn": {},
          "TrackerName": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "DisassociateTrackerConsumer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tracking/v0/trackers/{TrackerName}/consumers/{ConsumerArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ConsumerArn",
          "TrackerName"
        ],
        "members": {
          "ConsumerArn": {
            "location": "uri",
            "locationName": "ConsumerArn"
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "GetDevicePosition": {
      "http": {
        "method": "GET",
        "requestUri": "/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/positions/latest",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceId",
          "TrackerName"
        ],
        "members": {
          "DeviceId": {
            "location": "uri",
            "locationName": "DeviceId"
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Position",
          "ReceivedTime",
          "SampleTime"
        ],
        "members": {
          "Accuracy": {
            "shape": "Sm"
          },
          "DeviceId": {},
          "Position": {
            "shape": "So"
          },
          "PositionProperties": {
            "shape": "Sq"
          },
          "ReceivedTime": {
            "shape": "St"
          },
          "SampleTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "GetDevicePositionHistory": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/list-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "DeviceId",
          "TrackerName"
        ],
        "members": {
          "DeviceId": {
            "location": "uri",
            "locationName": "DeviceId"
          },
          "EndTimeExclusive": {
            "shape": "St"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "StartTimeInclusive": {
            "shape": "St"
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DevicePositions"
        ],
        "members": {
          "DevicePositions": {
            "shape": "S11"
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "GetGeofence": {
      "http": {
        "method": "GET",
        "requestUri": "/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "GeofenceId"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "GeofenceId": {
            "location": "uri",
            "locationName": "GeofenceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "GeofenceId",
          "Geometry",
          "Status",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "GeofenceId": {},
          "Geometry": {
            "shape": "S18"
          },
          "Status": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "GetMapGlyphs": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/glyphs/{FontStack}/{FontUnicodeRange}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FontStack",
          "FontUnicodeRange",
          "MapName"
        ],
        "members": {
          "FontStack": {
            "location": "uri",
            "locationName": "FontStack"
          },
          "FontUnicodeRange": {
            "location": "uri",
            "locationName": "FontUnicodeRange"
          },
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "GetMapSprites": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/sprites/{FileName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FileName",
          "MapName"
        ],
        "members": {
          "FileName": {
            "location": "uri",
            "locationName": "FileName"
          },
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "GetMapStyleDescriptor": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/style-descriptor",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "GetMapTile": {
      "http": {
        "method": "GET",
        "requestUri": "/maps/v0/maps/{MapName}/tiles/{Z}/{X}/{Y}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName",
          "X",
          "Y",
          "Z"
        ],
        "members": {
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          },
          "X": {
            "location": "uri",
            "locationName": "X"
          },
          "Y": {
            "location": "uri",
            "locationName": "Y"
          },
          "Z": {
            "location": "uri",
            "locationName": "Z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blob": {
            "type": "blob"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "Blob"
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "ListDevicePositions": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/list-positions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceId",
                "Position",
                "SampleTime"
              ],
              "members": {
                "Accuracy": {
                  "shape": "Sm"
                },
                "DeviceId": {},
                "Position": {
                  "shape": "So"
                },
                "PositionProperties": {
                  "shape": "Sq"
                },
                "SampleTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "ListGeofenceCollections": {
      "http": {
        "requestUri": "/geofencing/v0/list-collections",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CollectionName",
                "CreateTime",
                "Description",
                "UpdateTime"
              ],
              "members": {
                "CollectionName": {},
                "CreateTime": {
                  "shape": "St"
                },
                "Description": {},
                "PricingPlan": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
                },
                "PricingPlanDataSource": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Unused."
                },
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "ListGeofences": {
      "http": {
        "requestUri": "/geofencing/v0/collections/{CollectionName}/list-geofences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "GeofenceId",
                "Geometry",
                "Status",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "St"
                },
                "GeofenceId": {},
                "Geometry": {
                  "shape": "S18"
                },
                "Status": {},
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "ListMaps": {
      "http": {
        "requestUri": "/maps/v0/list-maps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "DataSource",
                "Description",
                "MapName",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "St"
                },
                "DataSource": {},
                "Description": {},
                "MapName": {},
                "PricingPlan": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
                },
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      }
    },
    "ListPlaceIndexes": {
      "http": {
        "requestUri": "/places/v0/list-indexes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "DataSource",
                "Description",
                "IndexName",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "St"
                },
                "DataSource": {},
                "Description": {},
                "IndexName": {},
                "PricingPlan": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
                },
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "ListRouteCalculators": {
      "http": {
        "requestUri": "/routes/v0/list-calculators",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CalculatorName",
                "CreateTime",
                "DataSource",
                "Description",
                "UpdateTime"
              ],
              "members": {
                "CalculatorName": {},
                "CreateTime": {
                  "shape": "St"
                },
                "DataSource": {},
                "Description": {},
                "PricingPlan": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
                },
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S30"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "metadata."
      }
    },
    "ListTrackerConsumers": {
      "http": {
        "requestUri": "/tracking/v0/trackers/{TrackerName}/list-consumers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConsumerArns"
        ],
        "members": {
          "ConsumerArns": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "ListTrackers": {
      "http": {
        "requestUri": "/tracking/v0/list-trackers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreateTime",
                "Description",
                "TrackerName",
                "UpdateTime"
              ],
              "members": {
                "CreateTime": {
                  "shape": "St"
                },
                "Description": {},
                "PricingPlan": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Always returns RequestBasedUsage."
                },
                "PricingPlanDataSource": {
                  "deprecated": true,
                  "deprecatedMessage": "Deprecated. Unused."
                },
                "TrackerName": {},
                "UpdateTime": {
                  "shape": "St"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      }
    },
    "PutGeofence": {
      "http": {
        "method": "PUT",
        "requestUri": "/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName",
          "GeofenceId",
          "Geometry"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "GeofenceId": {
            "location": "uri",
            "locationName": "GeofenceId"
          },
          "Geometry": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CreateTime",
          "GeofenceId",
          "UpdateTime"
        ],
        "members": {
          "CreateTime": {
            "shape": "St"
          },
          "GeofenceId": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      }
    },
    "SearchPlaceIndexForPosition": {
      "http": {
        "requestUri": "/places/v0/indexes/{IndexName}/search/position",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName",
          "Position"
        ],
        "members": {
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "Language": {},
          "MaxResults": {
            "type": "integer"
          },
          "Position": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results",
          "Summary"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Distance",
                "Place"
              ],
              "members": {
                "Distance": {
                  "type": "double"
                },
                "Place": {
                  "shape": "S62"
                }
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Position"
            ],
            "members": {
              "DataSource": {},
              "Language": {},
              "MaxResults": {
                "type": "integer"
              },
              "Position": {
                "shape": "So"
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "SearchPlaceIndexForSuggestions": {
      "http": {
        "requestUri": "/places/v0/indexes/{IndexName}/search/suggestions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName",
          "Text"
        ],
        "members": {
          "BiasPosition": {
            "shape": "So"
          },
          "FilterBBox": {
            "shape": "S2f"
          },
          "FilterCountries": {
            "shape": "S68"
          },
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "Language": {},
          "MaxResults": {
            "type": "integer"
          },
          "Text": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results",
          "Summary"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Text"
              ],
              "members": {
                "Text": {}
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Text"
            ],
            "members": {
              "BiasPosition": {
                "shape": "So"
              },
              "DataSource": {},
              "FilterBBox": {
                "shape": "S2f"
              },
              "FilterCountries": {
                "shape": "S68"
              },
              "Language": {},
              "MaxResults": {
                "type": "integer"
              },
              "Text": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "SearchPlaceIndexForText": {
      "http": {
        "requestUri": "/places/v0/indexes/{IndexName}/search/text",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName",
          "Text"
        ],
        "members": {
          "BiasPosition": {
            "shape": "So"
          },
          "FilterBBox": {
            "shape": "S2f"
          },
          "FilterCountries": {
            "shape": "S68"
          },
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "Language": {},
          "MaxResults": {
            "type": "integer"
          },
          "Text": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results",
          "Summary"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Place"
              ],
              "members": {
                "Distance": {
                  "type": "double"
                },
                "Place": {
                  "shape": "S62"
                },
                "Relevance": {
                  "type": "double"
                }
              }
            }
          },
          "Summary": {
            "type": "structure",
            "required": [
              "DataSource",
              "Text"
            ],
            "members": {
              "BiasPosition": {
                "shape": "So"
              },
              "DataSource": {},
              "FilterBBox": {
                "shape": "S2f"
              },
              "FilterCountries": {
                "shape": "S68"
              },
              "Language": {},
              "MaxResults": {
                "type": "integer"
              },
              "ResultBBox": {
                "shape": "S2f"
              },
              "Text": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "S30"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "metadata."
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "metadata."
      },
      "idempotent": true
    },
    "UpdateGeofenceCollection": {
      "http": {
        "method": "PATCH",
        "requestUri": "/geofencing/v0/collections/{CollectionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CollectionName"
        ],
        "members": {
          "CollectionName": {
            "location": "uri",
            "locationName": "CollectionName"
          },
          "Description": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "PricingPlanDataSource": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. No longer allowed."
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CollectionArn",
          "CollectionName",
          "UpdateTime"
        ],
        "members": {
          "CollectionArn": {},
          "CollectionName": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "geofencing."
      },
      "idempotent": true
    },
    "UpdateMap": {
      "http": {
        "method": "PATCH",
        "requestUri": "/maps/v0/maps/{MapName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "MapName"
        ],
        "members": {
          "Description": {},
          "MapName": {
            "location": "uri",
            "locationName": "MapName"
          },
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MapArn",
          "MapName",
          "UpdateTime"
        ],
        "members": {
          "MapArn": {},
          "MapName": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "maps."
      },
      "idempotent": true
    },
    "UpdatePlaceIndex": {
      "http": {
        "method": "PATCH",
        "requestUri": "/places/v0/indexes/{IndexName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "IndexName"
        ],
        "members": {
          "DataSourceConfiguration": {
            "shape": "S39"
          },
          "Description": {},
          "IndexName": {
            "location": "uri",
            "locationName": "IndexName"
          },
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IndexArn",
          "IndexName",
          "UpdateTime"
        ],
        "members": {
          "IndexArn": {},
          "IndexName": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "places."
      },
      "idempotent": true
    },
    "UpdateRouteCalculator": {
      "http": {
        "method": "PATCH",
        "requestUri": "/routes/v0/calculators/{CalculatorName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "CalculatorName"
        ],
        "members": {
          "CalculatorName": {
            "location": "uri",
            "locationName": "CalculatorName"
          },
          "Description": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CalculatorArn",
          "CalculatorName",
          "UpdateTime"
        ],
        "members": {
          "CalculatorArn": {},
          "CalculatorName": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "routes."
      },
      "idempotent": true
    },
    "UpdateTracker": {
      "http": {
        "method": "PATCH",
        "requestUri": "/tracking/v0/trackers/{TrackerName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "TrackerName"
        ],
        "members": {
          "Description": {},
          "PositionFiltering": {},
          "PricingPlan": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. If included, the only allowed value is RequestBasedUsage."
          },
          "PricingPlanDataSource": {
            "deprecated": true,
            "deprecatedMessage": "Deprecated. No longer allowed."
          },
          "TrackerName": {
            "location": "uri",
            "locationName": "TrackerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrackerArn",
          "TrackerName",
          "UpdateTime"
        ],
        "members": {
          "TrackerArn": {},
          "TrackerName": {},
          "UpdateTime": {
            "shape": "St"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "tracking."
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sb": {
      "type": "structure",
      "members": {
        "Code": {},
        "Message": {}
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "DeviceId",
        "Position",
        "SampleTime"
      ],
      "members": {
        "Accuracy": {
          "shape": "Sm"
        },
        "DeviceId": {},
        "Position": {
          "shape": "So"
        },
        "PositionProperties": {
          "shape": "Sq"
        },
        "SampleTime": {
          "shape": "St"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "Horizontal"
      ],
      "members": {
        "Horizontal": {
          "type": "double"
        }
      }
    },
    "So": {
      "type": "list",
      "member": {
        "type": "double"
      },
      "sensitive": true
    },
    "Sq": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "St": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S11": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Position",
          "ReceivedTime",
          "SampleTime"
        ],
        "members": {
          "Accuracy": {
            "shape": "Sm"
          },
          "DeviceId": {},
          "Position": {
            "shape": "So"
          },
          "PositionProperties": {
            "shape": "Sq"
          },
          "ReceivedTime": {
            "shape": "St"
          },
          "SampleTime": {
            "shape": "St"
          }
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "Polygon": {
          "type": "list",
          "member": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          }
        }
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "AvoidFerries": {
          "type": "boolean"
        },
        "AvoidTolls": {
          "type": "boolean"
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "AvoidFerries": {
          "type": "boolean"
        },
        "AvoidTolls": {
          "type": "boolean"
        },
        "Dimensions": {
          "type": "structure",
          "members": {
            "Height": {
              "type": "double"
            },
            "Length": {
              "type": "double"
            },
            "Unit": {},
            "Width": {
              "type": "double"
            }
          }
        },
        "Weight": {
          "type": "structure",
          "members": {
            "Total": {
              "type": "double"
            },
            "Unit": {}
          }
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "double"
      },
      "sensitive": true
    },
    "S30": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S35": {
      "type": "structure",
      "required": [
        "Style"
      ],
      "members": {
        "Style": {}
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "IntendedUse": {}
      }
    },
    "S62": {
      "type": "structure",
      "required": [
        "Geometry"
      ],
      "members": {
        "AddressNumber": {},
        "Country": {},
        "Geometry": {
          "type": "structure",
          "members": {
            "Point": {
              "shape": "So"
            }
          }
        },
        "Interpolated": {
          "type": "boolean"
        },
        "Label": {},
        "Municipality": {},
        "Neighborhood": {},
        "PostalCode": {},
        "Region": {},
        "Street": {},
        "SubRegion": {},
        "TimeZone": {
          "type": "structure",
          "required": [
            "Name"
          ],
          "members": {
            "Name": {},
            "Offset": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S68": {
      "type": "list",
      "member": {}
    }
  }
}
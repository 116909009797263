{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-03-31",
    "endpointPrefix": "lakeformation",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Lake Formation",
    "serviceId": "LakeFormation",
    "signatureVersion": "v4",
    "signingName": "lakeformation",
    "uid": "lakeformation-2017-03-31"
  },
  "operations": {
    "AddLFTagsToResource": {
      "http": {
        "requestUri": "/AddLFTagsToResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "LFTags"
        ],
        "members": {
          "CatalogId": {},
          "Resource": {
            "shape": "S3"
          },
          "LFTags": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sq"
          }
        }
      }
    },
    "BatchGrantPermissions": {
      "http": {
        "requestUri": "/BatchGrantPermissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "Entries": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "S13"
          }
        }
      }
    },
    "BatchRevokePermissions": {
      "http": {
        "requestUri": "/BatchRevokePermissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "Entries": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "S13"
          }
        }
      }
    },
    "CancelTransaction": {
      "http": {
        "requestUri": "/CancelTransaction"
      },
      "input": {
        "type": "structure",
        "required": [
          "TransactionId"
        ],
        "members": {
          "TransactionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CommitTransaction": {
      "http": {
        "requestUri": "/CommitTransaction"
      },
      "input": {
        "type": "structure",
        "required": [
          "TransactionId"
        ],
        "members": {
          "TransactionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransactionStatus": {}
        }
      }
    },
    "CreateDataCellsFilter": {
      "http": {
        "requestUri": "/CreateDataCellsFilter"
      },
      "input": {
        "type": "structure",
        "required": [
          "TableData"
        ],
        "members": {
          "TableData": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateLFTag": {
      "http": {
        "requestUri": "/CreateLFTag"
      },
      "input": {
        "type": "structure",
        "required": [
          "TagKey",
          "TagValues"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {},
          "TagValues": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDataCellsFilter": {
      "http": {
        "requestUri": "/DeleteDataCellsFilter"
      },
      "input": {
        "type": "structure",
        "members": {
          "TableCatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLFTag": {
      "http": {
        "requestUri": "/DeleteLFTag"
      },
      "input": {
        "type": "structure",
        "required": [
          "TagKey"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteObjectsOnCancel": {
      "http": {
        "requestUri": "/DeleteObjectsOnCancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "TransactionId",
          "Objects"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "TransactionId": {},
          "Objects": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Uri"
              ],
              "members": {
                "Uri": {},
                "ETag": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterResource": {
      "http": {
        "requestUri": "/DeregisterResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeResource": {
      "http": {
        "requestUri": "/DescribeResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInfo": {
            "shape": "S1z"
          }
        }
      }
    },
    "DescribeTransaction": {
      "http": {
        "requestUri": "/DescribeTransaction"
      },
      "input": {
        "type": "structure",
        "required": [
          "TransactionId"
        ],
        "members": {
          "TransactionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransactionDescription": {
            "shape": "S24"
          }
        }
      }
    },
    "ExtendTransaction": {
      "http": {
        "requestUri": "/ExtendTransaction"
      },
      "input": {
        "type": "structure",
        "members": {
          "TransactionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetDataLakeSettings": {
      "http": {
        "requestUri": "/GetDataLakeSettings"
      },
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataLakeSettings": {
            "shape": "S2a"
          }
        }
      }
    },
    "GetEffectivePermissionsForPath": {
      "http": {
        "requestUri": "/GetEffectivePermissionsForPath"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "CatalogId": {},
          "ResourceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "shape": "S2l"
          },
          "NextToken": {}
        }
      }
    },
    "GetLFTag": {
      "http": {
        "requestUri": "/GetLFTag"
      },
      "input": {
        "type": "structure",
        "required": [
          "TagKey"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "TagKey": {},
          "TagValues": {
            "shape": "Sg"
          }
        }
      }
    },
    "GetQueryState": {
      "http": {
        "requestUri": "/GetQueryState",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "QueryId"
        ],
        "members": {
          "QueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "State"
        ],
        "members": {
          "Error": {},
          "State": {}
        }
      },
      "endpoint": {
        "hostPrefix": "query-"
      }
    },
    "GetQueryStatistics": {
      "http": {
        "requestUri": "/GetQueryStatistics",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "QueryId"
        ],
        "members": {
          "QueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExecutionStatistics": {
            "type": "structure",
            "members": {
              "AverageExecutionTimeMillis": {
                "type": "long"
              },
              "DataScannedBytes": {
                "type": "long"
              },
              "WorkUnitsExecutedCount": {
                "type": "long"
              }
            }
          },
          "PlanningStatistics": {
            "type": "structure",
            "members": {
              "EstimatedDataToScanBytes": {
                "type": "long"
              },
              "PlanningTimeMillis": {
                "type": "long"
              },
              "QueueTimeMillis": {
                "type": "long"
              },
              "WorkUnitsGeneratedCount": {
                "type": "long"
              }
            }
          },
          "QuerySubmissionTime": {
            "type": "timestamp",
            "timestampFormat": "iso8601"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "query-"
      }
    },
    "GetResourceLFTags": {
      "http": {
        "requestUri": "/GetResourceLFTags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "CatalogId": {},
          "Resource": {
            "shape": "S3"
          },
          "ShowAssignedLFTags": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LFTagOnDatabase": {
            "shape": "Sn"
          },
          "LFTagsOnTable": {
            "shape": "Sn"
          },
          "LFTagsOnColumns": {
            "shape": "S39"
          }
        }
      }
    },
    "GetTableObjects": {
      "http": {
        "requestUri": "/GetTableObjects"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "TransactionId": {},
          "QueryAsOfTime": {
            "type": "timestamp"
          },
          "PartitionPredicate": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Objects": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PartitionValues": {
                  "shape": "S3g"
                },
                "Objects": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Uri": {},
                      "ETag": {},
                      "Size": {
                        "type": "long"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetTemporaryGluePartitionCredentials": {
      "http": {
        "requestUri": "/GetTemporaryGluePartitionCredentials"
      },
      "input": {
        "type": "structure",
        "required": [
          "TableArn",
          "Partition",
          "SupportedPermissionTypes"
        ],
        "members": {
          "TableArn": {},
          "Partition": {
            "type": "structure",
            "required": [
              "Values"
            ],
            "members": {
              "Values": {
                "type": "list",
                "member": {}
              }
            }
          },
          "Permissions": {
            "shape": "S10"
          },
          "DurationSeconds": {
            "type": "integer"
          },
          "AuditContext": {
            "shape": "S3q"
          },
          "SupportedPermissionTypes": {
            "shape": "S3s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessKeyId": {},
          "SecretAccessKey": {},
          "SessionToken": {},
          "Expiration": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetTemporaryGlueTableCredentials": {
      "http": {
        "requestUri": "/GetTemporaryGlueTableCredentials"
      },
      "input": {
        "type": "structure",
        "required": [
          "TableArn",
          "SupportedPermissionTypes"
        ],
        "members": {
          "TableArn": {},
          "Permissions": {
            "shape": "S10"
          },
          "DurationSeconds": {
            "type": "integer"
          },
          "AuditContext": {
            "shape": "S3q"
          },
          "SupportedPermissionTypes": {
            "shape": "S3s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessKeyId": {},
          "SecretAccessKey": {},
          "SessionToken": {},
          "Expiration": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetWorkUnitResults": {
      "http": {
        "requestUri": "/GetWorkUnitResults",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "QueryId",
          "WorkUnitId",
          "WorkUnitToken"
        ],
        "members": {
          "QueryId": {},
          "WorkUnitId": {
            "type": "long"
          },
          "WorkUnitToken": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResultStream": {
            "type": "blob",
            "streaming": true
          }
        },
        "payload": "ResultStream"
      },
      "endpoint": {
        "hostPrefix": "data-"
      }
    },
    "GetWorkUnits": {
      "http": {
        "requestUri": "/GetWorkUnits",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "QueryId"
        ],
        "members": {
          "NextToken": {},
          "PageSize": {
            "type": "integer"
          },
          "QueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryId",
          "WorkUnitRanges"
        ],
        "members": {
          "NextToken": {},
          "QueryId": {},
          "WorkUnitRanges": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkUnitIdMax",
                "WorkUnitIdMin",
                "WorkUnitToken"
              ],
              "members": {
                "WorkUnitIdMax": {
                  "type": "long"
                },
                "WorkUnitIdMin": {
                  "type": "long"
                },
                "WorkUnitToken": {}
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "query-"
      }
    },
    "GrantPermissions": {
      "http": {
        "requestUri": "/GrantPermissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Principal",
          "Resource",
          "Permissions"
        ],
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "Sy"
          },
          "Resource": {
            "shape": "S3"
          },
          "Permissions": {
            "shape": "S10"
          },
          "PermissionsWithGrantOption": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListDataCellsFilter": {
      "http": {
        "requestUri": "/ListDataCellsFilter"
      },
      "input": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "S7"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataCellsFilters": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLFTags": {
      "http": {
        "requestUri": "/ListLFTags"
      },
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "ResourceShareType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LFTags": {
            "shape": "Sn"
          },
          "NextToken": {}
        }
      }
    },
    "ListPermissions": {
      "http": {
        "requestUri": "/ListPermissions"
      },
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "Sy"
          },
          "ResourceType": {},
          "Resource": {
            "shape": "S3"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "IncludeRelated": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrincipalResourcePermissions": {
            "shape": "S2l"
          },
          "NextToken": {}
        }
      }
    },
    "ListResources": {
      "http": {
        "requestUri": "/ListResources"
      },
      "input": {
        "type": "structure",
        "members": {
          "FilterConditionList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Field": {},
                "ComparisonOperator": {},
                "StringValueList": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInfoList": {
            "type": "list",
            "member": {
              "shape": "S1z"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTableStorageOptimizers": {
      "http": {
        "requestUri": "/ListTableStorageOptimizers"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "StorageOptimizerType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageOptimizerList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StorageOptimizerType": {},
                "Config": {
                  "shape": "S56"
                },
                "ErrorMessage": {},
                "Warnings": {},
                "LastRunDetails": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTransactions": {
      "http": {
        "requestUri": "/ListTransactions"
      },
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "StatusFilter": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Transactions": {
            "type": "list",
            "member": {
              "shape": "S24"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutDataLakeSettings": {
      "http": {
        "requestUri": "/PutDataLakeSettings"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataLakeSettings"
        ],
        "members": {
          "CatalogId": {},
          "DataLakeSettings": {
            "shape": "S2a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterResource": {
      "http": {
        "requestUri": "/RegisterResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "UseServiceLinkedRole": {
            "type": "boolean"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveLFTagsFromResource": {
      "http": {
        "requestUri": "/RemoveLFTagsFromResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "LFTags"
        ],
        "members": {
          "CatalogId": {},
          "Resource": {
            "shape": "S3"
          },
          "LFTags": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sq"
          }
        }
      }
    },
    "RevokePermissions": {
      "http": {
        "requestUri": "/RevokePermissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Principal",
          "Resource",
          "Permissions"
        ],
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "Sy"
          },
          "Resource": {
            "shape": "S3"
          },
          "Permissions": {
            "shape": "S10"
          },
          "PermissionsWithGrantOption": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SearchDatabasesByLFTags": {
      "http": {
        "requestUri": "/SearchDatabasesByLFTags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Expression"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CatalogId": {},
          "Expression": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DatabaseList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Database": {
                  "shape": "S5"
                },
                "LFTags": {
                  "shape": "Sn"
                }
              }
            }
          }
        }
      }
    },
    "SearchTablesByLFTags": {
      "http": {
        "requestUri": "/SearchTablesByLFTags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Expression"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CatalogId": {},
          "Expression": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "TableList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Table": {
                  "shape": "S7"
                },
                "LFTagOnDatabase": {
                  "shape": "Sn"
                },
                "LFTagsOnTable": {
                  "shape": "Sn"
                },
                "LFTagsOnColumns": {
                  "shape": "S39"
                }
              }
            }
          }
        }
      }
    },
    "StartQueryPlanning": {
      "http": {
        "requestUri": "/StartQueryPlanning",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "QueryPlanningContext",
          "QueryString"
        ],
        "members": {
          "QueryPlanningContext": {
            "type": "structure",
            "required": [
              "DatabaseName"
            ],
            "members": {
              "CatalogId": {},
              "DatabaseName": {},
              "QueryAsOfTime": {
                "type": "timestamp"
              },
              "QueryParameters": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "TransactionId": {}
            }
          },
          "QueryString": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "QueryId"
        ],
        "members": {
          "QueryId": {}
        }
      },
      "endpoint": {
        "hostPrefix": "query-"
      }
    },
    "StartTransaction": {
      "http": {
        "requestUri": "/StartTransaction"
      },
      "input": {
        "type": "structure",
        "members": {
          "TransactionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransactionId": {}
        }
      }
    },
    "UpdateLFTag": {
      "http": {
        "requestUri": "/UpdateLFTag"
      },
      "input": {
        "type": "structure",
        "required": [
          "TagKey"
        ],
        "members": {
          "CatalogId": {},
          "TagKey": {},
          "TagValuesToDelete": {
            "shape": "Sg"
          },
          "TagValuesToAdd": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateResource": {
      "http": {
        "requestUri": "/UpdateResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "ResourceArn"
        ],
        "members": {
          "RoleArn": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTableObjects": {
      "http": {
        "requestUri": "/UpdateTableObjects"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "WriteOperations"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "TransactionId": {},
          "WriteOperations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AddObject": {
                  "type": "structure",
                  "required": [
                    "Uri",
                    "ETag",
                    "Size"
                  ],
                  "members": {
                    "Uri": {},
                    "ETag": {},
                    "Size": {
                      "type": "long"
                    },
                    "PartitionValues": {
                      "shape": "S3g"
                    }
                  }
                },
                "DeleteObject": {
                  "type": "structure",
                  "required": [
                    "Uri"
                  ],
                  "members": {
                    "Uri": {},
                    "ETag": {},
                    "PartitionValues": {
                      "shape": "S3g"
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTableStorageOptimizer": {
      "http": {
        "requestUri": "/UpdateTableStorageOptimizer"
      },
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "StorageOptimizerConfig"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "StorageOptimizerConfig": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S56"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "Catalog": {
          "type": "structure",
          "members": {}
        },
        "Database": {
          "shape": "S5"
        },
        "Table": {
          "shape": "S7"
        },
        "TableWithColumns": {
          "type": "structure",
          "required": [
            "DatabaseName",
            "Name"
          ],
          "members": {
            "CatalogId": {},
            "DatabaseName": {},
            "Name": {},
            "ColumnNames": {
              "shape": "Sa"
            },
            "ColumnWildcard": {
              "shape": "Sb"
            }
          }
        },
        "DataLocation": {
          "type": "structure",
          "required": [
            "ResourceArn"
          ],
          "members": {
            "CatalogId": {},
            "ResourceArn": {}
          }
        },
        "DataCellsFilter": {
          "type": "structure",
          "members": {
            "TableCatalogId": {},
            "DatabaseName": {},
            "TableName": {},
            "Name": {}
          }
        },
        "LFTag": {
          "type": "structure",
          "required": [
            "TagKey",
            "TagValues"
          ],
          "members": {
            "CatalogId": {},
            "TagKey": {},
            "TagValues": {
              "shape": "Sg"
            }
          }
        },
        "LFTagPolicy": {
          "type": "structure",
          "required": [
            "ResourceType",
            "Expression"
          ],
          "members": {
            "CatalogId": {},
            "ResourceType": {},
            "Expression": {
              "shape": "Sk"
            }
          }
        }
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "CatalogId": {},
        "Name": {}
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "DatabaseName"
      ],
      "members": {
        "CatalogId": {},
        "DatabaseName": {},
        "Name": {},
        "TableWildcard": {
          "type": "structure",
          "members": {}
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "structure",
      "members": {
        "ExcludedColumnNames": {
          "shape": "Sa"
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "Sk": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "TagKey",
          "TagValues"
        ],
        "members": {
          "TagKey": {},
          "TagValues": {
            "shape": "Sg"
          }
        }
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "shape": "So"
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "TagKey",
        "TagValues"
      ],
      "members": {
        "CatalogId": {},
        "TagKey": {},
        "TagValues": {
          "shape": "Sg"
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LFTag": {
            "shape": "So"
          },
          "Error": {
            "shape": "Ss"
          }
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "ErrorCode": {},
        "ErrorMessage": {}
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "shape": "Sw"
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Id": {},
        "Principal": {
          "shape": "Sy"
        },
        "Resource": {
          "shape": "S3"
        },
        "Permissions": {
          "shape": "S10"
        },
        "PermissionsWithGrantOption": {
          "shape": "S10"
        }
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "DataLakePrincipalIdentifier": {}
      }
    },
    "S10": {
      "type": "list",
      "member": {}
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RequestEntry": {
            "shape": "Sw"
          },
          "Error": {
            "shape": "Ss"
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "TableCatalogId",
        "DatabaseName",
        "TableName",
        "Name"
      ],
      "members": {
        "TableCatalogId": {},
        "DatabaseName": {},
        "TableName": {},
        "Name": {},
        "RowFilter": {
          "type": "structure",
          "members": {
            "FilterExpression": {},
            "AllRowsWildcard": {
              "type": "structure",
              "members": {}
            }
          }
        },
        "ColumnNames": {
          "shape": "Sa"
        },
        "ColumnWildcard": {
          "shape": "Sb"
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "ResourceArn": {},
        "RoleArn": {},
        "LastModified": {
          "type": "timestamp"
        }
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "TransactionId": {},
        "TransactionStatus": {},
        "TransactionStartTime": {
          "type": "timestamp"
        },
        "TransactionEndTime": {
          "type": "timestamp"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "DataLakeAdmins": {
          "shape": "S2b"
        },
        "CreateDatabaseDefaultPermissions": {
          "shape": "S2c"
        },
        "CreateTableDefaultPermissions": {
          "shape": "S2c"
        },
        "TrustedResourceOwners": {
          "type": "list",
          "member": {}
        },
        "AllowExternalDataFiltering": {
          "type": "boolean"
        },
        "ExternalDataFilteringAllowList": {
          "shape": "S2b"
        },
        "AuthorizedSessionTagValueList": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "shape": "Sy"
      }
    },
    "S2c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "shape": "Sy"
          },
          "Permissions": {
            "shape": "S10"
          }
        }
      }
    },
    "S2l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "shape": "Sy"
          },
          "Resource": {
            "shape": "S3"
          },
          "Permissions": {
            "shape": "S10"
          },
          "PermissionsWithGrantOption": {
            "shape": "S10"
          },
          "AdditionalDetails": {
            "type": "structure",
            "members": {
              "ResourceShare": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S39": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "LFTags": {
            "shape": "Sn"
          }
        }
      }
    },
    "S3g": {
      "type": "list",
      "member": {}
    },
    "S3q": {
      "type": "structure",
      "members": {
        "AdditionalAuditContext": {}
      }
    },
    "S3s": {
      "type": "list",
      "member": {}
    },
    "S56": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}
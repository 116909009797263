{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-12-02",
    "endpointPrefix": "iotsitewise",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT SiteWise",
    "serviceId": "IoTSiteWise",
    "signatureVersion": "v4",
    "signingName": "iotsitewise",
    "uid": "iotsitewise-2019-12-02"
  },
  "operations": {
    "AssociateAssets": {
      "http": {
        "requestUri": "/assets/{assetId}/associate"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId",
          "hierarchyId",
          "childAssetId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "hierarchyId": {},
          "childAssetId": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "AssociateTimeSeriesToAssetProperty": {
      "http": {
        "requestUri": "/timeseries/associate/"
      },
      "input": {
        "type": "structure",
        "required": [
          "alias",
          "assetId",
          "propertyId"
        ],
        "members": {
          "alias": {
            "location": "querystring",
            "locationName": "alias"
          },
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "BatchAssociateProjectAssets": {
      "http": {
        "requestUri": "/projects/{projectId}/assets/associate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId",
          "assetIds"
        ],
        "members": {
          "projectId": {
            "location": "uri",
            "locationName": "projectId"
          },
          "assetIds": {
            "shape": "S7"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "errors": {
            "type": "list",
            "member": {
              "shape": "Sa"
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "BatchDisassociateProjectAssets": {
      "http": {
        "requestUri": "/projects/{projectId}/assets/disassociate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId",
          "assetIds"
        ],
        "members": {
          "projectId": {
            "location": "uri",
            "locationName": "projectId"
          },
          "assetIds": {
            "shape": "S7"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "errors": {
            "type": "list",
            "member": {
              "shape": "Sa"
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "BatchPutAssetPropertyValue": {
      "http": {
        "requestUri": "/properties"
      },
      "input": {
        "type": "structure",
        "required": [
          "entries"
        ],
        "members": {
          "entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "entryId",
                "propertyValues"
              ],
              "members": {
                "entryId": {},
                "assetId": {},
                "propertyId": {},
                "propertyAlias": {},
                "propertyValues": {
                  "type": "list",
                  "member": {
                    "shape": "Sm"
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "errorEntries"
        ],
        "members": {
          "errorEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "entryId",
                "errors"
              ],
              "members": {
                "entryId": {},
                "errors": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "errorCode",
                      "errorMessage",
                      "timestamps"
                    ],
                    "members": {
                      "errorCode": {},
                      "errorMessage": {},
                      "timestamps": {
                        "type": "list",
                        "member": {
                          "shape": "Ss"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "CreateAccessPolicy": {
      "http": {
        "requestUri": "/access-policies",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "accessPolicyIdentity",
          "accessPolicyResource",
          "accessPolicyPermission"
        ],
        "members": {
          "accessPolicyIdentity": {
            "shape": "S15"
          },
          "accessPolicyResource": {
            "shape": "S1c"
          },
          "accessPolicyPermission": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accessPolicyId",
          "accessPolicyArn"
        ],
        "members": {
          "accessPolicyId": {},
          "accessPolicyArn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "CreateAsset": {
      "http": {
        "requestUri": "/assets",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "assetName",
          "assetModelId"
        ],
        "members": {
          "assetName": {},
          "assetModelId": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "assetArn",
          "assetStatus"
        ],
        "members": {
          "assetId": {},
          "assetArn": {},
          "assetStatus": {
            "shape": "S1n"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreateAssetModel": {
      "http": {
        "requestUri": "/asset-models",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "assetModelName"
        ],
        "members": {
          "assetModelName": {},
          "assetModelDescription": {},
          "assetModelProperties": {
            "shape": "S1x"
          },
          "assetModelHierarchies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "childAssetModelId"
              ],
              "members": {
                "name": {},
                "childAssetModelId": {}
              }
            }
          },
          "assetModelCompositeModels": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "type"
              ],
              "members": {
                "name": {},
                "description": {},
                "type": {},
                "properties": {
                  "shape": "S1x"
                }
              }
            }
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetModelId",
          "assetModelArn",
          "assetModelStatus"
        ],
        "members": {
          "assetModelId": {},
          "assetModelArn": {},
          "assetModelStatus": {
            "shape": "S2s"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreateDashboard": {
      "http": {
        "requestUri": "/dashboards",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId",
          "dashboardName",
          "dashboardDefinition"
        ],
        "members": {
          "projectId": {},
          "dashboardName": {},
          "dashboardDescription": {},
          "dashboardDefinition": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "dashboardId",
          "dashboardArn"
        ],
        "members": {
          "dashboardId": {},
          "dashboardArn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "CreateGateway": {
      "http": {
        "requestUri": "/20200301/gateways",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayName",
          "gatewayPlatform"
        ],
        "members": {
          "gatewayName": {},
          "gatewayPlatform": {
            "shape": "S2y"
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayId",
          "gatewayArn"
        ],
        "members": {
          "gatewayId": {},
          "gatewayArn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreatePortal": {
      "http": {
        "requestUri": "/portals",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "portalName",
          "portalContactEmail",
          "roleArn"
        ],
        "members": {
          "portalName": {},
          "portalDescription": {},
          "portalContactEmail": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "portalLogoImageFile": {
            "shape": "S35"
          },
          "roleArn": {},
          "tags": {
            "shape": "S1g"
          },
          "portalAuthMode": {},
          "notificationSenderEmail": {},
          "alarms": {
            "shape": "S39"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalId",
          "portalArn",
          "portalStartUrl",
          "portalStatus",
          "ssoApplicationId"
        ],
        "members": {
          "portalId": {},
          "portalArn": {},
          "portalStartUrl": {},
          "portalStatus": {
            "shape": "S3c"
          },
          "ssoApplicationId": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "CreateProject": {
      "http": {
        "requestUri": "/projects",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "portalId",
          "projectName"
        ],
        "members": {
          "portalId": {},
          "projectName": {},
          "projectDescription": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "projectId",
          "projectArn"
        ],
        "members": {
          "projectId": {},
          "projectArn": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DeleteAccessPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/access-policies/{accessPolicyId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "accessPolicyId"
        ],
        "members": {
          "accessPolicyId": {
            "location": "uri",
            "locationName": "accessPolicyId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DeleteAsset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/assets/{assetId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetStatus"
        ],
        "members": {
          "assetStatus": {
            "shape": "S1n"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteAssetModel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/asset-models/{assetModelId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "assetModelId"
        ],
        "members": {
          "assetModelId": {
            "location": "uri",
            "locationName": "assetModelId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetModelStatus"
        ],
        "members": {
          "assetModelStatus": {
            "shape": "S2s"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteDashboard": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dashboards/{dashboardId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "dashboardId"
        ],
        "members": {
          "dashboardId": {
            "location": "uri",
            "locationName": "dashboardId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DeleteGateway": {
      "http": {
        "method": "DELETE",
        "requestUri": "/20200301/gateways/{gatewayId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayId"
        ],
        "members": {
          "gatewayId": {
            "location": "uri",
            "locationName": "gatewayId"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeletePortal": {
      "http": {
        "method": "DELETE",
        "requestUri": "/portals/{portalId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "portalId"
        ],
        "members": {
          "portalId": {
            "location": "uri",
            "locationName": "portalId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalStatus"
        ],
        "members": {
          "portalStatus": {
            "shape": "S3c"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DeleteProject": {
      "http": {
        "method": "DELETE",
        "requestUri": "/projects/{projectId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId"
        ],
        "members": {
          "projectId": {
            "location": "uri",
            "locationName": "projectId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DeleteTimeSeries": {
      "http": {
        "requestUri": "/timeseries/delete/"
      },
      "input": {
        "type": "structure",
        "members": {
          "alias": {
            "location": "querystring",
            "locationName": "alias"
          },
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeAccessPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/access-policies/{accessPolicyId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "accessPolicyId"
        ],
        "members": {
          "accessPolicyId": {
            "location": "uri",
            "locationName": "accessPolicyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accessPolicyId",
          "accessPolicyArn",
          "accessPolicyIdentity",
          "accessPolicyResource",
          "accessPolicyPermission",
          "accessPolicyCreationDate",
          "accessPolicyLastUpdateDate"
        ],
        "members": {
          "accessPolicyId": {},
          "accessPolicyArn": {},
          "accessPolicyIdentity": {
            "shape": "S15"
          },
          "accessPolicyResource": {
            "shape": "S1c"
          },
          "accessPolicyPermission": {},
          "accessPolicyCreationDate": {
            "type": "timestamp"
          },
          "accessPolicyLastUpdateDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DescribeAsset": {
      "http": {
        "method": "GET",
        "requestUri": "/assets/{assetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "assetArn",
          "assetName",
          "assetModelId",
          "assetProperties",
          "assetHierarchies",
          "assetCreationDate",
          "assetLastUpdateDate",
          "assetStatus"
        ],
        "members": {
          "assetId": {},
          "assetArn": {},
          "assetName": {},
          "assetModelId": {},
          "assetProperties": {
            "shape": "S43"
          },
          "assetHierarchies": {
            "shape": "S48"
          },
          "assetCompositeModels": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "type",
                "properties"
              ],
              "members": {
                "name": {},
                "description": {},
                "type": {},
                "properties": {
                  "shape": "S43"
                }
              }
            }
          },
          "assetCreationDate": {
            "type": "timestamp"
          },
          "assetLastUpdateDate": {
            "type": "timestamp"
          },
          "assetStatus": {
            "shape": "S1n"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeAssetModel": {
      "http": {
        "method": "GET",
        "requestUri": "/asset-models/{assetModelId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetModelId"
        ],
        "members": {
          "assetModelId": {
            "location": "uri",
            "locationName": "assetModelId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetModelId",
          "assetModelArn",
          "assetModelName",
          "assetModelDescription",
          "assetModelProperties",
          "assetModelHierarchies",
          "assetModelCreationDate",
          "assetModelLastUpdateDate",
          "assetModelStatus"
        ],
        "members": {
          "assetModelId": {},
          "assetModelArn": {},
          "assetModelName": {},
          "assetModelDescription": {},
          "assetModelProperties": {
            "shape": "S4e"
          },
          "assetModelHierarchies": {
            "shape": "S4g"
          },
          "assetModelCompositeModels": {
            "shape": "S4i"
          },
          "assetModelCreationDate": {
            "type": "timestamp"
          },
          "assetModelLastUpdateDate": {
            "type": "timestamp"
          },
          "assetModelStatus": {
            "shape": "S2s"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeAssetProperty": {
      "http": {
        "method": "GET",
        "requestUri": "/assets/{assetId}/properties/{propertyId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId",
          "propertyId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "uri",
            "locationName": "propertyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "assetName",
          "assetModelId"
        ],
        "members": {
          "assetId": {},
          "assetName": {},
          "assetModelId": {},
          "assetProperty": {
            "shape": "S4m"
          },
          "compositeModel": {
            "type": "structure",
            "required": [
              "name",
              "type",
              "assetProperty"
            ],
            "members": {
              "name": {},
              "type": {},
              "assetProperty": {
                "shape": "S4m"
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeDashboard": {
      "http": {
        "method": "GET",
        "requestUri": "/dashboards/{dashboardId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dashboardId"
        ],
        "members": {
          "dashboardId": {
            "location": "uri",
            "locationName": "dashboardId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "dashboardId",
          "dashboardArn",
          "dashboardName",
          "projectId",
          "dashboardDefinition",
          "dashboardCreationDate",
          "dashboardLastUpdateDate"
        ],
        "members": {
          "dashboardId": {},
          "dashboardArn": {},
          "dashboardName": {},
          "projectId": {},
          "dashboardDescription": {},
          "dashboardDefinition": {},
          "dashboardCreationDate": {
            "type": "timestamp"
          },
          "dashboardLastUpdateDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DescribeDefaultEncryptionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/configuration/account/encryption"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "encryptionType",
          "configurationStatus"
        ],
        "members": {
          "encryptionType": {},
          "kmsKeyArn": {},
          "configurationStatus": {
            "shape": "S4t"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeGateway": {
      "http": {
        "method": "GET",
        "requestUri": "/20200301/gateways/{gatewayId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayId"
        ],
        "members": {
          "gatewayId": {
            "location": "uri",
            "locationName": "gatewayId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayId",
          "gatewayName",
          "gatewayArn",
          "gatewayCapabilitySummaries",
          "creationDate",
          "lastUpdateDate"
        ],
        "members": {
          "gatewayId": {},
          "gatewayName": {},
          "gatewayArn": {},
          "gatewayPlatform": {
            "shape": "S2y"
          },
          "gatewayCapabilitySummaries": {
            "shape": "S4y"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastUpdateDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeGatewayCapabilityConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/20200301/gateways/{gatewayId}/capability/{capabilityNamespace}"
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayId",
          "capabilityNamespace"
        ],
        "members": {
          "gatewayId": {
            "location": "uri",
            "locationName": "gatewayId"
          },
          "capabilityNamespace": {
            "location": "uri",
            "locationName": "capabilityNamespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewayId",
          "capabilityNamespace",
          "capabilityConfiguration",
          "capabilitySyncStatus"
        ],
        "members": {
          "gatewayId": {},
          "capabilityNamespace": {},
          "capabilityConfiguration": {},
          "capabilitySyncStatus": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/logging"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "loggingOptions"
        ],
        "members": {
          "loggingOptions": {
            "shape": "S57"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribePortal": {
      "http": {
        "method": "GET",
        "requestUri": "/portals/{portalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalId"
        ],
        "members": {
          "portalId": {
            "location": "uri",
            "locationName": "portalId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalId",
          "portalArn",
          "portalName",
          "portalClientId",
          "portalStartUrl",
          "portalContactEmail",
          "portalStatus",
          "portalCreationDate",
          "portalLastUpdateDate"
        ],
        "members": {
          "portalId": {},
          "portalArn": {},
          "portalName": {},
          "portalDescription": {},
          "portalClientId": {},
          "portalStartUrl": {},
          "portalContactEmail": {},
          "portalStatus": {
            "shape": "S3c"
          },
          "portalCreationDate": {
            "type": "timestamp"
          },
          "portalLastUpdateDate": {
            "type": "timestamp"
          },
          "portalLogoImageLocation": {
            "type": "structure",
            "required": [
              "id",
              "url"
            ],
            "members": {
              "id": {},
              "url": {}
            }
          },
          "roleArn": {},
          "portalAuthMode": {},
          "notificationSenderEmail": {},
          "alarms": {
            "shape": "S39"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DescribeProject": {
      "http": {
        "method": "GET",
        "requestUri": "/projects/{projectId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId"
        ],
        "members": {
          "projectId": {
            "location": "uri",
            "locationName": "projectId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "projectId",
          "projectArn",
          "projectName",
          "portalId",
          "projectCreationDate",
          "projectLastUpdateDate"
        ],
        "members": {
          "projectId": {},
          "projectArn": {},
          "projectName": {},
          "portalId": {},
          "projectDescription": {},
          "projectCreationDate": {
            "type": "timestamp"
          },
          "projectLastUpdateDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "DescribeStorageConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/configuration/account/storage"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "storageType",
          "configurationStatus"
        ],
        "members": {
          "storageType": {},
          "multiLayerStorage": {
            "shape": "S5i"
          },
          "disassociatedDataStorage": {},
          "retentionPeriod": {
            "shape": "S5l"
          },
          "configurationStatus": {
            "shape": "S4t"
          },
          "lastUpdateDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DescribeTimeSeries": {
      "http": {
        "method": "GET",
        "requestUri": "/timeseries/describe/"
      },
      "input": {
        "type": "structure",
        "members": {
          "alias": {
            "location": "querystring",
            "locationName": "alias"
          },
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "timeSeriesId",
          "dataType",
          "timeSeriesCreationDate",
          "timeSeriesLastUpdateDate"
        ],
        "members": {
          "assetId": {},
          "propertyId": {},
          "alias": {},
          "timeSeriesId": {},
          "dataType": {},
          "dataTypeSpec": {},
          "timeSeriesCreationDate": {
            "type": "timestamp"
          },
          "timeSeriesLastUpdateDate": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DisassociateAssets": {
      "http": {
        "requestUri": "/assets/{assetId}/disassociate"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId",
          "hierarchyId",
          "childAssetId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "hierarchyId": {},
          "childAssetId": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DisassociateTimeSeriesFromAssetProperty": {
      "http": {
        "requestUri": "/timeseries/disassociate/"
      },
      "input": {
        "type": "structure",
        "required": [
          "alias",
          "assetId",
          "propertyId"
        ],
        "members": {
          "alias": {
            "location": "querystring",
            "locationName": "alias"
          },
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetAssetPropertyAggregates": {
      "http": {
        "method": "GET",
        "requestUri": "/properties/aggregates"
      },
      "input": {
        "type": "structure",
        "required": [
          "aggregateTypes",
          "resolution",
          "startDate",
          "endDate"
        ],
        "members": {
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "propertyAlias": {
            "location": "querystring",
            "locationName": "propertyAlias"
          },
          "aggregateTypes": {
            "location": "querystring",
            "locationName": "aggregateTypes",
            "type": "list",
            "member": {}
          },
          "resolution": {
            "location": "querystring",
            "locationName": "resolution"
          },
          "qualities": {
            "shape": "S5x",
            "location": "querystring",
            "locationName": "qualities"
          },
          "startDate": {
            "location": "querystring",
            "locationName": "startDate",
            "type": "timestamp"
          },
          "endDate": {
            "location": "querystring",
            "locationName": "endDate",
            "type": "timestamp"
          },
          "timeOrdering": {
            "location": "querystring",
            "locationName": "timeOrdering"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "aggregatedValues"
        ],
        "members": {
          "aggregatedValues": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "timestamp",
                "value"
              ],
              "members": {
                "timestamp": {
                  "type": "timestamp"
                },
                "quality": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "average": {
                      "type": "double"
                    },
                    "count": {
                      "type": "double"
                    },
                    "maximum": {
                      "type": "double"
                    },
                    "minimum": {
                      "type": "double"
                    },
                    "sum": {
                      "type": "double"
                    },
                    "standardDeviation": {
                      "type": "double"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "GetAssetPropertyValue": {
      "http": {
        "method": "GET",
        "requestUri": "/properties/latest"
      },
      "input": {
        "type": "structure",
        "members": {
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "propertyAlias": {
            "location": "querystring",
            "locationName": "propertyAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "propertyValue": {
            "shape": "Sm"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "GetAssetPropertyValueHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/properties/history"
      },
      "input": {
        "type": "structure",
        "members": {
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "propertyAlias": {
            "location": "querystring",
            "locationName": "propertyAlias"
          },
          "startDate": {
            "location": "querystring",
            "locationName": "startDate",
            "type": "timestamp"
          },
          "endDate": {
            "location": "querystring",
            "locationName": "endDate",
            "type": "timestamp"
          },
          "qualities": {
            "shape": "S5x",
            "location": "querystring",
            "locationName": "qualities"
          },
          "timeOrdering": {
            "location": "querystring",
            "locationName": "timeOrdering"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetPropertyValueHistory"
        ],
        "members": {
          "assetPropertyValueHistory": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "GetInterpolatedAssetPropertyValues": {
      "http": {
        "method": "GET",
        "requestUri": "/properties/interpolated"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTimeInSeconds",
          "endTimeInSeconds",
          "quality",
          "intervalInSeconds",
          "type"
        ],
        "members": {
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "querystring",
            "locationName": "propertyId"
          },
          "propertyAlias": {
            "location": "querystring",
            "locationName": "propertyAlias"
          },
          "startTimeInSeconds": {
            "location": "querystring",
            "locationName": "startTimeInSeconds",
            "type": "long"
          },
          "startTimeOffsetInNanos": {
            "location": "querystring",
            "locationName": "startTimeOffsetInNanos",
            "type": "integer"
          },
          "endTimeInSeconds": {
            "location": "querystring",
            "locationName": "endTimeInSeconds",
            "type": "long"
          },
          "endTimeOffsetInNanos": {
            "location": "querystring",
            "locationName": "endTimeOffsetInNanos",
            "type": "integer"
          },
          "quality": {
            "location": "querystring",
            "locationName": "quality"
          },
          "intervalInSeconds": {
            "location": "querystring",
            "locationName": "intervalInSeconds",
            "type": "long"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          },
          "intervalWindowInSeconds": {
            "location": "querystring",
            "locationName": "intervalWindowInSeconds",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "interpolatedAssetPropertyValues"
        ],
        "members": {
          "interpolatedAssetPropertyValues": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "timestamp",
                "value"
              ],
              "members": {
                "timestamp": {
                  "shape": "Ss"
                },
                "value": {
                  "shape": "Sn"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "ListAccessPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/access-policies",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "identityType": {
            "location": "querystring",
            "locationName": "identityType"
          },
          "identityId": {
            "location": "querystring",
            "locationName": "identityId"
          },
          "resourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "resourceId": {
            "location": "querystring",
            "locationName": "resourceId"
          },
          "iamArn": {
            "location": "querystring",
            "locationName": "iamArn"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "accessPolicySummaries"
        ],
        "members": {
          "accessPolicySummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "identity",
                "resource",
                "permission"
              ],
              "members": {
                "id": {},
                "identity": {
                  "shape": "S15"
                },
                "resource": {
                  "shape": "S1c"
                },
                "permission": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "ListAssetModels": {
      "http": {
        "method": "GET",
        "requestUri": "/asset-models"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetModelSummaries"
        ],
        "members": {
          "assetModelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "name",
                "description",
                "creationDate",
                "lastUpdateDate",
                "status"
              ],
              "members": {
                "id": {},
                "arn": {},
                "name": {},
                "description": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                },
                "status": {
                  "shape": "S2s"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListAssetRelationships": {
      "http": {
        "method": "GET",
        "requestUri": "/assets/{assetId}/assetRelationships"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId",
          "traversalType"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "traversalType": {
            "location": "querystring",
            "locationName": "traversalType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetRelationshipSummaries"
        ],
        "members": {
          "assetRelationshipSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "relationshipType"
              ],
              "members": {
                "hierarchyInfo": {
                  "type": "structure",
                  "members": {
                    "parentAssetId": {},
                    "childAssetId": {}
                  }
                },
                "relationshipType": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListAssets": {
      "http": {
        "method": "GET",
        "requestUri": "/assets"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "assetModelId": {
            "location": "querystring",
            "locationName": "assetModelId"
          },
          "filter": {
            "location": "querystring",
            "locationName": "filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetSummaries"
        ],
        "members": {
          "assetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "name",
                "assetModelId",
                "creationDate",
                "lastUpdateDate",
                "status",
                "hierarchies"
              ],
              "members": {
                "id": {},
                "arn": {},
                "name": {},
                "assetModelId": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                },
                "status": {
                  "shape": "S1n"
                },
                "hierarchies": {
                  "shape": "S48"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListAssociatedAssets": {
      "http": {
        "method": "GET",
        "requestUri": "/assets/{assetId}/hierarchies"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "hierarchyId": {
            "location": "querystring",
            "locationName": "hierarchyId"
          },
          "traversalDirection": {
            "location": "querystring",
            "locationName": "traversalDirection"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetSummaries"
        ],
        "members": {
          "assetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "name",
                "assetModelId",
                "creationDate",
                "lastUpdateDate",
                "status",
                "hierarchies"
              ],
              "members": {
                "id": {},
                "arn": {},
                "name": {},
                "assetModelId": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                },
                "status": {
                  "shape": "S1n"
                },
                "hierarchies": {
                  "shape": "S48"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListDashboards": {
      "http": {
        "method": "GET",
        "requestUri": "/dashboards",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId"
        ],
        "members": {
          "projectId": {
            "location": "querystring",
            "locationName": "projectId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "dashboardSummaries"
        ],
        "members": {
          "dashboardSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "name"
              ],
              "members": {
                "id": {},
                "name": {},
                "description": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "ListGateways": {
      "http": {
        "method": "GET",
        "requestUri": "/20200301/gateways"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "gatewaySummaries"
        ],
        "members": {
          "gatewaySummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "gatewayId",
                "gatewayName",
                "creationDate",
                "lastUpdateDate"
              ],
              "members": {
                "gatewayId": {},
                "gatewayName": {},
                "gatewayPlatform": {
                  "shape": "S2y"
                },
                "gatewayCapabilitySummaries": {
                  "shape": "S4y"
                },
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListPortals": {
      "http": {
        "method": "GET",
        "requestUri": "/portals",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "portalSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "name",
                "startUrl",
                "status"
              ],
              "members": {
                "id": {},
                "name": {},
                "description": {},
                "startUrl": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                },
                "roleArn": {},
                "status": {
                  "shape": "S3c"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "ListProjectAssets": {
      "http": {
        "method": "GET",
        "requestUri": "/projects/{projectId}/assets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId"
        ],
        "members": {
          "projectId": {
            "location": "uri",
            "locationName": "projectId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetIds"
        ],
        "members": {
          "assetIds": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "ListProjects": {
      "http": {
        "method": "GET",
        "requestUri": "/projects",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "portalId"
        ],
        "members": {
          "portalId": {
            "location": "querystring",
            "locationName": "portalId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "projectSummaries"
        ],
        "members": {
          "projectSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "name"
              ],
              "members": {
                "id": {},
                "name": {},
                "description": {},
                "creationDate": {
                  "type": "timestamp"
                },
                "lastUpdateDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListTimeSeries": {
      "http": {
        "method": "GET",
        "requestUri": "/timeseries/"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "assetId": {
            "location": "querystring",
            "locationName": "assetId"
          },
          "aliasPrefix": {
            "location": "querystring",
            "locationName": "aliasPrefix"
          },
          "timeSeriesType": {
            "location": "querystring",
            "locationName": "timeSeriesType"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TimeSeriesSummaries"
        ],
        "members": {
          "TimeSeriesSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "timeSeriesId",
                "dataType",
                "timeSeriesCreationDate",
                "timeSeriesLastUpdateDate"
              ],
              "members": {
                "assetId": {},
                "propertyId": {},
                "alias": {},
                "timeSeriesId": {},
                "dataType": {},
                "dataTypeSpec": {},
                "timeSeriesCreationDate": {
                  "type": "timestamp"
                },
                "timeSeriesLastUpdateDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "PutDefaultEncryptionConfiguration": {
      "http": {
        "requestUri": "/configuration/account/encryption"
      },
      "input": {
        "type": "structure",
        "required": [
          "encryptionType"
        ],
        "members": {
          "encryptionType": {},
          "kmsKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "encryptionType",
          "configurationStatus"
        ],
        "members": {
          "encryptionType": {},
          "kmsKeyArn": {},
          "configurationStatus": {
            "shape": "S4t"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "PutLoggingOptions": {
      "http": {
        "method": "PUT",
        "requestUri": "/logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptions"
        ],
        "members": {
          "loggingOptions": {
            "shape": "S57"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "PutStorageConfiguration": {
      "http": {
        "requestUri": "/configuration/account/storage"
      },
      "input": {
        "type": "structure",
        "required": [
          "storageType"
        ],
        "members": {
          "storageType": {},
          "multiLayerStorage": {
            "shape": "S5i"
          },
          "disassociatedDataStorage": {},
          "retentionPeriod": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "storageType",
          "configurationStatus"
        ],
        "members": {
          "storageType": {},
          "multiLayerStorage": {
            "shape": "S5i"
          },
          "disassociatedDataStorage": {},
          "retentionPeriod": {
            "shape": "S5l"
          },
          "configurationStatus": {
            "shape": "S4t"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateAccessPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/access-policies/{accessPolicyId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "accessPolicyId",
          "accessPolicyIdentity",
          "accessPolicyResource",
          "accessPolicyPermission"
        ],
        "members": {
          "accessPolicyId": {
            "location": "uri",
            "locationName": "accessPolicyId"
          },
          "accessPolicyIdentity": {
            "shape": "S15"
          },
          "accessPolicyResource": {
            "shape": "S1c"
          },
          "accessPolicyPermission": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "UpdateAsset": {
      "http": {
        "method": "PUT",
        "requestUri": "/assets/{assetId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId",
          "assetName"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "assetName": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetStatus"
        ],
        "members": {
          "assetStatus": {
            "shape": "S1n"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateAssetModel": {
      "http": {
        "method": "PUT",
        "requestUri": "/asset-models/{assetModelId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "assetModelId",
          "assetModelName"
        ],
        "members": {
          "assetModelId": {
            "location": "uri",
            "locationName": "assetModelId"
          },
          "assetModelName": {},
          "assetModelDescription": {},
          "assetModelProperties": {
            "shape": "S4e"
          },
          "assetModelHierarchies": {
            "shape": "S4g"
          },
          "assetModelCompositeModels": {
            "shape": "S4i"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetModelStatus"
        ],
        "members": {
          "assetModelStatus": {
            "shape": "S2s"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateAssetProperty": {
      "http": {
        "method": "PUT",
        "requestUri": "/assets/{assetId}/properties/{propertyId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "assetId",
          "propertyId"
        ],
        "members": {
          "assetId": {
            "location": "uri",
            "locationName": "assetId"
          },
          "propertyId": {
            "location": "uri",
            "locationName": "propertyId"
          },
          "propertyAlias": {},
          "propertyNotificationState": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateDashboard": {
      "http": {
        "method": "PUT",
        "requestUri": "/dashboards/{dashboardId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dashboardId",
          "dashboardName",
          "dashboardDefinition"
        ],
        "members": {
          "dashboardId": {
            "location": "uri",
            "locationName": "dashboardId"
          },
          "dashboardName": {},
          "dashboardDescription": {},
          "dashboardDefinition": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "UpdateGateway": {
      "http": {
        "method": "PUT",
        "requestUri": "/20200301/gateways/{gatewayId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayId",
          "gatewayName"
        ],
        "members": {
          "gatewayId": {
            "location": "uri",
            "locationName": "gatewayId"
          },
          "gatewayName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateGatewayCapabilityConfiguration": {
      "http": {
        "requestUri": "/20200301/gateways/{gatewayId}/capability",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "gatewayId",
          "capabilityNamespace",
          "capabilityConfiguration"
        ],
        "members": {
          "gatewayId": {
            "location": "uri",
            "locationName": "gatewayId"
          },
          "capabilityNamespace": {},
          "capabilityConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "capabilityNamespace",
          "capabilitySyncStatus"
        ],
        "members": {
          "capabilityNamespace": {},
          "capabilitySyncStatus": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdatePortal": {
      "http": {
        "method": "PUT",
        "requestUri": "/portals/{portalId}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "portalId",
          "portalName",
          "portalContactEmail",
          "roleArn"
        ],
        "members": {
          "portalId": {
            "location": "uri",
            "locationName": "portalId"
          },
          "portalName": {},
          "portalDescription": {},
          "portalContactEmail": {},
          "portalLogoImage": {
            "type": "structure",
            "members": {
              "id": {},
              "file": {
                "shape": "S35"
              }
            }
          },
          "roleArn": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "notificationSenderEmail": {},
          "alarms": {
            "shape": "S39"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "portalStatus"
        ],
        "members": {
          "portalStatus": {
            "shape": "S3c"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    },
    "UpdateProject": {
      "http": {
        "method": "PUT",
        "requestUri": "/projects/{projectId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "projectId",
          "projectName"
        ],
        "members": {
          "projectId": {
            "location": "uri",
            "locationName": "projectId"
          },
          "projectName": {},
          "projectDescription": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "monitor."
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "required": [
        "assetId",
        "code",
        "message"
      ],
      "members": {
        "assetId": {},
        "code": {},
        "message": {}
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "value",
        "timestamp"
      ],
      "members": {
        "value": {
          "shape": "Sn"
        },
        "timestamp": {
          "shape": "Ss"
        },
        "quality": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "stringValue": {},
        "integerValue": {
          "type": "integer"
        },
        "doubleValue": {
          "type": "double"
        },
        "booleanValue": {
          "type": "boolean"
        }
      }
    },
    "Ss": {
      "type": "structure",
      "required": [
        "timeInSeconds"
      ],
      "members": {
        "timeInSeconds": {
          "type": "long"
        },
        "offsetInNanos": {
          "type": "integer"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "user": {
          "type": "structure",
          "required": [
            "id"
          ],
          "members": {
            "id": {}
          }
        },
        "group": {
          "type": "structure",
          "required": [
            "id"
          ],
          "members": {
            "id": {}
          }
        },
        "iamUser": {
          "type": "structure",
          "required": [
            "arn"
          ],
          "members": {
            "arn": {}
          }
        },
        "iamRole": {
          "type": "structure",
          "required": [
            "arn"
          ],
          "members": {
            "arn": {}
          }
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "portal": {
          "type": "structure",
          "required": [
            "id"
          ],
          "members": {
            "id": {}
          }
        },
        "project": {
          "type": "structure",
          "required": [
            "id"
          ],
          "members": {
            "id": {}
          }
        }
      }
    },
    "S1g": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1n": {
      "type": "structure",
      "required": [
        "state"
      ],
      "members": {
        "state": {},
        "error": {
          "shape": "S1p"
        }
      }
    },
    "S1p": {
      "type": "structure",
      "required": [
        "code",
        "message"
      ],
      "members": {
        "code": {},
        "message": {},
        "details": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "code",
              "message"
            ],
            "members": {
              "code": {},
              "message": {}
            }
          }
        }
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "dataType",
          "type"
        ],
        "members": {
          "name": {},
          "dataType": {},
          "dataTypeSpec": {},
          "unit": {},
          "type": {
            "shape": "S21"
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "attribute": {
          "type": "structure",
          "members": {
            "defaultValue": {}
          }
        },
        "measurement": {
          "type": "structure",
          "members": {
            "processingConfig": {
              "type": "structure",
              "required": [
                "forwardingConfig"
              ],
              "members": {
                "forwardingConfig": {
                  "shape": "S26"
                }
              }
            }
          }
        },
        "transform": {
          "type": "structure",
          "required": [
            "expression",
            "variables"
          ],
          "members": {
            "expression": {},
            "variables": {
              "shape": "S2a"
            },
            "processingConfig": {
              "type": "structure",
              "required": [
                "computeLocation"
              ],
              "members": {
                "computeLocation": {},
                "forwardingConfig": {
                  "shape": "S26"
                }
              }
            }
          }
        },
        "metric": {
          "type": "structure",
          "required": [
            "expression",
            "variables",
            "window"
          ],
          "members": {
            "expression": {},
            "variables": {
              "shape": "S2a"
            },
            "window": {
              "type": "structure",
              "members": {
                "tumbling": {
                  "type": "structure",
                  "required": [
                    "interval"
                  ],
                  "members": {
                    "interval": {},
                    "offset": {}
                  }
                }
              }
            },
            "processingConfig": {
              "type": "structure",
              "required": [
                "computeLocation"
              ],
              "members": {
                "computeLocation": {}
              }
            }
          }
        }
      }
    },
    "S26": {
      "type": "structure",
      "required": [
        "state"
      ],
      "members": {
        "state": {}
      }
    },
    "S2a": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {
            "type": "structure",
            "required": [
              "propertyId"
            ],
            "members": {
              "propertyId": {},
              "hierarchyId": {}
            }
          }
        }
      }
    },
    "S2s": {
      "type": "structure",
      "required": [
        "state"
      ],
      "members": {
        "state": {},
        "error": {
          "shape": "S1p"
        }
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "greengrass": {
          "type": "structure",
          "required": [
            "groupArn"
          ],
          "members": {
            "groupArn": {}
          }
        },
        "greengrassV2": {
          "type": "structure",
          "required": [
            "coreDeviceThingName"
          ],
          "members": {
            "coreDeviceThingName": {}
          }
        }
      }
    },
    "S35": {
      "type": "structure",
      "required": [
        "data",
        "type"
      ],
      "members": {
        "data": {
          "type": "blob"
        },
        "type": {}
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "alarmRoleArn"
      ],
      "members": {
        "alarmRoleArn": {},
        "notificationLambdaArn": {}
      }
    },
    "S3c": {
      "type": "structure",
      "required": [
        "state"
      ],
      "members": {
        "state": {},
        "error": {
          "type": "structure",
          "members": {
            "code": {},
            "message": {}
          }
        }
      }
    },
    "S43": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "id",
          "name",
          "dataType"
        ],
        "members": {
          "id": {},
          "name": {},
          "alias": {},
          "notification": {
            "shape": "S45"
          },
          "dataType": {},
          "dataTypeSpec": {},
          "unit": {}
        }
      }
    },
    "S45": {
      "type": "structure",
      "required": [
        "topic",
        "state"
      ],
      "members": {
        "topic": {},
        "state": {}
      }
    },
    "S48": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "id": {},
          "name": {}
        }
      }
    },
    "S4e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "dataType",
          "type"
        ],
        "members": {
          "id": {},
          "name": {},
          "dataType": {},
          "dataTypeSpec": {},
          "unit": {},
          "type": {
            "shape": "S21"
          }
        }
      }
    },
    "S4g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "childAssetModelId"
        ],
        "members": {
          "id": {},
          "name": {},
          "childAssetModelId": {}
        }
      }
    },
    "S4i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "type"
        ],
        "members": {
          "name": {},
          "description": {},
          "type": {},
          "properties": {
            "shape": "S4e"
          }
        }
      }
    },
    "S4m": {
      "type": "structure",
      "required": [
        "id",
        "name",
        "dataType"
      ],
      "members": {
        "id": {},
        "name": {},
        "alias": {},
        "notification": {
          "shape": "S45"
        },
        "dataType": {},
        "unit": {},
        "type": {
          "shape": "S21"
        }
      }
    },
    "S4t": {
      "type": "structure",
      "required": [
        "state"
      ],
      "members": {
        "state": {},
        "error": {
          "type": "structure",
          "required": [
            "code",
            "message"
          ],
          "members": {
            "code": {},
            "message": {}
          }
        }
      }
    },
    "S4y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "capabilityNamespace",
          "capabilitySyncStatus"
        ],
        "members": {
          "capabilityNamespace": {},
          "capabilitySyncStatus": {}
        }
      }
    },
    "S57": {
      "type": "structure",
      "required": [
        "level"
      ],
      "members": {
        "level": {}
      }
    },
    "S5i": {
      "type": "structure",
      "required": [
        "customerManagedS3Storage"
      ],
      "members": {
        "customerManagedS3Storage": {
          "type": "structure",
          "required": [
            "s3ResourceArn",
            "roleArn"
          ],
          "members": {
            "s3ResourceArn": {},
            "roleArn": {}
          }
        }
      }
    },
    "S5l": {
      "type": "structure",
      "members": {
        "numberOfDays": {
          "type": "integer"
        },
        "unlimited": {
          "type": "boolean"
        }
      }
    },
    "S5x": {
      "type": "list",
      "member": {}
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-10-26",
    "endpointPrefix": "securityhub",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS SecurityHub",
    "serviceId": "SecurityHub",
    "signatureVersion": "v4",
    "signingName": "securityhub",
    "uid": "securityhub-2018-10-26"
  },
  "operations": {
    "AcceptAdministratorInvitation": {
      "http": {
        "requestUri": "/administrator"
      },
      "input": {
        "type": "structure",
        "required": [
          "AdministratorId",
          "InvitationId"
        ],
        "members": {
          "AdministratorId": {},
          "InvitationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AcceptInvitation": {
      "http": {
        "requestUri": "/master"
      },
      "input": {
        "type": "structure",
        "required": [
          "MasterId",
          "InvitationId"
        ],
        "members": {
          "MasterId": {},
          "InvitationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "This API has been deprecated, use AcceptAdministratorInvitation API instead."
    },
    "BatchDisableStandards": {
      "http": {
        "requestUri": "/standards/deregister"
      },
      "input": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionArns"
        ],
        "members": {
          "StandardsSubscriptionArns": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StandardsSubscriptions": {
            "shape": "S9"
          }
        }
      }
    },
    "BatchEnableStandards": {
      "http": {
        "requestUri": "/standards/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionRequests"
        ],
        "members": {
          "StandardsSubscriptionRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StandardsArn"
              ],
              "members": {
                "StandardsArn": {},
                "StandardsInput": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StandardsSubscriptions": {
            "shape": "S9"
          }
        }
      }
    },
    "BatchImportFindings": {
      "http": {
        "requestUri": "/findings/import"
      },
      "input": {
        "type": "structure",
        "required": [
          "Findings"
        ],
        "members": {
          "Findings": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FailedCount",
          "SuccessCount"
        ],
        "members": {
          "FailedCount": {
            "type": "integer"
          },
          "SuccessCount": {
            "type": "integer"
          },
          "FailedFindings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Id",
                "ErrorCode",
                "ErrorMessage"
              ],
              "members": {
                "Id": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchUpdateFindings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/findings/batchupdate"
      },
      "input": {
        "type": "structure",
        "required": [
          "FindingIdentifiers"
        ],
        "members": {
          "FindingIdentifiers": {
            "shape": "Sgq"
          },
          "Note": {
            "shape": "Sgs"
          },
          "Severity": {
            "type": "structure",
            "members": {
              "Normalized": {
                "type": "integer"
              },
              "Product": {
                "type": "double"
              },
              "Label": {}
            }
          },
          "VerificationState": {},
          "Confidence": {
            "type": "integer"
          },
          "Criticality": {
            "type": "integer"
          },
          "Types": {
            "shape": "Sm"
          },
          "UserDefinedFields": {
            "shape": "St"
          },
          "Workflow": {
            "type": "structure",
            "members": {
              "Status": {}
            }
          },
          "RelatedFindings": {
            "shape": "Sfq"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProcessedFindings",
          "UnprocessedFindings"
        ],
        "members": {
          "ProcessedFindings": {
            "shape": "Sgq"
          },
          "UnprocessedFindings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FindingIdentifier",
                "ErrorCode",
                "ErrorMessage"
              ],
              "members": {
                "FindingIdentifier": {
                  "shape": "Sgr"
                },
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "CreateActionTarget": {
      "http": {
        "requestUri": "/actionTargets"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Description",
          "Id"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {}
        }
      }
    },
    "CreateFindingAggregator": {
      "http": {
        "requestUri": "/findingAggregator/create"
      },
      "input": {
        "type": "structure",
        "required": [
          "RegionLinkingMode"
        ],
        "members": {
          "RegionLinkingMode": {},
          "Regions": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FindingAggregatorArn": {},
          "FindingAggregationRegion": {},
          "RegionLinkingMode": {},
          "Regions": {
            "shape": "S15"
          }
        }
      }
    },
    "CreateInsight": {
      "http": {
        "requestUri": "/insights"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Filters",
          "GroupByAttribute"
        ],
        "members": {
          "Name": {},
          "Filters": {
            "shape": "Sh3"
          },
          "GroupByAttribute": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {}
        }
      }
    },
    "CreateMembers": {
      "http": {
        "requestUri": "/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountDetails"
        ],
        "members": {
          "AccountDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AccountId"
              ],
              "members": {
                "AccountId": {},
                "Email": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "Shs"
          }
        }
      }
    },
    "DeclineInvitations": {
      "http": {
        "requestUri": "/invitations/decline"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "Shv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "Shs"
          }
        }
      }
    },
    "DeleteActionTarget": {
      "http": {
        "method": "DELETE",
        "requestUri": "/actionTargets/{ActionTargetArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {
            "location": "uri",
            "locationName": "ActionTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {}
        }
      }
    },
    "DeleteFindingAggregator": {
      "http": {
        "method": "DELETE",
        "requestUri": "/findingAggregator/delete/{FindingAggregatorArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FindingAggregatorArn"
        ],
        "members": {
          "FindingAggregatorArn": {
            "location": "uri",
            "locationName": "FindingAggregatorArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteInsight": {
      "http": {
        "method": "DELETE",
        "requestUri": "/insights/{InsightArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {
            "location": "uri",
            "locationName": "InsightArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {}
        }
      }
    },
    "DeleteInvitations": {
      "http": {
        "requestUri": "/invitations/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "Shv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "Shs"
          }
        }
      }
    },
    "DeleteMembers": {
      "http": {
        "requestUri": "/members/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "Shv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "Shs"
          }
        }
      }
    },
    "DescribeActionTargets": {
      "http": {
        "requestUri": "/actionTargets/get"
      },
      "input": {
        "type": "structure",
        "members": {
          "ActionTargetArns": {
            "shape": "Si8"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ActionTargets"
        ],
        "members": {
          "ActionTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ActionTargetArn",
                "Name",
                "Description"
              ],
              "members": {
                "ActionTargetArn": {},
                "Name": {},
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeHub": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {
          "HubArn": {
            "location": "querystring",
            "locationName": "HubArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HubArn": {},
          "SubscribedAt": {},
          "AutoEnableControls": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeOrganizationConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/organization/configuration"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AutoEnable": {
            "type": "boolean"
          },
          "MemberAccountLimitReached": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeProducts": {
      "http": {
        "method": "GET",
        "requestUri": "/products"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "ProductArn": {
            "location": "querystring",
            "locationName": "ProductArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Products"
        ],
        "members": {
          "Products": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProductArn"
              ],
              "members": {
                "ProductArn": {},
                "ProductName": {},
                "CompanyName": {},
                "Description": {},
                "Categories": {
                  "type": "list",
                  "member": {}
                },
                "IntegrationTypes": {
                  "type": "list",
                  "member": {}
                },
                "MarketplaceUrl": {},
                "ActivationUrl": {},
                "ProductSubscriptionResourcePolicy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStandards": {
      "http": {
        "method": "GET",
        "requestUri": "/standards"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Standards": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StandardsArn": {},
                "Name": {},
                "Description": {},
                "EnabledByDefault": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStandardsControls": {
      "http": {
        "method": "GET",
        "requestUri": "/standards/controls/{StandardsSubscriptionArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionArn"
        ],
        "members": {
          "StandardsSubscriptionArn": {
            "location": "uri",
            "locationName": "StandardsSubscriptionArn"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Controls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StandardsControlArn": {},
                "ControlStatus": {},
                "DisabledReason": {},
                "ControlStatusUpdatedAt": {
                  "shape": "Siy"
                },
                "ControlId": {},
                "Title": {},
                "Description": {},
                "RemediationUrl": {},
                "SeverityRating": {},
                "RelatedRequirements": {
                  "shape": "Sfi"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisableImportFindingsForProduct": {
      "http": {
        "method": "DELETE",
        "requestUri": "/productSubscriptions/{ProductSubscriptionArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProductSubscriptionArn"
        ],
        "members": {
          "ProductSubscriptionArn": {
            "location": "uri",
            "locationName": "ProductSubscriptionArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableOrganizationAdminAccount": {
      "http": {
        "requestUri": "/organization/admin/disable"
      },
      "input": {
        "type": "structure",
        "required": [
          "AdminAccountId"
        ],
        "members": {
          "AdminAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableSecurityHub": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateFromAdministratorAccount": {
      "http": {
        "requestUri": "/administrator/disassociate"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateFromMasterAccount": {
      "http": {
        "requestUri": "/master/disassociate"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "This API has been deprecated, use DisassociateFromAdministratorAccount API instead."
    },
    "DisassociateMembers": {
      "http": {
        "requestUri": "/members/disassociate"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "Shv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableImportFindingsForProduct": {
      "http": {
        "requestUri": "/productSubscriptions"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProductArn"
        ],
        "members": {
          "ProductArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductSubscriptionArn": {}
        }
      }
    },
    "EnableOrganizationAdminAccount": {
      "http": {
        "requestUri": "/organization/admin/enable"
      },
      "input": {
        "type": "structure",
        "required": [
          "AdminAccountId"
        ],
        "members": {
          "AdminAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableSecurityHub": {
      "http": {
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sjh"
          },
          "EnableDefaultStandards": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAdministratorAccount": {
      "http": {
        "method": "GET",
        "requestUri": "/administrator"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Administrator": {
            "shape": "Sjn"
          }
        }
      }
    },
    "GetEnabledStandards": {
      "http": {
        "requestUri": "/standards/get"
      },
      "input": {
        "type": "structure",
        "members": {
          "StandardsSubscriptionArns": {
            "shape": "S7"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StandardsSubscriptions": {
            "shape": "S9"
          },
          "NextToken": {}
        }
      }
    },
    "GetFindingAggregator": {
      "http": {
        "method": "GET",
        "requestUri": "/findingAggregator/get/{FindingAggregatorArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "FindingAggregatorArn"
        ],
        "members": {
          "FindingAggregatorArn": {
            "location": "uri",
            "locationName": "FindingAggregatorArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FindingAggregatorArn": {},
          "FindingAggregationRegion": {},
          "RegionLinkingMode": {},
          "Regions": {
            "shape": "S15"
          }
        }
      }
    },
    "GetFindings": {
      "http": {
        "requestUri": "/findings"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh3"
          },
          "SortCriteria": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Field": {},
                "SortOrder": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Findings"
        ],
        "members": {
          "Findings": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetInsightResults": {
      "http": {
        "method": "GET",
        "requestUri": "/insights/results/{InsightArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {
            "location": "uri",
            "locationName": "InsightArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InsightResults"
        ],
        "members": {
          "InsightResults": {
            "type": "structure",
            "required": [
              "InsightArn",
              "GroupByAttribute",
              "ResultValues"
            ],
            "members": {
              "InsightArn": {},
              "GroupByAttribute": {},
              "ResultValues": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "GroupByAttributeValue",
                    "Count"
                  ],
                  "members": {
                    "GroupByAttributeValue": {},
                    "Count": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetInsights": {
      "http": {
        "requestUri": "/insights/get"
      },
      "input": {
        "type": "structure",
        "members": {
          "InsightArns": {
            "shape": "Si8"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Insights"
        ],
        "members": {
          "Insights": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InsightArn",
                "Name",
                "Filters",
                "GroupByAttribute"
              ],
              "members": {
                "InsightArn": {},
                "Name": {},
                "Filters": {
                  "shape": "Sh3"
                },
                "GroupByAttribute": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetInvitationsCount": {
      "http": {
        "method": "GET",
        "requestUri": "/invitations/count"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "InvitationsCount": {
            "type": "integer"
          }
        }
      }
    },
    "GetMasterAccount": {
      "http": {
        "method": "GET",
        "requestUri": "/master"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Master": {
            "shape": "Sjn"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "This API has been deprecated, use GetAdministratorAccount API instead."
    },
    "GetMembers": {
      "http": {
        "requestUri": "/members/get"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "Shv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "shape": "Skd"
          },
          "UnprocessedAccounts": {
            "shape": "Shs"
          }
        }
      }
    },
    "InviteMembers": {
      "http": {
        "requestUri": "/members/invite"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "Shv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "Shs"
          }
        }
      }
    },
    "ListEnabledProductsForImport": {
      "http": {
        "method": "GET",
        "requestUri": "/productSubscriptions"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductSubscriptions": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListFindingAggregators": {
      "http": {
        "method": "GET",
        "requestUri": "/findingAggregator/list"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FindingAggregators": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FindingAggregatorArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInvitations": {
      "http": {
        "method": "GET",
        "requestUri": "/invitations"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invitations": {
            "type": "list",
            "member": {
              "shape": "Sjn"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/members"
      },
      "input": {
        "type": "structure",
        "members": {
          "OnlyAssociated": {
            "location": "querystring",
            "locationName": "OnlyAssociated",
            "type": "boolean"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "shape": "Skd"
          },
          "NextToken": {}
        }
      }
    },
    "ListOrganizationAdminAccounts": {
      "http": {
        "method": "GET",
        "requestUri": "/organization/admin"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdminAccounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sjh"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "Sjh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateActionTarget": {
      "http": {
        "method": "PATCH",
        "requestUri": "/actionTargets/{ActionTargetArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {
            "location": "uri",
            "locationName": "ActionTargetArn"
          },
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateFindingAggregator": {
      "http": {
        "method": "PATCH",
        "requestUri": "/findingAggregator/update"
      },
      "input": {
        "type": "structure",
        "required": [
          "FindingAggregatorArn",
          "RegionLinkingMode"
        ],
        "members": {
          "FindingAggregatorArn": {},
          "RegionLinkingMode": {},
          "Regions": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FindingAggregatorArn": {},
          "FindingAggregationRegion": {},
          "RegionLinkingMode": {},
          "Regions": {
            "shape": "S15"
          }
        }
      }
    },
    "UpdateFindings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/findings"
      },
      "input": {
        "type": "structure",
        "required": [
          "Filters"
        ],
        "members": {
          "Filters": {
            "shape": "Sh3"
          },
          "Note": {
            "shape": "Sgs"
          },
          "RecordState": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateInsight": {
      "http": {
        "method": "PATCH",
        "requestUri": "/insights/{InsightArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {
            "location": "uri",
            "locationName": "InsightArn"
          },
          "Name": {},
          "Filters": {
            "shape": "Sh3"
          },
          "GroupByAttribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateOrganizationConfiguration": {
      "http": {
        "requestUri": "/organization/configuration"
      },
      "input": {
        "type": "structure",
        "required": [
          "AutoEnable"
        ],
        "members": {
          "AutoEnable": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateSecurityHubConfiguration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {
          "AutoEnableControls": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateStandardsControl": {
      "http": {
        "method": "PATCH",
        "requestUri": "/standards/control/{StandardsControlArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "StandardsControlArn"
        ],
        "members": {
          "StandardsControlArn": {
            "location": "uri",
            "locationName": "StandardsControlArn"
          },
          "ControlStatus": {},
          "DisabledReason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {}
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionArn",
          "StandardsArn",
          "StandardsInput",
          "StandardsStatus"
        ],
        "members": {
          "StandardsSubscriptionArn": {},
          "StandardsArn": {},
          "StandardsInput": {
            "shape": "Sb"
          },
          "StandardsStatus": {},
          "StandardsStatusReason": {
            "type": "structure",
            "required": [
              "StatusReasonCode"
            ],
            "members": {
              "StatusReasonCode": {}
            }
          }
        }
      }
    },
    "Sb": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sl": {
      "type": "structure",
      "required": [
        "SchemaVersion",
        "Id",
        "ProductArn",
        "GeneratorId",
        "AwsAccountId",
        "CreatedAt",
        "UpdatedAt",
        "Title",
        "Description",
        "Resources"
      ],
      "members": {
        "SchemaVersion": {},
        "Id": {},
        "ProductArn": {},
        "ProductName": {},
        "CompanyName": {},
        "Region": {},
        "GeneratorId": {},
        "AwsAccountId": {},
        "Types": {
          "shape": "Sm"
        },
        "FirstObservedAt": {},
        "LastObservedAt": {},
        "CreatedAt": {},
        "UpdatedAt": {},
        "Severity": {
          "type": "structure",
          "members": {
            "Product": {
              "type": "double"
            },
            "Label": {},
            "Normalized": {
              "type": "integer"
            },
            "Original": {}
          }
        },
        "Confidence": {
          "type": "integer"
        },
        "Criticality": {
          "type": "integer"
        },
        "Title": {},
        "Description": {},
        "Remediation": {
          "type": "structure",
          "members": {
            "Recommendation": {
              "type": "structure",
              "members": {
                "Text": {},
                "Url": {}
              }
            }
          }
        },
        "SourceUrl": {},
        "ProductFields": {
          "shape": "St"
        },
        "UserDefinedFields": {
          "shape": "St"
        },
        "Malware": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Type": {},
              "Path": {},
              "State": {}
            }
          }
        },
        "Network": {
          "type": "structure",
          "members": {
            "Direction": {},
            "Protocol": {},
            "OpenPortRange": {
              "shape": "S10"
            },
            "SourceIpV4": {},
            "SourceIpV6": {},
            "SourcePort": {
              "type": "integer"
            },
            "SourceDomain": {},
            "SourceMac": {},
            "DestinationIpV4": {},
            "DestinationIpV6": {},
            "DestinationPort": {
              "type": "integer"
            },
            "DestinationDomain": {}
          }
        },
        "NetworkPath": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ComponentId": {},
              "ComponentType": {},
              "Egress": {
                "shape": "S13"
              },
              "Ingress": {
                "shape": "S13"
              }
            }
          }
        },
        "Process": {
          "type": "structure",
          "members": {
            "Name": {},
            "Path": {},
            "Pid": {
              "type": "integer"
            },
            "ParentPid": {
              "type": "integer"
            },
            "LaunchedAt": {},
            "TerminatedAt": {}
          }
        },
        "ThreatIntelIndicators": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "Value": {},
              "Category": {},
              "LastObservedAt": {},
              "Source": {},
              "SourceUrl": {}
            }
          }
        },
        "Resources": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type",
              "Id"
            ],
            "members": {
              "Type": {},
              "Id": {},
              "Partition": {},
              "Region": {},
              "ResourceRole": {},
              "Tags": {
                "shape": "St"
              },
              "DataClassification": {
                "type": "structure",
                "members": {
                  "DetailedResultsLocation": {},
                  "Result": {
                    "type": "structure",
                    "members": {
                      "MimeType": {},
                      "SizeClassified": {
                        "type": "long"
                      },
                      "AdditionalOccurrences": {
                        "type": "boolean"
                      },
                      "Status": {
                        "type": "structure",
                        "members": {
                          "Code": {},
                          "Reason": {}
                        }
                      },
                      "SensitiveData": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Category": {},
                            "Detections": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Count": {
                                    "type": "long"
                                  },
                                  "Type": {},
                                  "Occurrences": {
                                    "shape": "S1o"
                                  }
                                }
                              }
                            },
                            "TotalCount": {
                              "type": "long"
                            }
                          }
                        }
                      },
                      "CustomDataIdentifiers": {
                        "type": "structure",
                        "members": {
                          "Detections": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Count": {
                                  "type": "long"
                                },
                                "Arn": {},
                                "Name": {},
                                "Occurrences": {
                                  "shape": "S1o"
                                }
                              }
                            }
                          },
                          "TotalCount": {
                            "type": "long"
                          }
                        }
                      }
                    }
                  }
                }
              },
              "Details": {
                "type": "structure",
                "members": {
                  "AwsAutoScalingAutoScalingGroup": {
                    "type": "structure",
                    "members": {
                      "LaunchConfigurationName": {},
                      "LoadBalancerNames": {
                        "shape": "S15"
                      },
                      "HealthCheckType": {},
                      "HealthCheckGracePeriod": {
                        "type": "integer"
                      },
                      "CreatedTime": {},
                      "MixedInstancesPolicy": {
                        "type": "structure",
                        "members": {
                          "InstancesDistribution": {
                            "type": "structure",
                            "members": {
                              "OnDemandAllocationStrategy": {},
                              "OnDemandBaseCapacity": {
                                "type": "integer"
                              },
                              "OnDemandPercentageAboveBaseCapacity": {
                                "type": "integer"
                              },
                              "SpotAllocationStrategy": {},
                              "SpotInstancePools": {
                                "type": "integer"
                              },
                              "SpotMaxPrice": {}
                            }
                          },
                          "LaunchTemplate": {
                            "type": "structure",
                            "members": {
                              "LaunchTemplateSpecification": {
                                "type": "structure",
                                "members": {
                                  "LaunchTemplateId": {},
                                  "LaunchTemplateName": {},
                                  "Version": {}
                                }
                              },
                              "Overrides": {
                                "type": "list",
                                "member": {
                                  "type": "structure",
                                  "members": {
                                    "InstanceType": {},
                                    "WeightedCapacity": {}
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "AvailabilityZones": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Value": {}
                          }
                        }
                      },
                      "LaunchTemplate": {
                        "type": "structure",
                        "members": {
                          "LaunchTemplateId": {},
                          "LaunchTemplateName": {},
                          "Version": {}
                        }
                      },
                      "CapacityRebalance": {
                        "type": "boolean"
                      }
                    }
                  },
                  "AwsCodeBuildProject": {
                    "type": "structure",
                    "members": {
                      "EncryptionKey": {},
                      "Artifacts": {
                        "shape": "S2c"
                      },
                      "Environment": {
                        "type": "structure",
                        "members": {
                          "Certificate": {},
                          "EnvironmentVariables": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Name": {},
                                "Type": {},
                                "Value": {}
                              }
                            }
                          },
                          "PrivilegedMode": {
                            "type": "boolean"
                          },
                          "ImagePullCredentialsType": {},
                          "RegistryCredential": {
                            "type": "structure",
                            "members": {
                              "Credential": {},
                              "CredentialProvider": {}
                            }
                          },
                          "Type": {}
                        }
                      },
                      "Name": {},
                      "Source": {
                        "type": "structure",
                        "members": {
                          "Type": {},
                          "Location": {},
                          "GitCloneDepth": {
                            "type": "integer"
                          },
                          "InsecureSsl": {
                            "type": "boolean"
                          }
                        }
                      },
                      "ServiceRole": {},
                      "LogsConfig": {
                        "type": "structure",
                        "members": {
                          "CloudWatchLogs": {
                            "type": "structure",
                            "members": {
                              "GroupName": {},
                              "Status": {},
                              "StreamName": {}
                            }
                          },
                          "S3Logs": {
                            "type": "structure",
                            "members": {
                              "EncryptionDisabled": {
                                "type": "boolean"
                              },
                              "Location": {},
                              "Status": {}
                            }
                          }
                        }
                      },
                      "VpcConfig": {
                        "type": "structure",
                        "members": {
                          "VpcId": {},
                          "Subnets": {
                            "shape": "S2n"
                          },
                          "SecurityGroupIds": {
                            "shape": "S2n"
                          }
                        }
                      },
                      "SecondaryArtifacts": {
                        "shape": "S2c"
                      }
                    }
                  },
                  "AwsCloudFrontDistribution": {
                    "type": "structure",
                    "members": {
                      "CacheBehaviors": {
                        "type": "structure",
                        "members": {
                          "Items": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "ViewerProtocolPolicy": {}
                              }
                            }
                          }
                        }
                      },
                      "DefaultCacheBehavior": {
                        "type": "structure",
                        "members": {
                          "ViewerProtocolPolicy": {}
                        }
                      },
                      "DefaultRootObject": {},
                      "DomainName": {},
                      "ETag": {},
                      "LastModifiedTime": {},
                      "Logging": {
                        "type": "structure",
                        "members": {
                          "Bucket": {},
                          "Enabled": {
                            "type": "boolean"
                          },
                          "IncludeCookies": {
                            "type": "boolean"
                          },
                          "Prefix": {}
                        }
                      },
                      "Origins": {
                        "type": "structure",
                        "members": {
                          "Items": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "DomainName": {},
                                "Id": {},
                                "OriginPath": {},
                                "S3OriginConfig": {
                                  "type": "structure",
                                  "members": {
                                    "OriginAccessIdentity": {}
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "OriginGroups": {
                        "type": "structure",
                        "members": {
                          "Items": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "FailoverCriteria": {
                                  "type": "structure",
                                  "members": {
                                    "StatusCodes": {
                                      "type": "structure",
                                      "members": {
                                        "Items": {
                                          "type": "list",
                                          "member": {
                                            "type": "integer"
                                          }
                                        },
                                        "Quantity": {
                                          "type": "integer"
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "ViewerCertificate": {
                        "type": "structure",
                        "members": {
                          "AcmCertificateArn": {},
                          "Certificate": {},
                          "CertificateSource": {},
                          "CloudFrontDefaultCertificate": {
                            "type": "boolean"
                          },
                          "IamCertificateId": {},
                          "MinimumProtocolVersion": {},
                          "SslSupportMethod": {}
                        }
                      },
                      "Status": {},
                      "WebAclId": {}
                    }
                  },
                  "AwsEc2Instance": {
                    "type": "structure",
                    "members": {
                      "Type": {},
                      "ImageId": {},
                      "IpV4Addresses": {
                        "shape": "S15"
                      },
                      "IpV6Addresses": {
                        "shape": "S15"
                      },
                      "KeyName": {},
                      "IamInstanceProfileArn": {},
                      "VpcId": {},
                      "SubnetId": {},
                      "LaunchedAt": {},
                      "NetworkInterfaces": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "NetworkInterfaceId": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsEc2NetworkInterface": {
                    "type": "structure",
                    "members": {
                      "Attachment": {
                        "type": "structure",
                        "members": {
                          "AttachTime": {},
                          "AttachmentId": {},
                          "DeleteOnTermination": {
                            "type": "boolean"
                          },
                          "DeviceIndex": {
                            "type": "integer"
                          },
                          "InstanceId": {},
                          "InstanceOwnerId": {},
                          "Status": {}
                        }
                      },
                      "NetworkInterfaceId": {},
                      "SecurityGroups": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "GroupName": {},
                            "GroupId": {}
                          }
                        }
                      },
                      "SourceDestCheck": {
                        "type": "boolean"
                      },
                      "IpV6Addresses": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "IpV6Address": {}
                          }
                        }
                      },
                      "PrivateIpAddresses": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "PrivateIpAddress": {},
                            "PrivateDnsName": {}
                          }
                        }
                      },
                      "PublicDnsName": {},
                      "PublicIp": {}
                    }
                  },
                  "AwsEc2SecurityGroup": {
                    "type": "structure",
                    "members": {
                      "GroupName": {},
                      "GroupId": {},
                      "OwnerId": {},
                      "VpcId": {},
                      "IpPermissions": {
                        "shape": "S3h"
                      },
                      "IpPermissionsEgress": {
                        "shape": "S3h"
                      }
                    }
                  },
                  "AwsEc2Volume": {
                    "type": "structure",
                    "members": {
                      "CreateTime": {},
                      "Encrypted": {
                        "type": "boolean"
                      },
                      "Size": {
                        "type": "integer"
                      },
                      "SnapshotId": {},
                      "Status": {},
                      "KmsKeyId": {},
                      "Attachments": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "AttachTime": {},
                            "DeleteOnTermination": {
                              "type": "boolean"
                            },
                            "InstanceId": {},
                            "Status": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsEc2Vpc": {
                    "type": "structure",
                    "members": {
                      "CidrBlockAssociationSet": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "AssociationId": {},
                            "CidrBlock": {},
                            "CidrBlockState": {}
                          }
                        }
                      },
                      "Ipv6CidrBlockAssociationSet": {
                        "shape": "S3x"
                      },
                      "DhcpOptionsId": {},
                      "State": {}
                    }
                  },
                  "AwsEc2Eip": {
                    "type": "structure",
                    "members": {
                      "InstanceId": {},
                      "PublicIp": {},
                      "AllocationId": {},
                      "AssociationId": {},
                      "Domain": {},
                      "PublicIpv4Pool": {},
                      "NetworkBorderGroup": {},
                      "NetworkInterfaceId": {},
                      "NetworkInterfaceOwnerId": {},
                      "PrivateIpAddress": {}
                    }
                  },
                  "AwsEc2Subnet": {
                    "type": "structure",
                    "members": {
                      "AssignIpv6AddressOnCreation": {
                        "type": "boolean"
                      },
                      "AvailabilityZone": {},
                      "AvailabilityZoneId": {},
                      "AvailableIpAddressCount": {
                        "type": "integer"
                      },
                      "CidrBlock": {},
                      "DefaultForAz": {
                        "type": "boolean"
                      },
                      "MapPublicIpOnLaunch": {
                        "type": "boolean"
                      },
                      "OwnerId": {},
                      "State": {},
                      "SubnetArn": {},
                      "SubnetId": {},
                      "VpcId": {},
                      "Ipv6CidrBlockAssociationSet": {
                        "shape": "S3x"
                      }
                    }
                  },
                  "AwsEc2NetworkAcl": {
                    "type": "structure",
                    "members": {
                      "IsDefault": {
                        "type": "boolean"
                      },
                      "NetworkAclId": {},
                      "OwnerId": {},
                      "VpcId": {},
                      "Associations": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "NetworkAclAssociationId": {},
                            "NetworkAclId": {},
                            "SubnetId": {}
                          }
                        }
                      },
                      "Entries": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "CidrBlock": {},
                            "Egress": {
                              "type": "boolean"
                            },
                            "IcmpTypeCode": {
                              "type": "structure",
                              "members": {
                                "Code": {
                                  "type": "integer"
                                },
                                "Type": {
                                  "type": "integer"
                                }
                              }
                            },
                            "Ipv6CidrBlock": {},
                            "PortRange": {
                              "type": "structure",
                              "members": {
                                "From": {
                                  "type": "integer"
                                },
                                "To": {
                                  "type": "integer"
                                }
                              }
                            },
                            "Protocol": {},
                            "RuleAction": {},
                            "RuleNumber": {
                              "type": "integer"
                            }
                          }
                        }
                      }
                    }
                  },
                  "AwsElbv2LoadBalancer": {
                    "type": "structure",
                    "members": {
                      "AvailabilityZones": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ZoneName": {},
                            "SubnetId": {}
                          }
                        }
                      },
                      "CanonicalHostedZoneId": {},
                      "CreatedTime": {},
                      "DNSName": {},
                      "IpAddressType": {},
                      "Scheme": {},
                      "SecurityGroups": {
                        "type": "list",
                        "member": {}
                      },
                      "State": {
                        "type": "structure",
                        "members": {
                          "Code": {},
                          "Reason": {}
                        }
                      },
                      "Type": {},
                      "VpcId": {},
                      "LoadBalancerAttributes": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Key": {},
                            "Value": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsElasticBeanstalkEnvironment": {
                    "type": "structure",
                    "members": {
                      "ApplicationName": {},
                      "Cname": {},
                      "DateCreated": {},
                      "DateUpdated": {},
                      "Description": {},
                      "EndpointUrl": {},
                      "EnvironmentArn": {},
                      "EnvironmentId": {},
                      "EnvironmentLinks": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "EnvironmentName": {},
                            "LinkName": {}
                          }
                        }
                      },
                      "EnvironmentName": {},
                      "OptionSettings": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Namespace": {},
                            "OptionName": {},
                            "ResourceName": {},
                            "Value": {}
                          }
                        }
                      },
                      "PlatformArn": {},
                      "SolutionStackName": {},
                      "Status": {},
                      "Tier": {
                        "type": "structure",
                        "members": {
                          "Name": {},
                          "Type": {},
                          "Version": {}
                        }
                      },
                      "VersionLabel": {}
                    }
                  },
                  "AwsElasticsearchDomain": {
                    "type": "structure",
                    "members": {
                      "AccessPolicies": {},
                      "DomainEndpointOptions": {
                        "type": "structure",
                        "members": {
                          "EnforceHTTPS": {
                            "type": "boolean"
                          },
                          "TLSSecurityPolicy": {}
                        }
                      },
                      "DomainId": {},
                      "DomainName": {},
                      "Endpoint": {},
                      "Endpoints": {
                        "shape": "St"
                      },
                      "ElasticsearchVersion": {},
                      "ElasticsearchClusterConfig": {
                        "type": "structure",
                        "members": {
                          "DedicatedMasterCount": {
                            "type": "integer"
                          },
                          "DedicatedMasterEnabled": {
                            "type": "boolean"
                          },
                          "DedicatedMasterType": {},
                          "InstanceCount": {
                            "type": "integer"
                          },
                          "InstanceType": {},
                          "ZoneAwarenessConfig": {
                            "type": "structure",
                            "members": {
                              "AvailabilityZoneCount": {
                                "type": "integer"
                              }
                            }
                          },
                          "ZoneAwarenessEnabled": {
                            "type": "boolean"
                          }
                        }
                      },
                      "EncryptionAtRestOptions": {
                        "type": "structure",
                        "members": {
                          "Enabled": {
                            "type": "boolean"
                          },
                          "KmsKeyId": {}
                        }
                      },
                      "LogPublishingOptions": {
                        "type": "structure",
                        "members": {
                          "IndexSlowLogs": {
                            "shape": "S4r"
                          },
                          "SearchSlowLogs": {
                            "shape": "S4r"
                          },
                          "AuditLogs": {
                            "shape": "S4r"
                          }
                        }
                      },
                      "NodeToNodeEncryptionOptions": {
                        "type": "structure",
                        "members": {
                          "Enabled": {
                            "type": "boolean"
                          }
                        }
                      },
                      "ServiceSoftwareOptions": {
                        "type": "structure",
                        "members": {
                          "AutomatedUpdateDate": {},
                          "Cancellable": {
                            "type": "boolean"
                          },
                          "CurrentVersion": {},
                          "Description": {},
                          "NewVersion": {},
                          "UpdateAvailable": {
                            "type": "boolean"
                          },
                          "UpdateStatus": {}
                        }
                      },
                      "VPCOptions": {
                        "type": "structure",
                        "members": {
                          "AvailabilityZones": {
                            "shape": "S2n"
                          },
                          "SecurityGroupIds": {
                            "shape": "S2n"
                          },
                          "SubnetIds": {
                            "shape": "S2n"
                          },
                          "VPCId": {}
                        }
                      }
                    }
                  },
                  "AwsS3Bucket": {
                    "type": "structure",
                    "members": {
                      "OwnerId": {},
                      "OwnerName": {},
                      "OwnerAccountId": {},
                      "CreatedAt": {},
                      "ServerSideEncryptionConfiguration": {
                        "type": "structure",
                        "members": {
                          "Rules": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "ApplyServerSideEncryptionByDefault": {
                                  "type": "structure",
                                  "members": {
                                    "SSEAlgorithm": {},
                                    "KMSMasterKeyID": {}
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "BucketLifecycleConfiguration": {
                        "type": "structure",
                        "members": {
                          "Rules": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "AbortIncompleteMultipartUpload": {
                                  "type": "structure",
                                  "members": {
                                    "DaysAfterInitiation": {
                                      "type": "integer"
                                    }
                                  }
                                },
                                "ExpirationDate": {},
                                "ExpirationInDays": {
                                  "type": "integer"
                                },
                                "ExpiredObjectDeleteMarker": {
                                  "type": "boolean"
                                },
                                "Filter": {
                                  "type": "structure",
                                  "members": {
                                    "Predicate": {
                                      "type": "structure",
                                      "members": {
                                        "Operands": {
                                          "type": "list",
                                          "member": {
                                            "type": "structure",
                                            "members": {
                                              "Prefix": {},
                                              "Tag": {
                                                "type": "structure",
                                                "members": {
                                                  "Key": {},
                                                  "Value": {}
                                                }
                                              },
                                              "Type": {}
                                            }
                                          }
                                        },
                                        "Prefix": {},
                                        "Tag": {
                                          "type": "structure",
                                          "members": {
                                            "Key": {},
                                            "Value": {}
                                          }
                                        },
                                        "Type": {}
                                      }
                                    }
                                  }
                                },
                                "ID": {},
                                "NoncurrentVersionExpirationInDays": {
                                  "type": "integer"
                                },
                                "NoncurrentVersionTransitions": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "members": {
                                      "Days": {
                                        "type": "integer"
                                      },
                                      "StorageClass": {}
                                    }
                                  }
                                },
                                "Prefix": {},
                                "Status": {},
                                "Transitions": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "members": {
                                      "Date": {},
                                      "Days": {
                                        "type": "integer"
                                      },
                                      "StorageClass": {}
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "PublicAccessBlockConfiguration": {
                        "shape": "S5e"
                      },
                      "AccessControlList": {},
                      "BucketLoggingConfiguration": {
                        "type": "structure",
                        "members": {
                          "DestinationBucketName": {},
                          "LogFilePrefix": {}
                        }
                      },
                      "BucketWebsiteConfiguration": {
                        "type": "structure",
                        "members": {
                          "ErrorDocument": {},
                          "IndexDocumentSuffix": {},
                          "RedirectAllRequestsTo": {
                            "type": "structure",
                            "members": {
                              "Hostname": {},
                              "Protocol": {}
                            }
                          },
                          "RoutingRules": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Condition": {
                                  "type": "structure",
                                  "members": {
                                    "HttpErrorCodeReturnedEquals": {},
                                    "KeyPrefixEquals": {}
                                  }
                                },
                                "Redirect": {
                                  "type": "structure",
                                  "members": {
                                    "Hostname": {},
                                    "HttpRedirectCode": {},
                                    "Protocol": {},
                                    "ReplaceKeyPrefixWith": {},
                                    "ReplaceKeyWith": {}
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "BucketNotificationConfiguration": {
                        "type": "structure",
                        "members": {
                          "Configurations": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Events": {
                                  "type": "list",
                                  "member": {}
                                },
                                "Filter": {
                                  "type": "structure",
                                  "members": {
                                    "S3KeyFilter": {
                                      "type": "structure",
                                      "members": {
                                        "FilterRules": {
                                          "type": "list",
                                          "member": {
                                            "type": "structure",
                                            "members": {
                                              "Name": {},
                                              "Value": {}
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                "Destination": {},
                                "Type": {}
                              }
                            }
                          }
                        }
                      },
                      "BucketVersioningConfiguration": {
                        "type": "structure",
                        "members": {
                          "IsMfaDeleteEnabled": {
                            "type": "boolean"
                          },
                          "Status": {}
                        }
                      }
                    }
                  },
                  "AwsS3AccountPublicAccessBlock": {
                    "shape": "S5e"
                  },
                  "AwsS3Object": {
                    "type": "structure",
                    "members": {
                      "LastModified": {},
                      "ETag": {},
                      "VersionId": {},
                      "ContentType": {},
                      "ServerSideEncryption": {},
                      "SSEKMSKeyId": {}
                    }
                  },
                  "AwsSecretsManagerSecret": {
                    "type": "structure",
                    "members": {
                      "RotationRules": {
                        "type": "structure",
                        "members": {
                          "AutomaticallyAfterDays": {
                            "type": "integer"
                          }
                        }
                      },
                      "RotationOccurredWithinFrequency": {
                        "type": "boolean"
                      },
                      "KmsKeyId": {},
                      "RotationEnabled": {
                        "type": "boolean"
                      },
                      "RotationLambdaArn": {},
                      "Deleted": {
                        "type": "boolean"
                      },
                      "Name": {},
                      "Description": {}
                    }
                  },
                  "AwsIamAccessKey": {
                    "type": "structure",
                    "members": {
                      "UserName": {
                        "deprecated": true,
                        "deprecatedMessage": "This filter is deprecated. Instead, use PrincipalName."
                      },
                      "Status": {},
                      "CreatedAt": {},
                      "PrincipalId": {},
                      "PrincipalType": {},
                      "PrincipalName": {},
                      "AccountId": {},
                      "AccessKeyId": {},
                      "SessionContext": {
                        "type": "structure",
                        "members": {
                          "Attributes": {
                            "type": "structure",
                            "members": {
                              "MfaAuthenticated": {
                                "type": "boolean"
                              },
                              "CreationDate": {}
                            }
                          },
                          "SessionIssuer": {
                            "type": "structure",
                            "members": {
                              "Type": {},
                              "PrincipalId": {},
                              "Arn": {},
                              "AccountId": {},
                              "UserName": {}
                            }
                          }
                        }
                      }
                    }
                  },
                  "AwsIamUser": {
                    "type": "structure",
                    "members": {
                      "AttachedManagedPolicies": {
                        "shape": "S65"
                      },
                      "CreateDate": {},
                      "GroupList": {
                        "shape": "S15"
                      },
                      "Path": {},
                      "PermissionsBoundary": {
                        "shape": "S67"
                      },
                      "UserId": {},
                      "UserName": {},
                      "UserPolicyList": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "PolicyName": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsIamPolicy": {
                    "type": "structure",
                    "members": {
                      "AttachmentCount": {
                        "type": "integer"
                      },
                      "CreateDate": {},
                      "DefaultVersionId": {},
                      "Description": {},
                      "IsAttachable": {
                        "type": "boolean"
                      },
                      "Path": {},
                      "PermissionsBoundaryUsageCount": {
                        "type": "integer"
                      },
                      "PolicyId": {},
                      "PolicyName": {},
                      "PolicyVersionList": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "VersionId": {},
                            "IsDefaultVersion": {
                              "type": "boolean"
                            },
                            "CreateDate": {}
                          }
                        }
                      },
                      "UpdateDate": {}
                    }
                  },
                  "AwsApiGatewayV2Stage": {
                    "type": "structure",
                    "members": {
                      "ClientCertificateId": {},
                      "CreatedDate": {},
                      "Description": {},
                      "DefaultRouteSettings": {
                        "shape": "S6e"
                      },
                      "DeploymentId": {},
                      "LastUpdatedDate": {},
                      "RouteSettings": {
                        "shape": "S6e"
                      },
                      "StageName": {},
                      "StageVariables": {
                        "shape": "St"
                      },
                      "AccessLogSettings": {
                        "shape": "S6f"
                      },
                      "AutoDeploy": {
                        "type": "boolean"
                      },
                      "LastDeploymentStatusMessage": {},
                      "ApiGatewayManaged": {
                        "type": "boolean"
                      }
                    }
                  },
                  "AwsApiGatewayV2Api": {
                    "type": "structure",
                    "members": {
                      "ApiEndpoint": {},
                      "ApiId": {},
                      "ApiKeySelectionExpression": {},
                      "CreatedDate": {},
                      "Description": {},
                      "Version": {},
                      "Name": {},
                      "ProtocolType": {},
                      "RouteSelectionExpression": {},
                      "CorsConfiguration": {
                        "type": "structure",
                        "members": {
                          "AllowOrigins": {
                            "shape": "S2n"
                          },
                          "AllowCredentials": {
                            "type": "boolean"
                          },
                          "ExposeHeaders": {
                            "shape": "S2n"
                          },
                          "MaxAge": {
                            "type": "integer"
                          },
                          "AllowMethods": {
                            "shape": "S2n"
                          },
                          "AllowHeaders": {
                            "shape": "S2n"
                          }
                        }
                      }
                    }
                  },
                  "AwsDynamoDbTable": {
                    "type": "structure",
                    "members": {
                      "AttributeDefinitions": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "AttributeName": {},
                            "AttributeType": {}
                          }
                        }
                      },
                      "BillingModeSummary": {
                        "type": "structure",
                        "members": {
                          "BillingMode": {},
                          "LastUpdateToPayPerRequestDateTime": {}
                        }
                      },
                      "CreationDateTime": {},
                      "GlobalSecondaryIndexes": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Backfilling": {
                              "type": "boolean"
                            },
                            "IndexArn": {},
                            "IndexName": {},
                            "IndexSizeBytes": {
                              "type": "long"
                            },
                            "IndexStatus": {},
                            "ItemCount": {
                              "type": "integer"
                            },
                            "KeySchema": {
                              "shape": "S6p"
                            },
                            "Projection": {
                              "shape": "S6r"
                            },
                            "ProvisionedThroughput": {
                              "shape": "S6s"
                            }
                          }
                        }
                      },
                      "GlobalTableVersion": {},
                      "ItemCount": {
                        "type": "integer"
                      },
                      "KeySchema": {
                        "shape": "S6p"
                      },
                      "LatestStreamArn": {},
                      "LatestStreamLabel": {},
                      "LocalSecondaryIndexes": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "IndexArn": {},
                            "IndexName": {},
                            "KeySchema": {
                              "shape": "S6p"
                            },
                            "Projection": {
                              "shape": "S6r"
                            }
                          }
                        }
                      },
                      "ProvisionedThroughput": {
                        "shape": "S6s"
                      },
                      "Replicas": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "GlobalSecondaryIndexes": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "IndexName": {},
                                  "ProvisionedThroughputOverride": {
                                    "shape": "S6z"
                                  }
                                }
                              }
                            },
                            "KmsMasterKeyId": {},
                            "ProvisionedThroughputOverride": {
                              "shape": "S6z"
                            },
                            "RegionName": {},
                            "ReplicaStatus": {},
                            "ReplicaStatusDescription": {}
                          }
                        }
                      },
                      "RestoreSummary": {
                        "type": "structure",
                        "members": {
                          "SourceBackupArn": {},
                          "SourceTableArn": {},
                          "RestoreDateTime": {},
                          "RestoreInProgress": {
                            "type": "boolean"
                          }
                        }
                      },
                      "SseDescription": {
                        "type": "structure",
                        "members": {
                          "InaccessibleEncryptionDateTime": {},
                          "Status": {},
                          "SseType": {},
                          "KmsMasterKeyArn": {}
                        }
                      },
                      "StreamSpecification": {
                        "type": "structure",
                        "members": {
                          "StreamEnabled": {
                            "type": "boolean"
                          },
                          "StreamViewType": {}
                        }
                      },
                      "TableId": {},
                      "TableName": {},
                      "TableSizeBytes": {
                        "type": "long"
                      },
                      "TableStatus": {}
                    }
                  },
                  "AwsApiGatewayStage": {
                    "type": "structure",
                    "members": {
                      "DeploymentId": {},
                      "ClientCertificateId": {},
                      "StageName": {},
                      "Description": {},
                      "CacheClusterEnabled": {
                        "type": "boolean"
                      },
                      "CacheClusterSize": {},
                      "CacheClusterStatus": {},
                      "MethodSettings": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "MetricsEnabled": {
                              "type": "boolean"
                            },
                            "LoggingLevel": {},
                            "DataTraceEnabled": {
                              "type": "boolean"
                            },
                            "ThrottlingBurstLimit": {
                              "type": "integer"
                            },
                            "ThrottlingRateLimit": {
                              "type": "double"
                            },
                            "CachingEnabled": {
                              "type": "boolean"
                            },
                            "CacheTtlInSeconds": {
                              "type": "integer"
                            },
                            "CacheDataEncrypted": {
                              "type": "boolean"
                            },
                            "RequireAuthorizationForCacheControl": {
                              "type": "boolean"
                            },
                            "UnauthorizedCacheControlHeaderStrategy": {},
                            "HttpMethod": {},
                            "ResourcePath": {}
                          }
                        }
                      },
                      "Variables": {
                        "shape": "St"
                      },
                      "DocumentationVersion": {},
                      "AccessLogSettings": {
                        "shape": "S6f"
                      },
                      "CanarySettings": {
                        "type": "structure",
                        "members": {
                          "PercentTraffic": {
                            "type": "double"
                          },
                          "DeploymentId": {},
                          "StageVariableOverrides": {
                            "shape": "St"
                          },
                          "UseStageCache": {
                            "type": "boolean"
                          }
                        }
                      },
                      "TracingEnabled": {
                        "type": "boolean"
                      },
                      "CreatedDate": {},
                      "LastUpdatedDate": {},
                      "WebAclArn": {}
                    }
                  },
                  "AwsApiGatewayRestApi": {
                    "type": "structure",
                    "members": {
                      "Id": {},
                      "Name": {},
                      "Description": {},
                      "CreatedDate": {},
                      "Version": {},
                      "BinaryMediaTypes": {
                        "shape": "S2n"
                      },
                      "MinimumCompressionSize": {
                        "type": "integer"
                      },
                      "ApiKeySource": {},
                      "EndpointConfiguration": {
                        "type": "structure",
                        "members": {
                          "Types": {
                            "shape": "S2n"
                          }
                        }
                      }
                    }
                  },
                  "AwsCloudTrailTrail": {
                    "type": "structure",
                    "members": {
                      "CloudWatchLogsLogGroupArn": {},
                      "CloudWatchLogsRoleArn": {},
                      "HasCustomEventSelectors": {
                        "type": "boolean"
                      },
                      "HomeRegion": {},
                      "IncludeGlobalServiceEvents": {
                        "type": "boolean"
                      },
                      "IsMultiRegionTrail": {
                        "type": "boolean"
                      },
                      "IsOrganizationTrail": {
                        "type": "boolean"
                      },
                      "KmsKeyId": {},
                      "LogFileValidationEnabled": {
                        "type": "boolean"
                      },
                      "Name": {},
                      "S3BucketName": {},
                      "S3KeyPrefix": {},
                      "SnsTopicArn": {},
                      "SnsTopicName": {},
                      "TrailArn": {}
                    }
                  },
                  "AwsSsmPatchCompliance": {
                    "type": "structure",
                    "members": {
                      "Patch": {
                        "type": "structure",
                        "members": {
                          "ComplianceSummary": {
                            "type": "structure",
                            "members": {
                              "Status": {},
                              "CompliantCriticalCount": {
                                "type": "integer"
                              },
                              "CompliantHighCount": {
                                "type": "integer"
                              },
                              "CompliantMediumCount": {
                                "type": "integer"
                              },
                              "ExecutionType": {},
                              "NonCompliantCriticalCount": {
                                "type": "integer"
                              },
                              "CompliantInformationalCount": {
                                "type": "integer"
                              },
                              "NonCompliantInformationalCount": {
                                "type": "integer"
                              },
                              "CompliantUnspecifiedCount": {
                                "type": "integer"
                              },
                              "NonCompliantLowCount": {
                                "type": "integer"
                              },
                              "NonCompliantHighCount": {
                                "type": "integer"
                              },
                              "CompliantLowCount": {
                                "type": "integer"
                              },
                              "ComplianceType": {},
                              "PatchBaselineId": {},
                              "OverallSeverity": {},
                              "NonCompliantMediumCount": {
                                "type": "integer"
                              },
                              "NonCompliantUnspecifiedCount": {
                                "type": "integer"
                              },
                              "PatchGroup": {}
                            }
                          }
                        }
                      }
                    }
                  },
                  "AwsCertificateManagerCertificate": {
                    "type": "structure",
                    "members": {
                      "CertificateAuthorityArn": {},
                      "CreatedAt": {},
                      "DomainName": {},
                      "DomainValidationOptions": {
                        "shape": "S7e"
                      },
                      "ExtendedKeyUsages": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "OId": {}
                          }
                        }
                      },
                      "FailureReason": {},
                      "ImportedAt": {},
                      "InUseBy": {
                        "shape": "S15"
                      },
                      "IssuedAt": {},
                      "Issuer": {},
                      "KeyAlgorithm": {},
                      "KeyUsages": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {}
                          }
                        }
                      },
                      "NotAfter": {},
                      "NotBefore": {},
                      "Options": {
                        "type": "structure",
                        "members": {
                          "CertificateTransparencyLoggingPreference": {}
                        }
                      },
                      "RenewalEligibility": {},
                      "RenewalSummary": {
                        "type": "structure",
                        "members": {
                          "DomainValidationOptions": {
                            "shape": "S7e"
                          },
                          "RenewalStatus": {},
                          "RenewalStatusReason": {},
                          "UpdatedAt": {}
                        }
                      },
                      "Serial": {},
                      "SignatureAlgorithm": {},
                      "Status": {},
                      "Subject": {},
                      "SubjectAlternativeNames": {
                        "shape": "S15"
                      },
                      "Type": {}
                    }
                  },
                  "AwsRedshiftCluster": {
                    "type": "structure",
                    "members": {
                      "AllowVersionUpgrade": {
                        "type": "boolean"
                      },
                      "AutomatedSnapshotRetentionPeriod": {
                        "type": "integer"
                      },
                      "AvailabilityZone": {},
                      "ClusterAvailabilityStatus": {},
                      "ClusterCreateTime": {},
                      "ClusterIdentifier": {},
                      "ClusterNodes": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "NodeRole": {},
                            "PrivateIpAddress": {},
                            "PublicIpAddress": {}
                          }
                        }
                      },
                      "ClusterParameterGroups": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ClusterParameterStatusList": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "ParameterName": {},
                                  "ParameterApplyStatus": {},
                                  "ParameterApplyErrorDescription": {}
                                }
                              }
                            },
                            "ParameterApplyStatus": {},
                            "ParameterGroupName": {}
                          }
                        }
                      },
                      "ClusterPublicKey": {},
                      "ClusterRevisionNumber": {},
                      "ClusterSecurityGroups": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ClusterSecurityGroupName": {},
                            "Status": {}
                          }
                        }
                      },
                      "ClusterSnapshotCopyStatus": {
                        "type": "structure",
                        "members": {
                          "DestinationRegion": {},
                          "ManualSnapshotRetentionPeriod": {
                            "type": "integer"
                          },
                          "RetentionPeriod": {
                            "type": "integer"
                          },
                          "SnapshotCopyGrantName": {}
                        }
                      },
                      "ClusterStatus": {},
                      "ClusterSubnetGroupName": {},
                      "ClusterVersion": {},
                      "DBName": {},
                      "DeferredMaintenanceWindows": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DeferMaintenanceEndTime": {},
                            "DeferMaintenanceIdentifier": {},
                            "DeferMaintenanceStartTime": {}
                          }
                        }
                      },
                      "ElasticIpStatus": {
                        "type": "structure",
                        "members": {
                          "ElasticIp": {},
                          "Status": {}
                        }
                      },
                      "ElasticResizeNumberOfNodeOptions": {},
                      "Encrypted": {
                        "type": "boolean"
                      },
                      "Endpoint": {
                        "type": "structure",
                        "members": {
                          "Address": {},
                          "Port": {
                            "type": "integer"
                          }
                        }
                      },
                      "EnhancedVpcRouting": {
                        "type": "boolean"
                      },
                      "ExpectedNextSnapshotScheduleTime": {},
                      "ExpectedNextSnapshotScheduleTimeStatus": {},
                      "HsmStatus": {
                        "type": "structure",
                        "members": {
                          "HsmClientCertificateIdentifier": {},
                          "HsmConfigurationIdentifier": {},
                          "Status": {}
                        }
                      },
                      "IamRoles": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ApplyStatus": {},
                            "IamRoleArn": {}
                          }
                        }
                      },
                      "KmsKeyId": {},
                      "MaintenanceTrackName": {},
                      "ManualSnapshotRetentionPeriod": {
                        "type": "integer"
                      },
                      "MasterUsername": {},
                      "NextMaintenanceWindowStartTime": {},
                      "NodeType": {},
                      "NumberOfNodes": {
                        "type": "integer"
                      },
                      "PendingActions": {
                        "shape": "S15"
                      },
                      "PendingModifiedValues": {
                        "type": "structure",
                        "members": {
                          "AutomatedSnapshotRetentionPeriod": {
                            "type": "integer"
                          },
                          "ClusterIdentifier": {},
                          "ClusterType": {},
                          "ClusterVersion": {},
                          "EncryptionType": {},
                          "EnhancedVpcRouting": {
                            "type": "boolean"
                          },
                          "MaintenanceTrackName": {},
                          "MasterUserPassword": {},
                          "NodeType": {},
                          "NumberOfNodes": {
                            "type": "integer"
                          },
                          "PubliclyAccessible": {
                            "type": "boolean"
                          }
                        }
                      },
                      "PreferredMaintenanceWindow": {},
                      "PubliclyAccessible": {
                        "type": "boolean"
                      },
                      "ResizeInfo": {
                        "type": "structure",
                        "members": {
                          "AllowCancelResize": {
                            "type": "boolean"
                          },
                          "ResizeType": {}
                        }
                      },
                      "RestoreStatus": {
                        "type": "structure",
                        "members": {
                          "CurrentRestoreRateInMegaBytesPerSecond": {
                            "type": "double"
                          },
                          "ElapsedTimeInSeconds": {
                            "type": "long"
                          },
                          "EstimatedTimeToCompletionInSeconds": {
                            "type": "long"
                          },
                          "ProgressInMegaBytes": {
                            "type": "long"
                          },
                          "SnapshotSizeInMegaBytes": {
                            "type": "long"
                          },
                          "Status": {}
                        }
                      },
                      "SnapshotScheduleIdentifier": {},
                      "SnapshotScheduleState": {},
                      "VpcId": {},
                      "VpcSecurityGroups": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Status": {},
                            "VpcSecurityGroupId": {}
                          }
                        }
                      },
                      "LoggingStatus": {
                        "type": "structure",
                        "members": {
                          "BucketName": {},
                          "LastFailureMessage": {},
                          "LastFailureTime": {},
                          "LastSuccessfulDeliveryTime": {},
                          "LoggingEnabled": {
                            "type": "boolean"
                          },
                          "S3KeyPrefix": {}
                        }
                      }
                    }
                  },
                  "AwsElbLoadBalancer": {
                    "type": "structure",
                    "members": {
                      "AvailabilityZones": {
                        "shape": "S15"
                      },
                      "BackendServerDescriptions": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "InstancePort": {
                              "type": "integer"
                            },
                            "PolicyNames": {
                              "shape": "S15"
                            }
                          }
                        }
                      },
                      "CanonicalHostedZoneName": {},
                      "CanonicalHostedZoneNameID": {},
                      "CreatedTime": {},
                      "DnsName": {},
                      "HealthCheck": {
                        "type": "structure",
                        "members": {
                          "HealthyThreshold": {
                            "type": "integer"
                          },
                          "Interval": {
                            "type": "integer"
                          },
                          "Target": {},
                          "Timeout": {
                            "type": "integer"
                          },
                          "UnhealthyThreshold": {
                            "type": "integer"
                          }
                        }
                      },
                      "Instances": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "InstanceId": {}
                          }
                        }
                      },
                      "ListenerDescriptions": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Listener": {
                              "type": "structure",
                              "members": {
                                "InstancePort": {
                                  "type": "integer"
                                },
                                "InstanceProtocol": {},
                                "LoadBalancerPort": {
                                  "type": "integer"
                                },
                                "Protocol": {},
                                "SslCertificateId": {}
                              }
                            },
                            "PolicyNames": {
                              "shape": "S15"
                            }
                          }
                        }
                      },
                      "LoadBalancerAttributes": {
                        "type": "structure",
                        "members": {
                          "AccessLog": {
                            "type": "structure",
                            "members": {
                              "EmitInterval": {
                                "type": "integer"
                              },
                              "Enabled": {
                                "type": "boolean"
                              },
                              "S3BucketName": {},
                              "S3BucketPrefix": {}
                            }
                          },
                          "ConnectionDraining": {
                            "type": "structure",
                            "members": {
                              "Enabled": {
                                "type": "boolean"
                              },
                              "Timeout": {
                                "type": "integer"
                              }
                            }
                          },
                          "ConnectionSettings": {
                            "type": "structure",
                            "members": {
                              "IdleTimeout": {
                                "type": "integer"
                              }
                            }
                          },
                          "CrossZoneLoadBalancing": {
                            "type": "structure",
                            "members": {
                              "Enabled": {
                                "type": "boolean"
                              }
                            }
                          },
                          "AdditionalAttributes": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Key": {},
                                "Value": {}
                              }
                            }
                          }
                        }
                      },
                      "LoadBalancerName": {},
                      "Policies": {
                        "type": "structure",
                        "members": {
                          "AppCookieStickinessPolicies": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "CookieName": {},
                                "PolicyName": {}
                              }
                            }
                          },
                          "LbCookieStickinessPolicies": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "CookieExpirationPeriod": {
                                  "type": "long"
                                },
                                "PolicyName": {}
                              }
                            }
                          },
                          "OtherPolicies": {
                            "shape": "S15"
                          }
                        }
                      },
                      "Scheme": {},
                      "SecurityGroups": {
                        "shape": "S15"
                      },
                      "SourceSecurityGroup": {
                        "type": "structure",
                        "members": {
                          "GroupName": {},
                          "OwnerAlias": {}
                        }
                      },
                      "Subnets": {
                        "shape": "S15"
                      },
                      "VpcId": {}
                    }
                  },
                  "AwsIamGroup": {
                    "type": "structure",
                    "members": {
                      "AttachedManagedPolicies": {
                        "shape": "S65"
                      },
                      "CreateDate": {},
                      "GroupId": {},
                      "GroupName": {},
                      "GroupPolicyList": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "PolicyName": {}
                          }
                        }
                      },
                      "Path": {}
                    }
                  },
                  "AwsIamRole": {
                    "type": "structure",
                    "members": {
                      "AssumeRolePolicyDocument": {},
                      "AttachedManagedPolicies": {
                        "shape": "S65"
                      },
                      "CreateDate": {},
                      "InstanceProfileList": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Arn": {},
                            "CreateDate": {},
                            "InstanceProfileId": {},
                            "InstanceProfileName": {},
                            "Path": {},
                            "Roles": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Arn": {},
                                  "AssumeRolePolicyDocument": {},
                                  "CreateDate": {},
                                  "Path": {},
                                  "RoleId": {},
                                  "RoleName": {}
                                }
                              }
                            }
                          }
                        }
                      },
                      "PermissionsBoundary": {
                        "shape": "S67"
                      },
                      "RoleId": {},
                      "RoleName": {},
                      "RolePolicyList": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "PolicyName": {}
                          }
                        }
                      },
                      "MaxSessionDuration": {
                        "type": "integer"
                      },
                      "Path": {}
                    }
                  },
                  "AwsKmsKey": {
                    "type": "structure",
                    "members": {
                      "AWSAccountId": {},
                      "CreationDate": {
                        "type": "double"
                      },
                      "KeyId": {},
                      "KeyManager": {},
                      "KeyState": {},
                      "Origin": {},
                      "Description": {},
                      "KeyRotationStatus": {
                        "type": "boolean"
                      }
                    }
                  },
                  "AwsLambdaFunction": {
                    "type": "structure",
                    "members": {
                      "Code": {
                        "type": "structure",
                        "members": {
                          "S3Bucket": {},
                          "S3Key": {},
                          "S3ObjectVersion": {},
                          "ZipFile": {}
                        }
                      },
                      "CodeSha256": {},
                      "DeadLetterConfig": {
                        "type": "structure",
                        "members": {
                          "TargetArn": {}
                        }
                      },
                      "Environment": {
                        "type": "structure",
                        "members": {
                          "Variables": {
                            "shape": "St"
                          },
                          "Error": {
                            "type": "structure",
                            "members": {
                              "ErrorCode": {},
                              "Message": {}
                            }
                          }
                        }
                      },
                      "FunctionName": {},
                      "Handler": {},
                      "KmsKeyArn": {},
                      "LastModified": {},
                      "Layers": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Arn": {},
                            "CodeSize": {
                              "type": "integer"
                            }
                          }
                        }
                      },
                      "MasterArn": {},
                      "MemorySize": {
                        "type": "integer"
                      },
                      "RevisionId": {},
                      "Role": {},
                      "Runtime": {},
                      "Timeout": {
                        "type": "integer"
                      },
                      "TracingConfig": {
                        "type": "structure",
                        "members": {
                          "Mode": {}
                        }
                      },
                      "VpcConfig": {
                        "type": "structure",
                        "members": {
                          "SecurityGroupIds": {
                            "shape": "S2n"
                          },
                          "SubnetIds": {
                            "shape": "S2n"
                          },
                          "VpcId": {}
                        }
                      },
                      "Version": {}
                    }
                  },
                  "AwsLambdaLayerVersion": {
                    "type": "structure",
                    "members": {
                      "Version": {
                        "type": "long"
                      },
                      "CompatibleRuntimes": {
                        "shape": "S2n"
                      },
                      "CreatedDate": {}
                    }
                  },
                  "AwsRdsDbInstance": {
                    "type": "structure",
                    "members": {
                      "AssociatedRoles": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "RoleArn": {},
                            "FeatureName": {},
                            "Status": {}
                          }
                        }
                      },
                      "CACertificateIdentifier": {},
                      "DBClusterIdentifier": {},
                      "DBInstanceIdentifier": {},
                      "DBInstanceClass": {},
                      "DbInstancePort": {
                        "type": "integer"
                      },
                      "DbiResourceId": {},
                      "DBName": {},
                      "DeletionProtection": {
                        "type": "boolean"
                      },
                      "Endpoint": {
                        "shape": "S9m"
                      },
                      "Engine": {},
                      "EngineVersion": {},
                      "IAMDatabaseAuthenticationEnabled": {
                        "type": "boolean"
                      },
                      "InstanceCreateTime": {},
                      "KmsKeyId": {},
                      "PubliclyAccessible": {
                        "type": "boolean"
                      },
                      "StorageEncrypted": {
                        "type": "boolean"
                      },
                      "TdeCredentialArn": {},
                      "VpcSecurityGroups": {
                        "shape": "S9n"
                      },
                      "MultiAz": {
                        "type": "boolean"
                      },
                      "EnhancedMonitoringResourceArn": {},
                      "DbInstanceStatus": {},
                      "MasterUsername": {},
                      "AllocatedStorage": {
                        "type": "integer"
                      },
                      "PreferredBackupWindow": {},
                      "BackupRetentionPeriod": {
                        "type": "integer"
                      },
                      "DbSecurityGroups": {
                        "shape": "S15"
                      },
                      "DbParameterGroups": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DbParameterGroupName": {},
                            "ParameterApplyStatus": {}
                          }
                        }
                      },
                      "AvailabilityZone": {},
                      "DbSubnetGroup": {
                        "type": "structure",
                        "members": {
                          "DbSubnetGroupName": {},
                          "DbSubnetGroupDescription": {},
                          "VpcId": {},
                          "SubnetGroupStatus": {},
                          "Subnets": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "SubnetIdentifier": {},
                                "SubnetAvailabilityZone": {
                                  "type": "structure",
                                  "members": {
                                    "Name": {}
                                  }
                                },
                                "SubnetStatus": {}
                              }
                            }
                          },
                          "DbSubnetGroupArn": {}
                        }
                      },
                      "PreferredMaintenanceWindow": {},
                      "PendingModifiedValues": {
                        "type": "structure",
                        "members": {
                          "DbInstanceClass": {},
                          "AllocatedStorage": {
                            "type": "integer"
                          },
                          "MasterUserPassword": {},
                          "Port": {
                            "type": "integer"
                          },
                          "BackupRetentionPeriod": {
                            "type": "integer"
                          },
                          "MultiAZ": {
                            "type": "boolean"
                          },
                          "EngineVersion": {},
                          "LicenseModel": {},
                          "Iops": {
                            "type": "integer"
                          },
                          "DbInstanceIdentifier": {},
                          "StorageType": {},
                          "CaCertificateIdentifier": {},
                          "DbSubnetGroupName": {},
                          "PendingCloudWatchLogsExports": {
                            "type": "structure",
                            "members": {
                              "LogTypesToEnable": {
                                "shape": "S15"
                              },
                              "LogTypesToDisable": {
                                "shape": "S15"
                              }
                            }
                          },
                          "ProcessorFeatures": {
                            "shape": "S9x"
                          }
                        }
                      },
                      "LatestRestorableTime": {},
                      "AutoMinorVersionUpgrade": {
                        "type": "boolean"
                      },
                      "ReadReplicaSourceDBInstanceIdentifier": {},
                      "ReadReplicaDBInstanceIdentifiers": {
                        "shape": "S15"
                      },
                      "ReadReplicaDBClusterIdentifiers": {
                        "shape": "S15"
                      },
                      "LicenseModel": {},
                      "Iops": {
                        "type": "integer"
                      },
                      "OptionGroupMemberships": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "OptionGroupName": {},
                            "Status": {}
                          }
                        }
                      },
                      "CharacterSetName": {},
                      "SecondaryAvailabilityZone": {},
                      "StatusInfos": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "StatusType": {},
                            "Normal": {
                              "type": "boolean"
                            },
                            "Status": {},
                            "Message": {}
                          }
                        }
                      },
                      "StorageType": {},
                      "DomainMemberships": {
                        "shape": "Sa3"
                      },
                      "CopyTagsToSnapshot": {
                        "type": "boolean"
                      },
                      "MonitoringInterval": {
                        "type": "integer"
                      },
                      "MonitoringRoleArn": {},
                      "PromotionTier": {
                        "type": "integer"
                      },
                      "Timezone": {},
                      "PerformanceInsightsEnabled": {
                        "type": "boolean"
                      },
                      "PerformanceInsightsKmsKeyId": {},
                      "PerformanceInsightsRetentionPeriod": {
                        "type": "integer"
                      },
                      "EnabledCloudWatchLogsExports": {
                        "shape": "S15"
                      },
                      "ProcessorFeatures": {
                        "shape": "S9x"
                      },
                      "ListenerEndpoint": {
                        "shape": "S9m"
                      },
                      "MaxAllocatedStorage": {
                        "type": "integer"
                      }
                    }
                  },
                  "AwsSnsTopic": {
                    "type": "structure",
                    "members": {
                      "KmsMasterKeyId": {},
                      "Subscription": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Endpoint": {},
                            "Protocol": {}
                          }
                        }
                      },
                      "TopicName": {},
                      "Owner": {}
                    }
                  },
                  "AwsSqsQueue": {
                    "type": "structure",
                    "members": {
                      "KmsDataKeyReusePeriodSeconds": {
                        "type": "integer"
                      },
                      "KmsMasterKeyId": {},
                      "QueueName": {},
                      "DeadLetterTargetArn": {}
                    }
                  },
                  "AwsWafWebAcl": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "DefaultAction": {},
                      "Rules": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Action": {
                              "type": "structure",
                              "members": {
                                "Type": {}
                              }
                            },
                            "ExcludedRules": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "RuleId": {}
                                }
                              }
                            },
                            "OverrideAction": {
                              "type": "structure",
                              "members": {
                                "Type": {}
                              }
                            },
                            "Priority": {
                              "type": "integer"
                            },
                            "RuleId": {},
                            "Type": {}
                          }
                        }
                      },
                      "WebAclId": {}
                    }
                  },
                  "AwsRdsDbSnapshot": {
                    "type": "structure",
                    "members": {
                      "DbSnapshotIdentifier": {},
                      "DbInstanceIdentifier": {},
                      "SnapshotCreateTime": {},
                      "Engine": {},
                      "AllocatedStorage": {
                        "type": "integer"
                      },
                      "Status": {},
                      "Port": {
                        "type": "integer"
                      },
                      "AvailabilityZone": {},
                      "VpcId": {},
                      "InstanceCreateTime": {},
                      "MasterUsername": {},
                      "EngineVersion": {},
                      "LicenseModel": {},
                      "SnapshotType": {},
                      "Iops": {
                        "type": "integer"
                      },
                      "OptionGroupName": {},
                      "PercentProgress": {
                        "type": "integer"
                      },
                      "SourceRegion": {},
                      "SourceDbSnapshotIdentifier": {},
                      "StorageType": {},
                      "TdeCredentialArn": {},
                      "Encrypted": {
                        "type": "boolean"
                      },
                      "KmsKeyId": {},
                      "Timezone": {},
                      "IamDatabaseAuthenticationEnabled": {
                        "type": "boolean"
                      },
                      "ProcessorFeatures": {
                        "shape": "S9x"
                      },
                      "DbiResourceId": {}
                    }
                  },
                  "AwsRdsDbClusterSnapshot": {
                    "type": "structure",
                    "members": {
                      "AvailabilityZones": {
                        "shape": "S15"
                      },
                      "SnapshotCreateTime": {},
                      "Engine": {},
                      "AllocatedStorage": {
                        "type": "integer"
                      },
                      "Status": {},
                      "Port": {
                        "type": "integer"
                      },
                      "VpcId": {},
                      "ClusterCreateTime": {},
                      "MasterUsername": {},
                      "EngineVersion": {},
                      "LicenseModel": {},
                      "SnapshotType": {},
                      "PercentProgress": {
                        "type": "integer"
                      },
                      "StorageEncrypted": {
                        "type": "boolean"
                      },
                      "KmsKeyId": {},
                      "DbClusterIdentifier": {},
                      "DbClusterSnapshotIdentifier": {},
                      "IamDatabaseAuthenticationEnabled": {
                        "type": "boolean"
                      }
                    }
                  },
                  "AwsRdsDbCluster": {
                    "type": "structure",
                    "members": {
                      "AllocatedStorage": {
                        "type": "integer"
                      },
                      "AvailabilityZones": {
                        "shape": "S15"
                      },
                      "BackupRetentionPeriod": {
                        "type": "integer"
                      },
                      "DatabaseName": {},
                      "Status": {},
                      "Endpoint": {},
                      "ReaderEndpoint": {},
                      "CustomEndpoints": {
                        "shape": "S15"
                      },
                      "MultiAz": {
                        "type": "boolean"
                      },
                      "Engine": {},
                      "EngineVersion": {},
                      "Port": {
                        "type": "integer"
                      },
                      "MasterUsername": {},
                      "PreferredBackupWindow": {},
                      "PreferredMaintenanceWindow": {},
                      "ReadReplicaIdentifiers": {
                        "shape": "S15"
                      },
                      "VpcSecurityGroups": {
                        "shape": "S9n"
                      },
                      "HostedZoneId": {},
                      "StorageEncrypted": {
                        "type": "boolean"
                      },
                      "KmsKeyId": {},
                      "DbClusterResourceId": {},
                      "AssociatedRoles": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "RoleArn": {},
                            "Status": {}
                          }
                        }
                      },
                      "ClusterCreateTime": {},
                      "EnabledCloudWatchLogsExports": {
                        "shape": "S15"
                      },
                      "EngineMode": {},
                      "DeletionProtection": {
                        "type": "boolean"
                      },
                      "HttpEndpointEnabled": {
                        "type": "boolean"
                      },
                      "ActivityStreamStatus": {},
                      "CopyTagsToSnapshot": {
                        "type": "boolean"
                      },
                      "CrossAccountClone": {
                        "type": "boolean"
                      },
                      "DomainMemberships": {
                        "shape": "Sa3"
                      },
                      "DbClusterParameterGroup": {},
                      "DbSubnetGroup": {},
                      "DbClusterOptionGroupMemberships": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DbClusterOptionGroupName": {},
                            "Status": {}
                          }
                        }
                      },
                      "DbClusterIdentifier": {},
                      "DbClusterMembers": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "IsClusterWriter": {
                              "type": "boolean"
                            },
                            "PromotionTier": {
                              "type": "integer"
                            },
                            "DbInstanceIdentifier": {},
                            "DbClusterParameterGroupStatus": {}
                          }
                        }
                      },
                      "IamDatabaseAuthenticationEnabled": {
                        "type": "boolean"
                      }
                    }
                  },
                  "AwsEcsCluster": {
                    "type": "structure",
                    "members": {
                      "CapacityProviders": {
                        "shape": "S2n"
                      },
                      "ClusterSettings": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Value": {}
                          }
                        }
                      },
                      "Configuration": {
                        "type": "structure",
                        "members": {
                          "ExecuteCommandConfiguration": {
                            "type": "structure",
                            "members": {
                              "KmsKeyId": {},
                              "LogConfiguration": {
                                "type": "structure",
                                "members": {
                                  "CloudWatchEncryptionEnabled": {
                                    "type": "boolean"
                                  },
                                  "CloudWatchLogGroupName": {},
                                  "S3BucketName": {},
                                  "S3EncryptionEnabled": {
                                    "type": "boolean"
                                  },
                                  "S3KeyPrefix": {}
                                }
                              },
                              "Logging": {}
                            }
                          }
                        }
                      },
                      "DefaultCapacityProviderStrategy": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Base": {
                              "type": "integer"
                            },
                            "CapacityProvider": {},
                            "Weight": {
                              "type": "integer"
                            }
                          }
                        }
                      }
                    }
                  },
                  "AwsEcsTaskDefinition": {
                    "type": "structure",
                    "members": {
                      "ContainerDefinitions": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Command": {
                              "shape": "S2n"
                            },
                            "Cpu": {
                              "type": "integer"
                            },
                            "DependsOn": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Condition": {},
                                  "ContainerName": {}
                                }
                              }
                            },
                            "DisableNetworking": {
                              "type": "boolean"
                            },
                            "DnsSearchDomains": {
                              "shape": "S2n"
                            },
                            "DnsServers": {
                              "shape": "S2n"
                            },
                            "DockerLabels": {
                              "shape": "St"
                            },
                            "DockerSecurityOptions": {
                              "shape": "S2n"
                            },
                            "EntryPoint": {
                              "shape": "S2n"
                            },
                            "Environment": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Value": {}
                                }
                              }
                            },
                            "EnvironmentFiles": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Type": {},
                                  "Value": {}
                                }
                              }
                            },
                            "Essential": {
                              "type": "boolean"
                            },
                            "ExtraHosts": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Hostname": {},
                                  "IpAddress": {}
                                }
                              }
                            },
                            "FirelensConfiguration": {
                              "type": "structure",
                              "members": {
                                "Options": {
                                  "shape": "St"
                                },
                                "Type": {}
                              }
                            },
                            "HealthCheck": {
                              "type": "structure",
                              "members": {
                                "Command": {
                                  "shape": "S2n"
                                },
                                "Interval": {
                                  "type": "integer"
                                },
                                "Retries": {
                                  "type": "integer"
                                },
                                "StartPeriod": {
                                  "type": "integer"
                                },
                                "Timeout": {
                                  "type": "integer"
                                }
                              }
                            },
                            "Hostname": {},
                            "Image": {},
                            "Interactive": {
                              "type": "boolean"
                            },
                            "Links": {
                              "shape": "S2n"
                            },
                            "LinuxParameters": {
                              "type": "structure",
                              "members": {
                                "Capabilities": {
                                  "type": "structure",
                                  "members": {
                                    "Add": {
                                      "shape": "S2n"
                                    },
                                    "Drop": {
                                      "shape": "S2n"
                                    }
                                  }
                                },
                                "Devices": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "members": {
                                      "ContainerPath": {},
                                      "HostPath": {},
                                      "Permissions": {
                                        "shape": "S2n"
                                      }
                                    }
                                  }
                                },
                                "InitProcessEnabled": {
                                  "type": "boolean"
                                },
                                "MaxSwap": {
                                  "type": "integer"
                                },
                                "SharedMemorySize": {
                                  "type": "integer"
                                },
                                "Swappiness": {
                                  "type": "integer"
                                },
                                "Tmpfs": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "members": {
                                      "ContainerPath": {},
                                      "MountOptions": {
                                        "shape": "S2n"
                                      },
                                      "Size": {
                                        "type": "integer"
                                      }
                                    }
                                  }
                                }
                              }
                            },
                            "LogConfiguration": {
                              "type": "structure",
                              "members": {
                                "LogDriver": {},
                                "Options": {
                                  "shape": "St"
                                },
                                "SecretOptions": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "members": {
                                      "Name": {},
                                      "ValueFrom": {}
                                    }
                                  }
                                }
                              }
                            },
                            "Memory": {
                              "type": "integer"
                            },
                            "MemoryReservation": {
                              "type": "integer"
                            },
                            "MountPoints": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "ContainerPath": {},
                                  "ReadOnly": {
                                    "type": "boolean"
                                  },
                                  "SourceVolume": {}
                                }
                              }
                            },
                            "Name": {},
                            "PortMappings": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "ContainerPort": {
                                    "type": "integer"
                                  },
                                  "HostPort": {
                                    "type": "integer"
                                  },
                                  "Protocol": {}
                                }
                              }
                            },
                            "Privileged": {
                              "type": "boolean"
                            },
                            "PseudoTerminal": {
                              "type": "boolean"
                            },
                            "ReadonlyRootFilesystem": {
                              "type": "boolean"
                            },
                            "RepositoryCredentials": {
                              "type": "structure",
                              "members": {
                                "CredentialsParameter": {}
                              }
                            },
                            "ResourceRequirements": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Type": {},
                                  "Value": {}
                                }
                              }
                            },
                            "Secrets": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "ValueFrom": {}
                                }
                              }
                            },
                            "StartTimeout": {
                              "type": "integer"
                            },
                            "StopTimeout": {
                              "type": "integer"
                            },
                            "SystemControls": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Namespace": {},
                                  "Value": {}
                                }
                              }
                            },
                            "Ulimits": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "HardLimit": {
                                    "type": "integer"
                                  },
                                  "Name": {},
                                  "SoftLimit": {
                                    "type": "integer"
                                  }
                                }
                              }
                            },
                            "User": {},
                            "VolumesFrom": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "ReadOnly": {
                                    "type": "boolean"
                                  },
                                  "SourceContainer": {}
                                }
                              }
                            },
                            "WorkingDirectory": {}
                          }
                        }
                      },
                      "Cpu": {},
                      "ExecutionRoleArn": {},
                      "Family": {},
                      "InferenceAccelerators": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DeviceName": {},
                            "DeviceType": {}
                          }
                        }
                      },
                      "IpcMode": {},
                      "Memory": {},
                      "NetworkMode": {},
                      "PidMode": {},
                      "PlacementConstraints": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Expression": {},
                            "Type": {}
                          }
                        }
                      },
                      "ProxyConfiguration": {
                        "type": "structure",
                        "members": {
                          "ContainerName": {},
                          "ProxyConfigurationProperties": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Name": {},
                                "Value": {}
                              }
                            }
                          },
                          "Type": {}
                        }
                      },
                      "RequiresCompatibilities": {
                        "shape": "S2n"
                      },
                      "TaskRoleArn": {},
                      "Volumes": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DockerVolumeConfiguration": {
                              "type": "structure",
                              "members": {
                                "Autoprovision": {
                                  "type": "boolean"
                                },
                                "Driver": {},
                                "DriverOpts": {
                                  "shape": "St"
                                },
                                "Labels": {
                                  "shape": "St"
                                },
                                "Scope": {}
                              }
                            },
                            "EfsVolumeConfiguration": {
                              "type": "structure",
                              "members": {
                                "AuthorizationConfig": {
                                  "type": "structure",
                                  "members": {
                                    "AccessPointId": {},
                                    "Iam": {}
                                  }
                                },
                                "FilesystemId": {},
                                "RootDirectory": {},
                                "TransitEncryption": {},
                                "TransitEncryptionPort": {
                                  "type": "integer"
                                }
                              }
                            },
                            "Host": {
                              "type": "structure",
                              "members": {
                                "SourcePath": {}
                              }
                            },
                            "Name": {}
                          }
                        }
                      }
                    }
                  },
                  "Container": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "ImageId": {},
                      "ImageName": {},
                      "LaunchedAt": {}
                    }
                  },
                  "Other": {
                    "shape": "St"
                  },
                  "AwsRdsEventSubscription": {
                    "type": "structure",
                    "members": {
                      "CustSubscriptionId": {},
                      "CustomerAwsId": {},
                      "Enabled": {
                        "type": "boolean"
                      },
                      "EventCategoriesList": {
                        "shape": "S2n"
                      },
                      "EventSubscriptionArn": {},
                      "SnsTopicArn": {},
                      "SourceIdsList": {
                        "shape": "S2n"
                      },
                      "SourceType": {},
                      "Status": {},
                      "SubscriptionCreationTime": {}
                    }
                  },
                  "AwsEcsService": {
                    "type": "structure",
                    "members": {
                      "CapacityProviderStrategy": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Base": {
                              "type": "integer"
                            },
                            "CapacityProvider": {},
                            "Weight": {
                              "type": "integer"
                            }
                          }
                        }
                      },
                      "Cluster": {},
                      "DeploymentConfiguration": {
                        "type": "structure",
                        "members": {
                          "DeploymentCircuitBreaker": {
                            "type": "structure",
                            "members": {
                              "Enable": {
                                "type": "boolean"
                              },
                              "Rollback": {
                                "type": "boolean"
                              }
                            }
                          },
                          "MaximumPercent": {
                            "type": "integer"
                          },
                          "MinimumHealthyPercent": {
                            "type": "integer"
                          }
                        }
                      },
                      "DeploymentController": {
                        "type": "structure",
                        "members": {
                          "Type": {}
                        }
                      },
                      "DesiredCount": {
                        "type": "integer"
                      },
                      "EnableEcsManagedTags": {
                        "type": "boolean"
                      },
                      "EnableExecuteCommand": {
                        "type": "boolean"
                      },
                      "HealthCheckGracePeriodSeconds": {
                        "type": "integer"
                      },
                      "LaunchType": {},
                      "LoadBalancers": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ContainerName": {},
                            "ContainerPort": {
                              "type": "integer"
                            },
                            "LoadBalancerName": {},
                            "TargetGroupArn": {}
                          }
                        }
                      },
                      "Name": {},
                      "NetworkConfiguration": {
                        "type": "structure",
                        "members": {
                          "AwsVpcConfiguration": {
                            "type": "structure",
                            "members": {
                              "AssignPublicIp": {},
                              "SecurityGroups": {
                                "shape": "S2n"
                              },
                              "Subnets": {
                                "shape": "S2n"
                              }
                            }
                          }
                        }
                      },
                      "PlacementConstraints": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Expression": {},
                            "Type": {}
                          }
                        }
                      },
                      "PlacementStrategies": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Field": {},
                            "Type": {}
                          }
                        }
                      },
                      "PlatformVersion": {},
                      "PropagateTags": {},
                      "Role": {},
                      "SchedulingStrategy": {},
                      "ServiceArn": {},
                      "ServiceName": {},
                      "ServiceRegistries": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ContainerName": {},
                            "ContainerPort": {
                              "type": "integer"
                            },
                            "Port": {
                              "type": "integer"
                            },
                            "RegistryArn": {}
                          }
                        }
                      },
                      "TaskDefinition": {}
                    }
                  },
                  "AwsAutoScalingLaunchConfiguration": {
                    "type": "structure",
                    "members": {
                      "AssociatePublicIpAddress": {
                        "type": "boolean"
                      },
                      "BlockDeviceMappings": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DeviceName": {},
                            "Ebs": {
                              "type": "structure",
                              "members": {
                                "DeleteOnTermination": {
                                  "type": "boolean"
                                },
                                "Encrypted": {
                                  "type": "boolean"
                                },
                                "Iops": {
                                  "type": "integer"
                                },
                                "SnapshotId": {},
                                "VolumeSize": {
                                  "type": "integer"
                                },
                                "VolumeType": {}
                              }
                            },
                            "NoDevice": {
                              "type": "boolean"
                            },
                            "VirtualName": {}
                          }
                        }
                      },
                      "ClassicLinkVpcId": {},
                      "ClassicLinkVpcSecurityGroups": {
                        "shape": "S2n"
                      },
                      "CreatedTime": {},
                      "EbsOptimized": {
                        "type": "boolean"
                      },
                      "IamInstanceProfile": {},
                      "ImageId": {},
                      "InstanceMonitoring": {
                        "type": "structure",
                        "members": {
                          "Enabled": {
                            "type": "boolean"
                          }
                        }
                      },
                      "InstanceType": {},
                      "KernelId": {},
                      "KeyName": {},
                      "LaunchConfigurationName": {},
                      "PlacementTenancy": {},
                      "RamdiskId": {},
                      "SecurityGroups": {
                        "shape": "S2n"
                      },
                      "SpotPrice": {},
                      "UserData": {},
                      "MetadataOptions": {
                        "type": "structure",
                        "members": {
                          "HttpEndpoint": {},
                          "HttpPutResponseHopLimit": {
                            "type": "integer"
                          },
                          "HttpTokens": {}
                        }
                      }
                    }
                  },
                  "AwsEc2VpnConnection": {
                    "type": "structure",
                    "members": {
                      "VpnConnectionId": {},
                      "State": {},
                      "CustomerGatewayId": {},
                      "CustomerGatewayConfiguration": {},
                      "Type": {},
                      "VpnGatewayId": {},
                      "Category": {},
                      "VgwTelemetry": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "AcceptedRouteCount": {
                              "type": "integer"
                            },
                            "CertificateArn": {},
                            "LastStatusChange": {},
                            "OutsideIpAddress": {},
                            "Status": {},
                            "StatusMessage": {}
                          }
                        }
                      },
                      "Options": {
                        "type": "structure",
                        "members": {
                          "StaticRoutesOnly": {
                            "type": "boolean"
                          },
                          "TunnelOptions": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "DpdTimeoutSeconds": {
                                  "type": "integer"
                                },
                                "IkeVersions": {
                                  "shape": "S2n"
                                },
                                "OutsideIpAddress": {},
                                "Phase1DhGroupNumbers": {
                                  "shape": "Sd5"
                                },
                                "Phase1EncryptionAlgorithms": {
                                  "shape": "S2n"
                                },
                                "Phase1IntegrityAlgorithms": {
                                  "shape": "S2n"
                                },
                                "Phase1LifetimeSeconds": {
                                  "type": "integer"
                                },
                                "Phase2DhGroupNumbers": {
                                  "shape": "Sd5"
                                },
                                "Phase2EncryptionAlgorithms": {
                                  "shape": "S2n"
                                },
                                "Phase2IntegrityAlgorithms": {
                                  "shape": "S2n"
                                },
                                "Phase2LifetimeSeconds": {
                                  "type": "integer"
                                },
                                "PreSharedKey": {},
                                "RekeyFuzzPercentage": {
                                  "type": "integer"
                                },
                                "RekeyMarginTimeSeconds": {
                                  "type": "integer"
                                },
                                "ReplayWindowSize": {
                                  "type": "integer"
                                },
                                "TunnelInsideCidr": {}
                              }
                            }
                          }
                        }
                      },
                      "Routes": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DestinationCidrBlock": {},
                            "State": {}
                          }
                        }
                      },
                      "TransitGatewayId": {}
                    }
                  },
                  "AwsEcrContainerImage": {
                    "type": "structure",
                    "members": {
                      "RegistryId": {},
                      "RepositoryName": {},
                      "Architecture": {},
                      "ImageDigest": {},
                      "ImageTags": {
                        "shape": "S2n"
                      },
                      "ImagePublishedAt": {}
                    }
                  },
                  "AwsOpenSearchServiceDomain": {
                    "type": "structure",
                    "members": {
                      "Arn": {},
                      "AccessPolicies": {},
                      "DomainName": {},
                      "Id": {},
                      "DomainEndpoint": {},
                      "EngineVersion": {},
                      "EncryptionAtRestOptions": {
                        "type": "structure",
                        "members": {
                          "Enabled": {
                            "type": "boolean"
                          },
                          "KmsKeyId": {}
                        }
                      },
                      "NodeToNodeEncryptionOptions": {
                        "type": "structure",
                        "members": {
                          "Enabled": {
                            "type": "boolean"
                          }
                        }
                      },
                      "ServiceSoftwareOptions": {
                        "type": "structure",
                        "members": {
                          "AutomatedUpdateDate": {},
                          "Cancellable": {
                            "type": "boolean"
                          },
                          "CurrentVersion": {},
                          "Description": {},
                          "NewVersion": {},
                          "UpdateAvailable": {
                            "type": "boolean"
                          },
                          "UpdateStatus": {},
                          "OptionalDeployment": {
                            "type": "boolean"
                          }
                        }
                      },
                      "ClusterConfig": {
                        "type": "structure",
                        "members": {
                          "InstanceCount": {
                            "type": "integer"
                          },
                          "WarmEnabled": {
                            "type": "boolean"
                          },
                          "WarmCount": {
                            "type": "integer"
                          },
                          "DedicatedMasterEnabled": {
                            "type": "boolean"
                          },
                          "ZoneAwarenessConfig": {
                            "type": "structure",
                            "members": {
                              "AvailabilityZoneCount": {
                                "type": "integer"
                              }
                            }
                          },
                          "DedicatedMasterCount": {
                            "type": "integer"
                          },
                          "InstanceType": {},
                          "WarmType": {},
                          "ZoneAwarenessEnabled": {
                            "type": "boolean"
                          },
                          "DedicatedMasterType": {}
                        }
                      },
                      "DomainEndpointOptions": {
                        "type": "structure",
                        "members": {
                          "CustomEndpointCertificateArn": {},
                          "CustomEndpointEnabled": {
                            "type": "boolean"
                          },
                          "EnforceHTTPS": {
                            "type": "boolean"
                          },
                          "CustomEndpoint": {},
                          "TLSSecurityPolicy": {}
                        }
                      },
                      "VpcOptions": {
                        "type": "structure",
                        "members": {
                          "SecurityGroupIds": {
                            "shape": "S2n"
                          },
                          "SubnetIds": {
                            "shape": "S2n"
                          }
                        }
                      },
                      "LogPublishingOptions": {
                        "type": "structure",
                        "members": {
                          "IndexSlowLogs": {
                            "shape": "Sdi"
                          },
                          "SearchSlowLogs": {
                            "shape": "Sdi"
                          },
                          "AuditLogs": {
                            "shape": "Sdi"
                          }
                        }
                      },
                      "DomainEndpoints": {
                        "shape": "St"
                      }
                    }
                  },
                  "AwsEc2VpcEndpointService": {
                    "type": "structure",
                    "members": {
                      "AcceptanceRequired": {
                        "type": "boolean"
                      },
                      "AvailabilityZones": {
                        "shape": "S2n"
                      },
                      "BaseEndpointDnsNames": {
                        "shape": "S2n"
                      },
                      "ManagesVpcEndpoints": {
                        "type": "boolean"
                      },
                      "GatewayLoadBalancerArns": {
                        "shape": "S2n"
                      },
                      "NetworkLoadBalancerArns": {
                        "shape": "S2n"
                      },
                      "PrivateDnsName": {},
                      "ServiceId": {},
                      "ServiceName": {},
                      "ServiceState": {},
                      "ServiceType": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "ServiceType": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsXrayEncryptionConfig": {
                    "type": "structure",
                    "members": {
                      "KeyId": {},
                      "Status": {},
                      "Type": {}
                    }
                  },
                  "AwsWafRateBasedRule": {
                    "type": "structure",
                    "members": {
                      "MetricName": {},
                      "Name": {},
                      "RateKey": {},
                      "RateLimit": {
                        "type": "long"
                      },
                      "RuleId": {},
                      "MatchPredicates": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DataId": {},
                            "Negated": {
                              "type": "boolean"
                            },
                            "Type": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsWafRegionalRateBasedRule": {
                    "type": "structure",
                    "members": {
                      "MetricName": {},
                      "Name": {},
                      "RateKey": {},
                      "RateLimit": {
                        "type": "long"
                      },
                      "RuleId": {},
                      "MatchPredicates": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "DataId": {},
                            "Negated": {
                              "type": "boolean"
                            },
                            "Type": {}
                          }
                        }
                      }
                    }
                  },
                  "AwsEcrRepository": {
                    "type": "structure",
                    "members": {
                      "Arn": {},
                      "ImageScanningConfiguration": {
                        "type": "structure",
                        "members": {
                          "ScanOnPush": {
                            "type": "boolean"
                          }
                        }
                      },
                      "ImageTagMutability": {},
                      "LifecyclePolicy": {
                        "type": "structure",
                        "members": {
                          "LifecyclePolicyText": {},
                          "RegistryId": {}
                        }
                      },
                      "RepositoryName": {},
                      "RepositoryPolicyText": {}
                    }
                  },
                  "AwsEksCluster": {
                    "type": "structure",
                    "members": {
                      "Arn": {},
                      "CertificateAuthorityData": {},
                      "ClusterStatus": {},
                      "Endpoint": {},
                      "Name": {},
                      "ResourcesVpcConfig": {
                        "type": "structure",
                        "members": {
                          "SecurityGroupIds": {
                            "shape": "S2n"
                          },
                          "SubnetIds": {
                            "shape": "S2n"
                          }
                        }
                      },
                      "RoleArn": {},
                      "Version": {},
                      "Logging": {
                        "type": "structure",
                        "members": {
                          "ClusterLogging": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Enabled": {
                                  "type": "boolean"
                                },
                                "Types": {
                                  "shape": "S2n"
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  "AwsNetworkFirewallFirewallPolicy": {
                    "type": "structure",
                    "members": {
                      "FirewallPolicy": {
                        "type": "structure",
                        "members": {
                          "StatefulRuleGroupReferences": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "ResourceArn": {}
                              }
                            }
                          },
                          "StatelessCustomActions": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "ActionDefinition": {
                                  "shape": "Se7"
                                },
                                "ActionName": {}
                              }
                            }
                          },
                          "StatelessDefaultActions": {
                            "shape": "S2n"
                          },
                          "StatelessFragmentDefaultActions": {
                            "shape": "S2n"
                          },
                          "StatelessRuleGroupReferences": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "Priority": {
                                  "type": "integer"
                                },
                                "ResourceArn": {}
                              }
                            }
                          }
                        }
                      },
                      "FirewallPolicyArn": {},
                      "FirewallPolicyId": {},
                      "FirewallPolicyName": {},
                      "Description": {}
                    }
                  },
                  "AwsNetworkFirewallFirewall": {
                    "type": "structure",
                    "members": {
                      "DeleteProtection": {
                        "type": "boolean"
                      },
                      "Description": {},
                      "FirewallArn": {},
                      "FirewallId": {},
                      "FirewallName": {},
                      "FirewallPolicyArn": {},
                      "FirewallPolicyChangeProtection": {
                        "type": "boolean"
                      },
                      "SubnetChangeProtection": {
                        "type": "boolean"
                      },
                      "SubnetMappings": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "SubnetId": {}
                          }
                        }
                      },
                      "VpcId": {}
                    }
                  },
                  "AwsNetworkFirewallRuleGroup": {
                    "type": "structure",
                    "members": {
                      "Capacity": {
                        "type": "integer"
                      },
                      "Description": {},
                      "RuleGroup": {
                        "type": "structure",
                        "members": {
                          "RuleVariables": {
                            "type": "structure",
                            "members": {
                              "IpSets": {
                                "type": "structure",
                                "members": {
                                  "Definition": {
                                    "shape": "S2n"
                                  }
                                }
                              },
                              "PortSets": {
                                "type": "structure",
                                "members": {
                                  "Definition": {
                                    "shape": "S2n"
                                  }
                                }
                              }
                            }
                          },
                          "RulesSource": {
                            "type": "structure",
                            "members": {
                              "RulesSourceList": {
                                "type": "structure",
                                "members": {
                                  "GeneratedRulesType": {},
                                  "TargetTypes": {
                                    "shape": "S2n"
                                  },
                                  "Targets": {
                                    "shape": "S2n"
                                  }
                                }
                              },
                              "RulesString": {},
                              "StatefulRules": {
                                "type": "list",
                                "member": {
                                  "type": "structure",
                                  "members": {
                                    "Action": {},
                                    "Header": {
                                      "type": "structure",
                                      "members": {
                                        "Destination": {},
                                        "DestinationPort": {},
                                        "Direction": {},
                                        "Protocol": {},
                                        "Source": {},
                                        "SourcePort": {}
                                      }
                                    },
                                    "RuleOptions": {
                                      "type": "list",
                                      "member": {
                                        "type": "structure",
                                        "members": {
                                          "Keyword": {},
                                          "Settings": {
                                            "type": "list",
                                            "member": {}
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              },
                              "StatelessRulesAndCustomActions": {
                                "type": "structure",
                                "members": {
                                  "CustomActions": {
                                    "type": "list",
                                    "member": {
                                      "type": "structure",
                                      "members": {
                                        "ActionDefinition": {
                                          "shape": "Se7"
                                        },
                                        "ActionName": {}
                                      }
                                    }
                                  },
                                  "StatelessRules": {
                                    "type": "list",
                                    "member": {
                                      "type": "structure",
                                      "members": {
                                        "Priority": {
                                          "type": "integer"
                                        },
                                        "RuleDefinition": {
                                          "type": "structure",
                                          "members": {
                                            "Actions": {
                                              "shape": "S2n"
                                            },
                                            "MatchAttributes": {
                                              "type": "structure",
                                              "members": {
                                                "DestinationPorts": {
                                                  "type": "list",
                                                  "member": {
                                                    "type": "structure",
                                                    "members": {
                                                      "FromPort": {
                                                        "type": "integer"
                                                      },
                                                      "ToPort": {
                                                        "type": "integer"
                                                      }
                                                    }
                                                  }
                                                },
                                                "Destinations": {
                                                  "type": "list",
                                                  "member": {
                                                    "type": "structure",
                                                    "members": {
                                                      "AddressDefinition": {}
                                                    }
                                                  }
                                                },
                                                "Protocols": {
                                                  "type": "list",
                                                  "member": {
                                                    "type": "integer"
                                                  }
                                                },
                                                "SourcePorts": {
                                                  "type": "list",
                                                  "member": {
                                                    "type": "structure",
                                                    "members": {
                                                      "FromPort": {
                                                        "type": "integer"
                                                      },
                                                      "ToPort": {
                                                        "type": "integer"
                                                      }
                                                    }
                                                  }
                                                },
                                                "Sources": {
                                                  "type": "list",
                                                  "member": {
                                                    "type": "structure",
                                                    "members": {
                                                      "AddressDefinition": {}
                                                    }
                                                  }
                                                },
                                                "TcpFlags": {
                                                  "type": "list",
                                                  "member": {
                                                    "type": "structure",
                                                    "members": {
                                                      "Flags": {
                                                        "shape": "S2n"
                                                      },
                                                      "Masks": {
                                                        "shape": "S2n"
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      "RuleGroupArn": {},
                      "RuleGroupId": {},
                      "RuleGroupName": {},
                      "Type": {}
                    }
                  },
                  "AwsRdsDbSecurityGroup": {
                    "type": "structure",
                    "members": {
                      "DbSecurityGroupArn": {},
                      "DbSecurityGroupDescription": {},
                      "DbSecurityGroupName": {},
                      "Ec2SecurityGroups": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Ec2SecurityGroupId": {},
                            "Ec2SecurityGroupName": {},
                            "Ec2SecurityGroupOwnerId": {},
                            "Status": {}
                          }
                        }
                      },
                      "IpRanges": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "CidrIp": {},
                            "Status": {}
                          }
                        }
                      },
                      "OwnerId": {},
                      "VpcId": {}
                    }
                  }
                }
              }
            }
          }
        },
        "Compliance": {
          "type": "structure",
          "members": {
            "Status": {},
            "RelatedRequirements": {
              "shape": "Sfi"
            },
            "StatusReasons": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "ReasonCode"
                ],
                "members": {
                  "ReasonCode": {},
                  "Description": {}
                }
              }
            }
          }
        },
        "VerificationState": {},
        "WorkflowState": {
          "type": "string",
          "deprecated": true,
          "deprecatedMessage": "This filter is deprecated. Instead, use SeverityLabel or FindingProviderFieldsSeverityLabel."
        },
        "Workflow": {
          "type": "structure",
          "members": {
            "Status": {}
          }
        },
        "RecordState": {},
        "RelatedFindings": {
          "shape": "Sfq"
        },
        "Note": {
          "type": "structure",
          "required": [
            "Text",
            "UpdatedBy",
            "UpdatedAt"
          ],
          "members": {
            "Text": {},
            "UpdatedBy": {},
            "UpdatedAt": {}
          }
        },
        "Vulnerabilities": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Id"
            ],
            "members": {
              "Id": {},
              "VulnerablePackages": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Version": {},
                    "Epoch": {},
                    "Release": {},
                    "Architecture": {},
                    "PackageManager": {},
                    "FilePath": {}
                  }
                }
              },
              "Cvss": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Version": {},
                    "BaseScore": {
                      "type": "double"
                    },
                    "BaseVector": {},
                    "Source": {},
                    "Adjustments": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Metric": {},
                          "Reason": {}
                        }
                      }
                    }
                  }
                }
              },
              "RelatedVulnerabilities": {
                "shape": "S15"
              },
              "Vendor": {
                "type": "structure",
                "required": [
                  "Name"
                ],
                "members": {
                  "Name": {},
                  "Url": {},
                  "VendorSeverity": {},
                  "VendorCreatedAt": {},
                  "VendorUpdatedAt": {}
                }
              },
              "ReferenceUrls": {
                "shape": "S15"
              }
            }
          }
        },
        "PatchSummary": {
          "type": "structure",
          "required": [
            "Id"
          ],
          "members": {
            "Id": {},
            "InstalledCount": {
              "type": "integer"
            },
            "MissingCount": {
              "type": "integer"
            },
            "FailedCount": {
              "type": "integer"
            },
            "InstalledOtherCount": {
              "type": "integer"
            },
            "InstalledRejectedCount": {
              "type": "integer"
            },
            "InstalledPendingReboot": {
              "type": "integer"
            },
            "OperationStartTime": {},
            "OperationEndTime": {},
            "RebootOption": {},
            "Operation": {}
          }
        },
        "Action": {
          "type": "structure",
          "members": {
            "ActionType": {},
            "NetworkConnectionAction": {
              "type": "structure",
              "members": {
                "ConnectionDirection": {},
                "RemoteIpDetails": {
                  "shape": "Sg5"
                },
                "RemotePortDetails": {
                  "type": "structure",
                  "members": {
                    "Port": {
                      "type": "integer"
                    },
                    "PortName": {}
                  }
                },
                "LocalPortDetails": {
                  "shape": "Sgb"
                },
                "Protocol": {},
                "Blocked": {
                  "type": "boolean"
                }
              }
            },
            "AwsApiCallAction": {
              "type": "structure",
              "members": {
                "Api": {},
                "ServiceName": {},
                "CallerType": {},
                "RemoteIpDetails": {
                  "shape": "Sg5"
                },
                "DomainDetails": {
                  "type": "structure",
                  "members": {
                    "Domain": {}
                  }
                },
                "AffectedResources": {
                  "shape": "St"
                },
                "FirstSeen": {},
                "LastSeen": {}
              }
            },
            "DnsRequestAction": {
              "type": "structure",
              "members": {
                "Domain": {},
                "Protocol": {},
                "Blocked": {
                  "type": "boolean"
                }
              }
            },
            "PortProbeAction": {
              "type": "structure",
              "members": {
                "PortProbeDetails": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "LocalPortDetails": {
                        "shape": "Sgb"
                      },
                      "LocalIpDetails": {
                        "type": "structure",
                        "members": {
                          "IpAddressV4": {}
                        }
                      },
                      "RemoteIpDetails": {
                        "shape": "Sg5"
                      }
                    }
                  }
                },
                "Blocked": {
                  "type": "boolean"
                }
              }
            }
          }
        },
        "FindingProviderFields": {
          "type": "structure",
          "members": {
            "Confidence": {
              "type": "integer"
            },
            "Criticality": {
              "type": "integer"
            },
            "RelatedFindings": {
              "shape": "Sfq"
            },
            "Severity": {
              "type": "structure",
              "members": {
                "Label": {},
                "Original": {}
              }
            },
            "Types": {
              "shape": "Sm"
            }
          }
        },
        "Sample": {
          "type": "boolean"
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "structure",
      "members": {
        "Begin": {
          "type": "integer"
        },
        "End": {
          "type": "integer"
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "Protocol": {},
        "Destination": {
          "shape": "S14"
        },
        "Source": {
          "shape": "S14"
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "Address": {
          "shape": "S15"
        },
        "PortRanges": {
          "type": "list",
          "member": {
            "shape": "S10"
          }
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S1o": {
      "type": "structure",
      "members": {
        "LineRanges": {
          "shape": "S1p"
        },
        "OffsetRanges": {
          "shape": "S1p"
        },
        "Pages": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PageNumber": {
                "type": "long"
              },
              "LineRange": {
                "shape": "S1q"
              },
              "OffsetRange": {
                "shape": "S1q"
              }
            }
          }
        },
        "Records": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "JsonPath": {},
              "RecordIndex": {
                "type": "long"
              }
            }
          }
        },
        "Cells": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Column": {
                "type": "long"
              },
              "Row": {
                "type": "long"
              },
              "ColumnName": {},
              "CellReference": {}
            }
          }
        }
      }
    },
    "S1p": {
      "type": "list",
      "member": {
        "shape": "S1q"
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "Start": {
          "type": "long"
        },
        "End": {
          "type": "long"
        },
        "StartColumn": {
          "type": "long"
        }
      }
    },
    "S2c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ArtifactIdentifier": {},
          "EncryptionDisabled": {
            "type": "boolean"
          },
          "Location": {},
          "Name": {},
          "NamespaceType": {},
          "OverrideArtifactName": {
            "type": "boolean"
          },
          "Packaging": {},
          "Path": {},
          "Type": {}
        }
      }
    },
    "S2n": {
      "type": "list",
      "member": {}
    },
    "S3h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "IpProtocol": {},
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupId": {},
                "GroupName": {},
                "PeeringStatus": {},
                "UserId": {},
                "VpcId": {},
                "VpcPeeringConnectionId": {}
              }
            }
          },
          "IpRanges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CidrIp": {}
              }
            }
          },
          "Ipv6Ranges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CidrIpv6": {}
              }
            }
          },
          "PrefixListIds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PrefixListId": {}
              }
            }
          }
        }
      }
    },
    "S3x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AssociationId": {},
          "Ipv6CidrBlock": {},
          "CidrBlockState": {}
        }
      }
    },
    "S4r": {
      "type": "structure",
      "members": {
        "CloudWatchLogsLogGroupArn": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "BlockPublicAcls": {
          "type": "boolean"
        },
        "BlockPublicPolicy": {
          "type": "boolean"
        },
        "IgnorePublicAcls": {
          "type": "boolean"
        },
        "RestrictPublicBuckets": {
          "type": "boolean"
        }
      }
    },
    "S65": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PolicyName": {},
          "PolicyArn": {}
        }
      }
    },
    "S67": {
      "type": "structure",
      "members": {
        "PermissionsBoundaryArn": {},
        "PermissionsBoundaryType": {}
      }
    },
    "S6e": {
      "type": "structure",
      "members": {
        "DetailedMetricsEnabled": {
          "type": "boolean"
        },
        "LoggingLevel": {},
        "DataTraceEnabled": {
          "type": "boolean"
        },
        "ThrottlingBurstLimit": {
          "type": "integer"
        },
        "ThrottlingRateLimit": {
          "type": "double"
        }
      }
    },
    "S6f": {
      "type": "structure",
      "members": {
        "Format": {},
        "DestinationArn": {}
      }
    },
    "S6p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AttributeName": {},
          "KeyType": {}
        }
      }
    },
    "S6r": {
      "type": "structure",
      "members": {
        "NonKeyAttributes": {
          "shape": "S15"
        },
        "ProjectionType": {}
      }
    },
    "S6s": {
      "type": "structure",
      "members": {
        "LastDecreaseDateTime": {},
        "LastIncreaseDateTime": {},
        "NumberOfDecreasesToday": {
          "type": "integer"
        },
        "ReadCapacityUnits": {
          "type": "integer"
        },
        "WriteCapacityUnits": {
          "type": "integer"
        }
      }
    },
    "S6z": {
      "type": "structure",
      "members": {
        "ReadCapacityUnits": {
          "type": "integer"
        }
      }
    },
    "S7e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DomainName": {},
          "ResourceRecord": {
            "type": "structure",
            "members": {
              "Name": {},
              "Type": {},
              "Value": {}
            }
          },
          "ValidationDomain": {},
          "ValidationEmails": {
            "shape": "S15"
          },
          "ValidationMethod": {},
          "ValidationStatus": {}
        }
      }
    },
    "S9m": {
      "type": "structure",
      "members": {
        "Address": {},
        "Port": {
          "type": "integer"
        },
        "HostedZoneId": {}
      }
    },
    "S9n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S9x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "Sa3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Domain": {},
          "Status": {},
          "Fqdn": {},
          "IamRoleName": {}
        }
      }
    },
    "Sd5": {
      "type": "list",
      "member": {
        "type": "integer"
      }
    },
    "Sdi": {
      "type": "structure",
      "members": {
        "CloudWatchLogsLogGroupArn": {},
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "Se7": {
      "type": "structure",
      "members": {
        "PublishMetricAction": {
          "type": "structure",
          "members": {
            "Dimensions": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Value": {}
                }
              }
            }
          }
        }
      }
    },
    "Sfi": {
      "type": "list",
      "member": {}
    },
    "Sfq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ProductArn",
          "Id"
        ],
        "members": {
          "ProductArn": {},
          "Id": {}
        }
      }
    },
    "Sg5": {
      "type": "structure",
      "members": {
        "IpAddressV4": {},
        "Organization": {
          "type": "structure",
          "members": {
            "Asn": {
              "type": "integer"
            },
            "AsnOrg": {},
            "Isp": {},
            "Org": {}
          }
        },
        "Country": {
          "type": "structure",
          "members": {
            "CountryCode": {},
            "CountryName": {}
          }
        },
        "City": {
          "type": "structure",
          "members": {
            "CityName": {}
          }
        },
        "GeoLocation": {
          "type": "structure",
          "members": {
            "Lon": {
              "type": "double"
            },
            "Lat": {
              "type": "double"
            }
          }
        }
      }
    },
    "Sgb": {
      "type": "structure",
      "members": {
        "Port": {
          "type": "integer"
        },
        "PortName": {}
      }
    },
    "Sgq": {
      "type": "list",
      "member": {
        "shape": "Sgr"
      }
    },
    "Sgr": {
      "type": "structure",
      "required": [
        "Id",
        "ProductArn"
      ],
      "members": {
        "Id": {},
        "ProductArn": {}
      }
    },
    "Sgs": {
      "type": "structure",
      "required": [
        "Text",
        "UpdatedBy"
      ],
      "members": {
        "Text": {},
        "UpdatedBy": {}
      }
    },
    "Sh3": {
      "type": "structure",
      "members": {
        "ProductArn": {
          "shape": "Sh4"
        },
        "AwsAccountId": {
          "shape": "Sh4"
        },
        "Id": {
          "shape": "Sh4"
        },
        "GeneratorId": {
          "shape": "Sh4"
        },
        "Region": {
          "shape": "Sh4"
        },
        "Type": {
          "shape": "Sh4"
        },
        "FirstObservedAt": {
          "shape": "Sh7"
        },
        "LastObservedAt": {
          "shape": "Sh7"
        },
        "CreatedAt": {
          "shape": "Sh7"
        },
        "UpdatedAt": {
          "shape": "Sh7"
        },
        "SeverityProduct": {
          "shape": "Shb",
          "deprecated": true,
          "deprecatedMessage": "This filter is deprecated. Instead, use FindingProviderSeverityOriginal."
        },
        "SeverityNormalized": {
          "shape": "Shb",
          "deprecated": true,
          "deprecatedMessage": "This filter is deprecated. Instead, use SeverityLabel or FindingProviderFieldsSeverityLabel."
        },
        "SeverityLabel": {
          "shape": "Sh4"
        },
        "Confidence": {
          "shape": "Shb"
        },
        "Criticality": {
          "shape": "Shb"
        },
        "Title": {
          "shape": "Sh4"
        },
        "Description": {
          "shape": "Sh4"
        },
        "RecommendationText": {
          "shape": "Sh4"
        },
        "SourceUrl": {
          "shape": "Sh4"
        },
        "ProductFields": {
          "shape": "Shd"
        },
        "ProductName": {
          "shape": "Sh4"
        },
        "CompanyName": {
          "shape": "Sh4"
        },
        "UserDefinedFields": {
          "shape": "Shd"
        },
        "MalwareName": {
          "shape": "Sh4"
        },
        "MalwareType": {
          "shape": "Sh4"
        },
        "MalwarePath": {
          "shape": "Sh4"
        },
        "MalwareState": {
          "shape": "Sh4"
        },
        "NetworkDirection": {
          "shape": "Sh4"
        },
        "NetworkProtocol": {
          "shape": "Sh4"
        },
        "NetworkSourceIpV4": {
          "shape": "Shg"
        },
        "NetworkSourceIpV6": {
          "shape": "Shg"
        },
        "NetworkSourcePort": {
          "shape": "Shb"
        },
        "NetworkSourceDomain": {
          "shape": "Sh4"
        },
        "NetworkSourceMac": {
          "shape": "Sh4"
        },
        "NetworkDestinationIpV4": {
          "shape": "Shg"
        },
        "NetworkDestinationIpV6": {
          "shape": "Shg"
        },
        "NetworkDestinationPort": {
          "shape": "Shb"
        },
        "NetworkDestinationDomain": {
          "shape": "Sh4"
        },
        "ProcessName": {
          "shape": "Sh4"
        },
        "ProcessPath": {
          "shape": "Sh4"
        },
        "ProcessPid": {
          "shape": "Shb"
        },
        "ProcessParentPid": {
          "shape": "Shb"
        },
        "ProcessLaunchedAt": {
          "shape": "Sh7"
        },
        "ProcessTerminatedAt": {
          "shape": "Sh7"
        },
        "ThreatIntelIndicatorType": {
          "shape": "Sh4"
        },
        "ThreatIntelIndicatorValue": {
          "shape": "Sh4"
        },
        "ThreatIntelIndicatorCategory": {
          "shape": "Sh4"
        },
        "ThreatIntelIndicatorLastObservedAt": {
          "shape": "Sh7"
        },
        "ThreatIntelIndicatorSource": {
          "shape": "Sh4"
        },
        "ThreatIntelIndicatorSourceUrl": {
          "shape": "Sh4"
        },
        "ResourceType": {
          "shape": "Sh4"
        },
        "ResourceId": {
          "shape": "Sh4"
        },
        "ResourcePartition": {
          "shape": "Sh4"
        },
        "ResourceRegion": {
          "shape": "Sh4"
        },
        "ResourceTags": {
          "shape": "Shd"
        },
        "ResourceAwsEc2InstanceType": {
          "shape": "Sh4"
        },
        "ResourceAwsEc2InstanceImageId": {
          "shape": "Sh4"
        },
        "ResourceAwsEc2InstanceIpV4Addresses": {
          "shape": "Shg"
        },
        "ResourceAwsEc2InstanceIpV6Addresses": {
          "shape": "Shg"
        },
        "ResourceAwsEc2InstanceKeyName": {
          "shape": "Sh4"
        },
        "ResourceAwsEc2InstanceIamInstanceProfileArn": {
          "shape": "Sh4"
        },
        "ResourceAwsEc2InstanceVpcId": {
          "shape": "Sh4"
        },
        "ResourceAwsEc2InstanceSubnetId": {
          "shape": "Sh4"
        },
        "ResourceAwsEc2InstanceLaunchedAt": {
          "shape": "Sh7"
        },
        "ResourceAwsS3BucketOwnerId": {
          "shape": "Sh4"
        },
        "ResourceAwsS3BucketOwnerName": {
          "shape": "Sh4"
        },
        "ResourceAwsIamAccessKeyUserName": {
          "shape": "Sh4",
          "deprecated": true,
          "deprecatedMessage": "This filter is deprecated. Instead, use ResourceAwsIamAccessKeyPrincipalName."
        },
        "ResourceAwsIamAccessKeyPrincipalName": {
          "shape": "Sh4"
        },
        "ResourceAwsIamAccessKeyStatus": {
          "shape": "Sh4"
        },
        "ResourceAwsIamAccessKeyCreatedAt": {
          "shape": "Sh7"
        },
        "ResourceAwsIamUserUserName": {
          "shape": "Sh4"
        },
        "ResourceContainerName": {
          "shape": "Sh4"
        },
        "ResourceContainerImageId": {
          "shape": "Sh4"
        },
        "ResourceContainerImageName": {
          "shape": "Sh4"
        },
        "ResourceContainerLaunchedAt": {
          "shape": "Sh7"
        },
        "ResourceDetailsOther": {
          "shape": "Shd"
        },
        "ComplianceStatus": {
          "shape": "Sh4"
        },
        "VerificationState": {
          "shape": "Sh4"
        },
        "WorkflowState": {
          "shape": "Sh4"
        },
        "WorkflowStatus": {
          "shape": "Sh4"
        },
        "RecordState": {
          "shape": "Sh4"
        },
        "RelatedFindingsProductArn": {
          "shape": "Sh4"
        },
        "RelatedFindingsId": {
          "shape": "Sh4"
        },
        "NoteText": {
          "shape": "Sh4"
        },
        "NoteUpdatedAt": {
          "shape": "Sh7"
        },
        "NoteUpdatedBy": {
          "shape": "Sh4"
        },
        "Keyword": {
          "deprecated": true,
          "deprecatedMessage": "The Keyword property is deprecated.",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Value": {}
            }
          }
        },
        "FindingProviderFieldsConfidence": {
          "shape": "Shb"
        },
        "FindingProviderFieldsCriticality": {
          "shape": "Shb"
        },
        "FindingProviderFieldsRelatedFindingsId": {
          "shape": "Sh4"
        },
        "FindingProviderFieldsRelatedFindingsProductArn": {
          "shape": "Sh4"
        },
        "FindingProviderFieldsSeverityLabel": {
          "shape": "Sh4"
        },
        "FindingProviderFieldsSeverityOriginal": {
          "shape": "Sh4"
        },
        "FindingProviderFieldsTypes": {
          "shape": "Sh4"
        },
        "Sample": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Value": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "Sh4": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Value": {},
          "Comparison": {}
        }
      }
    },
    "Sh7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Start": {},
          "End": {},
          "DateRange": {
            "type": "structure",
            "members": {
              "Value": {
                "type": "integer"
              },
              "Unit": {}
            }
          }
        }
      }
    },
    "Shb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Gte": {
            "type": "double"
          },
          "Lte": {
            "type": "double"
          },
          "Eq": {
            "type": "double"
          }
        }
      }
    },
    "Shd": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "Comparison": {}
        }
      }
    },
    "Shg": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Cidr": {}
        }
      }
    },
    "Shs": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "ProcessingResult": {}
        }
      }
    },
    "Shv": {
      "type": "list",
      "member": {}
    },
    "Si8": {
      "type": "list",
      "member": {}
    },
    "Siy": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sjh": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sjn": {
      "type": "structure",
      "members": {
        "AccountId": {},
        "InvitationId": {},
        "InvitedAt": {
          "shape": "Siy"
        },
        "MemberStatus": {}
      }
    },
    "Skd": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "Email": {},
          "MasterId": {
            "deprecated": true,
            "deprecatedMessage": "This field is deprecated, use AdministratorId instead."
          },
          "AdministratorId": {},
          "MemberStatus": {},
          "InvitedAt": {
            "shape": "Siy"
          },
          "UpdatedAt": {
            "shape": "Siy"
          }
        }
      }
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-06-24",
    "endpointPrefix": "servicecatalog-appregistry",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AppRegistry",
    "serviceFullName": "AWS Service Catalog App Registry",
    "serviceId": "Service Catalog AppRegistry",
    "signatureVersion": "v4",
    "signingName": "servicecatalog",
    "uid": "AWS242AppRegistry-2020-06-24"
  },
  "operations": {
    "AssociateAttributeGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{application}/attribute-groups/{attributeGroup}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "attributeGroup"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "attributeGroup": {
            "location": "uri",
            "locationName": "attributeGroup"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "attributeGroupArn": {}
        }
      }
    },
    "AssociateResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{application}/resources/{resourceType}/{resource}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "resourceType",
          "resource"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "resourceType": {
            "location": "uri",
            "locationName": "resourceType"
          },
          "resource": {
            "location": "uri",
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "resourceArn": {}
        }
      }
    },
    "CreateApplication": {
      "http": {
        "requestUri": "/applications",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "clientToken"
        ],
        "members": {
          "name": {},
          "description": {},
          "tags": {
            "shape": "Sf"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "application": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateAttributeGroup": {
      "http": {
        "requestUri": "/attribute-groups",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "attributes",
          "clientToken"
        ],
        "members": {
          "name": {},
          "description": {},
          "attributes": {},
          "tags": {
            "shape": "Sf"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributeGroup": {
            "shape": "Sq"
          }
        }
      }
    },
    "DeleteApplication": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{application}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "application": {
            "shape": "Su"
          }
        }
      }
    },
    "DeleteAttributeGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/attribute-groups/{attributeGroup}"
      },
      "input": {
        "type": "structure",
        "required": [
          "attributeGroup"
        ],
        "members": {
          "attributeGroup": {
            "location": "uri",
            "locationName": "attributeGroup"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributeGroup": {
            "shape": "Sx"
          }
        }
      }
    },
    "DisassociateAttributeGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{application}/attribute-groups/{attributeGroup}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "attributeGroup"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "attributeGroup": {
            "location": "uri",
            "locationName": "attributeGroup"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "attributeGroupArn": {}
        }
      }
    },
    "DisassociateResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{application}/resources/{resourceType}/{resource}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "resourceType",
          "resource"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "resourceType": {
            "location": "uri",
            "locationName": "resourceType"
          },
          "resource": {
            "location": "uri",
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "resourceArn": {}
        }
      }
    },
    "GetApplication": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{application}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "id": {},
          "arn": {},
          "name": {},
          "description": {},
          "creationTime": {
            "shape": "Sm"
          },
          "lastUpdateTime": {
            "shape": "Sm"
          },
          "associatedResourceCount": {
            "type": "integer"
          },
          "tags": {
            "shape": "Sf"
          },
          "integrations": {
            "type": "structure",
            "members": {
              "resourceGroup": {
                "shape": "S16"
              }
            }
          }
        }
      }
    },
    "GetAssociatedResource": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{application}/resources/{resourceType}/{resource}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "resourceType",
          "resource"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "resourceType": {
            "location": "uri",
            "locationName": "resourceType"
          },
          "resource": {
            "location": "uri",
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resource": {
            "type": "structure",
            "members": {
              "name": {},
              "arn": {},
              "associationTime": {
                "shape": "Sm"
              },
              "integrations": {
                "type": "structure",
                "members": {
                  "resourceGroup": {
                    "shape": "S16"
                  }
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "GetAttributeGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/attribute-groups/{attributeGroup}"
      },
      "input": {
        "type": "structure",
        "required": [
          "attributeGroup"
        ],
        "members": {
          "attributeGroup": {
            "location": "uri",
            "locationName": "attributeGroup"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "id": {},
          "arn": {},
          "name": {},
          "description": {},
          "attributes": {},
          "creationTime": {
            "shape": "Sm"
          },
          "lastUpdateTime": {
            "shape": "Sm"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      }
    },
    "ListApplications": {
      "http": {
        "method": "GET",
        "requestUri": "/applications"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applications": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListAssociatedAttributeGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{application}/attribute-groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributeGroups": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListAssociatedResources": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{application}/resources"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListAttributeGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/attribute-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributeGroups": {
            "type": "list",
            "member": {
              "shape": "Sx"
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sf"
          }
        }
      }
    },
    "SyncResource": {
      "http": {
        "requestUri": "/sync/{resourceType}/{resource}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceType",
          "resource"
        ],
        "members": {
          "resourceType": {
            "location": "uri",
            "locationName": "resourceType"
          },
          "resource": {
            "location": "uri",
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "resourceArn": {},
          "actionTaken": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApplication": {
      "http": {
        "method": "PATCH",
        "requestUri": "/applications/{application}"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {
            "location": "uri",
            "locationName": "application"
          },
          "name": {
            "deprecated": true,
            "deprecatedMessage": "Name update for application is deprecated."
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "application": {
            "shape": "Sk"
          }
        }
      }
    },
    "UpdateAttributeGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/attribute-groups/{attributeGroup}"
      },
      "input": {
        "type": "structure",
        "required": [
          "attributeGroup"
        ],
        "members": {
          "attributeGroup": {
            "location": "uri",
            "locationName": "attributeGroup"
          },
          "name": {
            "deprecated": true,
            "deprecatedMessage": "Name update for attribute group is deprecated."
          },
          "description": {},
          "attributes": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributeGroup": {
            "shape": "Sq"
          }
        }
      }
    }
  },
  "shapes": {
    "Sf": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sk": {
      "type": "structure",
      "members": {
        "id": {},
        "arn": {},
        "name": {},
        "description": {},
        "creationTime": {
          "shape": "Sm"
        },
        "lastUpdateTime": {
          "shape": "Sm"
        },
        "tags": {
          "shape": "Sf"
        }
      }
    },
    "Sm": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sq": {
      "type": "structure",
      "members": {
        "id": {},
        "arn": {},
        "name": {},
        "description": {},
        "creationTime": {
          "shape": "Sm"
        },
        "lastUpdateTime": {
          "shape": "Sm"
        },
        "tags": {
          "shape": "Sf"
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "id": {},
        "arn": {},
        "name": {},
        "description": {},
        "creationTime": {
          "shape": "Sm"
        },
        "lastUpdateTime": {
          "shape": "Sm"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "id": {},
        "arn": {},
        "name": {},
        "description": {},
        "creationTime": {
          "shape": "Sm"
        },
        "lastUpdateTime": {
          "shape": "Sm"
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "state": {},
        "arn": {},
        "errorMessage": {}
      }
    }
  }
}
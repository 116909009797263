import { action } from 'mobx'
import rootStore from '~/src/app/store'
import { Quaternion, Vector3, Color3, Matrix } from '@babylonjs/core/Maths/math'
import { GizmoManager } from '@babylonjs/core/Gizmos/gizmoManager'
// import { TransformNode } from '@babylonjs/core/Meshes/transformNode'
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial'
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder'
import {
  disposeGizmoManager,
  styleRotationGizmo,
  stylePositionGizmo,
} from '../mixins'
import {
  nuke,
  getRotation,
  getPivotWrapper,
  getTransformWrapper,
} from '~/src/utils/nodes'
import { getToolState, removeToolState } from '~/src/utils/tools'
import { isEmpty, assign, forEach } from 'lodash'

// debug
import { toJS } from 'mobx'

class RotationTool {
  key = 'rotation'

  // scene management state
  node = null
  wrapper = null
  pivot = null
  state = null
  lastRotation = null
  totalRotation = Vector3.Zero()
  pivotMarker = null

  constructor() {}

  attachToScene(node, state) {
    const container = getTransformWrapper(node)
    const wrapper = getPivotWrapper(node)
    assign(this, { node, wrapper, container, state })
    this.setupPivotMarker()
    this.setupGizmos()
    this.activateRotationMode()
    this.attachObservables()
    rootStore.appState.hideLabels()
    this.totalRotation = Vector3.Zero()
    // update the floating toolbar position when moving the part
    this.wrapper.onAfterWorldMatrixUpdateObservable.add(
      this.updateToolbarPosition,
    )
  }

  dettachFromScene(node, _state) {
    this.gizmoManager.attachToMesh(null)
    disposeGizmoManager(this.gizmoManager)
    this.disposeObservables()
    this.gizmoManager = null
    this.wrapper.onAfterWorldMatrixUpdateObservable.removeCallback(
      this.updateToolbarPosition,
    )
    // pivot marker
    nuke(this.pivotMarker)
    // state
    assign(this, { node: null, state: null, pivotMarker: null })
    rootStore.appState.showLabels()
  }

  setupPivotMarker() {
    const scale = rootStore.modelRepository.getScaleForNode(this.node)
    this.pivotMarker = MeshBuilder.CreateSphere('marker', {
      diameter: 0.03 / scale,
    })
    this.pivotMarker.material = new StandardMaterial('pivot-marker-material')
    this.pivotMarker.material.diffuseColor = Color3.White()
    this.pivotMarker.material.emissiveColor = Color3.White()
    this.pivotMarker.isPickable = false
    this.pivotMarker.parent = this.container
    this.pivotMarker.rotation.copyFrom(getRotation(this.wrapper))
    this.pivotMarker.position.copyFrom(this.wrapper.position)
  }

  updatePivotMarker() {
    this.pivotMarker.rotation.copyFrom(getRotation(this.wrapper))
    this.pivotMarker.position.copyFrom(this.wrapper.position)
  }

  setupGizmos(node, state) {
    const {
      sceneManager: { scene },
    } = rootStore
    this.gizmoManager = new GizmoManager(scene, 2)
    this.gizmoManager.usePointerToAttachGizmos = false
    this.gizmoManager.attachToMesh(this.pivotMarker)
  }

  // rotation mode

  activateRotationMode() {
    const {
      undo,
      sceneManager: { scene },
    } = rootStore
    this.deactivatePivotMode()
    this.gizmoManager.rotationGizmoEnabled = true
    styleRotationGizmo(this.gizmoManager)
    // store original node position (if not already saved)
    const { rotationGizmo } = this.gizmoManager.gizmos
    rotationGizmo.snapDistance = 0.001

    rotationGizmo.onDragStartObservable.add(() => {
      undo.saveSnapshot()
      const startRotation = this.wrapper.rotation.clone()
      // reference
      this.maybeStoreReferenceRotation(this.state, startRotation)
      // accumulators
      this.totalRotation = Vector3.Zero()
      // snap observables
      const { xGizmo, yGizmo, zGizmo } = rotationGizmo
      xGizmo.onSnapObservable.add(e => this.updateTotalRotation('x', xGizmo, e))
      yGizmo.onSnapObservable.add(e => this.updateTotalRotation('y', yGizmo, e))
      zGizmo.onSnapObservable.add(e => this.updateTotalRotation('z', zGizmo, e))
    })

    // calculate the total rotation delta (against the stored original reference)
    rotationGizmo.onDragEndObservable.add((...args) => {
      // clear snap observables
      rotationGizmo.xGizmo.onSnapObservable.clear()
      rotationGizmo.yGizmo.onSnapObservable.clear()
      rotationGizmo.zGizmo.onSnapObservable.clear()
      this.storeTotalDelta(this.state, this.totalRotation)
      this.totalRotation = Vector3.Zero()
      rootStore.appState.updateStepThumbnail()
    })
  }

  deactivateRotationMode() {
    const { rotationGizmo } = this.gizmoManager.gizmos
    if (!rotationGizmo) return
    rotationGizmo.onDragStartObservable.clear()
    rotationGizmo.onDragEndObservable.clear()
    this.gizmoManager.rotationGizmoEnabled = false
  }

  updateTotalRotation = (axis, planeGizmo, { snapDistance }) => {
    const {
      sceneManager: { camera },
    } = rootStore
    // -- BEGIN cameraFlip calculations --
    // all this shit just to counter-transform the angle when the camera is behind the gizmo :,(
    const nodeTranslation = Vector3.Zero()
    const nodeQuaternion = new Quaternion(0, 0, 0, 1)
    const _nodeScale = new Vector3(1, 1, 1)
    planeGizmo.attachedNode
      .getWorldMatrix()
      .decompose(_nodeScale, nodeQuaternion, nodeTranslation)
    const planeNormals = {
      x: new Vector3(1, 0, 0),
      y: new Vector3(0, 1, 0),
      z: new Vector3(0, 0, 1),
    }
    const rotationMatrix = new Matrix()
    nodeQuaternion.toRotationMatrix(rotationMatrix)
    const planeNormalTowardsCamera = Vector3.TransformCoordinates(
      planeNormals[axis],
      rotationMatrix,
    )
    const camVec = camera.position.subtract(nodeTranslation)
    if (Vector3.Dot(camVec, planeNormalTowardsCamera) > 0) {
      // CAMERA FLIPPED!
      snapDistance = -snapDistance
    }
    // -- END cameraFlip calculations --
    this.totalRotation[axis] += snapDistance
  }

  // set pivot mode

  get hasRotationApplied() {
    return !!this.state.delta
  }

  get hasScaleApplied() {
    return !this.wrapper.scaling.equals(Vector3.One())
  }

  activatePivotMode() {
    const {
      undo,
      sceneManager: { scene },
    } = rootStore
    if (this.hasRotationApplied || this.hasScaleApplied) {
      this.promptPivotWarningModal()
    } else {
      this.deactivateRotationMode()
      this.gizmoManager.positionGizmoEnabled = true
      stylePositionGizmo(this.gizmoManager)
      // store original node position (if not already saved)
      const { positionGizmo } = this.gizmoManager.gizmos
      positionGizmo.onDragStartObservable.add(() => {
        undo.saveSnapshot('Pivot point')
        // reference
        const startPivot = this.pivotMarker.position
        this.maybeStoreReferencePivot(this.state, startPivot)
      })
      // calculate the total positiondelta (against the stored original reference)
      positionGizmo.onDragEndObservable.add(() => {
        const pivot = this.pivotMarker.position.clone()
        this.storePivot(this.state, pivot)
      })
    }
  }

  deactivatePivotMode() {
    const { positionGizmo } = this.gizmoManager.gizmos
    if (!positionGizmo) return
    positionGizmo.onDragStartObservable.clear()
    positionGizmo.onDragEndObservable.clear()
    this.gizmoManager.positionGizmoEnabled = false
  }

  promptPivotWarningModal = action(() => {
    // affects external code (PivotWarningAlert)
    const { appState } = rootStore
    appState.showPivotWarningModal = true
  })

  resetPartRotation = action((node, step) => {
    // called from external code (PivotWarningAlert)
    const state = getToolState(node, step, this)
    this.resetState(node, state)
    removeToolState(node, step, this)
  })

  // aux

  updateToolbarPosition = () => {
    const { toolbar } = rootStore
    toolbar.updateFloatingPosition()
  }

  // observables

  attachObservables() {
    const {
      sceneManager: { scene },
    } = rootStore
    document.addEventListener('keydown', this.onKeyEvent)
    document.addEventListener('keyup', this.onKeyEvent)
    scene.onBeforeRenderObservable.add(this.updateNodeRotation)
  }

  disposeObservables() {
    const {
      sceneManager: { scene },
    } = rootStore
    document.removeEventListener('keydown', this.onKeyEvent)
    document.removeEventListener('keyup', this.onKeyEvent)
    scene.onBeforeRenderObservable.removeCallback(this.updateNodeRotation)
  }

  // TODO: find a way to abstract/generalize this a bit (shortuctManager or something)

  onKeyEvent = e => {
    if (event.key === 'Shift') {
      if (event.type === 'keydown') this.activatePivotMode()
      else if (event.type === 'keyup') this.activateRotationMode()
    }
  }

  updateNodeRotation = () => {
    const pivot = this.pivotMarker.position
    this.wrapper.position.copyFrom(pivot)
    this.node.position = Vector3.Zero().subtract(pivot)

    // this.node.rotation = this.pivotMarker.rotation.clone()
    const originalRotation = Vector3.FromArray(
      this.state.originalRotation || getRotation(this.wrapper).asArray(),
    )
    const accumulatedDelta = this.totalRotation.add(
      this.state.delta ? Vector3.FromArray(this.state.delta) : Vector3.Zero(),
    )
    const finalRotation = accumulatedDelta.add(originalRotation)
    this.wrapper.rotation = finalRotation
  }

  // data

  maybeStoreReferenceRotation(state, startRotation) {
    // never overwrite originalPosition if present!
    if (!state.originalRotation) {
      state.originalRotation = startRotation.asArray()
    }
  }

  maybeStoreReferencePivot(state, startPivot) {
    if (!state.originalPivot) {
      state.originalPivot = startPivot.asArray()
    }
  }

  storeTotalDelta = action((state, rotationDelta) => {
    const originalRotation = Vector3.FromArray(state.originalRotation)
    const accumulatedDelta = rotationDelta.add(
      state.delta ? Vector3.FromArray(state.delta) : Vector3.Zero(),
    )
    const finalRotation = accumulatedDelta.add(originalRotation)
    state.rotation = finalRotation.asArray()
    state.delta = accumulatedDelta.asArray()
  })

  storePivot = action((state, pivotPosition) => {
    state.pivot = pivotPosition.asArray()
  })

  resetState = action((node, state) => {
    assign(state, {
      rotation: undefined,
      delta: undefined,
      pivot: undefined,
    })
    const wrapper = getPivotWrapper(node)
    wrapper.position = Vector3.Zero()
    wrapper.rotation = Vector3.Zero()
    node.position = Vector3.Zero()
    // pivot marker
    this.updatePivotMarker()
  })

  // application

  onBeforeTransition(node, originState = {}, targetState = {}, refState) {
    // TODO: review this work with pivot points and convert it to translations within the wrapper
    const wrapper = getPivotWrapper(node)
    if (targetState.rotation && !targetState.pivot) {
      wrapper.position = Vector3.Zero()
      node.position = Vector3.Zero()
    } else {
      const targetPivot = Vector3.FromArray(
        targetState.pivot ||
          // this is is a bit weird, but we prefer originState.pivot over originalPivot
          originState.pivot ||
          originState.originalPivot || [0, 0, 0],
      )
      wrapper.position = targetPivot
      node.position = Vector3.Zero().subtract(targetPivot)
    }
  }

  onAfterTransition(node, originState, targetState, refState) {}

  applyToNode(node, originState = {}, targetState = {}, refState, t = 1) {
    const wrapper = getPivotWrapper(node)
    if (!targetState.rotation && !originState.originalRotation) return
    // if no target position, then restore the original position
    const { startRotation, startPivot } = refState
    const targetRotation = Vector3.FromArray(
      targetState.rotation || originState.originalRotation,
    )
    const rotation = Vector3.Lerp(startRotation, targetRotation, t)
    wrapper.rotation = rotation
  }

  resetToOriginal(node, originState = {}, targetState = {}) {
    const wrapper = getPivotWrapper(node)
    if (isEmpty(originState) && isEmpty(targetState)) return
    const originalRotation = Vector3.FromArray(
      targetState.originalRotation || originState.originalRotation || [0, 0, 0],
    )
    wrapper.rotation.copyFrom(originalRotation)
    const originalPivot = Vector3.FromArray(
      targetState.originalPivot || originState.originalPivot || [0, 0, 0],
    )
    wrapper.position = originalPivot
    node.position = Vector3.Zero().subtract(originalPivot)
  }

  getRefState(node, _originState, _targetState) {
    const wrapper = getPivotWrapper(node)
    return {
      startRotation: getRotation(wrapper),
      startPivot: wrapper.position.clone(),
    }
  }
}

export default new RotationTool()

{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-08-11",
    "endpointPrefix": "amplifyuibuilder",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Amplify UI Builder",
    "serviceId": "AmplifyUIBuilder",
    "signatureVersion": "v4",
    "signingName": "amplifyuibuilder",
    "uid": "amplifyuibuilder-2021-08-11"
  },
  "operations": {
    "CreateComponent": {
      "http": {
        "requestUri": "/app/{appId}/environment/{environmentName}/components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "componentToCreate",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "componentToCreate": {
            "type": "structure",
            "required": [
              "bindingProperties",
              "componentType",
              "name",
              "overrides",
              "properties",
              "variants"
            ],
            "members": {
              "bindingProperties": {
                "shape": "S4"
              },
              "children": {
                "shape": "S9"
              },
              "collectionProperties": {
                "shape": "Sn"
              },
              "componentType": {},
              "events": {
                "shape": "Sb"
              },
              "name": {},
              "overrides": {
                "shape": "Sv"
              },
              "properties": {
                "shape": "Sl"
              },
              "schemaVersion": {},
              "sourceId": {},
              "tags": {
                "shape": "Sx"
              },
              "variants": {
                "shape": "S10"
              }
            }
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          }
        },
        "payload": "componentToCreate"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S14"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "CreateTheme": {
      "http": {
        "requestUri": "/app/{appId}/environment/{environmentName}/themes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "themeToCreate"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "themeToCreate": {
            "type": "structure",
            "required": [
              "name",
              "values"
            ],
            "members": {
              "name": {},
              "overrides": {
                "shape": "S1a"
              },
              "tags": {
                "shape": "Sx"
              },
              "values": {
                "shape": "S1a"
              }
            }
          }
        },
        "payload": "themeToCreate"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S1e"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "DeleteComponent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app/{appId}/environment/{environmentName}/components/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "DeleteTheme": {
      "http": {
        "method": "DELETE",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "idempotent": true
    },
    "ExchangeCodeForToken": {
      "http": {
        "requestUri": "/tokens/{provider}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provider",
          "request"
        ],
        "members": {
          "provider": {
            "location": "uri",
            "locationName": "provider"
          },
          "request": {
            "type": "structure",
            "required": [
              "code",
              "redirectUri"
            ],
            "members": {
              "code": {
                "shape": "S1k"
              },
              "redirectUri": {}
            }
          }
        },
        "payload": "request"
      },
      "output": {
        "type": "structure",
        "required": [
          "accessToken",
          "expiresIn",
          "refreshToken"
        ],
        "members": {
          "accessToken": {
            "shape": "S1k"
          },
          "expiresIn": {
            "type": "integer"
          },
          "refreshToken": {
            "shape": "S1k"
          }
        }
      }
    },
    "ExportComponents": {
      "http": {
        "method": "GET",
        "requestUri": "/export/app/{appId}/environment/{environmentName}/components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "shape": "S14"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ExportThemes": {
      "http": {
        "method": "GET",
        "requestUri": "/export/app/{appId}/environment/{environmentName}/themes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetComponent": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/components/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "component": {
            "shape": "S14"
          }
        },
        "payload": "component"
      }
    },
    "GetTheme": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "theme": {
            "shape": "S1e"
          }
        },
        "payload": "theme"
      }
    },
    "ListComponents": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appId",
                "componentType",
                "environmentName",
                "id",
                "name"
              ],
              "members": {
                "appId": {},
                "componentType": {},
                "environmentName": {},
                "id": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThemes": {
      "http": {
        "method": "GET",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entities"
        ],
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "appId",
                "environmentName",
                "id",
                "name"
              ],
              "members": {
                "appId": {},
                "environmentName": {},
                "id": {},
                "name": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RefreshToken": {
      "http": {
        "requestUri": "/tokens/{provider}/refresh",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provider",
          "refreshTokenBody"
        ],
        "members": {
          "provider": {
            "location": "uri",
            "locationName": "provider"
          },
          "refreshTokenBody": {
            "type": "structure",
            "required": [
              "token"
            ],
            "members": {
              "token": {
                "shape": "S1k"
              }
            }
          }
        },
        "payload": "refreshTokenBody"
      },
      "output": {
        "type": "structure",
        "required": [
          "accessToken",
          "expiresIn"
        ],
        "members": {
          "accessToken": {
            "shape": "S1k"
          },
          "expiresIn": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateComponent": {
      "http": {
        "method": "PATCH",
        "requestUri": "/app/{appId}/environment/{environmentName}/components/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id",
          "updatedComponent"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "updatedComponent": {
            "type": "structure",
            "members": {
              "bindingProperties": {
                "shape": "S4"
              },
              "children": {
                "shape": "S9"
              },
              "collectionProperties": {
                "shape": "Sn"
              },
              "componentType": {},
              "events": {
                "shape": "Sb"
              },
              "id": {},
              "name": {},
              "overrides": {
                "shape": "Sv"
              },
              "properties": {
                "shape": "Sl"
              },
              "schemaVersion": {},
              "sourceId": {},
              "variants": {
                "shape": "S10"
              }
            }
          }
        },
        "payload": "updatedComponent"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S14"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    },
    "UpdateTheme": {
      "http": {
        "method": "PATCH",
        "requestUri": "/app/{appId}/environment/{environmentName}/themes/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "appId",
          "environmentName",
          "id",
          "updatedTheme"
        ],
        "members": {
          "appId": {
            "location": "uri",
            "locationName": "appId"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "environmentName": {
            "location": "uri",
            "locationName": "environmentName"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "updatedTheme": {
            "type": "structure",
            "required": [
              "values"
            ],
            "members": {
              "id": {},
              "name": {},
              "overrides": {
                "shape": "S1a"
              },
              "values": {
                "shape": "S1a"
              }
            }
          }
        },
        "payload": "updatedTheme"
      },
      "output": {
        "type": "structure",
        "members": {
          "entity": {
            "shape": "S1e"
          }
        },
        "payload": "entity"
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S4": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "bindingProperties": {
            "type": "structure",
            "members": {
              "bucket": {},
              "defaultValue": {},
              "field": {},
              "key": {},
              "model": {},
              "predicates": {
                "shape": "S7"
              },
              "userAttribute": {}
            }
          },
          "defaultValue": {},
          "type": {}
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "shape": "S8"
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "and": {
          "shape": "S7"
        },
        "field": {},
        "operand": {},
        "operator": {},
        "or": {
          "shape": "S7"
        }
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "componentType",
          "name",
          "properties"
        ],
        "members": {
          "children": {
            "shape": "S9"
          },
          "componentType": {},
          "events": {
            "shape": "Sb"
          },
          "name": {},
          "properties": {
            "shape": "Sl"
          },
          "sourceId": {}
        }
      }
    },
    "Sb": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "action": {},
          "bindingEvent": {},
          "parameters": {
            "type": "structure",
            "members": {
              "anchor": {
                "shape": "Se"
              },
              "fields": {
                "shape": "Sl"
              },
              "global": {
                "shape": "Se"
              },
              "id": {
                "shape": "Se"
              },
              "model": {},
              "state": {
                "type": "structure",
                "required": [
                  "componentName",
                  "property",
                  "set"
                ],
                "members": {
                  "componentName": {},
                  "property": {},
                  "set": {
                    "shape": "Se"
                  }
                }
              },
              "target": {
                "shape": "Se"
              },
              "type": {
                "shape": "Se"
              },
              "url": {
                "shape": "Se"
              }
            }
          }
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "bindingProperties": {
          "shape": "Sf"
        },
        "bindings": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "required": [
              "element",
              "property"
            ],
            "members": {
              "element": {},
              "property": {}
            }
          }
        },
        "collectionBindingProperties": {
          "shape": "Sf"
        },
        "componentName": {},
        "concat": {
          "type": "list",
          "member": {
            "shape": "Se"
          }
        },
        "condition": {
          "type": "structure",
          "members": {
            "else": {
              "shape": "Se"
            },
            "field": {},
            "operand": {},
            "operandType": {},
            "operator": {},
            "property": {},
            "then": {
              "shape": "Se"
            }
          }
        },
        "configured": {
          "type": "boolean"
        },
        "defaultValue": {},
        "event": {},
        "importedValue": {},
        "model": {},
        "property": {},
        "type": {},
        "userAttribute": {},
        "value": {}
      }
    },
    "Sf": {
      "type": "structure",
      "required": [
        "property"
      ],
      "members": {
        "field": {},
        "property": {}
      }
    },
    "Sl": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Se"
      }
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "model"
        ],
        "members": {
          "identifiers": {
            "type": "list",
            "member": {}
          },
          "model": {},
          "predicate": {
            "shape": "S8"
          },
          "sort": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "direction",
                "field"
              ],
              "members": {
                "direction": {},
                "field": {}
              }
            }
          }
        }
      }
    },
    "Sv": {
      "type": "map",
      "key": {},
      "value": {
        "type": "map",
        "key": {},
        "value": {}
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "overrides": {
            "shape": "Sv"
          },
          "variantValues": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "appId",
        "bindingProperties",
        "componentType",
        "createdAt",
        "environmentName",
        "id",
        "name",
        "overrides",
        "properties",
        "variants"
      ],
      "members": {
        "appId": {},
        "bindingProperties": {
          "shape": "S4"
        },
        "children": {
          "shape": "S9"
        },
        "collectionProperties": {
          "shape": "Sn"
        },
        "componentType": {},
        "createdAt": {
          "shape": "S15"
        },
        "environmentName": {},
        "events": {
          "shape": "Sb"
        },
        "id": {},
        "modifiedAt": {
          "shape": "S15"
        },
        "name": {},
        "overrides": {
          "shape": "Sv"
        },
        "properties": {
          "shape": "Sl"
        },
        "schemaVersion": {},
        "sourceId": {},
        "tags": {
          "shape": "Sx"
        },
        "variants": {
          "shape": "S10"
        }
      }
    },
    "S15": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S1a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "children": {
                "shape": "S1a"
              },
              "value": {}
            }
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "appId",
        "createdAt",
        "environmentName",
        "id",
        "name",
        "values"
      ],
      "members": {
        "appId": {},
        "createdAt": {
          "shape": "S15"
        },
        "environmentName": {},
        "id": {},
        "modifiedAt": {
          "shape": "S15"
        },
        "name": {},
        "overrides": {
          "shape": "S1a"
        },
        "tags": {
          "shape": "Sx"
        },
        "values": {
          "shape": "S1a"
        }
      }
    },
    "S1k": {
      "type": "string",
      "sensitive": true
    }
  }
}
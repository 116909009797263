{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-03-01",
    "endpointPrefix": "fsx",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon FSx",
    "serviceId": "FSx",
    "signatureVersion": "v4",
    "signingName": "fsx",
    "targetPrefix": "AWSSimbaAPIService_v20180301",
    "uid": "fsx-2018-03-01"
  },
  "operations": {
    "AssociateFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Aliases"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Aliases": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          }
        }
      }
    },
    "CancelDataRepositoryTask": {
      "input": {
        "type": "structure",
        "required": [
          "TaskId"
        ],
        "members": {
          "TaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lifecycle": {},
          "TaskId": {}
        }
      },
      "idempotent": true
    },
    "CopyBackup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceBackupId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SourceBackupId": {},
          "SourceRegion": {},
          "KmsKeyId": {},
          "CopyTags": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "So"
          }
        }
      },
      "idempotent": true
    },
    "CreateBackup": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          },
          "VolumeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "So"
          }
        }
      },
      "idempotent": true
    },
    "CreateDataRepositoryAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "FileSystemPath",
          "DataRepositoryPath"
        ],
        "members": {
          "FileSystemId": {},
          "FileSystemPath": {},
          "DataRepositoryPath": {},
          "BatchImportMetaDataOnCreate": {
            "type": "boolean"
          },
          "ImportedFileChunkSize": {
            "type": "integer"
          },
          "S3": {
            "shape": "S41"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S47"
          }
        }
      },
      "idempotent": true
    },
    "CreateDataRepositoryTask": {
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "FileSystemId",
          "Report"
        ],
        "members": {
          "Type": {},
          "Paths": {
            "shape": "S4b"
          },
          "FileSystemId": {},
          "Report": {
            "shape": "S4d"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataRepositoryTask": {
            "shape": "S4h"
          }
        }
      },
      "idempotent": true
    },
    "CreateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemType",
          "StorageCapacity",
          "SubnetIds"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemType": {},
          "StorageCapacity": {
            "type": "integer"
          },
          "StorageType": {},
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S4r"
          },
          "Tags": {
            "shape": "Sj"
          },
          "KmsKeyId": {},
          "WindowsConfiguration": {
            "shape": "S4t"
          },
          "LustreConfiguration": {
            "shape": "S4x"
          },
          "OntapConfiguration": {
            "type": "structure",
            "required": [
              "DeploymentType",
              "ThroughputCapacity"
            ],
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "DailyAutomaticBackupStartTime": {},
              "DeploymentType": {},
              "EndpointIpAddressRange": {},
              "FsxAdminPassword": {
                "shape": "S50"
              },
              "DiskIopsConfiguration": {
                "shape": "S3m"
              },
              "PreferredSubnetId": {},
              "RouteTableIds": {
                "shape": "S3p"
              },
              "ThroughputCapacity": {
                "type": "integer"
              },
              "WeeklyMaintenanceStartTime": {}
            }
          },
          "FileSystemTypeVersion": {},
          "OpenZFSConfiguration": {
            "shape": "S51"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateFileSystemFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "SubnetIds"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SubnetIds": {
            "shape": "S15"
          },
          "SecurityGroupIds": {
            "shape": "S4r"
          },
          "Tags": {
            "shape": "Sj"
          },
          "WindowsConfiguration": {
            "shape": "S4t"
          },
          "LustreConfiguration": {
            "shape": "S4x"
          },
          "StorageType": {},
          "KmsKeyId": {},
          "FileSystemTypeVersion": {},
          "OpenZFSConfiguration": {
            "shape": "S51"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "VolumeId": {},
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S3c"
          }
        }
      },
      "idempotent": true
    },
    "CreateStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Name"
        ],
        "members": {
          "ActiveDirectoryConfiguration": {
            "type": "structure",
            "required": [
              "NetBiosName"
            ],
            "members": {
              "NetBiosName": {},
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S4u"
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Name": {},
          "SvmAdminPassword": {
            "shape": "S50"
          },
          "Tags": {
            "shape": "Sj"
          },
          "RootVolumeSecurityStyle": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachine": {
            "shape": "S5e"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeType",
          "Name"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeType": {},
          "Name": {},
          "OntapConfiguration": {
            "shape": "S5l"
          },
          "Tags": {
            "shape": "Sj"
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "required": [
              "ParentVolumeId"
            ],
            "members": {
              "ParentVolumeId": {},
              "StorageCapacityReservationGiB": {
                "type": "integer"
              },
              "StorageCapacityQuotaGiB": {
                "type": "integer"
              },
              "RecordSizeKiB": {
                "type": "integer"
              },
              "DataCompressionType": {},
              "CopyTagsToSnapshots": {
                "type": "boolean"
              },
              "OriginSnapshot": {
                "type": "structure",
                "required": [
                  "SnapshotARN",
                  "CopyStrategy"
                ],
                "members": {
                  "SnapshotARN": {},
                  "CopyStrategy": {}
                }
              },
              "ReadOnly": {
                "type": "boolean"
              },
              "NfsExports": {
                "shape": "S32"
              },
              "UserAndGroupQuotas": {
                "shape": "S39"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2d"
          }
        }
      }
    },
    "CreateVolumeFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "Name"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "OntapConfiguration": {
            "shape": "S5l"
          },
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2d"
          }
        }
      }
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteDataRepositoryAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "DeleteDataInFileSystem"
        ],
        "members": {
          "AssociationId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "DeleteDataInFileSystem": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {},
          "Lifecycle": {},
          "DeleteDataInFileSystem": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DeleteFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              },
              "Options": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "Lifecycle": {},
          "WindowsResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "LustreResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "OpenZFSResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SnapshotId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "StorageVirtualMachineId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageVirtualMachineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachineId": {},
          "Lifecycle": {}
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "Options": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {},
          "Lifecycle": {},
          "OntapResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "Sj"
              }
            }
          }
        }
      }
    },
    "DescribeBackups": {
      "input": {
        "type": "structure",
        "members": {
          "BackupIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S6k"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backups": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDataRepositoryAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S6k"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "type": "list",
            "member": {
              "shape": "S47"
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "DescribeDataRepositoryTasks": {
      "input": {
        "type": "structure",
        "members": {
          "TaskIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataRepositoryTasks": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystems": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemIds": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystems": {
            "type": "list",
            "member": {
              "shape": "Sx"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "SnapshotIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "S3c"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStorageVirtualMachines": {
      "input": {
        "type": "structure",
        "members": {
          "StorageVirtualMachineIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachines": {
            "type": "list",
            "member": {
              "shape": "S5e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "VolumeIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "type": "list",
            "member": {
              "shape": "S2d"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisassociateFileSystemAliases": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId",
          "Aliases"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemId": {},
          "Aliases": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "shape": "S7"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sj"
          },
          "NextToken": {}
        }
      }
    },
    "ReleaseFileSystemNfsV3Locks": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      },
      "idempotent": true
    },
    "RestoreVolumeFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId",
          "SnapshotId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "SnapshotId": {},
          "Options": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateDataRepositoryAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ImportedFileChunkSize": {
            "type": "integer"
          },
          "S3": {
            "shape": "S41"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S47"
          }
        }
      },
      "idempotent": true
    },
    "UpdateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageCapacity": {
            "type": "integer"
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "ThroughputCapacity": {
                "type": "integer"
              },
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S8n"
              },
              "AuditLogConfiguration": {
                "shape": "S4w"
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "AutoImportPolicy": {},
              "DataCompressionType": {},
              "LogConfiguration": {
                "shape": "S4y"
              }
            }
          },
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "DailyAutomaticBackupStartTime": {},
              "FsxAdminPassword": {
                "shape": "S50"
              },
              "WeeklyMaintenanceStartTime": {},
              "DiskIopsConfiguration": {
                "shape": "S3m"
              },
              "ThroughputCapacity": {
                "type": "integer"
              }
            }
          },
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "CopyTagsToBackups": {
                "type": "boolean"
              },
              "CopyTagsToVolumes": {
                "type": "boolean"
              },
              "DailyAutomaticBackupStartTime": {},
              "ThroughputCapacity": {
                "type": "integer"
              },
              "WeeklyMaintenanceStartTime": {},
              "DiskIopsConfiguration": {
                "shape": "S3m"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sx"
          }
        }
      }
    },
    "UpdateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SnapshotId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "SnapshotId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "S3c"
          }
        }
      },
      "idempotent": true
    },
    "UpdateStorageVirtualMachine": {
      "input": {
        "type": "structure",
        "required": [
          "StorageVirtualMachineId"
        ],
        "members": {
          "ActiveDirectoryConfiguration": {
            "type": "structure",
            "members": {
              "SelfManagedActiveDirectoryConfiguration": {
                "shape": "S8n"
              }
            }
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "StorageVirtualMachineId": {},
          "SvmAdminPassword": {
            "shape": "S50"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageVirtualMachine": {
            "shape": "S5e"
          }
        }
      }
    },
    "UpdateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeId": {},
          "OntapConfiguration": {
            "type": "structure",
            "members": {
              "JunctionPath": {},
              "SecurityStyle": {},
              "SizeInMegabytes": {
                "type": "integer"
              },
              "StorageEfficiencyEnabled": {
                "type": "boolean"
              },
              "TieringPolicy": {
                "shape": "S2m"
              }
            }
          },
          "Name": {},
          "OpenZFSConfiguration": {
            "type": "structure",
            "members": {
              "StorageCapacityReservationGiB": {
                "type": "integer"
              },
              "StorageCapacityQuotaGiB": {
                "type": "integer"
              },
              "RecordSizeKiB": {
                "type": "integer"
              },
              "DataCompressionType": {},
              "NfsExports": {
                "shape": "S32"
              },
              "UserAndGroupQuotas": {
                "shape": "S39"
              },
              "ReadOnly": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volume": {
            "shape": "S2d"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Lifecycle": {}
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "BackupId",
        "Lifecycle",
        "Type",
        "CreationTime",
        "FileSystem"
      ],
      "members": {
        "BackupId": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Type": {},
        "ProgressPercent": {
          "type": "integer"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "FileSystem": {
          "shape": "Sx"
        },
        "DirectoryInformation": {
          "type": "structure",
          "members": {
            "DomainName": {},
            "ActiveDirectoryId": {},
            "ResourceARN": {}
          }
        },
        "OwnerId": {},
        "SourceBackupId": {},
        "SourceBackupRegion": {},
        "ResourceType": {},
        "Volume": {
          "shape": "S2d"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "FileSystemType": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "StorageCapacity": {
          "type": "integer"
        },
        "StorageType": {},
        "VpcId": {},
        "SubnetIds": {
          "shape": "S15"
        },
        "NetworkInterfaceIds": {
          "type": "list",
          "member": {}
        },
        "DNSName": {},
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "WindowsConfiguration": {
          "type": "structure",
          "members": {
            "ActiveDirectoryId": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "shape": "S1c"
            },
            "DeploymentType": {},
            "RemoteAdministrationEndpoint": {},
            "PreferredSubnetId": {},
            "PreferredFileServerIp": {},
            "ThroughputCapacity": {
              "type": "integer"
            },
            "MaintenanceOperationsInProgress": {
              "type": "list",
              "member": {}
            },
            "WeeklyMaintenanceStartTime": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "Aliases": {
              "shape": "S7"
            },
            "AuditLogConfiguration": {
              "type": "structure",
              "required": [
                "FileAccessAuditLogLevel",
                "FileShareAccessAuditLogLevel"
              ],
              "members": {
                "FileAccessAuditLogLevel": {},
                "FileShareAccessAuditLogLevel": {},
                "AuditLogDestination": {}
              }
            }
          }
        },
        "LustreConfiguration": {
          "type": "structure",
          "members": {
            "WeeklyMaintenanceStartTime": {},
            "DataRepositoryConfiguration": {
              "type": "structure",
              "members": {
                "Lifecycle": {},
                "ImportPath": {},
                "ExportPath": {},
                "ImportedFileChunkSize": {
                  "type": "integer"
                },
                "AutoImportPolicy": {},
                "FailureDetails": {
                  "shape": "S1z"
                }
              }
            },
            "DeploymentType": {},
            "PerUnitStorageThroughput": {
              "type": "integer"
            },
            "MountName": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "DriveCacheType": {},
            "DataCompressionType": {},
            "LogConfiguration": {
              "type": "structure",
              "required": [
                "Level"
              ],
              "members": {
                "Level": {},
                "Destination": {}
              }
            }
          }
        },
        "AdministrativeActions": {
          "shape": "S27"
        },
        "OntapConfiguration": {
          "type": "structure",
          "members": {
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "DailyAutomaticBackupStartTime": {},
            "DeploymentType": {},
            "EndpointIpAddressRange": {},
            "Endpoints": {
              "type": "structure",
              "members": {
                "Intercluster": {
                  "shape": "S3k"
                },
                "Management": {
                  "shape": "S3k"
                }
              }
            },
            "DiskIopsConfiguration": {
              "shape": "S3m"
            },
            "PreferredSubnetId": {},
            "RouteTableIds": {
              "shape": "S3p"
            },
            "ThroughputCapacity": {
              "type": "integer"
            },
            "WeeklyMaintenanceStartTime": {}
          }
        },
        "FileSystemTypeVersion": {},
        "OpenZFSConfiguration": {
          "type": "structure",
          "members": {
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            },
            "CopyTagsToVolumes": {
              "type": "boolean"
            },
            "DailyAutomaticBackupStartTime": {},
            "DeploymentType": {},
            "ThroughputCapacity": {
              "type": "integer"
            },
            "WeeklyMaintenanceStartTime": {},
            "DiskIopsConfiguration": {
              "shape": "S3m"
            },
            "RootVolumeId": {}
          }
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "DomainName": {},
        "OrganizationalUnitDistinguishedName": {},
        "FileSystemAdministratorsGroup": {},
        "UserName": {},
        "DnsIps": {
          "shape": "S1h"
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1z": {
      "type": "structure",
      "members": {
        "Message": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AdministrativeActionType": {},
          "ProgressPercent": {
            "type": "integer"
          },
          "RequestTime": {
            "type": "timestamp"
          },
          "Status": {},
          "TargetFileSystemValues": {
            "shape": "Sx"
          },
          "FailureDetails": {
            "type": "structure",
            "members": {
              "Message": {}
            }
          },
          "TargetVolumeValues": {
            "shape": "S2d"
          },
          "TargetSnapshotValues": {
            "shape": "S3c"
          }
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "Lifecycle": {},
        "Name": {},
        "OntapConfiguration": {
          "type": "structure",
          "members": {
            "FlexCacheEndpointType": {},
            "JunctionPath": {},
            "SecurityStyle": {},
            "SizeInMegabytes": {
              "type": "integer"
            },
            "StorageEfficiencyEnabled": {
              "type": "boolean"
            },
            "StorageVirtualMachineId": {},
            "StorageVirtualMachineRoot": {
              "type": "boolean"
            },
            "TieringPolicy": {
              "shape": "S2m"
            },
            "UUID": {},
            "OntapVolumeType": {}
          }
        },
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "VolumeId": {},
        "VolumeType": {},
        "LifecycleTransitionReason": {
          "shape": "S2t"
        },
        "AdministrativeActions": {
          "shape": "S27"
        },
        "OpenZFSConfiguration": {
          "type": "structure",
          "members": {
            "ParentVolumeId": {},
            "VolumePath": {},
            "StorageCapacityReservationGiB": {
              "type": "integer"
            },
            "StorageCapacityQuotaGiB": {
              "type": "integer"
            },
            "RecordSizeKiB": {
              "type": "integer"
            },
            "DataCompressionType": {},
            "CopyTagsToSnapshots": {
              "type": "boolean"
            },
            "OriginSnapshot": {
              "type": "structure",
              "members": {
                "SnapshotARN": {},
                "CopyStrategy": {}
              }
            },
            "ReadOnly": {
              "type": "boolean"
            },
            "NfsExports": {
              "shape": "S32"
            },
            "UserAndGroupQuotas": {
              "shape": "S39"
            }
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "CoolingPeriod": {
          "type": "integer"
        },
        "Name": {}
      }
    },
    "S2t": {
      "type": "structure",
      "members": {
        "Message": {}
      }
    },
    "S32": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ClientConfigurations"
        ],
        "members": {
          "ClientConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Clients",
                "Options"
              ],
              "members": {
                "Clients": {},
                "Options": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "S39": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Type",
          "Id",
          "StorageCapacityQuotaGiB"
        ],
        "members": {
          "Type": {},
          "Id": {
            "type": "integer"
          },
          "StorageCapacityQuotaGiB": {
            "type": "integer"
          }
        }
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "ResourceARN": {},
        "SnapshotId": {},
        "Name": {},
        "VolumeId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "Lifecycle": {},
        "LifecycleTransitionReason": {
          "shape": "S2t"
        },
        "Tags": {
          "shape": "Sj"
        },
        "AdministrativeActions": {
          "shape": "S27"
        }
      }
    },
    "S3k": {
      "type": "structure",
      "members": {
        "DNSName": {},
        "IpAddresses": {
          "shape": "S3l"
        }
      }
    },
    "S3l": {
      "type": "list",
      "member": {}
    },
    "S3m": {
      "type": "structure",
      "members": {
        "Mode": {},
        "Iops": {
          "type": "long"
        }
      }
    },
    "S3p": {
      "type": "list",
      "member": {}
    },
    "S41": {
      "type": "structure",
      "members": {
        "AutoImportPolicy": {
          "type": "structure",
          "members": {
            "Events": {
              "shape": "S43"
            }
          }
        },
        "AutoExportPolicy": {
          "type": "structure",
          "members": {
            "Events": {
              "shape": "S43"
            }
          }
        }
      }
    },
    "S43": {
      "type": "list",
      "member": {}
    },
    "S47": {
      "type": "structure",
      "members": {
        "AssociationId": {},
        "ResourceARN": {},
        "FileSystemId": {},
        "Lifecycle": {},
        "FailureDetails": {
          "shape": "S1z"
        },
        "FileSystemPath": {},
        "DataRepositoryPath": {},
        "BatchImportMetaDataOnCreate": {
          "type": "boolean"
        },
        "ImportedFileChunkSize": {
          "type": "integer"
        },
        "S3": {
          "shape": "S41"
        },
        "Tags": {
          "shape": "Sj"
        },
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S4b": {
      "type": "list",
      "member": {}
    },
    "S4d": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Path": {},
        "Format": {},
        "Scope": {}
      }
    },
    "S4h": {
      "type": "structure",
      "required": [
        "TaskId",
        "Lifecycle",
        "Type",
        "CreationTime",
        "FileSystemId"
      ],
      "members": {
        "TaskId": {},
        "Lifecycle": {},
        "Type": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "ResourceARN": {},
        "Tags": {
          "shape": "Sj"
        },
        "FileSystemId": {},
        "Paths": {
          "shape": "S4b"
        },
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Status": {
          "type": "structure",
          "members": {
            "TotalCount": {
              "type": "long"
            },
            "SucceededCount": {
              "type": "long"
            },
            "FailedCount": {
              "type": "long"
            },
            "LastUpdatedTime": {
              "type": "timestamp"
            }
          }
        },
        "Report": {
          "shape": "S4d"
        }
      }
    },
    "S4r": {
      "type": "list",
      "member": {}
    },
    "S4t": {
      "type": "structure",
      "required": [
        "ThroughputCapacity"
      ],
      "members": {
        "ActiveDirectoryId": {},
        "SelfManagedActiveDirectoryConfiguration": {
          "shape": "S4u"
        },
        "DeploymentType": {},
        "PreferredSubnetId": {},
        "ThroughputCapacity": {
          "type": "integer"
        },
        "WeeklyMaintenanceStartTime": {},
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "Aliases": {
          "shape": "S4"
        },
        "AuditLogConfiguration": {
          "shape": "S4w"
        }
      }
    },
    "S4u": {
      "type": "structure",
      "required": [
        "DomainName",
        "UserName",
        "Password",
        "DnsIps"
      ],
      "members": {
        "DomainName": {},
        "OrganizationalUnitDistinguishedName": {},
        "FileSystemAdministratorsGroup": {},
        "UserName": {},
        "Password": {
          "shape": "S4v"
        },
        "DnsIps": {
          "shape": "S1h"
        }
      }
    },
    "S4v": {
      "type": "string",
      "sensitive": true
    },
    "S4w": {
      "type": "structure",
      "required": [
        "FileAccessAuditLogLevel",
        "FileShareAccessAuditLogLevel"
      ],
      "members": {
        "FileAccessAuditLogLevel": {},
        "FileShareAccessAuditLogLevel": {},
        "AuditLogDestination": {}
      }
    },
    "S4x": {
      "type": "structure",
      "members": {
        "WeeklyMaintenanceStartTime": {},
        "ImportPath": {},
        "ExportPath": {},
        "ImportedFileChunkSize": {
          "type": "integer"
        },
        "DeploymentType": {},
        "AutoImportPolicy": {},
        "PerUnitStorageThroughput": {
          "type": "integer"
        },
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "DriveCacheType": {},
        "DataCompressionType": {},
        "LogConfiguration": {
          "shape": "S4y"
        }
      }
    },
    "S4y": {
      "type": "structure",
      "required": [
        "Level"
      ],
      "members": {
        "Level": {},
        "Destination": {}
      }
    },
    "S50": {
      "type": "string",
      "sensitive": true
    },
    "S51": {
      "type": "structure",
      "required": [
        "DeploymentType",
        "ThroughputCapacity"
      ],
      "members": {
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        },
        "CopyTagsToVolumes": {
          "type": "boolean"
        },
        "DailyAutomaticBackupStartTime": {},
        "DeploymentType": {},
        "ThroughputCapacity": {
          "type": "integer"
        },
        "WeeklyMaintenanceStartTime": {},
        "DiskIopsConfiguration": {
          "shape": "S3m"
        },
        "RootVolumeConfiguration": {
          "type": "structure",
          "members": {
            "RecordSizeKiB": {
              "type": "integer"
            },
            "DataCompressionType": {},
            "NfsExports": {
              "shape": "S32"
            },
            "UserAndGroupQuotas": {
              "shape": "S39"
            },
            "CopyTagsToSnapshots": {
              "type": "boolean"
            },
            "ReadOnly": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "ActiveDirectoryConfiguration": {
          "type": "structure",
          "members": {
            "NetBiosName": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "shape": "S1c"
            }
          }
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "Endpoints": {
          "type": "structure",
          "members": {
            "Iscsi": {
              "shape": "S5h"
            },
            "Management": {
              "shape": "S5h"
            },
            "Nfs": {
              "shape": "S5h"
            },
            "Smb": {
              "shape": "S5h"
            }
          }
        },
        "FileSystemId": {},
        "Lifecycle": {},
        "Name": {},
        "ResourceARN": {},
        "StorageVirtualMachineId": {},
        "Subtype": {},
        "UUID": {},
        "Tags": {
          "shape": "Sj"
        },
        "LifecycleTransitionReason": {
          "shape": "S2t"
        },
        "RootVolumeSecurityStyle": {}
      }
    },
    "S5h": {
      "type": "structure",
      "members": {
        "DNSName": {},
        "IpAddresses": {
          "shape": "S3l"
        }
      }
    },
    "S5l": {
      "type": "structure",
      "required": [
        "JunctionPath",
        "SizeInMegabytes",
        "StorageEfficiencyEnabled",
        "StorageVirtualMachineId"
      ],
      "members": {
        "JunctionPath": {},
        "SecurityStyle": {},
        "SizeInMegabytes": {
          "type": "integer"
        },
        "StorageEfficiencyEnabled": {
          "type": "boolean"
        },
        "StorageVirtualMachineId": {},
        "TieringPolicy": {
          "shape": "S2m"
        }
      }
    },
    "S6k": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S8n": {
      "type": "structure",
      "members": {
        "UserName": {},
        "Password": {
          "shape": "S4v"
        },
        "DnsIps": {
          "shape": "S1h"
        }
      }
    }
  }
}
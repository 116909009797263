{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-27",
    "endpointPrefix": "textract",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Textract",
    "serviceId": "Textract",
    "signatureVersion": "v4",
    "targetPrefix": "Textract",
    "uid": "textract-2018-06-27"
  },
  "operations": {
    "AnalyzeDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Document",
          "FeatureTypes"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          },
          "FeatureTypes": {
            "shape": "S8"
          },
          "HumanLoopConfig": {
            "type": "structure",
            "required": [
              "HumanLoopName",
              "FlowDefinitionArn"
            ],
            "members": {
              "HumanLoopName": {},
              "FlowDefinitionArn": {},
              "DataAttributes": {
                "type": "structure",
                "members": {
                  "ContentClassifiers": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          },
          "QueriesConfig": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "Blocks": {
            "shape": "Sp"
          },
          "HumanLoopActivationOutput": {
            "type": "structure",
            "members": {
              "HumanLoopArn": {},
              "HumanLoopActivationReasons": {
                "type": "list",
                "member": {}
              },
              "HumanLoopActivationConditionsEvaluationResults": {
                "jsonvalue": true
              }
            }
          },
          "AnalyzeDocumentModelVersion": {}
        }
      }
    },
    "AnalyzeExpense": {
      "input": {
        "type": "structure",
        "required": [
          "Document"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "ExpenseDocuments": {
            "shape": "S1f"
          }
        }
      }
    },
    "AnalyzeID": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentPages"
        ],
        "members": {
          "DocumentPages": {
            "type": "list",
            "member": {
              "shape": "S2"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IdentityDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DocumentIndex": {
                  "type": "integer"
                },
                "IdentityDocumentFields": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Type": {
                        "shape": "S1w"
                      },
                      "ValueDetection": {
                        "shape": "S1w"
                      }
                    }
                  }
                }
              }
            }
          },
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "AnalyzeIDModelVersion": {}
        }
      }
    },
    "DetectDocumentText": {
      "input": {
        "type": "structure",
        "required": [
          "Document"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "Blocks": {
            "shape": "Sp"
          },
          "DetectDocumentTextModelVersion": {}
        }
      }
    },
    "GetDocumentAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "JobStatus": {},
          "NextToken": {},
          "Blocks": {
            "shape": "Sp"
          },
          "Warnings": {
            "shape": "S27"
          },
          "StatusMessage": {},
          "AnalyzeDocumentModelVersion": {}
        }
      }
    },
    "GetDocumentTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "JobStatus": {},
          "NextToken": {},
          "Blocks": {
            "shape": "Sp"
          },
          "Warnings": {
            "shape": "S27"
          },
          "StatusMessage": {},
          "DetectDocumentTextModelVersion": {}
        }
      }
    },
    "GetExpenseAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sn"
          },
          "JobStatus": {},
          "NextToken": {},
          "ExpenseDocuments": {
            "shape": "S1f"
          },
          "Warnings": {
            "shape": "S27"
          },
          "StatusMessage": {},
          "AnalyzeExpenseModelVersion": {}
        }
      }
    },
    "StartDocumentAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation",
          "FeatureTypes"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S2h"
          },
          "FeatureTypes": {
            "shape": "S8"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S2k"
          },
          "OutputConfig": {
            "shape": "S2n"
          },
          "KMSKeyId": {},
          "QueriesConfig": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartDocumentTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S2h"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S2k"
          },
          "OutputConfig": {
            "shape": "S2n"
          },
          "KMSKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartExpenseAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S2h"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S2k"
          },
          "OutputConfig": {
            "shape": "S2n"
          },
          "KMSKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "Bytes": {
          "type": "blob"
        },
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S4": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Name": {},
        "Version": {}
      }
    },
    "S8": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "structure",
      "required": [
        "Queries"
      ],
      "members": {
        "Queries": {
          "type": "list",
          "member": {
            "shape": "Si"
          }
        }
      }
    },
    "Si": {
      "type": "structure",
      "required": [
        "Text"
      ],
      "members": {
        "Text": {},
        "Alias": {},
        "Pages": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "Pages": {
          "type": "integer"
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BlockType": {},
          "Confidence": {
            "type": "float"
          },
          "Text": {},
          "TextType": {},
          "RowIndex": {
            "type": "integer"
          },
          "ColumnIndex": {
            "type": "integer"
          },
          "RowSpan": {
            "type": "integer"
          },
          "ColumnSpan": {
            "type": "integer"
          },
          "Geometry": {
            "shape": "Sv"
          },
          "Id": {},
          "Relationships": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Ids": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "EntityTypes": {
            "type": "list",
            "member": {}
          },
          "SelectionStatus": {},
          "Page": {
            "type": "integer"
          },
          "Query": {
            "shape": "Si"
          }
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "type": "structure",
          "members": {
            "Width": {
              "type": "float"
            },
            "Height": {
              "type": "float"
            },
            "Left": {
              "type": "float"
            },
            "Top": {
              "type": "float"
            }
          }
        },
        "Polygon": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "X": {
                "type": "float"
              },
              "Y": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ExpenseIndex": {
            "type": "integer"
          },
          "SummaryFields": {
            "shape": "S1h"
          },
          "LineItemGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LineItemGroupIndex": {
                  "type": "integer"
                },
                "LineItems": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "LineItemExpenseFields": {
                        "shape": "S1h"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {
            "type": "structure",
            "members": {
              "Text": {},
              "Confidence": {
                "type": "float"
              }
            }
          },
          "LabelDetection": {
            "shape": "S1k"
          },
          "ValueDetection": {
            "shape": "S1k"
          },
          "PageNumber": {
            "type": "integer"
          }
        }
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Text": {},
        "Geometry": {
          "shape": "Sv"
        },
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "required": [
        "Text"
      ],
      "members": {
        "Text": {},
        "NormalizedValue": {
          "type": "structure",
          "members": {
            "Value": {},
            "ValueType": {}
          }
        },
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {},
          "Pages": {
            "type": "list",
            "member": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "required": [
        "SNSTopicArn",
        "RoleArn"
      ],
      "members": {
        "SNSTopicArn": {},
        "RoleArn": {}
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "S3Bucket"
      ],
      "members": {
        "S3Bucket": {},
        "S3Prefix": {}
      }
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-08-01",
    "endpointPrefix": "nimble",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AmazonNimbleStudio",
    "serviceId": "nimble",
    "signatureVersion": "v4",
    "signingName": "nimble",
    "uid": "nimble-2020-08-01"
  },
  "operations": {
    "AcceptEulas": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/eula-acceptances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "eulaIds": {
            "shape": "S3"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eulaAcceptances": {
            "shape": "S6"
          }
        }
      }
    },
    "CreateLaunchProfile": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ec2SubnetIds",
          "launchProfileProtocolVersions",
          "name",
          "streamConfiguration",
          "studioComponentIds",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "description": {
            "shape": "Sc"
          },
          "ec2SubnetIds": {
            "shape": "Sd"
          },
          "launchProfileProtocolVersions": {
            "shape": "Sf"
          },
          "name": {
            "shape": "Sh"
          },
          "streamConfiguration": {
            "shape": "Si"
          },
          "studioComponentIds": {
            "shape": "Sw"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateStreamingImage": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ec2ImageId",
          "name",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "description": {
            "type": "string",
            "sensitive": true
          },
          "ec2ImageId": {},
          "name": {
            "type": "string",
            "sensitive": true
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S1f"
          }
        }
      }
    },
    "CreateStreamingSession": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "ec2InstanceType": {},
          "launchProfileId": {},
          "ownedBy": {},
          "streamingImageId": {},
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1r"
          }
        }
      }
    },
    "CreateStreamingSessionStream": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}/streams",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "expirationInSeconds": {
            "type": "integer"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "stream": {
            "shape": "S1y"
          }
        }
      },
      "idempotent": true
    },
    "CreateStudio": {
      "http": {
        "requestUri": "/2020-08-01/studios",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "adminRoleArn",
          "displayName",
          "studioName",
          "userRoleArn"
        ],
        "members": {
          "adminRoleArn": {},
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "displayName": {
            "type": "string",
            "sensitive": true
          },
          "studioEncryptionConfiguration": {
            "shape": "S24"
          },
          "studioName": {},
          "tags": {
            "shape": "Sx"
          },
          "userRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "studio": {
            "shape": "S29"
          }
        }
      }
    },
    "CreateStudioComponent": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "studioId",
          "type"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "configuration": {
            "shape": "S2f"
          },
          "description": {
            "shape": "S2v"
          },
          "ec2SecurityGroupIds": {
            "shape": "S2w"
          },
          "initializationScripts": {
            "shape": "S2y"
          },
          "name": {
            "shape": "S33"
          },
          "scriptParameters": {
            "shape": "S34"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "subtype": {},
          "tags": {
            "shape": "Sx"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S3b"
          }
        }
      }
    },
    "DeleteLaunchProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Sz"
          }
        }
      },
      "idempotent": true
    },
    "DeleteLaunchProfileMember": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "principalId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteStreamingImage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images/{streamingImageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "streamingImageId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "streamingImageId": {
            "location": "uri",
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S1f"
          }
        }
      },
      "idempotent": true
    },
    "DeleteStreamingSession": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1r"
          }
        }
      },
      "idempotent": true
    },
    "DeleteStudio": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "studio"
        ],
        "members": {
          "studio": {
            "shape": "S29"
          }
        }
      },
      "idempotent": true
    },
    "DeleteStudioComponent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components/{studioComponentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioComponentId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "studioComponentId": {
            "location": "uri",
            "locationName": "studioComponentId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S3b"
          }
        }
      },
      "idempotent": true
    },
    "DeleteStudioMember": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/studios/{studioId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "principalId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetEula": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/eulas/{eulaId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "eulaId"
        ],
        "members": {
          "eulaId": {
            "location": "uri",
            "locationName": "eulaId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eula": {
            "shape": "S3v"
          }
        }
      }
    },
    "GetLaunchProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "studioId"
        ],
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Sz"
          }
        }
      }
    },
    "GetLaunchProfileDetails": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/details",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "studioId"
        ],
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Sz"
          },
          "streamingImages": {
            "shape": "S41"
          },
          "studioComponentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "shape": "S8"
                },
                "createdBy": {},
                "description": {
                  "shape": "S2v"
                },
                "name": {
                  "shape": "S33"
                },
                "studioComponentId": {},
                "subtype": {},
                "type": {},
                "updatedAt": {
                  "shape": "S8"
                },
                "updatedBy": {}
              }
            }
          }
        }
      }
    },
    "GetLaunchProfileInitialization": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/init",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "launchProfileProtocolVersions",
          "launchPurpose",
          "platform",
          "studioId"
        ],
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "launchProfileProtocolVersions": {
            "shape": "S45",
            "location": "querystring",
            "locationName": "launchProfileProtocolVersions"
          },
          "launchPurpose": {
            "location": "querystring",
            "locationName": "launchPurpose"
          },
          "platform": {
            "location": "querystring",
            "locationName": "platform"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfileInitialization": {
            "type": "structure",
            "members": {
              "activeDirectory": {
                "type": "structure",
                "members": {
                  "computerAttributes": {
                    "shape": "S2h"
                  },
                  "directoryId": {},
                  "directoryName": {},
                  "dnsIpAddresses": {
                    "type": "list",
                    "member": {}
                  },
                  "organizationalUnitDistinguishedName": {},
                  "studioComponentId": {},
                  "studioComponentName": {
                    "shape": "S33"
                  }
                }
              },
              "ec2SecurityGroupIds": {
                "type": "list",
                "member": {}
              },
              "launchProfileId": {},
              "launchProfileProtocolVersion": {},
              "launchPurpose": {},
              "name": {
                "shape": "Sh"
              },
              "platform": {},
              "systemInitializationScripts": {
                "shape": "S4d"
              },
              "userInitializationScripts": {
                "shape": "S4d"
              }
            }
          }
        }
      }
    },
    "GetLaunchProfileMember": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "principalId",
          "studioId"
        ],
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S4h"
          }
        }
      }
    },
    "GetStreamingImage": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images/{streamingImageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "streamingImageId",
          "studioId"
        ],
        "members": {
          "streamingImageId": {
            "location": "uri",
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S1f"
          }
        }
      }
    },
    "GetStreamingSession": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "studioId"
        ],
        "members": {
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1r"
          }
        }
      }
    },
    "GetStreamingSessionStream": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}/streams/{streamId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "streamId",
          "studioId"
        ],
        "members": {
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "stream": {
            "shape": "S1y"
          }
        }
      }
    },
    "GetStudio": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "studio"
        ],
        "members": {
          "studio": {
            "shape": "S29"
          }
        }
      }
    },
    "GetStudioComponent": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components/{studioComponentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioComponentId",
          "studioId"
        ],
        "members": {
          "studioComponentId": {
            "location": "uri",
            "locationName": "studioComponentId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S3b"
          }
        }
      }
    },
    "GetStudioMember": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "principalId",
          "studioId"
        ],
        "members": {
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S4v"
          }
        }
      }
    },
    "ListEulaAcceptances": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/eula-acceptances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "eulaIds": {
            "shape": "S45",
            "location": "querystring",
            "locationName": "eulaIds"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eulaAcceptances": {
            "shape": "S6"
          },
          "nextToken": {}
        }
      }
    },
    "ListEulas": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/eulas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "eulaIds": {
            "shape": "S45",
            "location": "querystring",
            "locationName": "eulaIds"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eulas": {
            "type": "list",
            "member": {
              "shape": "S3v"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListLaunchProfileMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "studioId"
        ],
        "members": {
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "members": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListLaunchProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "principalId": {
            "location": "querystring",
            "locationName": "principalId"
          },
          "states": {
            "location": "querystring",
            "locationName": "states",
            "type": "list",
            "member": {}
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfiles": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListStreamingImages": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "owner": {
            "location": "querystring",
            "locationName": "owner"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "streamingImages": {
            "shape": "S41"
          }
        }
      }
    },
    "ListStreamingSessions": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "createdBy": {
            "location": "querystring",
            "locationName": "createdBy"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ownedBy": {
            "location": "querystring",
            "locationName": "ownedBy"
          },
          "sessionIds": {
            "location": "querystring",
            "locationName": "sessionIds"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "sessions": {
            "type": "list",
            "member": {
              "shape": "S1r"
            }
          }
        }
      }
    },
    "ListStudioComponents": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "states": {
            "location": "querystring",
            "locationName": "states",
            "type": "list",
            "member": {}
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "types": {
            "location": "querystring",
            "locationName": "types",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "studioComponents": {
            "type": "list",
            "member": {
              "shape": "S3b"
            }
          }
        }
      }
    },
    "ListStudioMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios/{studioId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "members": {
            "type": "list",
            "member": {
              "shape": "S4v"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListStudios": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/studios",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "studios"
        ],
        "members": {
          "nextToken": {},
          "studios": {
            "type": "list",
            "member": {
              "shape": "S29"
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2020-08-01/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sx"
          }
        }
      }
    },
    "PutLaunchProfileMembers": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identityStoreId",
          "launchProfileId",
          "members",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "identityStoreId": {},
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "members": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "persona",
                "principalId"
              ],
              "members": {
                "persona": {},
                "principalId": {}
              }
            }
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutStudioMembers": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/membership",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identityStoreId",
          "members",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "identityStoreId": {},
          "members": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "persona",
                "principalId"
              ],
              "members": {
                "persona": {},
                "principalId": {}
              }
            }
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartStreamingSession": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}/start",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1r"
          }
        }
      },
      "idempotent": true
    },
    "StartStudioSSOConfigurationRepair": {
      "http": {
        "method": "PUT",
        "requestUri": "/2020-08-01/studios/{studioId}/sso-configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "studio"
        ],
        "members": {
          "studio": {
            "shape": "S29"
          }
        }
      },
      "idempotent": true
    },
    "StopStreamingSession": {
      "http": {
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-sessions/{sessionId}/stop",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "session": {
            "shape": "S1r"
          }
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/2020-08-01/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2020-08-01/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "shape": "S45",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateLaunchProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "description": {
            "shape": "Sc"
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "launchProfileProtocolVersions": {
            "shape": "Sf"
          },
          "name": {
            "shape": "Sh"
          },
          "streamConfiguration": {
            "shape": "Si"
          },
          "studioComponentIds": {
            "shape": "Sw"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "launchProfile": {
            "shape": "Sz"
          }
        }
      }
    },
    "UpdateLaunchProfileMember": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/launch-profiles/{launchProfileId}/membership/{principalId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "launchProfileId",
          "persona",
          "principalId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "launchProfileId": {
            "location": "uri",
            "locationName": "launchProfileId"
          },
          "persona": {},
          "principalId": {
            "location": "uri",
            "locationName": "principalId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "member": {
            "shape": "S4h"
          }
        }
      }
    },
    "UpdateStreamingImage": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/streaming-images/{streamingImageId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "streamingImageId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "description": {
            "type": "string",
            "sensitive": true
          },
          "name": {
            "type": "string",
            "sensitive": true
          },
          "streamingImageId": {
            "location": "uri",
            "locationName": "streamingImageId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamingImage": {
            "shape": "S1f"
          }
        }
      }
    },
    "UpdateStudio": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioId"
        ],
        "members": {
          "adminRoleArn": {},
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "displayName": {
            "type": "string",
            "sensitive": true
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "userRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "studio"
        ],
        "members": {
          "studio": {
            "shape": "S29"
          }
        }
      }
    },
    "UpdateStudioComponent": {
      "http": {
        "method": "PATCH",
        "requestUri": "/2020-08-01/studios/{studioId}/studio-components/{studioComponentId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "studioComponentId",
          "studioId"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "header",
            "locationName": "X-Amz-Client-Token"
          },
          "configuration": {
            "shape": "S2f"
          },
          "description": {
            "shape": "S2v"
          },
          "ec2SecurityGroupIds": {
            "shape": "S2w"
          },
          "initializationScripts": {
            "shape": "S2y"
          },
          "name": {
            "shape": "S33"
          },
          "scriptParameters": {
            "shape": "S34"
          },
          "studioComponentId": {
            "location": "uri",
            "locationName": "studioComponentId"
          },
          "studioId": {
            "location": "uri",
            "locationName": "studioId"
          },
          "subtype": {},
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "studioComponent": {
            "shape": "S3b"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "acceptedAt": {
            "shape": "S8"
          },
          "acceptedBy": {},
          "accepteeId": {},
          "eulaAcceptanceId": {},
          "eulaId": {}
        }
      }
    },
    "S8": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sc": {
      "type": "string",
      "sensitive": true
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "list",
      "member": {}
    },
    "Sh": {
      "type": "string",
      "sensitive": true
    },
    "Si": {
      "type": "structure",
      "required": [
        "clipboardMode",
        "ec2InstanceTypes",
        "streamingImageIds"
      ],
      "members": {
        "clipboardMode": {},
        "ec2InstanceTypes": {
          "shape": "Sk"
        },
        "maxSessionLengthInMinutes": {
          "type": "integer"
        },
        "maxStoppedSessionLengthInMinutes": {
          "type": "integer"
        },
        "sessionStorage": {
          "shape": "So"
        },
        "streamingImageIds": {
          "shape": "Su"
        }
      }
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "So": {
      "type": "structure",
      "required": [
        "mode"
      ],
      "members": {
        "mode": {
          "type": "list",
          "member": {}
        },
        "root": {
          "type": "structure",
          "members": {
            "linux": {
              "type": "string",
              "sensitive": true
            },
            "windows": {
              "type": "string",
              "sensitive": true
            }
          }
        }
      }
    },
    "Su": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "list",
      "member": {}
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sz": {
      "type": "structure",
      "members": {
        "arn": {},
        "createdAt": {
          "shape": "S8"
        },
        "createdBy": {},
        "description": {
          "shape": "Sc"
        },
        "ec2SubnetIds": {
          "shape": "Sd"
        },
        "launchProfileId": {},
        "launchProfileProtocolVersions": {
          "shape": "Sf"
        },
        "name": {
          "shape": "Sh"
        },
        "state": {},
        "statusCode": {},
        "statusMessage": {},
        "streamConfiguration": {
          "type": "structure",
          "required": [
            "clipboardMode",
            "ec2InstanceTypes",
            "streamingImageIds"
          ],
          "members": {
            "clipboardMode": {},
            "ec2InstanceTypes": {
              "shape": "Sk"
            },
            "maxSessionLengthInMinutes": {
              "type": "integer"
            },
            "maxStoppedSessionLengthInMinutes": {
              "type": "integer"
            },
            "sessionStorage": {
              "shape": "So"
            },
            "streamingImageIds": {
              "shape": "Su"
            }
          }
        },
        "studioComponentIds": {
          "shape": "Sw"
        },
        "tags": {
          "shape": "Sx"
        },
        "updatedAt": {
          "shape": "S8"
        },
        "updatedBy": {},
        "validationResults": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "state",
              "statusCode",
              "statusMessage",
              "type"
            ],
            "members": {
              "state": {},
              "statusCode": {},
              "statusMessage": {},
              "type": {}
            }
          }
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "arn": {},
        "description": {
          "type": "string",
          "sensitive": true
        },
        "ec2ImageId": {},
        "encryptionConfiguration": {
          "type": "structure",
          "required": [
            "keyType"
          ],
          "members": {
            "keyArn": {},
            "keyType": {}
          }
        },
        "eulaIds": {
          "shape": "S3"
        },
        "name": {
          "type": "string",
          "sensitive": true
        },
        "owner": {},
        "platform": {},
        "state": {},
        "statusCode": {},
        "statusMessage": {},
        "streamingImageId": {},
        "tags": {
          "shape": "Sx"
        }
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "arn": {},
        "createdAt": {
          "shape": "S8"
        },
        "createdBy": {},
        "ec2InstanceType": {},
        "launchProfileId": {},
        "ownedBy": {},
        "sessionId": {},
        "startedAt": {
          "shape": "S8"
        },
        "startedBy": {},
        "state": {},
        "statusCode": {},
        "statusMessage": {},
        "stopAt": {
          "shape": "S8"
        },
        "stoppedAt": {
          "shape": "S8"
        },
        "stoppedBy": {},
        "streamingImageId": {},
        "tags": {
          "shape": "Sx"
        },
        "terminateAt": {
          "shape": "S8"
        },
        "updatedAt": {
          "shape": "S8"
        },
        "updatedBy": {}
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "createdAt": {
          "shape": "S8"
        },
        "createdBy": {},
        "expiresAt": {
          "shape": "S8"
        },
        "ownedBy": {},
        "state": {},
        "statusCode": {},
        "streamId": {},
        "url": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S24": {
      "type": "structure",
      "required": [
        "keyType"
      ],
      "members": {
        "keyArn": {},
        "keyType": {}
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "adminRoleArn": {},
        "arn": {},
        "createdAt": {
          "shape": "S8"
        },
        "displayName": {
          "type": "string",
          "sensitive": true
        },
        "homeRegion": {},
        "ssoClientId": {},
        "state": {},
        "statusCode": {},
        "statusMessage": {},
        "studioEncryptionConfiguration": {
          "shape": "S24"
        },
        "studioId": {},
        "studioName": {},
        "studioUrl": {},
        "tags": {
          "shape": "Sx"
        },
        "updatedAt": {
          "shape": "S8"
        },
        "userRoleArn": {}
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "activeDirectoryConfiguration": {
          "type": "structure",
          "members": {
            "computerAttributes": {
              "shape": "S2h"
            },
            "directoryId": {},
            "organizationalUnitDistinguishedName": {}
          }
        },
        "computeFarmConfiguration": {
          "type": "structure",
          "members": {
            "activeDirectoryUser": {},
            "endpoint": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "licenseServiceConfiguration": {
          "type": "structure",
          "members": {
            "endpoint": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "sharedFileSystemConfiguration": {
          "type": "structure",
          "members": {
            "endpoint": {
              "shape": "S2s"
            },
            "fileSystemId": {},
            "linuxMountPoint": {
              "type": "string",
              "sensitive": true
            },
            "shareName": {
              "shape": "S2s"
            },
            "windowsMountDrive": {}
          }
        }
      },
      "union": true
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      },
      "sensitive": true
    },
    "S2s": {
      "type": "string",
      "sensitive": true
    },
    "S2v": {
      "type": "string",
      "sensitive": true
    },
    "S2w": {
      "type": "list",
      "member": {}
    },
    "S2y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "launchProfileProtocolVersion": {},
          "platform": {},
          "runContext": {},
          "script": {
            "shape": "S32"
          }
        }
      }
    },
    "S32": {
      "type": "string",
      "sensitive": true
    },
    "S33": {
      "type": "string",
      "sensitive": true
    },
    "S34": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      },
      "sensitive": true
    },
    "S3b": {
      "type": "structure",
      "members": {
        "arn": {},
        "configuration": {
          "shape": "S2f"
        },
        "createdAt": {
          "shape": "S8"
        },
        "createdBy": {},
        "description": {
          "shape": "S2v"
        },
        "ec2SecurityGroupIds": {
          "shape": "S2w"
        },
        "initializationScripts": {
          "shape": "S2y"
        },
        "name": {
          "shape": "S33"
        },
        "scriptParameters": {
          "shape": "S34"
        },
        "state": {},
        "statusCode": {},
        "statusMessage": {},
        "studioComponentId": {},
        "subtype": {},
        "tags": {
          "shape": "Sx"
        },
        "type": {},
        "updatedAt": {
          "shape": "S8"
        },
        "updatedBy": {}
      }
    },
    "S3v": {
      "type": "structure",
      "members": {
        "content": {},
        "createdAt": {
          "shape": "S8"
        },
        "eulaId": {},
        "name": {},
        "updatedAt": {
          "shape": "S8"
        }
      }
    },
    "S41": {
      "type": "list",
      "member": {
        "shape": "S1f"
      }
    },
    "S45": {
      "type": "list",
      "member": {}
    },
    "S4d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "script": {
            "shape": "S32"
          },
          "studioComponentId": {},
          "studioComponentName": {
            "shape": "S33"
          }
        }
      }
    },
    "S4h": {
      "type": "structure",
      "members": {
        "identityStoreId": {},
        "persona": {},
        "principalId": {},
        "sid": {}
      }
    },
    "S4v": {
      "type": "structure",
      "members": {
        "identityStoreId": {},
        "persona": {},
        "principalId": {},
        "sid": {}
      }
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-02-26",
    "endpointPrefix": "drs",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "drs",
    "serviceFullName": "Elastic Disaster Recovery Service",
    "serviceId": "drs",
    "signatureVersion": "v4",
    "signingName": "drs",
    "uid": "drs-2020-02-26"
  },
  "operations": {
    "CreateReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/CreateReplicationConfigurationTemplate",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "associateDefaultSecurityGroup",
          "bandwidthThrottling",
          "createPublicIP",
          "dataPlaneRouting",
          "defaultLargeStagingDiskType",
          "ebsEncryption",
          "pitPolicy",
          "replicationServerInstanceType",
          "replicationServersSecurityGroupsIDs",
          "stagingAreaSubnetId",
          "stagingAreaTags",
          "useDedicatedReplicationServer"
        ],
        "members": {
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "pitPolicy": {
            "shape": "S8"
          },
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "Sd"
          },
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "Sg"
          },
          "tags": {
            "shape": "Sg"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sj"
      }
    },
    "DeleteJob": {
      "http": {
        "requestUri": "/DeleteJob",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "jobID"
        ],
        "members": {
          "jobID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteRecoveryInstance": {
      "http": {
        "requestUri": "/DeleteRecoveryInstance",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceID"
        ],
        "members": {
          "recoveryInstanceID": {}
        }
      }
    },
    "DeleteReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/DeleteReplicationConfigurationTemplate",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateID"
        ],
        "members": {
          "replicationConfigurationTemplateID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteSourceServer": {
      "http": {
        "requestUri": "/DeleteSourceServer",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeJobLogItems": {
      "http": {
        "requestUri": "/DescribeJobLogItems",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobID"
        ],
        "members": {
          "jobID": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "event": {},
                "eventData": {
                  "type": "structure",
                  "members": {
                    "conversionServerID": {},
                    "rawError": {},
                    "sourceServerID": {},
                    "targetInstanceID": {}
                  }
                },
                "logDateTime": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobs": {
      "http": {
        "requestUri": "/DescribeJobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "fromDate": {},
              "jobIDs": {
                "type": "list",
                "member": {}
              },
              "toDate": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S1a"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeRecoveryInstances": {
      "http": {
        "requestUri": "/DescribeRecoveryInstances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "recoveryInstanceIDs": {
                "type": "list",
                "member": {}
              },
              "sourceServerIDs": {
                "type": "list",
                "member": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "dataReplicationInfo": {
                  "type": "structure",
                  "members": {
                    "dataReplicationError": {
                      "type": "structure",
                      "members": {
                        "error": {},
                        "rawError": {}
                      }
                    },
                    "dataReplicationInitiation": {
                      "type": "structure",
                      "members": {
                        "startDateTime": {},
                        "steps": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "name": {},
                              "status": {}
                            }
                          }
                        }
                      }
                    },
                    "dataReplicationState": {},
                    "etaDateTime": {},
                    "lagDuration": {},
                    "replicatedDisks": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "backloggedStorageBytes": {
                            "type": "long"
                          },
                          "deviceName": {},
                          "replicatedStorageBytes": {
                            "type": "long"
                          },
                          "rescannedStorageBytes": {
                            "type": "long"
                          },
                          "totalStorageBytes": {
                            "type": "long"
                          }
                        }
                      }
                    }
                  }
                },
                "ec2InstanceID": {},
                "ec2InstanceState": {},
                "failback": {
                  "type": "structure",
                  "members": {
                    "agentLastSeenByServiceDateTime": {},
                    "elapsedReplicationDuration": {},
                    "failbackClientID": {},
                    "failbackClientLastSeenByServiceDateTime": {},
                    "failbackInitiationTime": {},
                    "failbackJobID": {},
                    "failbackToOriginalServer": {
                      "type": "boolean"
                    },
                    "firstByteDateTime": {},
                    "state": {}
                  }
                },
                "isDrill": {
                  "type": "boolean"
                },
                "jobID": {},
                "pointInTimeSnapshotDateTime": {},
                "recoveryInstanceID": {},
                "recoveryInstanceProperties": {
                  "type": "structure",
                  "members": {
                    "cpus": {
                      "shape": "S24"
                    },
                    "disks": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "bytes": {
                            "type": "long"
                          },
                          "ebsVolumeID": {},
                          "internalDeviceName": {}
                        }
                      }
                    },
                    "identificationHints": {
                      "shape": "S29"
                    },
                    "lastUpdatedDateTime": {},
                    "networkInterfaces": {
                      "shape": "S2a"
                    },
                    "os": {
                      "shape": "S2d"
                    },
                    "ramBytes": {
                      "type": "long"
                    }
                  }
                },
                "sourceServerID": {},
                "tags": {
                  "shape": "Sg"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeRecoverySnapshots": {
      "http": {
        "requestUri": "/DescribeRecoverySnapshots",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "fromDateTime": {},
              "toDateTime": {}
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "order": {},
          "sourceServerID": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "expectedTimestamp",
                "snapshotID",
                "sourceServerID"
              ],
              "members": {
                "ebsSnapshots": {
                  "type": "list",
                  "member": {}
                },
                "expectedTimestamp": {},
                "snapshotID": {},
                "sourceServerID": {},
                "timestamp": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeReplicationConfigurationTemplates": {
      "http": {
        "requestUri": "/DescribeReplicationConfigurationTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateIDs"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "replicationConfigurationTemplateIDs": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "Sj"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeSourceServers": {
      "http": {
        "requestUri": "/DescribeSourceServers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "filters"
        ],
        "members": {
          "filters": {
            "type": "structure",
            "members": {
              "hardwareId": {},
              "sourceServerIDs": {
                "type": "list",
                "member": {}
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "shape": "S2w"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DisconnectRecoveryInstance": {
      "http": {
        "requestUri": "/DisconnectRecoveryInstance",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceID"
        ],
        "members": {
          "recoveryInstanceID": {}
        }
      }
    },
    "DisconnectSourceServer": {
      "http": {
        "requestUri": "/DisconnectSourceServer",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S2w"
      }
    },
    "GetFailbackReplicationConfiguration": {
      "http": {
        "requestUri": "/GetFailbackReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceID"
        ],
        "members": {
          "recoveryInstanceID": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "recoveryInstanceID"
        ],
        "members": {
          "bandwidthThrottling": {
            "type": "long"
          },
          "name": {},
          "recoveryInstanceID": {},
          "usePrivateIP": {
            "type": "boolean"
          }
        }
      }
    },
    "GetLaunchConfiguration": {
      "http": {
        "requestUri": "/GetLaunchConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S3l"
      }
    },
    "GetReplicationConfiguration": {
      "http": {
        "requestUri": "/GetReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S3r"
      }
    },
    "InitializeService": {
      "http": {
        "requestUri": "/InitializeService",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sg"
          }
        }
      }
    },
    "RetryDataReplication": {
      "http": {
        "requestUri": "/RetryDataReplication",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "sourceServerID": {}
        }
      },
      "output": {
        "shape": "S2w"
      }
    },
    "StartFailbackLaunch": {
      "http": {
        "requestUri": "/StartFailbackLaunch",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceIDs"
        ],
        "members": {
          "recoveryInstanceIDs": {
            "type": "list",
            "member": {}
          },
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S1a"
          }
        }
      }
    },
    "StartRecovery": {
      "http": {
        "requestUri": "/StartRecovery",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServers"
        ],
        "members": {
          "isDrill": {
            "type": "boolean"
          },
          "sourceServers": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sourceServerID"
              ],
              "members": {
                "recoverySnapshotID": {},
                "sourceServerID": {}
              }
            }
          },
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S1a"
          }
        }
      }
    },
    "StopFailback": {
      "http": {
        "requestUri": "/StopFailback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceID"
        ],
        "members": {
          "recoveryInstanceID": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sg"
          }
        }
      },
      "idempotent": true
    },
    "TerminateRecoveryInstances": {
      "http": {
        "requestUri": "/TerminateRecoveryInstances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceIDs"
        ],
        "members": {
          "recoveryInstanceIDs": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S1a"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {},
            "sensitive": true
          }
        }
      },
      "idempotent": true
    },
    "UpdateFailbackReplicationConfiguration": {
      "http": {
        "requestUri": "/UpdateFailbackReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "recoveryInstanceID"
        ],
        "members": {
          "bandwidthThrottling": {
            "type": "long"
          },
          "name": {},
          "recoveryInstanceID": {},
          "usePrivateIP": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateLaunchConfiguration": {
      "http": {
        "requestUri": "/UpdateLaunchConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "copyPrivateIp": {
            "type": "boolean"
          },
          "copyTags": {
            "type": "boolean"
          },
          "launchDisposition": {},
          "licensing": {
            "shape": "S3n"
          },
          "name": {},
          "sourceServerID": {},
          "targetInstanceTypeRightSizingMethod": {}
        }
      },
      "output": {
        "shape": "S3l"
      },
      "idempotent": true
    },
    "UpdateReplicationConfiguration": {
      "http": {
        "requestUri": "/UpdateReplicationConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceServerID"
        ],
        "members": {
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "name": {},
          "pitPolicy": {
            "shape": "S8"
          },
          "replicatedDisks": {
            "shape": "S3s"
          },
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "Sd"
          },
          "sourceServerID": {},
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "Sg"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S3r"
      },
      "idempotent": true
    },
    "UpdateReplicationConfigurationTemplate": {
      "http": {
        "requestUri": "/UpdateReplicationConfigurationTemplate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "replicationConfigurationTemplateID"
        ],
        "members": {
          "arn": {},
          "associateDefaultSecurityGroup": {
            "type": "boolean"
          },
          "bandwidthThrottling": {
            "type": "long"
          },
          "createPublicIP": {
            "type": "boolean"
          },
          "dataPlaneRouting": {},
          "defaultLargeStagingDiskType": {},
          "ebsEncryption": {},
          "ebsEncryptionKeyArn": {},
          "pitPolicy": {
            "shape": "S8"
          },
          "replicationConfigurationTemplateID": {},
          "replicationServerInstanceType": {},
          "replicationServersSecurityGroupsIDs": {
            "shape": "Sd"
          },
          "stagingAreaSubnetId": {},
          "stagingAreaTags": {
            "shape": "Sg"
          },
          "useDedicatedReplicationServer": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sj"
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "interval",
          "retentionDuration",
          "units"
        ],
        "members": {
          "enabled": {
            "type": "boolean"
          },
          "interval": {
            "type": "integer"
          },
          "retentionDuration": {
            "type": "integer"
          },
          "ruleID": {
            "type": "long"
          },
          "units": {}
        }
      }
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "Sj": {
      "type": "structure",
      "required": [
        "replicationConfigurationTemplateID"
      ],
      "members": {
        "arn": {},
        "associateDefaultSecurityGroup": {
          "type": "boolean"
        },
        "bandwidthThrottling": {
          "type": "long"
        },
        "createPublicIP": {
          "type": "boolean"
        },
        "dataPlaneRouting": {},
        "defaultLargeStagingDiskType": {},
        "ebsEncryption": {},
        "ebsEncryptionKeyArn": {},
        "pitPolicy": {
          "shape": "S8"
        },
        "replicationConfigurationTemplateID": {},
        "replicationServerInstanceType": {},
        "replicationServersSecurityGroupsIDs": {
          "shape": "Sd"
        },
        "stagingAreaSubnetId": {},
        "stagingAreaTags": {
          "shape": "Sg"
        },
        "tags": {
          "shape": "Sg"
        },
        "useDedicatedReplicationServer": {
          "type": "boolean"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "jobID"
      ],
      "members": {
        "arn": {},
        "creationDateTime": {},
        "endDateTime": {},
        "initiatedBy": {},
        "jobID": {},
        "participatingServers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "launchStatus": {},
              "recoveryInstanceID": {},
              "sourceServerID": {}
            }
          }
        },
        "status": {},
        "tags": {
          "shape": "Sg"
        },
        "type": {}
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "cores": {
            "type": "long"
          },
          "modelName": {}
        }
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "awsInstanceID": {},
        "fqdn": {},
        "hostname": {},
        "vmWareUuid": {}
      }
    },
    "S2a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ips": {
            "type": "list",
            "member": {}
          },
          "isPrimary": {
            "type": "boolean"
          },
          "macAddress": {}
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "fullString": {}
      }
    },
    "S2w": {
      "type": "structure",
      "members": {
        "arn": {},
        "dataReplicationInfo": {
          "type": "structure",
          "members": {
            "dataReplicationError": {
              "type": "structure",
              "members": {
                "error": {},
                "rawError": {}
              }
            },
            "dataReplicationInitiation": {
              "type": "structure",
              "members": {
                "nextAttemptDateTime": {},
                "startDateTime": {},
                "steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "status": {}
                    }
                  }
                }
              }
            },
            "dataReplicationState": {},
            "etaDateTime": {},
            "lagDuration": {},
            "replicatedDisks": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "backloggedStorageBytes": {
                    "type": "long"
                  },
                  "deviceName": {},
                  "replicatedStorageBytes": {
                    "type": "long"
                  },
                  "rescannedStorageBytes": {
                    "type": "long"
                  },
                  "totalStorageBytes": {
                    "type": "long"
                  }
                }
              }
            }
          }
        },
        "lastLaunchResult": {},
        "lifeCycle": {
          "type": "structure",
          "members": {
            "addedToServiceDateTime": {},
            "elapsedReplicationDuration": {},
            "firstByteDateTime": {},
            "lastLaunch": {
              "type": "structure",
              "members": {
                "initiated": {
                  "type": "structure",
                  "members": {
                    "apiCallDateTime": {},
                    "jobID": {},
                    "type": {}
                  }
                }
              }
            },
            "lastSeenByServiceDateTime": {}
          }
        },
        "recoveryInstanceId": {},
        "sourceProperties": {
          "type": "structure",
          "members": {
            "cpus": {
              "shape": "S24"
            },
            "disks": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "bytes": {
                    "type": "long"
                  },
                  "deviceName": {}
                }
              }
            },
            "identificationHints": {
              "shape": "S29"
            },
            "lastUpdatedDateTime": {},
            "networkInterfaces": {
              "shape": "S2a"
            },
            "os": {
              "shape": "S2d"
            },
            "ramBytes": {
              "type": "long"
            },
            "recommendedInstanceType": {}
          }
        },
        "sourceServerID": {},
        "tags": {
          "shape": "Sg"
        }
      }
    },
    "S3l": {
      "type": "structure",
      "members": {
        "copyPrivateIp": {
          "type": "boolean"
        },
        "copyTags": {
          "type": "boolean"
        },
        "ec2LaunchTemplateID": {},
        "launchDisposition": {},
        "licensing": {
          "shape": "S3n"
        },
        "name": {},
        "sourceServerID": {},
        "targetInstanceTypeRightSizingMethod": {}
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "osByol": {
          "type": "boolean"
        }
      }
    },
    "S3r": {
      "type": "structure",
      "members": {
        "associateDefaultSecurityGroup": {
          "type": "boolean"
        },
        "bandwidthThrottling": {
          "type": "long"
        },
        "createPublicIP": {
          "type": "boolean"
        },
        "dataPlaneRouting": {},
        "defaultLargeStagingDiskType": {},
        "ebsEncryption": {},
        "ebsEncryptionKeyArn": {},
        "name": {},
        "pitPolicy": {
          "shape": "S8"
        },
        "replicatedDisks": {
          "shape": "S3s"
        },
        "replicationServerInstanceType": {},
        "replicationServersSecurityGroupsIDs": {
          "shape": "Sd"
        },
        "sourceServerID": {},
        "stagingAreaSubnetId": {},
        "stagingAreaTags": {
          "shape": "Sg"
        },
        "useDedicatedReplicationServer": {
          "type": "boolean"
        }
      }
    },
    "S3s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "deviceName": {},
          "iops": {
            "type": "long"
          },
          "isBootDisk": {
            "type": "boolean"
          },
          "stagingDiskType": {},
          "throughput": {
            "type": "long"
          }
        }
      }
    }
  }
}
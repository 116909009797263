import { flow } from 'mobx'
import { wait } from '~/src/utils/general'
import { useSelectedStep } from '~/src/hooks/training'
import { useStore } from '~/src/app/store'

export function useInstanceModelIntoSelectedStep() {
  const { appState, undo, screenshots, modelRepository } = useStore()
  const selectedStep = useSelectedStep()
  return flow(function* (modelData) {
    undo.saveSnapshot('Model removed')
    const { id } = selectedStep.addObject(modelData)
    appState.setLoading(id, 0)
    const success = yield modelRepository.loadModel(id, modelData)
    if (success !== null) {
      modelRepository.addModelToScene(id)
      yield wait(100)
      selectedStep.thumbnail = yield screenshots.generateThumbnail(
        `${selectedStep.id}-thumbnail.png`,
      )
    } else {
      undo.discardSnapshot()
      selectedStep.removeObject(id)
    }
    appState.clearLoading(id)
  })
}

import { action } from 'mobx'
import Cloak from '~/src/utils/components/Cloak'
import StepList from '~/src/features/stepList/StepList'
import PlayerViewport from '~/src/features/player/PlayerViewport'
import LoadingScreen from '~/src/app/components/LoadingScreen'
import LeftSidebar from './components/LeftSidebar'
import LeftSidebarDrawer from './components/LeftSidebarDrawer'
import StepInstructions from '~/src/features/stepInstructions/StepInstructions'
import Toolbar from '~/src/features/toolbar/Toolbar'
import PlayerUI from '~/src/app/pages/player/PlayerUI'
import LabelOverlay from '~/src/features/tools/label/LabelOverlay'
import { useStore } from '~/src/app/store'
import SaveStatusDisplay from '~/src/features/persistence/SaveStatusDisplay'
import LiveChat from '~/src/app/components/LiveChat'
import PivotWarningAlert from '~/src/app/components/PivotWarningAlert'
import rotationTool from '~/src/features/tools/rotation/rotationTool'
import scaleTool from '~/src/features/tools/scale/scaleTool'
import RestrictionsMobileOverlay from './RestrictionsMobileOverlay'

// NOTE: this page has some logic to switch between edit/preview modes
// NOTE: *make sure to NOT re-render <PlayerViewport />*
// NOTE: we're avoiding calling ui.get(...) from THIS component to avoid re-render

const EditorPageView = () => {
  const { appState, toolbar, undo } = useStore()

  const isMode =
    (...modes) =>
    () =>
      modes.includes(appState.mode)

  const onResetPivotTransforms = action(() => {
    const node = toolbar.selectedNode
    const step = appState.getSelectedStep()
    if (!node || !step) return
    undo.saveSnapshot('Reset transformations (pivot modal)')
    rotationTool.resetPartRotation(node, step)
    scaleTool.resetPartScale(node, step)
    appState.showPivotWarningModal = false
  })

  return (
    <>
      <div className="editor-container">
        <Cloak isVisible={isMode('editor')}>
          <LeftSidebar />
          <LeftSidebarDrawer />
          <StepList />
          <SaveStatusDisplay />
        </Cloak>

        <div className="viewport-container">
          <PlayerViewport />
          <LabelOverlay />

          {/* On-Viewport Editor UI/Layout */}
          <Cloak isVisible={isMode('editor')}>
            <StepInstructions />
            <Toolbar />
          </Cloak>

          {/* On-Viewport Player UI/Layout */}
          <Cloak isVisible={isMode('player', 'preview')}>
            <PlayerUI />
          </Cloak>

          <Cloak isVisible={() => appState.showPivotWarningModal}>
            <PivotWarningAlert
              isOpen={true}
              onConfirm={onResetPivotTransforms}
            />
          </Cloak>
        </div>

        <LoadingScreen />

        <LiveChat />
      </div>
      <RestrictionsMobileOverlay />
    </>
  )
}

export default EditorPageView

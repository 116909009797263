import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoginPage from './pages/auth/LoginPage'
import PlayerPage from './pages/player/PlayerPage'
import EditorPage from './pages/editor/EditorPage'
import NotFoundPageView from './pages/error/NotFoundPageView'
import { StoreProvider } from './store'
import ProtectedContent from '~/src/app/components/ProtectedContent'
import { env } from '../features/backend/env'

const App = () => (
  <StoreProvider>
    <Router>
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />

        <Route path="/play/:id+" element={<PlayerPage />} />

        <Route path="/not-found" element={<NotFoundPageView />} />

        <Route
          index
          element={() => {
            window.location.replace(env.managerURL)
            return null
          }}
        />

        <Route
          path="/:id"
          element={<ProtectedContent>{() => <EditorPage />}</ProtectedContent>}
        />

        {/* 404 */}
        <Route path="*" element={<NotFoundPageView />} />
      </Routes>
    </Router>
  </StoreProvider>
)

export default App

{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-08-10",
    "endpointPrefix": "batch",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWS Batch",
    "serviceFullName": "AWS Batch",
    "serviceId": "Batch",
    "signatureVersion": "v4",
    "uid": "batch-2016-08-10"
  },
  "operations": {
    "CancelJob": {
      "http": {
        "requestUri": "/v1/canceljob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "reason"
        ],
        "members": {
          "jobId": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateComputeEnvironment": {
      "http": {
        "requestUri": "/v1/createcomputeenvironment"
      },
      "input": {
        "type": "structure",
        "required": [
          "computeEnvironmentName",
          "type"
        ],
        "members": {
          "computeEnvironmentName": {},
          "type": {},
          "state": {},
          "unmanagedvCpus": {
            "type": "integer"
          },
          "computeResources": {
            "shape": "S8"
          },
          "serviceRole": {},
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "computeEnvironmentName": {},
          "computeEnvironmentArn": {}
        }
      }
    },
    "CreateJobQueue": {
      "http": {
        "requestUri": "/v1/createjobqueue"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobQueueName",
          "priority",
          "computeEnvironmentOrder"
        ],
        "members": {
          "jobQueueName": {},
          "state": {},
          "schedulingPolicyArn": {},
          "priority": {
            "type": "integer"
          },
          "computeEnvironmentOrder": {
            "shape": "So"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobQueueName",
          "jobQueueArn"
        ],
        "members": {
          "jobQueueName": {},
          "jobQueueArn": {}
        }
      }
    },
    "CreateSchedulingPolicy": {
      "http": {
        "requestUri": "/v1/createschedulingpolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "fairsharePolicy": {
            "shape": "Ss"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "name",
          "arn"
        ],
        "members": {
          "name": {},
          "arn": {}
        }
      }
    },
    "DeleteComputeEnvironment": {
      "http": {
        "requestUri": "/v1/deletecomputeenvironment"
      },
      "input": {
        "type": "structure",
        "required": [
          "computeEnvironment"
        ],
        "members": {
          "computeEnvironment": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteJobQueue": {
      "http": {
        "requestUri": "/v1/deletejobqueue"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobQueue"
        ],
        "members": {
          "jobQueue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSchedulingPolicy": {
      "http": {
        "requestUri": "/v1/deleteschedulingpolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterJobDefinition": {
      "http": {
        "requestUri": "/v1/deregisterjobdefinition"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobDefinition"
        ],
        "members": {
          "jobDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeComputeEnvironments": {
      "http": {
        "requestUri": "/v1/describecomputeenvironments"
      },
      "input": {
        "type": "structure",
        "members": {
          "computeEnvironments": {
            "shape": "Sb"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "computeEnvironments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "computeEnvironmentName",
                "computeEnvironmentArn"
              ],
              "members": {
                "computeEnvironmentName": {},
                "computeEnvironmentArn": {},
                "unmanagedvCpus": {
                  "type": "integer"
                },
                "ecsClusterArn": {},
                "tags": {
                  "shape": "Si"
                },
                "type": {},
                "state": {},
                "status": {},
                "statusReason": {},
                "computeResources": {
                  "shape": "S8"
                },
                "serviceRole": {},
                "updatePolicy": {
                  "shape": "S1a"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobDefinitions": {
      "http": {
        "requestUri": "/v1/describejobdefinitions"
      },
      "input": {
        "type": "structure",
        "members": {
          "jobDefinitions": {
            "shape": "Sb"
          },
          "maxResults": {
            "type": "integer"
          },
          "jobDefinitionName": {},
          "status": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobDefinitions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobDefinitionName",
                "jobDefinitionArn",
                "revision",
                "type"
              ],
              "members": {
                "jobDefinitionName": {},
                "jobDefinitionArn": {},
                "revision": {
                  "type": "integer"
                },
                "status": {},
                "type": {},
                "schedulingPriority": {
                  "type": "integer"
                },
                "parameters": {
                  "shape": "S1h"
                },
                "retryStrategy": {
                  "shape": "S1i"
                },
                "containerProperties": {
                  "shape": "S1m"
                },
                "timeout": {
                  "shape": "S2i"
                },
                "nodeProperties": {
                  "shape": "S2j"
                },
                "tags": {
                  "shape": "Si"
                },
                "propagateTags": {
                  "type": "boolean"
                },
                "platformCapabilities": {
                  "shape": "S2m"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobQueues": {
      "http": {
        "requestUri": "/v1/describejobqueues"
      },
      "input": {
        "type": "structure",
        "members": {
          "jobQueues": {
            "shape": "Sb"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobQueues": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobQueueName",
                "jobQueueArn",
                "state",
                "priority",
                "computeEnvironmentOrder"
              ],
              "members": {
                "jobQueueName": {},
                "jobQueueArn": {},
                "state": {},
                "schedulingPolicyArn": {},
                "status": {},
                "statusReason": {},
                "priority": {
                  "type": "integer"
                },
                "computeEnvironmentOrder": {
                  "shape": "So"
                },
                "tags": {
                  "shape": "Si"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobs": {
      "http": {
        "requestUri": "/v1/describejobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobs"
        ],
        "members": {
          "jobs": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobName",
                "jobId",
                "jobQueue",
                "status",
                "startedAt",
                "jobDefinition"
              ],
              "members": {
                "jobArn": {},
                "jobName": {},
                "jobId": {},
                "jobQueue": {},
                "status": {},
                "shareIdentifier": {},
                "schedulingPriority": {
                  "type": "integer"
                },
                "attempts": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "container": {
                        "type": "structure",
                        "members": {
                          "containerInstanceArn": {},
                          "taskArn": {},
                          "exitCode": {
                            "type": "integer"
                          },
                          "reason": {},
                          "logStreamName": {},
                          "networkInterfaces": {
                            "shape": "S31"
                          }
                        }
                      },
                      "startedAt": {
                        "type": "long"
                      },
                      "stoppedAt": {
                        "type": "long"
                      },
                      "statusReason": {}
                    }
                  }
                },
                "statusReason": {},
                "createdAt": {
                  "type": "long"
                },
                "retryStrategy": {
                  "shape": "S1i"
                },
                "startedAt": {
                  "type": "long"
                },
                "stoppedAt": {
                  "type": "long"
                },
                "dependsOn": {
                  "shape": "S34"
                },
                "jobDefinition": {},
                "parameters": {
                  "shape": "S1h"
                },
                "container": {
                  "type": "structure",
                  "members": {
                    "image": {},
                    "vcpus": {
                      "type": "integer"
                    },
                    "memory": {
                      "type": "integer"
                    },
                    "command": {
                      "shape": "Sb"
                    },
                    "jobRoleArn": {},
                    "executionRoleArn": {},
                    "volumes": {
                      "shape": "S1n"
                    },
                    "environment": {
                      "shape": "S1u"
                    },
                    "mountPoints": {
                      "shape": "S1w"
                    },
                    "readonlyRootFilesystem": {
                      "type": "boolean"
                    },
                    "ulimits": {
                      "shape": "S1y"
                    },
                    "privileged": {
                      "type": "boolean"
                    },
                    "user": {},
                    "exitCode": {
                      "type": "integer"
                    },
                    "reason": {},
                    "containerInstanceArn": {},
                    "taskArn": {},
                    "logStreamName": {},
                    "instanceType": {},
                    "networkInterfaces": {
                      "shape": "S31"
                    },
                    "resourceRequirements": {
                      "shape": "S20"
                    },
                    "linuxParameters": {
                      "shape": "S23"
                    },
                    "logConfiguration": {
                      "shape": "S2a"
                    },
                    "secrets": {
                      "shape": "S2d"
                    },
                    "networkConfiguration": {
                      "shape": "S2f"
                    },
                    "fargatePlatformConfiguration": {
                      "shape": "S2h"
                    }
                  }
                },
                "nodeDetails": {
                  "type": "structure",
                  "members": {
                    "nodeIndex": {
                      "type": "integer"
                    },
                    "isMainNode": {
                      "type": "boolean"
                    }
                  }
                },
                "nodeProperties": {
                  "shape": "S2j"
                },
                "arrayProperties": {
                  "type": "structure",
                  "members": {
                    "statusSummary": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "integer"
                      }
                    },
                    "size": {
                      "type": "integer"
                    },
                    "index": {
                      "type": "integer"
                    }
                  }
                },
                "timeout": {
                  "shape": "S2i"
                },
                "tags": {
                  "shape": "Si"
                },
                "propagateTags": {
                  "type": "boolean"
                },
                "platformCapabilities": {
                  "shape": "S2m"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSchedulingPolicies": {
      "http": {
        "requestUri": "/v1/describeschedulingpolicies"
      },
      "input": {
        "type": "structure",
        "required": [
          "arns"
        ],
        "members": {
          "arns": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schedulingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "arn"
              ],
              "members": {
                "name": {},
                "arn": {},
                "fairsharePolicy": {
                  "shape": "Ss"
                },
                "tags": {
                  "shape": "Si"
                }
              }
            }
          }
        }
      }
    },
    "ListJobs": {
      "http": {
        "requestUri": "/v1/listjobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "jobQueue": {},
          "arrayJobId": {},
          "multiNodeJobId": {},
          "jobStatus": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "values": {
                  "shape": "Sb"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobSummaryList"
        ],
        "members": {
          "jobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobId",
                "jobName"
              ],
              "members": {
                "jobArn": {},
                "jobId": {},
                "jobName": {},
                "createdAt": {
                  "type": "long"
                },
                "status": {},
                "statusReason": {},
                "startedAt": {
                  "type": "long"
                },
                "stoppedAt": {
                  "type": "long"
                },
                "container": {
                  "type": "structure",
                  "members": {
                    "exitCode": {
                      "type": "integer"
                    },
                    "reason": {}
                  }
                },
                "arrayProperties": {
                  "type": "structure",
                  "members": {
                    "size": {
                      "type": "integer"
                    },
                    "index": {
                      "type": "integer"
                    }
                  }
                },
                "nodeProperties": {
                  "type": "structure",
                  "members": {
                    "isMainNode": {
                      "type": "boolean"
                    },
                    "numNodes": {
                      "type": "integer"
                    },
                    "nodeIndex": {
                      "type": "integer"
                    }
                  }
                },
                "jobDefinition": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSchedulingPolicies": {
      "http": {
        "requestUri": "/v1/listschedulingpolicies"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schedulingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn"
              ],
              "members": {
                "arn": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Si"
          }
        }
      }
    },
    "RegisterJobDefinition": {
      "http": {
        "requestUri": "/v1/registerjobdefinition"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobDefinitionName",
          "type"
        ],
        "members": {
          "jobDefinitionName": {},
          "type": {},
          "parameters": {
            "shape": "S1h"
          },
          "schedulingPriority": {
            "type": "integer"
          },
          "containerProperties": {
            "shape": "S1m"
          },
          "nodeProperties": {
            "shape": "S2j"
          },
          "retryStrategy": {
            "shape": "S1i"
          },
          "propagateTags": {
            "type": "boolean"
          },
          "timeout": {
            "shape": "S2i"
          },
          "tags": {
            "shape": "Si"
          },
          "platformCapabilities": {
            "shape": "S2m"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobDefinitionName",
          "jobDefinitionArn",
          "revision"
        ],
        "members": {
          "jobDefinitionName": {},
          "jobDefinitionArn": {},
          "revision": {
            "type": "integer"
          }
        }
      }
    },
    "SubmitJob": {
      "http": {
        "requestUri": "/v1/submitjob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "jobQueue",
          "jobDefinition"
        ],
        "members": {
          "jobName": {},
          "jobQueue": {},
          "shareIdentifier": {},
          "schedulingPriorityOverride": {
            "type": "integer"
          },
          "arrayProperties": {
            "type": "structure",
            "members": {
              "size": {
                "type": "integer"
              }
            }
          },
          "dependsOn": {
            "shape": "S34"
          },
          "jobDefinition": {},
          "parameters": {
            "shape": "S1h"
          },
          "containerOverrides": {
            "shape": "S3z"
          },
          "nodeOverrides": {
            "type": "structure",
            "members": {
              "numNodes": {
                "type": "integer"
              },
              "nodePropertyOverrides": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "targetNodes"
                  ],
                  "members": {
                    "targetNodes": {},
                    "containerOverrides": {
                      "shape": "S3z"
                    }
                  }
                }
              }
            }
          },
          "retryStrategy": {
            "shape": "S1i"
          },
          "propagateTags": {
            "type": "boolean"
          },
          "timeout": {
            "shape": "S2i"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobName",
          "jobId"
        ],
        "members": {
          "jobArn": {},
          "jobName": {},
          "jobId": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TerminateJob": {
      "http": {
        "requestUri": "/v1/terminatejob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "reason"
        ],
        "members": {
          "jobId": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateComputeEnvironment": {
      "http": {
        "requestUri": "/v1/updatecomputeenvironment"
      },
      "input": {
        "type": "structure",
        "required": [
          "computeEnvironment"
        ],
        "members": {
          "computeEnvironment": {},
          "state": {},
          "unmanagedvCpus": {
            "type": "integer"
          },
          "computeResources": {
            "type": "structure",
            "members": {
              "minvCpus": {
                "type": "integer"
              },
              "maxvCpus": {
                "type": "integer"
              },
              "desiredvCpus": {
                "type": "integer"
              },
              "subnets": {
                "shape": "Sb"
              },
              "securityGroupIds": {
                "shape": "Sb"
              },
              "allocationStrategy": {},
              "instanceTypes": {
                "shape": "Sb"
              },
              "ec2KeyPair": {},
              "instanceRole": {},
              "tags": {
                "shape": "Sc"
              },
              "placementGroup": {},
              "bidPercentage": {
                "type": "integer"
              },
              "launchTemplate": {
                "shape": "Sd"
              },
              "ec2Configuration": {
                "shape": "Se"
              },
              "updateToLatestImageVersion": {
                "type": "boolean"
              },
              "type": {},
              "imageId": {}
            }
          },
          "serviceRole": {},
          "updatePolicy": {
            "shape": "S1a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "computeEnvironmentName": {},
          "computeEnvironmentArn": {}
        }
      }
    },
    "UpdateJobQueue": {
      "http": {
        "requestUri": "/v1/updatejobqueue"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobQueue"
        ],
        "members": {
          "jobQueue": {},
          "state": {},
          "schedulingPolicyArn": {},
          "priority": {
            "type": "integer"
          },
          "computeEnvironmentOrder": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobQueueName": {},
          "jobQueueArn": {}
        }
      }
    },
    "UpdateSchedulingPolicy": {
      "http": {
        "requestUri": "/v1/updateschedulingpolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "fairsharePolicy": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "structure",
      "required": [
        "type",
        "maxvCpus",
        "subnets"
      ],
      "members": {
        "type": {},
        "allocationStrategy": {},
        "minvCpus": {
          "type": "integer"
        },
        "maxvCpus": {
          "type": "integer"
        },
        "desiredvCpus": {
          "type": "integer"
        },
        "instanceTypes": {
          "shape": "Sb"
        },
        "imageId": {
          "deprecated": true,
          "deprecatedMessage": "This field is deprecated, use ec2Configuration[].imageIdOverride instead."
        },
        "subnets": {
          "shape": "Sb"
        },
        "securityGroupIds": {
          "shape": "Sb"
        },
        "ec2KeyPair": {},
        "instanceRole": {},
        "tags": {
          "shape": "Sc"
        },
        "placementGroup": {},
        "bidPercentage": {
          "type": "integer"
        },
        "spotIamFleetRole": {},
        "launchTemplate": {
          "shape": "Sd"
        },
        "ec2Configuration": {
          "shape": "Se"
        }
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sc": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sd": {
      "type": "structure",
      "members": {
        "launchTemplateId": {},
        "launchTemplateName": {},
        "version": {}
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "imageType"
        ],
        "members": {
          "imageType": {},
          "imageIdOverride": {}
        }
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "So": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "order",
          "computeEnvironment"
        ],
        "members": {
          "order": {
            "type": "integer"
          },
          "computeEnvironment": {}
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "shareDecaySeconds": {
          "type": "integer"
        },
        "computeReservation": {
          "type": "integer"
        },
        "shareDistribution": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "shareIdentifier"
            ],
            "members": {
              "shareIdentifier": {},
              "weightFactor": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "terminateJobsOnUpdate": {
          "type": "boolean"
        },
        "jobExecutionTimeoutMinutes": {
          "type": "long"
        }
      }
    },
    "S1h": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1i": {
      "type": "structure",
      "members": {
        "attempts": {
          "type": "integer"
        },
        "evaluateOnExit": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "action"
            ],
            "members": {
              "onStatusReason": {},
              "onReason": {},
              "onExitCode": {},
              "action": {}
            }
          }
        }
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "image": {},
        "vcpus": {
          "deprecated": true,
          "deprecatedMessage": "This field is deprecated, use resourceRequirements instead.",
          "type": "integer"
        },
        "memory": {
          "deprecated": true,
          "deprecatedMessage": "This field is deprecated, use resourceRequirements instead.",
          "type": "integer"
        },
        "command": {
          "shape": "Sb"
        },
        "jobRoleArn": {},
        "executionRoleArn": {},
        "volumes": {
          "shape": "S1n"
        },
        "environment": {
          "shape": "S1u"
        },
        "mountPoints": {
          "shape": "S1w"
        },
        "readonlyRootFilesystem": {
          "type": "boolean"
        },
        "privileged": {
          "type": "boolean"
        },
        "ulimits": {
          "shape": "S1y"
        },
        "user": {},
        "instanceType": {},
        "resourceRequirements": {
          "shape": "S20"
        },
        "linuxParameters": {
          "shape": "S23"
        },
        "logConfiguration": {
          "shape": "S2a"
        },
        "secrets": {
          "shape": "S2d"
        },
        "networkConfiguration": {
          "shape": "S2f"
        },
        "fargatePlatformConfiguration": {
          "shape": "S2h"
        }
      }
    },
    "S1n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "host": {
            "type": "structure",
            "members": {
              "sourcePath": {}
            }
          },
          "name": {},
          "efsVolumeConfiguration": {
            "type": "structure",
            "required": [
              "fileSystemId"
            ],
            "members": {
              "fileSystemId": {},
              "rootDirectory": {},
              "transitEncryption": {},
              "transitEncryptionPort": {
                "type": "integer"
              },
              "authorizationConfig": {
                "type": "structure",
                "members": {
                  "accessPointId": {},
                  "iam": {}
                }
              }
            }
          }
        }
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "containerPath": {},
          "readOnly": {
            "type": "boolean"
          },
          "sourceVolume": {}
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "hardLimit",
          "name",
          "softLimit"
        ],
        "members": {
          "hardLimit": {
            "type": "integer"
          },
          "name": {},
          "softLimit": {
            "type": "integer"
          }
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value",
          "type"
        ],
        "members": {
          "value": {},
          "type": {}
        }
      }
    },
    "S23": {
      "type": "structure",
      "members": {
        "devices": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "hostPath"
            ],
            "members": {
              "hostPath": {},
              "containerPath": {},
              "permissions": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "initProcessEnabled": {
          "type": "boolean"
        },
        "sharedMemorySize": {
          "type": "integer"
        },
        "tmpfs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "containerPath",
              "size"
            ],
            "members": {
              "containerPath": {},
              "size": {
                "type": "integer"
              },
              "mountOptions": {
                "shape": "Sb"
              }
            }
          }
        },
        "maxSwap": {
          "type": "integer"
        },
        "swappiness": {
          "type": "integer"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "required": [
        "logDriver"
      ],
      "members": {
        "logDriver": {},
        "options": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "secretOptions": {
          "shape": "S2d"
        }
      }
    },
    "S2d": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "valueFrom"
        ],
        "members": {
          "name": {},
          "valueFrom": {}
        }
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "assignPublicIp": {}
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "platformVersion": {}
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "attemptDurationSeconds": {
          "type": "integer"
        }
      }
    },
    "S2j": {
      "type": "structure",
      "required": [
        "numNodes",
        "mainNode",
        "nodeRangeProperties"
      ],
      "members": {
        "numNodes": {
          "type": "integer"
        },
        "mainNode": {
          "type": "integer"
        },
        "nodeRangeProperties": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "targetNodes"
            ],
            "members": {
              "targetNodes": {},
              "container": {
                "shape": "S1m"
              }
            }
          }
        }
      }
    },
    "S2m": {
      "type": "list",
      "member": {}
    },
    "S31": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "attachmentId": {},
          "ipv6Address": {},
          "privateIpv4Address": {}
        }
      }
    },
    "S34": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "jobId": {},
          "type": {}
        }
      }
    },
    "S3z": {
      "type": "structure",
      "members": {
        "vcpus": {
          "deprecated": true,
          "deprecatedMessage": "This field is deprecated, use resourceRequirements instead.",
          "type": "integer"
        },
        "memory": {
          "deprecated": true,
          "deprecatedMessage": "This field is deprecated, use resourceRequirements instead.",
          "type": "integer"
        },
        "command": {
          "shape": "Sb"
        },
        "instanceType": {},
        "environment": {
          "shape": "S1u"
        },
        "resourceRequirements": {
          "shape": "S20"
        }
      }
    }
  }
}
{
  "pagination": {
    "GetAppMonitorData": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Events"
    },
    "ListAppMonitors": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AppMonitorSummaries"
    }
  }
}

{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-09",
    "endpointPrefix": "datasync",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "DataSync",
    "serviceFullName": "AWS DataSync",
    "serviceId": "DataSync",
    "signatureVersion": "v4",
    "signingName": "datasync",
    "targetPrefix": "FmrsService",
    "uid": "datasync-2018-11-09"
  },
  "operations": {
    "CancelTaskExecution": {
      "input": {
        "type": "structure",
        "required": [
          "TaskExecutionArn"
        ],
        "members": {
          "TaskExecutionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAgent": {
      "input": {
        "type": "structure",
        "required": [
          "ActivationKey"
        ],
        "members": {
          "ActivationKey": {},
          "AgentName": {},
          "Tags": {
            "shape": "S7"
          },
          "VpcEndpointId": {},
          "SubnetArns": {
            "shape": "Sb"
          },
          "SecurityGroupArns": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AgentArn": {}
        }
      }
    },
    "CreateLocationEfs": {
      "input": {
        "type": "structure",
        "required": [
          "EfsFilesystemArn",
          "Ec2Config"
        ],
        "members": {
          "Subdirectory": {},
          "EfsFilesystemArn": {},
          "Ec2Config": {
            "shape": "Sk"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationFsxLustre": {
      "input": {
        "type": "structure",
        "required": [
          "FsxFilesystemArn",
          "SecurityGroupArns"
        ],
        "members": {
          "FsxFilesystemArn": {},
          "SecurityGroupArns": {
            "shape": "Sl"
          },
          "Subdirectory": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationFsxOpenZfs": {
      "input": {
        "type": "structure",
        "required": [
          "FsxFilesystemArn",
          "Protocol",
          "SecurityGroupArns"
        ],
        "members": {
          "FsxFilesystemArn": {},
          "Protocol": {
            "shape": "St"
          },
          "SecurityGroupArns": {
            "shape": "Sl"
          },
          "Subdirectory": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationFsxWindows": {
      "input": {
        "type": "structure",
        "required": [
          "FsxFilesystemArn",
          "SecurityGroupArns",
          "User",
          "Password"
        ],
        "members": {
          "Subdirectory": {},
          "FsxFilesystemArn": {},
          "SecurityGroupArns": {
            "shape": "Sl"
          },
          "Tags": {
            "shape": "S7"
          },
          "User": {},
          "Domain": {},
          "Password": {
            "shape": "S13"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationHdfs": {
      "input": {
        "type": "structure",
        "required": [
          "NameNodes",
          "AuthenticationType",
          "AgentArns"
        ],
        "members": {
          "Subdirectory": {},
          "NameNodes": {
            "shape": "S17"
          },
          "BlockSize": {
            "type": "integer"
          },
          "ReplicationFactor": {
            "type": "integer"
          },
          "KmsKeyProviderUri": {},
          "QopConfiguration": {
            "shape": "S1e"
          },
          "AuthenticationType": {},
          "SimpleUser": {},
          "KerberosPrincipal": {},
          "KerberosKeytab": {
            "type": "blob"
          },
          "KerberosKrb5Conf": {
            "type": "blob"
          },
          "AgentArns": {
            "shape": "S1m"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationNfs": {
      "input": {
        "type": "structure",
        "required": [
          "Subdirectory",
          "ServerHostname",
          "OnPremConfig"
        ],
        "members": {
          "Subdirectory": {},
          "ServerHostname": {},
          "OnPremConfig": {
            "shape": "S1r"
          },
          "MountOptions": {
            "shape": "Sv"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationObjectStorage": {
      "input": {
        "type": "structure",
        "required": [
          "ServerHostname",
          "BucketName",
          "AgentArns"
        ],
        "members": {
          "ServerHostname": {},
          "ServerPort": {
            "type": "integer"
          },
          "ServerProtocol": {},
          "Subdirectory": {},
          "BucketName": {},
          "AccessKey": {},
          "SecretKey": {
            "shape": "S1z"
          },
          "AgentArns": {
            "shape": "S1m"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationS3": {
      "input": {
        "type": "structure",
        "required": [
          "S3BucketArn",
          "S3Config"
        ],
        "members": {
          "Subdirectory": {},
          "S3BucketArn": {},
          "S3StorageClass": {},
          "S3Config": {
            "shape": "S24"
          },
          "AgentArns": {
            "shape": "S1m"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateLocationSmb": {
      "input": {
        "type": "structure",
        "required": [
          "Subdirectory",
          "ServerHostname",
          "User",
          "Password",
          "AgentArns"
        ],
        "members": {
          "Subdirectory": {},
          "ServerHostname": {},
          "User": {},
          "Domain": {},
          "Password": {
            "shape": "S13"
          },
          "AgentArns": {
            "shape": "S1m"
          },
          "MountOptions": {
            "shape": "S29"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {}
        }
      }
    },
    "CreateTask": {
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationArn",
          "DestinationLocationArn"
        ],
        "members": {
          "SourceLocationArn": {},
          "DestinationLocationArn": {},
          "CloudWatchLogGroupArn": {},
          "Name": {},
          "Options": {
            "shape": "S2e"
          },
          "Excludes": {
            "shape": "S2t"
          },
          "Schedule": {
            "shape": "S2x"
          },
          "Tags": {
            "shape": "S7"
          },
          "Includes": {
            "shape": "S2t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskArn": {}
        }
      }
    },
    "DeleteAgent": {
      "input": {
        "type": "structure",
        "required": [
          "AgentArn"
        ],
        "members": {
          "AgentArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteLocation": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTask": {
      "input": {
        "type": "structure",
        "required": [
          "TaskArn"
        ],
        "members": {
          "TaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAgent": {
      "input": {
        "type": "structure",
        "required": [
          "AgentArn"
        ],
        "members": {
          "AgentArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AgentArn": {},
          "Name": {},
          "Status": {},
          "LastConnectionTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "EndpointType": {},
          "PrivateLinkConfig": {
            "type": "structure",
            "members": {
              "VpcEndpointId": {},
              "PrivateLinkEndpoint": {},
              "SubnetArns": {
                "shape": "Sb"
              },
              "SecurityGroupArns": {
                "shape": "Sd"
              }
            }
          }
        }
      }
    },
    "DescribeLocationEfs": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "Ec2Config": {
            "shape": "Sk"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationFsxLustre": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "SecurityGroupArns": {
            "shape": "Sl"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationFsxOpenZfs": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "SecurityGroupArns": {
            "shape": "Sl"
          },
          "Protocol": {
            "shape": "St"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationFsxWindows": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "SecurityGroupArns": {
            "shape": "Sl"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "User": {},
          "Domain": {}
        }
      }
    },
    "DescribeLocationHdfs": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "NameNodes": {
            "shape": "S17"
          },
          "BlockSize": {
            "type": "integer"
          },
          "ReplicationFactor": {
            "type": "integer"
          },
          "KmsKeyProviderUri": {},
          "QopConfiguration": {
            "shape": "S1e"
          },
          "AuthenticationType": {},
          "SimpleUser": {},
          "KerberosPrincipal": {},
          "AgentArns": {
            "shape": "S1m"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationNfs": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "OnPremConfig": {
            "shape": "S1r"
          },
          "MountOptions": {
            "shape": "Sv"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationObjectStorage": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "AccessKey": {},
          "ServerPort": {
            "type": "integer"
          },
          "ServerProtocol": {},
          "AgentArns": {
            "shape": "S1m"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationS3": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "S3StorageClass": {},
          "S3Config": {
            "shape": "S24"
          },
          "AgentArns": {
            "shape": "S1m"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeLocationSmb": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationArn": {},
          "LocationUri": {},
          "AgentArns": {
            "shape": "S1m"
          },
          "User": {},
          "Domain": {},
          "MountOptions": {
            "shape": "S29"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeTask": {
      "input": {
        "type": "structure",
        "required": [
          "TaskArn"
        ],
        "members": {
          "TaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskArn": {},
          "Status": {},
          "Name": {},
          "CurrentTaskExecutionArn": {},
          "SourceLocationArn": {},
          "DestinationLocationArn": {},
          "CloudWatchLogGroupArn": {},
          "SourceNetworkInterfaceArns": {
            "type": "list",
            "member": {}
          },
          "DestinationNetworkInterfaceArns": {
            "type": "list",
            "member": {}
          },
          "Options": {
            "shape": "S2e"
          },
          "Excludes": {
            "shape": "S2t"
          },
          "Schedule": {
            "shape": "S2x"
          },
          "ErrorCode": {},
          "ErrorDetail": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "Includes": {
            "shape": "S2t"
          }
        }
      }
    },
    "DescribeTaskExecution": {
      "input": {
        "type": "structure",
        "required": [
          "TaskExecutionArn"
        ],
        "members": {
          "TaskExecutionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskExecutionArn": {},
          "Status": {},
          "Options": {
            "shape": "S2e"
          },
          "Excludes": {
            "shape": "S2t"
          },
          "Includes": {
            "shape": "S2t"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EstimatedFilesToTransfer": {
            "type": "long"
          },
          "EstimatedBytesToTransfer": {
            "type": "long"
          },
          "FilesTransferred": {
            "type": "long"
          },
          "BytesWritten": {
            "type": "long"
          },
          "BytesTransferred": {
            "type": "long"
          },
          "Result": {
            "type": "structure",
            "members": {
              "PrepareDuration": {
                "type": "long"
              },
              "PrepareStatus": {},
              "TotalDuration": {
                "type": "long"
              },
              "TransferDuration": {
                "type": "long"
              },
              "TransferStatus": {},
              "VerifyDuration": {
                "type": "long"
              },
              "VerifyStatus": {},
              "ErrorCode": {},
              "ErrorDetail": {}
            }
          }
        }
      }
    },
    "ListAgents": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Agents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AgentArn": {},
                "Name": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLocations": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values",
                "Operator"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "shape": "S4l"
                },
                "Operator": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Locations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LocationArn": {},
                "LocationUri": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "shape": "S8"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTaskExecutions": {
      "input": {
        "type": "structure",
        "members": {
          "TaskArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TaskExecutionArn": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTasks": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values",
                "Operator"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "shape": "S4l"
                },
                "Operator": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TaskArn": {},
                "Status": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartTaskExecution": {
      "input": {
        "type": "structure",
        "required": [
          "TaskArn"
        ],
        "members": {
          "TaskArn": {},
          "OverrideOptions": {
            "shape": "S2e"
          },
          "Includes": {
            "shape": "S2t"
          },
          "Excludes": {
            "shape": "S2t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskExecutionArn": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Keys"
        ],
        "members": {
          "ResourceArn": {},
          "Keys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAgent": {
      "input": {
        "type": "structure",
        "required": [
          "AgentArn"
        ],
        "members": {
          "AgentArn": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLocationHdfs": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {},
          "Subdirectory": {},
          "NameNodes": {
            "shape": "S17"
          },
          "BlockSize": {
            "type": "integer"
          },
          "ReplicationFactor": {
            "type": "integer"
          },
          "KmsKeyProviderUri": {},
          "QopConfiguration": {
            "shape": "S1e"
          },
          "AuthenticationType": {},
          "SimpleUser": {},
          "KerberosPrincipal": {},
          "KerberosKeytab": {
            "type": "blob"
          },
          "KerberosKrb5Conf": {
            "type": "blob"
          },
          "AgentArns": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLocationNfs": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {},
          "Subdirectory": {},
          "OnPremConfig": {
            "shape": "S1r"
          },
          "MountOptions": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLocationObjectStorage": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {},
          "ServerPort": {
            "type": "integer"
          },
          "ServerProtocol": {},
          "Subdirectory": {},
          "AccessKey": {},
          "SecretKey": {
            "shape": "S1z"
          },
          "AgentArns": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLocationSmb": {
      "input": {
        "type": "structure",
        "required": [
          "LocationArn"
        ],
        "members": {
          "LocationArn": {},
          "Subdirectory": {},
          "User": {},
          "Domain": {},
          "Password": {
            "shape": "S13"
          },
          "AgentArns": {
            "shape": "S1m"
          },
          "MountOptions": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTask": {
      "input": {
        "type": "structure",
        "required": [
          "TaskArn"
        ],
        "members": {
          "TaskArn": {},
          "Options": {
            "shape": "S2e"
          },
          "Excludes": {
            "shape": "S2t"
          },
          "Schedule": {
            "shape": "S2x"
          },
          "Name": {},
          "CloudWatchLogGroupArn": {},
          "Includes": {
            "shape": "S2t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTaskExecution": {
      "input": {
        "type": "structure",
        "required": [
          "TaskExecutionArn",
          "Options"
        ],
        "members": {
          "TaskExecutionArn": {},
          "Options": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {
        "shape": "S8"
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "Key"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sk": {
      "type": "structure",
      "required": [
        "SubnetArn",
        "SecurityGroupArns"
      ],
      "members": {
        "SubnetArn": {},
        "SecurityGroupArns": {
          "shape": "Sl"
        }
      }
    },
    "Sl": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "structure",
      "members": {
        "NFS": {
          "type": "structure",
          "members": {
            "MountOptions": {
              "shape": "Sv"
            }
          }
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "Version": {}
      }
    },
    "S13": {
      "type": "string",
      "sensitive": true
    },
    "S17": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Hostname",
          "Port"
        ],
        "members": {
          "Hostname": {},
          "Port": {
            "type": "integer"
          }
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "RpcProtection": {},
        "DataTransferProtection": {}
      }
    },
    "S1m": {
      "type": "list",
      "member": {}
    },
    "S1r": {
      "type": "structure",
      "required": [
        "AgentArns"
      ],
      "members": {
        "AgentArns": {
          "shape": "S1m"
        }
      }
    },
    "S1z": {
      "type": "string",
      "sensitive": true
    },
    "S24": {
      "type": "structure",
      "required": [
        "BucketAccessRoleArn"
      ],
      "members": {
        "BucketAccessRoleArn": {}
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "Version": {}
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "VerifyMode": {},
        "OverwriteMode": {},
        "Atime": {},
        "Mtime": {},
        "Uid": {},
        "Gid": {},
        "PreserveDeletedFiles": {},
        "PreserveDevices": {},
        "PosixPermissions": {},
        "BytesPerSecond": {
          "type": "long"
        },
        "TaskQueueing": {},
        "LogLevel": {},
        "TransferMode": {},
        "SecurityDescriptorCopyFlags": {}
      }
    },
    "S2t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "FilterType": {},
          "Value": {}
        }
      }
    },
    "S2x": {
      "type": "structure",
      "required": [
        "ScheduleExpression"
      ],
      "members": {
        "ScheduleExpression": {}
      }
    },
    "S4l": {
      "type": "list",
      "member": {}
    }
  }
}
{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-04-08",
    "endpointPrefix": "workspaces",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon WorkSpaces",
    "serviceId": "WorkSpaces",
    "signatureVersion": "v4",
    "targetPrefix": "WorkspacesService",
    "uid": "workspaces-2015-04-08"
  },
  "operations": {
    "AssociateConnectionAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId",
          "ResourceId"
        ],
        "members": {
          "AliasId": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionIdentifier": {}
        }
      }
    },
    "AssociateIpGroups": {
      "input": {
        "type": "structure",
        "required": [
          "DirectoryId",
          "GroupIds"
        ],
        "members": {
          "DirectoryId": {},
          "GroupIds": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AuthorizeIpRules": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "UserRules"
        ],
        "members": {
          "GroupId": {},
          "UserRules": {
            "shape": "Sc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CopyWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SourceImageId",
          "SourceRegion"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "SourceImageId": {},
          "SourceRegion": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {}
        }
      }
    },
    "CreateConnectClientAddIn": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Name",
          "URL"
        ],
        "members": {
          "ResourceId": {},
          "Name": {},
          "URL": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddInId": {}
        }
      }
    },
    "CreateConnectionAlias": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionString"
        ],
        "members": {
          "ConnectionString": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AliasId": {}
        }
      }
    },
    "CreateIpGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "GroupDesc": {},
          "UserRules": {
            "shape": "Sc"
          },
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {}
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateUpdatedWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Description",
          "SourceImageId"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "SourceImageId": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {}
        }
      }
    },
    "CreateWorkspaceBundle": {
      "input": {
        "type": "structure",
        "required": [
          "BundleName",
          "BundleDescription",
          "ImageId",
          "ComputeType",
          "UserStorage"
        ],
        "members": {
          "BundleName": {},
          "BundleDescription": {},
          "ImageId": {},
          "ComputeType": {
            "shape": "S1a"
          },
          "UserStorage": {
            "shape": "S1c"
          },
          "RootStorage": {
            "shape": "S1d"
          },
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkspaceBundle": {
            "shape": "S1f"
          }
        }
      }
    },
    "CreateWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "Workspaces"
        ],
        "members": {
          "Workspaces": {
            "type": "list",
            "member": {
              "shape": "S1m"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceRequest": {
                  "shape": "S1m"
                },
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "PendingRequests": {
            "shape": "S1z"
          }
        }
      }
    },
    "DeleteClientBranding": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Platforms"
        ],
        "members": {
          "ResourceId": {},
          "Platforms": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConnectClientAddIn": {
      "input": {
        "type": "structure",
        "required": [
          "AddInId",
          "ResourceId"
        ],
        "members": {
          "AddInId": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConnectionAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteIpGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkspaceBundle": {
      "input": {
        "type": "structure",
        "members": {
          "BundleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterWorkspaceDirectory": {
      "input": {
        "type": "structure",
        "required": [
          "DirectoryId"
        ],
        "members": {
          "DirectoryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccount": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "DedicatedTenancySupport": {},
          "DedicatedTenancyManagementCidrRange": {}
        }
      }
    },
    "DescribeAccountModifications": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountModifications": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModificationState": {},
                "DedicatedTenancySupport": {},
                "DedicatedTenancyManagementCidrRange": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeClientBranding": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeviceTypeWindows": {
            "shape": "S36"
          },
          "DeviceTypeOsx": {
            "shape": "S36"
          },
          "DeviceTypeAndroid": {
            "shape": "S36"
          },
          "DeviceTypeIos": {
            "shape": "S3c"
          },
          "DeviceTypeLinux": {
            "shape": "S36"
          },
          "DeviceTypeWeb": {
            "shape": "S36"
          }
        }
      }
    },
    "DescribeClientProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIds"
        ],
        "members": {
          "ResourceIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientPropertiesList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceId": {},
                "ClientProperties": {
                  "shape": "S3i"
                }
              }
            }
          }
        }
      }
    },
    "DescribeConnectClientAddIns": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddIns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AddInId": {},
                "ResourceId": {},
                "Name": {},
                "URL": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConnectionAliasPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AliasId": {},
          "ConnectionAliasPermissions": {
            "type": "list",
            "member": {
              "shape": "S3s"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConnectionAliases": {
      "input": {
        "type": "structure",
        "members": {
          "AliasIds": {
            "type": "list",
            "member": {}
          },
          "ResourceId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionAliases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConnectionString": {},
                "AliasId": {},
                "State": {},
                "OwnerAccountId": {},
                "Associations": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "AssociationStatus": {},
                      "AssociatedAccountId": {},
                      "ResourceId": {},
                      "ConnectionIdentifier": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeIpGroups": {
      "input": {
        "type": "structure",
        "members": {
          "GroupIds": {
            "shape": "S8"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "groupId": {},
                "groupName": {},
                "groupDesc": {},
                "userRules": {
                  "shape": "Sc"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Sm"
          }
        }
      }
    },
    "DescribeWorkspaceBundles": {
      "input": {
        "type": "structure",
        "members": {
          "BundleIds": {
            "type": "list",
            "member": {}
          },
          "Owner": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bundles": {
            "type": "list",
            "member": {
              "shape": "S1f"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaceDirectories": {
      "input": {
        "type": "structure",
        "members": {
          "DirectoryIds": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Directories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DirectoryId": {},
                "Alias": {},
                "DirectoryName": {},
                "RegistrationCode": {},
                "SubnetIds": {
                  "shape": "S4l"
                },
                "DnsIpAddresses": {
                  "type": "list",
                  "member": {}
                },
                "CustomerUserName": {},
                "IamRoleId": {},
                "DirectoryType": {},
                "WorkspaceSecurityGroupId": {},
                "State": {},
                "WorkspaceCreationProperties": {
                  "type": "structure",
                  "members": {
                    "EnableWorkDocs": {
                      "type": "boolean"
                    },
                    "EnableInternetAccess": {
                      "type": "boolean"
                    },
                    "DefaultOu": {},
                    "CustomSecurityGroupId": {},
                    "UserEnabledAsLocalAdministrator": {
                      "type": "boolean"
                    },
                    "EnableMaintenanceMode": {
                      "type": "boolean"
                    }
                  }
                },
                "ipGroupIds": {
                  "shape": "S8"
                },
                "WorkspaceAccessProperties": {
                  "shape": "S4t"
                },
                "Tenancy": {},
                "SelfservicePermissions": {
                  "shape": "S4w"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaceImagePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {},
          "ImagePermissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SharedAccountId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaceImages": {
      "input": {
        "type": "structure",
        "members": {
          "ImageIds": {
            "type": "list",
            "member": {}
          },
          "ImageType": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ImageId": {},
                "Name": {},
                "Description": {},
                "OperatingSystem": {
                  "type": "structure",
                  "members": {
                    "Type": {}
                  }
                },
                "State": {},
                "RequiredTenancy": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "Created": {
                  "type": "timestamp"
                },
                "OwnerAccountId": {},
                "Updates": {
                  "type": "structure",
                  "members": {
                    "UpdateAvailable": {
                      "type": "boolean"
                    },
                    "Description": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaceSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "WorkspaceId"
        ],
        "members": {
          "WorkspaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RebuildSnapshots": {
            "shape": "S5g"
          },
          "RestoreSnapshots": {
            "shape": "S5g"
          }
        }
      }
    },
    "DescribeWorkspaces": {
      "input": {
        "type": "structure",
        "members": {
          "WorkspaceIds": {
            "shape": "S5j"
          },
          "DirectoryId": {},
          "UserName": {},
          "BundleId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workspaces": {
            "shape": "S1z"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspacesConnectionStatus": {
      "input": {
        "type": "structure",
        "members": {
          "WorkspaceIds": {
            "shape": "S5j"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkspacesConnectionStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceId": {},
                "ConnectionState": {},
                "ConnectionStateCheckTimestamp": {
                  "type": "timestamp"
                },
                "LastKnownUserConnectionTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisassociateConnectionAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateIpGroups": {
      "input": {
        "type": "structure",
        "required": [
          "DirectoryId",
          "GroupIds"
        ],
        "members": {
          "DirectoryId": {},
          "GroupIds": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ImportClientBranding": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "DeviceTypeWindows": {
            "shape": "S5v"
          },
          "DeviceTypeOsx": {
            "shape": "S5v"
          },
          "DeviceTypeAndroid": {
            "shape": "S5v"
          },
          "DeviceTypeIos": {
            "type": "structure",
            "members": {
              "Logo": {
                "type": "blob"
              },
              "Logo2x": {
                "type": "blob"
              },
              "Logo3x": {
                "type": "blob"
              },
              "SupportEmail": {},
              "SupportLink": {},
              "ForgotPasswordLink": {},
              "LoginMessage": {
                "shape": "S39"
              }
            }
          },
          "DeviceTypeLinux": {
            "shape": "S5v"
          },
          "DeviceTypeWeb": {
            "shape": "S5v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeviceTypeWindows": {
            "shape": "S36"
          },
          "DeviceTypeOsx": {
            "shape": "S36"
          },
          "DeviceTypeAndroid": {
            "shape": "S36"
          },
          "DeviceTypeIos": {
            "shape": "S3c"
          },
          "DeviceTypeLinux": {
            "shape": "S36"
          },
          "DeviceTypeWeb": {
            "shape": "S36"
          }
        }
      }
    },
    "ImportWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "Ec2ImageId",
          "IngestionProcess",
          "ImageName",
          "ImageDescription"
        ],
        "members": {
          "Ec2ImageId": {},
          "IngestionProcess": {},
          "ImageName": {},
          "ImageDescription": {},
          "Tags": {
            "shape": "Sm"
          },
          "Applications": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {}
        }
      }
    },
    "ListAvailableManagementCidrRanges": {
      "input": {
        "type": "structure",
        "required": [
          "ManagementCidrRangeConstraint"
        ],
        "members": {
          "ManagementCidrRangeConstraint": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ManagementCidrRanges": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "MigrateWorkspace": {
      "input": {
        "type": "structure",
        "required": [
          "SourceWorkspaceId",
          "BundleId"
        ],
        "members": {
          "SourceWorkspaceId": {},
          "BundleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceWorkspaceId": {},
          "TargetWorkspaceId": {}
        }
      }
    },
    "ModifyAccount": {
      "input": {
        "type": "structure",
        "members": {
          "DedicatedTenancySupport": {},
          "DedicatedTenancyManagementCidrRange": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyClientProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "ClientProperties"
        ],
        "members": {
          "ResourceId": {},
          "ClientProperties": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifySelfservicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "SelfservicePermissions"
        ],
        "members": {
          "ResourceId": {},
          "SelfservicePermissions": {
            "shape": "S4w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyWorkspaceAccessProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "WorkspaceAccessProperties"
        ],
        "members": {
          "ResourceId": {},
          "WorkspaceAccessProperties": {
            "shape": "S4t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyWorkspaceCreationProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "WorkspaceCreationProperties"
        ],
        "members": {
          "ResourceId": {},
          "WorkspaceCreationProperties": {
            "type": "structure",
            "members": {
              "EnableWorkDocs": {
                "type": "boolean"
              },
              "EnableInternetAccess": {
                "type": "boolean"
              },
              "DefaultOu": {},
              "CustomSecurityGroupId": {},
              "UserEnabledAsLocalAdministrator": {
                "type": "boolean"
              },
              "EnableMaintenanceMode": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyWorkspaceProperties": {
      "input": {
        "type": "structure",
        "required": [
          "WorkspaceId",
          "WorkspaceProperties"
        ],
        "members": {
          "WorkspaceId": {},
          "WorkspaceProperties": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyWorkspaceState": {
      "input": {
        "type": "structure",
        "required": [
          "WorkspaceId",
          "WorkspaceState"
        ],
        "members": {
          "WorkspaceId": {},
          "WorkspaceState": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RebootWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "RebootWorkspaceRequests"
        ],
        "members": {
          "RebootWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkspaceId"
              ],
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S71"
            }
          }
        }
      }
    },
    "RebuildWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "RebuildWorkspaceRequests"
        ],
        "members": {
          "RebuildWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkspaceId"
              ],
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S71"
            }
          }
        }
      }
    },
    "RegisterWorkspaceDirectory": {
      "input": {
        "type": "structure",
        "required": [
          "DirectoryId",
          "EnableWorkDocs"
        ],
        "members": {
          "DirectoryId": {},
          "SubnetIds": {
            "shape": "S4l"
          },
          "EnableWorkDocs": {
            "type": "boolean"
          },
          "EnableSelfService": {
            "type": "boolean"
          },
          "Tenancy": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RestoreWorkspace": {
      "input": {
        "type": "structure",
        "required": [
          "WorkspaceId"
        ],
        "members": {
          "WorkspaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RevokeIpRules": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "UserRules"
        ],
        "members": {
          "GroupId": {},
          "UserRules": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "StartWorkspaceRequests"
        ],
        "members": {
          "StartWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S71"
            }
          }
        }
      }
    },
    "StopWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "StopWorkspaceRequests"
        ],
        "members": {
          "StopWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S71"
            }
          }
        }
      }
    },
    "TerminateWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "TerminateWorkspaceRequests"
        ],
        "members": {
          "TerminateWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkspaceId"
              ],
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S71"
            }
          }
        }
      }
    },
    "UpdateConnectClientAddIn": {
      "input": {
        "type": "structure",
        "required": [
          "AddInId",
          "ResourceId"
        ],
        "members": {
          "AddInId": {},
          "ResourceId": {},
          "Name": {},
          "URL": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateConnectionAliasPermission": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId",
          "ConnectionAliasPermission"
        ],
        "members": {
          "AliasId": {},
          "ConnectionAliasPermission": {
            "shape": "S3s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRulesOfIpGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "UserRules"
        ],
        "members": {
          "GroupId": {},
          "UserRules": {
            "shape": "Sc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkspaceBundle": {
      "input": {
        "type": "structure",
        "members": {
          "BundleId": {},
          "ImageId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkspaceImagePermission": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId",
          "AllowCopyImage",
          "SharedAccountId"
        ],
        "members": {
          "ImageId": {},
          "AllowCopyImage": {
            "type": "boolean"
          },
          "SharedAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "list",
      "member": {}
    },
    "Sc": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ipRule": {},
          "ruleDesc": {}
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "Name": {}
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "Capacity": {}
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "Capacity": {}
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "BundleId": {},
        "Name": {},
        "Owner": {},
        "Description": {},
        "ImageId": {},
        "RootStorage": {
          "shape": "S1d"
        },
        "UserStorage": {
          "shape": "S1c"
        },
        "ComputeType": {
          "shape": "S1a"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        },
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S1m": {
      "type": "structure",
      "required": [
        "DirectoryId",
        "UserName",
        "BundleId"
      ],
      "members": {
        "DirectoryId": {},
        "UserName": {},
        "BundleId": {},
        "VolumeEncryptionKey": {},
        "UserVolumeEncryptionEnabled": {
          "type": "boolean"
        },
        "RootVolumeEncryptionEnabled": {
          "type": "boolean"
        },
        "WorkspaceProperties": {
          "shape": "S1q"
        },
        "Tags": {
          "shape": "Sm"
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "RunningMode": {},
        "RunningModeAutoStopTimeoutInMinutes": {
          "type": "integer"
        },
        "RootVolumeSizeGib": {
          "type": "integer"
        },
        "UserVolumeSizeGib": {
          "type": "integer"
        },
        "ComputeTypeName": {}
      }
    },
    "S1z": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "WorkspaceId": {},
          "DirectoryId": {},
          "UserName": {},
          "IpAddress": {},
          "State": {},
          "BundleId": {},
          "SubnetId": {},
          "ErrorMessage": {},
          "ErrorCode": {},
          "ComputerName": {},
          "VolumeEncryptionKey": {},
          "UserVolumeEncryptionEnabled": {
            "type": "boolean"
          },
          "RootVolumeEncryptionEnabled": {
            "type": "boolean"
          },
          "WorkspaceProperties": {
            "shape": "S1q"
          },
          "ModificationStates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Resource": {},
                "State": {}
              }
            }
          }
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "LogoUrl": {},
        "SupportEmail": {},
        "SupportLink": {},
        "ForgotPasswordLink": {},
        "LoginMessage": {
          "shape": "S39"
        }
      }
    },
    "S39": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3c": {
      "type": "structure",
      "members": {
        "LogoUrl": {},
        "Logo2xUrl": {},
        "Logo3xUrl": {},
        "SupportEmail": {},
        "SupportLink": {},
        "ForgotPasswordLink": {},
        "LoginMessage": {
          "shape": "S39"
        }
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "ReconnectEnabled": {}
      }
    },
    "S3s": {
      "type": "structure",
      "required": [
        "SharedAccountId",
        "AllowAssociation"
      ],
      "members": {
        "SharedAccountId": {},
        "AllowAssociation": {
          "type": "boolean"
        }
      }
    },
    "S4l": {
      "type": "list",
      "member": {}
    },
    "S4t": {
      "type": "structure",
      "members": {
        "DeviceTypeWindows": {},
        "DeviceTypeOsx": {},
        "DeviceTypeWeb": {},
        "DeviceTypeIos": {},
        "DeviceTypeAndroid": {},
        "DeviceTypeChromeOs": {},
        "DeviceTypeZeroClient": {},
        "DeviceTypeLinux": {}
      }
    },
    "S4w": {
      "type": "structure",
      "members": {
        "RestartWorkspace": {},
        "IncreaseVolumeSize": {},
        "ChangeComputeType": {},
        "SwitchRunningMode": {},
        "RebuildWorkspace": {}
      }
    },
    "S5g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SnapshotTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S5j": {
      "type": "list",
      "member": {}
    },
    "S5v": {
      "type": "structure",
      "members": {
        "Logo": {
          "type": "blob"
        },
        "SupportEmail": {},
        "SupportLink": {},
        "ForgotPasswordLink": {},
        "LoginMessage": {
          "shape": "S39"
        }
      }
    },
    "S71": {
      "type": "structure",
      "members": {
        "WorkspaceId": {},
        "ErrorCode": {},
        "ErrorMessage": {}
      }
    }
  }
}
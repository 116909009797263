{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-03-31",
    "endpointPrefix": "wellarchitected",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Well-Architected",
    "serviceFullName": "AWS Well-Architected Tool",
    "serviceId": "WellArchitected",
    "signatureVersion": "v4",
    "signingName": "wellarchitected",
    "uid": "wellarchitected-2020-03-31"
  },
  "operations": {
    "AssociateLenses": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/associateLenses"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAliases"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAliases": {
            "shape": "S3"
          }
        }
      }
    },
    "CreateLensShare": {
      "http": {
        "requestUri": "/lenses/{LensAlias}/shares"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias",
          "SharedWith",
          "ClientRequestToken"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "SharedWith": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShareId": {}
        }
      }
    },
    "CreateLensVersion": {
      "http": {
        "requestUri": "/lenses/{LensAlias}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias",
          "LensVersion",
          "ClientRequestToken"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "LensVersion": {},
          "IsMajorVersion": {
            "type": "boolean"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensArn": {},
          "LensVersion": {}
        }
      }
    },
    "CreateMilestone": {
      "http": {
        "requestUri": "/workloads/{WorkloadId}/milestones"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "MilestoneName",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "MilestoneName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          }
        }
      }
    },
    "CreateWorkload": {
      "http": {
        "requestUri": "/workloads"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadName",
          "Description",
          "Environment",
          "ReviewOwner",
          "Lenses",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadName": {},
          "Description": {},
          "Environment": {},
          "AccountIds": {
            "shape": "Sn"
          },
          "AwsRegions": {
            "shape": "Sp"
          },
          "NonAwsRegions": {
            "shape": "Sr"
          },
          "PillarPriorities": {
            "shape": "St"
          },
          "ArchitecturalDesign": {},
          "ReviewOwner": {},
          "IndustryType": {},
          "Industry": {},
          "Lenses": {
            "shape": "Sz"
          },
          "Notes": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "WorkloadArn": {}
        }
      }
    },
    "CreateWorkloadShare": {
      "http": {
        "requestUri": "/workloads/{WorkloadId}/shares"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "SharedWith",
          "PermissionType",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "SharedWith": {},
          "PermissionType": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "ShareId": {}
        }
      }
    },
    "DeleteLens": {
      "http": {
        "method": "DELETE",
        "requestUri": "/lenses/{LensAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias",
          "ClientRequestToken",
          "LensStatus"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "ClientRequestToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "ClientRequestToken"
          },
          "LensStatus": {
            "location": "querystring",
            "locationName": "LensStatus"
          }
        }
      }
    },
    "DeleteLensShare": {
      "http": {
        "method": "DELETE",
        "requestUri": "/lenses/{LensAlias}/shares/{ShareId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareId",
          "LensAlias",
          "ClientRequestToken"
        ],
        "members": {
          "ShareId": {
            "location": "uri",
            "locationName": "ShareId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "ClientRequestToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "ClientRequestToken"
          }
        }
      }
    },
    "DeleteWorkload": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workloads/{WorkloadId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "ClientRequestToken"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "ClientRequestToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "ClientRequestToken"
          }
        }
      }
    },
    "DeleteWorkloadShare": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workloads/{WorkloadId}/shares/{ShareId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareId",
          "WorkloadId",
          "ClientRequestToken"
        ],
        "members": {
          "ShareId": {
            "location": "uri",
            "locationName": "ShareId"
          },
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "ClientRequestToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "ClientRequestToken"
          }
        }
      }
    },
    "DisassociateLenses": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/disassociateLenses"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAliases"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAliases": {
            "shape": "S3"
          }
        }
      }
    },
    "ExportLens": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses/{LensAlias}/export"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "LensVersion": {
            "location": "querystring",
            "locationName": "LensVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensJSON": {}
        }
      }
    },
    "GetAnswer": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/answers/{QuestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias",
          "QuestionId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "QuestionId": {
            "location": "uri",
            "locationName": "QuestionId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensAlias": {},
          "LensArn": {},
          "Answer": {
            "shape": "S1l"
          }
        }
      }
    },
    "GetLens": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses/{LensAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "LensVersion": {
            "location": "querystring",
            "locationName": "LensVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lens": {
            "type": "structure",
            "members": {
              "LensArn": {},
              "LensVersion": {},
              "Name": {},
              "Description": {},
              "Owner": {},
              "ShareInvitationId": {}
            }
          }
        }
      }
    },
    "GetLensReview": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensReview": {
            "shape": "S2h"
          }
        }
      }
    },
    "GetLensReviewReport": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/report"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensReviewReport": {
            "type": "structure",
            "members": {
              "LensAlias": {},
              "LensArn": {},
              "Base64String": {}
            }
          }
        }
      }
    },
    "GetLensVersionDifference": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses/{LensAlias}/versionDifference"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "BaseLensVersion": {
            "location": "querystring",
            "locationName": "BaseLensVersion"
          },
          "TargetLensVersion": {
            "location": "querystring",
            "locationName": "TargetLensVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensAlias": {},
          "LensArn": {},
          "BaseLensVersion": {},
          "TargetLensVersion": {},
          "LatestLensVersion": {},
          "VersionDifferences": {
            "type": "structure",
            "members": {
              "PillarDifferences": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "PillarId": {},
                    "PillarName": {},
                    "DifferenceStatus": {},
                    "QuestionDifferences": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "QuestionId": {},
                          "QuestionTitle": {},
                          "DifferenceStatus": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetMilestone": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/milestones/{MilestoneNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "MilestoneNumber"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "MilestoneNumber": {
            "location": "uri",
            "locationName": "MilestoneNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "Milestone": {
            "type": "structure",
            "members": {
              "MilestoneNumber": {
                "type": "integer"
              },
              "MilestoneName": {},
              "RecordedAt": {
                "type": "timestamp"
              },
              "Workload": {
                "shape": "S35"
              }
            }
          }
        }
      }
    },
    "GetWorkload": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workload": {
            "shape": "S35"
          }
        }
      }
    },
    "ImportLens": {
      "http": {
        "method": "PUT",
        "requestUri": "/importLens"
      },
      "input": {
        "type": "structure",
        "required": [
          "JSONString",
          "ClientRequestToken"
        ],
        "members": {
          "LensAlias": {},
          "JSONString": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensArn": {},
          "Status": {}
        }
      }
    },
    "ListAnswers": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/answers"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "PillarId": {
            "location": "querystring",
            "locationName": "PillarId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensAlias": {},
          "LensArn": {},
          "AnswerSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QuestionId": {},
                "PillarId": {},
                "QuestionTitle": {},
                "Choices": {
                  "shape": "S1r"
                },
                "SelectedChoices": {
                  "shape": "S1z"
                },
                "ChoiceAnswerSummaries": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ChoiceId": {},
                      "Status": {},
                      "Reason": {}
                    }
                  }
                },
                "IsApplicable": {
                  "type": "boolean"
                },
                "Risk": {},
                "Reason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLensReviewImprovements": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/improvements"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "PillarId": {
            "location": "querystring",
            "locationName": "PillarId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensAlias": {},
          "LensArn": {},
          "ImprovementSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QuestionId": {},
                "PillarId": {},
                "QuestionTitle": {},
                "Risk": {},
                "ImprovementPlanUrl": {},
                "ImprovementPlans": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ChoiceId": {},
                      "DisplayText": {},
                      "ImprovementPlanUrl": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLensReviews": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/lensReviews"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "MilestoneNumber": {
            "location": "querystring",
            "locationName": "MilestoneNumber",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneNumber": {
            "type": "integer"
          },
          "LensReviewSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LensAlias": {},
                "LensArn": {},
                "LensVersion": {},
                "LensName": {},
                "LensStatus": {},
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "RiskCounts": {
                  "shape": "S2m"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLensShares": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses/{LensAlias}/shares"
      },
      "input": {
        "type": "structure",
        "required": [
          "LensAlias"
        ],
        "members": {
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "SharedWithPrefix": {
            "location": "querystring",
            "locationName": "SharedWithPrefix"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensShareSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ShareId": {},
                "SharedWith": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLenses": {
      "http": {
        "method": "GET",
        "requestUri": "/lenses"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "LensType": {
            "location": "querystring",
            "locationName": "LensType"
          },
          "LensStatus": {
            "location": "querystring",
            "locationName": "LensStatus"
          },
          "LensName": {
            "location": "querystring",
            "locationName": "LensName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LensSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LensArn": {},
                "LensAlias": {},
                "LensName": {},
                "LensType": {},
                "Description": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "UpdatedAt": {
                  "type": "timestamp"
                },
                "LensVersion": {},
                "Owner": {},
                "LensStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMilestones": {
      "http": {
        "requestUri": "/workloads/{WorkloadId}/milestonesSummaries"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "MilestoneSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MilestoneNumber": {
                  "type": "integer"
                },
                "MilestoneName": {},
                "RecordedAt": {
                  "type": "timestamp"
                },
                "WorkloadSummary": {
                  "shape": "S4c"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNotifications": {
      "http": {
        "requestUri": "/notifications"
      },
      "input": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotificationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "LensUpgradeSummary": {
                  "type": "structure",
                  "members": {
                    "WorkloadId": {},
                    "WorkloadName": {},
                    "LensAlias": {},
                    "LensArn": {},
                    "CurrentLensVersion": {},
                    "LatestLensVersion": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListShareInvitations": {
      "http": {
        "method": "GET",
        "requestUri": "/shareInvitations"
      },
      "input": {
        "type": "structure",
        "members": {
          "WorkloadNamePrefix": {
            "location": "querystring",
            "locationName": "WorkloadNamePrefix"
          },
          "LensNamePrefix": {
            "location": "querystring",
            "locationName": "LensNamePrefix"
          },
          "ShareResourceType": {
            "location": "querystring",
            "locationName": "ShareResourceType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShareInvitationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ShareInvitationId": {},
                "SharedBy": {},
                "SharedWith": {},
                "PermissionType": {},
                "ShareResourceType": {},
                "WorkloadName": {},
                "WorkloadId": {},
                "LensName": {},
                "LensArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{WorkloadArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadArn"
        ],
        "members": {
          "WorkloadArn": {
            "location": "uri",
            "locationName": "WorkloadArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S11"
          }
        }
      }
    },
    "ListWorkloadShares": {
      "http": {
        "method": "GET",
        "requestUri": "/workloads/{WorkloadId}/shares"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "SharedWithPrefix": {
            "location": "querystring",
            "locationName": "SharedWithPrefix"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "WorkloadShareSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ShareId": {},
                "SharedWith": {},
                "PermissionType": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkloads": {
      "http": {
        "requestUri": "/workloadsSummaries"
      },
      "input": {
        "type": "structure",
        "members": {
          "WorkloadNamePrefix": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadSummaries": {
            "type": "list",
            "member": {
              "shape": "S4c"
            }
          },
          "NextToken": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{WorkloadArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadArn",
          "Tags"
        ],
        "members": {
          "WorkloadArn": {
            "location": "uri",
            "locationName": "WorkloadArn"
          },
          "Tags": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{WorkloadArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadArn",
          "TagKeys"
        ],
        "members": {
          "WorkloadArn": {
            "location": "uri",
            "locationName": "WorkloadArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAnswer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/answers/{QuestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias",
          "QuestionId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "QuestionId": {
            "location": "uri",
            "locationName": "QuestionId"
          },
          "SelectedChoices": {
            "shape": "S1z"
          },
          "ChoiceUpdates": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "Status"
              ],
              "members": {
                "Status": {},
                "Reason": {},
                "Notes": {}
              }
            }
          },
          "Notes": {},
          "IsApplicable": {
            "type": "boolean"
          },
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "LensAlias": {},
          "LensArn": {},
          "Answer": {
            "shape": "S1l"
          }
        }
      }
    },
    "UpdateLensReview": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "LensNotes": {},
          "PillarNotes": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "LensReview": {
            "shape": "S2h"
          }
        }
      }
    },
    "UpdateShareInvitation": {
      "http": {
        "method": "PATCH",
        "requestUri": "/shareInvitations/{ShareInvitationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareInvitationId",
          "ShareInvitationAction"
        ],
        "members": {
          "ShareInvitationId": {
            "location": "uri",
            "locationName": "ShareInvitationId"
          },
          "ShareInvitationAction": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShareInvitation": {
            "type": "structure",
            "members": {
              "ShareInvitationId": {},
              "ShareResourceType": {},
              "WorkloadId": {},
              "LensAlias": {},
              "LensArn": {}
            }
          }
        }
      }
    },
    "UpdateWorkload": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "WorkloadName": {},
          "Description": {},
          "Environment": {},
          "AccountIds": {
            "shape": "Sn"
          },
          "AwsRegions": {
            "shape": "Sp"
          },
          "NonAwsRegions": {
            "shape": "Sr"
          },
          "PillarPriorities": {
            "shape": "St"
          },
          "ArchitecturalDesign": {},
          "ReviewOwner": {},
          "IsReviewOwnerUpdateAcknowledged": {
            "type": "boolean"
          },
          "IndustryType": {},
          "Industry": {},
          "Notes": {},
          "ImprovementStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workload": {
            "shape": "S35"
          }
        }
      }
    },
    "UpdateWorkloadShare": {
      "http": {
        "method": "PATCH",
        "requestUri": "/workloads/{WorkloadId}/shares/{ShareId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ShareId",
          "WorkloadId",
          "PermissionType"
        ],
        "members": {
          "ShareId": {
            "location": "uri",
            "locationName": "ShareId"
          },
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "PermissionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkloadId": {},
          "WorkloadShare": {
            "type": "structure",
            "members": {
              "ShareId": {},
              "SharedBy": {},
              "SharedWith": {},
              "PermissionType": {},
              "Status": {},
              "WorkloadName": {},
              "WorkloadId": {}
            }
          }
        }
      }
    },
    "UpgradeLensReview": {
      "http": {
        "method": "PUT",
        "requestUri": "/workloads/{WorkloadId}/lensReviews/{LensAlias}/upgrade"
      },
      "input": {
        "type": "structure",
        "required": [
          "WorkloadId",
          "LensAlias",
          "MilestoneName"
        ],
        "members": {
          "WorkloadId": {
            "location": "uri",
            "locationName": "WorkloadId"
          },
          "LensAlias": {
            "location": "uri",
            "locationName": "LensAlias"
          },
          "MilestoneName": {},
          "ClientRequestToken": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sp": {
      "type": "list",
      "member": {}
    },
    "Sr": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "list",
      "member": {}
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S11": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1l": {
      "type": "structure",
      "members": {
        "QuestionId": {},
        "PillarId": {},
        "QuestionTitle": {},
        "QuestionDescription": {},
        "ImprovementPlanUrl": {},
        "HelpfulResourceUrl": {},
        "HelpfulResourceDisplayText": {},
        "Choices": {
          "shape": "S1r"
        },
        "SelectedChoices": {
          "shape": "S1z"
        },
        "ChoiceAnswers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ChoiceId": {},
              "Status": {},
              "Reason": {},
              "Notes": {}
            }
          }
        },
        "IsApplicable": {
          "type": "boolean"
        },
        "Risk": {},
        "Notes": {},
        "Reason": {}
      }
    },
    "S1r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ChoiceId": {},
          "Title": {},
          "Description": {},
          "HelpfulResource": {
            "shape": "S1w"
          },
          "ImprovementPlan": {
            "shape": "S1w"
          }
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "DisplayText": {},
        "Url": {}
      }
    },
    "S1z": {
      "type": "list",
      "member": {}
    },
    "S2h": {
      "type": "structure",
      "members": {
        "LensAlias": {},
        "LensArn": {},
        "LensVersion": {},
        "LensName": {},
        "LensStatus": {},
        "PillarReviewSummaries": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PillarId": {},
              "PillarName": {},
              "Notes": {},
              "RiskCounts": {
                "shape": "S2m"
              }
            }
          }
        },
        "UpdatedAt": {
          "type": "timestamp"
        },
        "Notes": {},
        "RiskCounts": {
          "shape": "S2m"
        },
        "NextToken": {}
      }
    },
    "S2m": {
      "type": "map",
      "key": {},
      "value": {
        "type": "integer"
      }
    },
    "S35": {
      "type": "structure",
      "members": {
        "WorkloadId": {},
        "WorkloadArn": {},
        "WorkloadName": {},
        "Description": {},
        "Environment": {},
        "UpdatedAt": {
          "type": "timestamp"
        },
        "AccountIds": {
          "shape": "Sn"
        },
        "AwsRegions": {
          "shape": "Sp"
        },
        "NonAwsRegions": {
          "shape": "Sr"
        },
        "ArchitecturalDesign": {},
        "ReviewOwner": {},
        "ReviewRestrictionDate": {
          "type": "timestamp"
        },
        "IsReviewOwnerUpdateAcknowledged": {
          "type": "boolean"
        },
        "IndustryType": {},
        "Industry": {},
        "Notes": {},
        "ImprovementStatus": {},
        "RiskCounts": {
          "shape": "S2m"
        },
        "PillarPriorities": {
          "shape": "St"
        },
        "Lenses": {
          "shape": "Sz"
        },
        "Owner": {},
        "ShareInvitationId": {},
        "Tags": {
          "shape": "S11"
        }
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "WorkloadId": {},
        "WorkloadArn": {},
        "WorkloadName": {},
        "Owner": {},
        "UpdatedAt": {
          "type": "timestamp"
        },
        "Lenses": {
          "shape": "Sz"
        },
        "RiskCounts": {
          "shape": "S2m"
        },
        "ImprovementStatus": {}
      }
    }
  }
}